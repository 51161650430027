import { Button } from '@mui/material'
import React from 'react'
import { HiOutlinePlus } from 'react-icons/hi'
import { Link } from 'react-router'
import { Tooltip as ReactTooltip } from "react-tooltip";
import intl from 'components/i18n/ReactIntlWrapper';

export default function PlusButton({link, tooltip}) {
  return (
    <>
        <Link to={link}>
            <Button 
                variant="contained" 
                color="primary" 
                id='newInvoice' 
                data-tooltip-id='tooltipButton'
                >   
                <HiOutlinePlus className='plusIcon '/>
            </Button>
        </Link>
        <ReactTooltip
            id='tooltipButton'
            place='left'
            content={intl.get(tooltip)}
        />
    </>
  )
}

import { Box, Button, ButtonGroup, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BiChevronLeft, BiChevronRight, BiChevronsLeft, BiChevronsRight } from 'react-icons/bi';
import 'scss/Paginator.scss';

type PaginatorProps = {
    currentPage?: number;
    totalRecords: number;
    onPageChange: (page: number) => void;
    pageSize?:number
    onPageSizeChange: (pageSize: number) => void;
}

const TOTAL_PAGES_BUTTON = 5;

const Paginator = ({ currentPage = 1, totalRecords, onPageChange, pageSize = 10, onPageSizeChange }: PaginatorProps) => {
    const [pageSizeState, setPageSizeState] = useState<number>(pageSize);
    const [totalPages, setTotalPages] = useState<number>(Math.ceil(totalRecords / pageSize));

    useEffect(() => {
        setTotalPages(Math.ceil(totalRecords / pageSizeState));
    }, [totalRecords, pageSizeState]);

    const handlePageSizeChange = (page: number) => {
        setPageSizeState(page);
        onPageSizeChange(page);
        onPageChange(1);
    }

    const getVisiblePages = (currentPage) => {
        const half = Math.floor(TOTAL_PAGES_BUTTON / 2);
        let start = Math.max(1, currentPage - half);
        let end = Math.min(totalPages, start + TOTAL_PAGES_BUTTON - 1);
        if (end - start + 1 < TOTAL_PAGES_BUTTON) {
            start = Math.max(1, end - TOTAL_PAGES_BUTTON + 1);
        }
        return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    };

    return (<Box className="paginator" display='grid' gridTemplateColumns='1fr auto' alignItems='center' margin={2}>
        <Box display='flex' justifyContent='center'>
            <ButtonGroup className='paginator'>
                <Button onClick={() => onPageChange(1)} disabled={currentPage === 1}>
                    <BiChevronsLeft/>
                </Button>
                <Button onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1}>
                    <BiChevronLeft/>
                </Button>
                {getVisiblePages(currentPage).map((page) => (
                    <Button key={page} onClick={() => onPageChange(page)} className={page === currentPage ? 'active text-white fw-bold' : ''}>
                        {page}
                    </Button>
                ))}
                <Button onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                    <BiChevronRight/>
                </Button>
                <Button onClick={() => onPageChange(totalPages)} disabled={currentPage === totalPages}>
                    <BiChevronsRight/>
                </Button>
            </ButtonGroup>
        </Box>
        <Select value={pageSizeState} onChange={(e) => handlePageSizeChange(e.target.value as number)} sx={{width: 80}}>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
        </Select>
    </Box>);
}

export default Paginator;
import { format, parseISO } from "date-fns";
import intl from 'components/i18n/ReactIntlWrapper';
import { DateRange } from "./constants";

export const toHoursAndMinutes = (totalMinutes: number) => {
    if (totalMinutes === undefined)
        return '';
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return ('00' + hours).slice(-2) + ":" + ('00' + minutes).slice(-2);
}
export const toMinutes = (hoursAndMinutes: String) => {
    const splited = hoursAndMinutes.split(":");
    return (splited.length == 2 ? parseInt(splited[0]) * 60 + parseInt(splited[1]) : null);
}

export const previousMonday = (date: Date): Date => {
    return  new Date(date.setDate(date.getDate() - date.getDay() + 1));
}


export const 
formatTime = (target): string => {
    var value = target.value;
    const timePattern = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
    if (timePattern.test(value))
        return value;
   /* if (value.length > 2) {
        value = ('0' + value).slice(-4);
    }
    else {
        value = ('0' + value + '00').slice(-4);
    }*/
    value = value.replaceAll('_','');
    let mm = parseInt(value.substr(2, 2));
    let hh = parseInt(value.slice(0, 2));
    if (isNaN(mm)) mm = 0;
    if (isNaN(hh)) hh = 0;
    if (mm > 59) {
        mm = mm - 60;
    }
    else 

    if (hh > 23) {
        hh = hh % 24;
    }
    
    let formate = ('0' + hh).slice(-2) + ':' + ('0' + mm).slice(-2);
    value = formate;
    return formate;
    // handleInputChange({ target: { name: 'duration', value: formate } });
}
export const unformatTime = (value) => {
    if (value) {
        const unformate = value.replace(":", "");
        return unformate;
    }
    return '';

    // handleInputChange({ target: { name: 'duration', value: value.replace(":", "") } });
}
export const formatUTCDate = (date: Date): string => {
    if (typeof date.getUTCFullYear === 'function') {
        const year = date.getUTCFullYear()
        const month = date.getUTCMonth() + 1 // Date provides month index; not month number
        const day = date.getUTCDate()
        return `${year}-${padToTwo(month)}-${padToTwo(day)}`;
    }
    return date as unknown as string;
}

export const getCurrentDateFormat=(): string=>{
    return 'P';
}

export const formatDate = (date: Date): string => {
    return format(date, getCurrentDateFormat());
}

export const formatDateRange = (date: DateRange): string => {
    //const splittedTo =  moment(date.to).format('L').split(/\//);
    const splittedTo =  format(date.to, getCurrentDateFormat()).split(/\//);
    const to = splittedTo[1] + '/' + splittedTo[0] + '/' + splittedTo[2];
    //const splittedFrom =  moment(date.from).format('L').split(/\//);
    const splittedFrom =  format(date.from, getCurrentDateFormat()).split(/\//);
    const from = splittedFrom[1] + '/' + splittedFrom[0] + '/' + splittedFrom[2];

    return `${from} - ${to}`;
}

export const formatDateForAPI = (date: Date): string => {
    // console.log('formatDate', date, 'tz:', date.getTimezoneOffset())
     if (typeof date.getFullYear === 'function') {
         const year = date.getFullYear()
         const month = date.getMonth() + 1 // Date provides month index; not month number
         const day = date.getDate()
         return `${year}-${padToTwo(month)}-${padToTwo(day)}`;
     }
     return date as unknown as string;
 
 }


export const newDateAsUTC = () => {
    return new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0));
}

export const convertDateAsUTC = (date:string) => {
    const dt = parseISO(date);
    return new Date(Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate(), 0, 0, 0));
}

export const convertDateAsUTC2 = (date:Date) => {
    const dt = date;
    return new Date(Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate(), 0, 0, 0));
}


function padToTwo(number): string {
    return number > 9 ? number : '0' + number
}

export const uniformizeDate = (date): Date => {
    if (typeof date.getTimezoneOffset === 'function')
        return new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000);
    else {
        if (date !== '') {
            const _date = new Date(date);
            return new Date(_date.valueOf() + _date.getTimezoneOffset() * 60 * 1000);
        }
        return newDateAsUTC();
    }
}

export const sortCollection = <T>(array: T[], property: string, order: number): T[] => {
    return array?.sort((a, b) => {
        if (!a[property] || !b[property]) return 0;
        return a[property] >= b[property] ? 1 * order : -1 * order;
    });
}

export const useToastHelper = (toast) => {
  return (response, successKey, errorKey) => {
    let status = response.status || 500; // No status on a network error.
    let data = response.data || {};
    if (data && "statusCode" in data) {
      // Wrapped response.
      status = response.data.statusCode;
      data = response.data.response;
    }
    if (data.messageKey) {
      successKey = data.messageKey;
      errorKey = data.messageKey;
    }
    if (status >= 200 && status < 300) {
      toast.success({ body: intl.get(successKey) });
    } else {
      toast.error({ body: intl.get(errorKey) });
    }
    if (data.errors && Array.isArray(data.errors)) {
      data.errors.forEach((e) => {
        if (e.messageKey) {
          // We don't fall back to errorKey, that could cause mixed messages such as:
          // [Client created] [There was an error creating client]
          toast.warning({ body: intl.get(e.messageKey) });
        }
      });
    }
  };
};

export const formatDateTimeOff = (dateString) => {
    const fechaDate = new Date(dateString);
    const day = String(fechaDate.getDate()).padStart(2, '0');
    const month = String(fechaDate.getMonth() + 1).padStart(2, '0');
    const year = fechaDate.getFullYear();
    return `${day}/${month}/${year}`;
};

export const formatDateInvoices = (dateString) => {
    const date = new Date(dateString);
    return format(date, 'dd MMM yyyy');
};

export const formatDateFilterInvoices = (dateString) => {
    const fechaDate = new Date(dateString);
    const day = String(fechaDate.getDate()).padStart(2, '0');
    const month = String(fechaDate.getMonth() + 1).padStart(2, '0');
    const year = fechaDate.getFullYear();
    return `${year}-${month}-${day}`;
};

import React from 'react';
import { HiOutlinePencilAlt } from 'react-icons/hi';
import { useNavigate } from 'react-router';
import intl from 'components/i18n/ReactIntlWrapper';

const UserRow = ({ id, email, className }) => {

    const navigate = useNavigate();

    const handleClickRow = () => {
        navigate('/user/detail/' + id);
    };

    return (
        <>
            {
                email ?
                <tr className={className}>
                    <td>{email}</td>
                    <td className='text-center'>
                        <a className='td-icon mx-1' onClick={handleClickRow} title={intl.get('roleRow.editButton.title')}>
                            <HiOutlinePencilAlt/>
                        </a>
                    </td>
                </tr> :
                null
            }
        </>
    )   
}

export default UserRow;
import React, { useMemo } from "react";
import "../../scss/Time Off/FileUploader.scss";
import intl from 'components/i18n/ReactIntlWrapper';
import PdfViewer from "../common/PdfRenderer";
import { useDropzone } from "react-dropzone";
import { Grid, Button, Typography } from "@mui/material";
import { useNewInvoice } from './newInvoiceProvider';

const NewInvoiceDropZone = ({ errors, setErrors, files, disabled = false }) => {
  const { setInput, isManualCreation } = useNewInvoice();
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, open } =
  useDropzone({
    accept: { "application/pdf": [] },
    onDrop: (file) => {
      if (file?.[0]?.type === "application/pdf") {
        setInput((prevInput) => ({
          ...prevInput,
          attachments: prevInput.attachments ? [...prevInput.attachments, file?.[0]] : [file?.[0]]
        }));
      }
    },
    maxFiles: 1,
    noClick: true,
    disabled: disabled
  });

  const handleDeletePDF = function(){
    setInput((prevInput) => ({
        ...prevInput,
        attachments: ""
      }));
  }

  const className = useMemo(() => {
    let classNames = "base-style";
    if (files?.[0]) classNames += " attachmentRow";
    if (isFocused) classNames += " focused-style";
    if (isDragAccept) classNames += " accept-style";
    if (isDragReject) classNames += " reject-style";
    return classNames;
  }, [isFocused, isDragAccept, isDragReject, files]);

  return (
    <div style={{display: isManualCreation ? "none" : ""}}>
      <Typography variant="body2" color={errors?.attachments ? "error" : "inherit"} style={{marginTop: errors?.attachments ? "-30px" : "0px"}}>
          {errors?.attachments && (<span>{errors?.attachments}</span>)}
      </Typography>
    {!files?.[0] ?
      <div {...getRootProps({ className })} style={{minHeight: "600px", marginRight:"20px"}}>
        <input {...getInputProps()} />
          <Grid container justifyContent="center">
            <Grid item xs={12} md={7} lg={7} xl={7}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={open}
              >
                {intl.get('newInvoice.uploadInvoice')}
              </Button>
              <Typography variant="body2" style={{marginTop: "10px"}} className="dropZoneText" align="center">
                {intl.get('newInvoice.addInvoiceText')}
              </Typography>
            </Grid>
          </Grid>
      </div>
      :
        <div>
            <Grid container justifyContent="center" style={{paddingRight: "10px"}}>
                <PdfViewer file={files?.[0]} />
            </Grid>
            <Grid container justifyContent="flex-end">
                <Typography variant="body2" style={{textDecoration: "underline", cursor: "pointer"}} onClick={handleDeletePDF}>
                -{intl.get('newInvoice.removeInvoice')}
                </Typography>
            </Grid>
        </div>
        }

    </div>
  );
};

export default NewInvoiceDropZone;

import React, { useState } from "react";
import intl from 'components/i18n/ReactIntlWrapper';
import Spinner from "./Spinner";
import {Link} from "react-router";
import { useApiData } from "api/ApiProvider";
/**
 * Renders a button which, when selected, will open a popup for login
 */
const SignInButton: React.FunctionComponent<{}> = () =>  {

        const {loginUrl} = useApiData();
        const [loading, setLoading] = useState(false);

        const onClick = () => {
                setLoading(true);
        }

        return (<>
                {loading || loginUrl == null && <Spinner />}
                <Link className="btn btn-primary btn-lg" to={loginUrl} onClick={onClick}>
                        {intl.get('singInButton')}
                </Link>
        </>)
}

export default SignInButton;
import React, { useState, useEffect } from 'react';
import ProjectsNavbar from './ProjectsNavbar';
import Spinner from '../Spinner';
import ProjectsList from './ProjectsList';
import Filters from '../../fixture/filtersProject';
import ProjectPagination from '../Project/ProjectPagination';
import { useApi } from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import 'scss/Project/Projects.scss';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import {PermissionsGate } from '../PermissionsGate';
import { ROLES, SCOPES } from 'common/permissions';
import intl from 'components/i18n/ReactIntlWrapper';
import ItemsSelect from '../ItemsSelect';

export type ProjectsInput = {
	queryTerm?: string | null,
	sortValue?: string | null,
	orderValue?: string | null,
	filterValue?: null,
	currentPage?: number,
	clicked?: boolean
};

export type SearchResult = {
	totalRecords: number | null,
	projects: any[]
};

const Projects = () => {

	const [input, setInput] = useState<ProjectsInput | null>({
		queryTerm: "",
		sortValue: "Name",
		orderValue: "asc",
		filterValue: null,
		currentPage: 1,
		clicked: false
	});

	const api: Api = useApi();

	const [searchResult, setSearchResult] = useState<SearchResult | null>({
		totalRecords: null,
		projects: []
	});

	const [loading, setLoading] = useState<boolean>(false);

	const [pageCount, setpageCount] = useState<number>(0);

	const [limit, setLimit] = useState<number>(() => {
		return parseInt(localStorage.getItem('itemsPerPageProjects'), 10) || 10;
	});

	useEffect(() => {
		setLimit(parseInt(localStorage.getItem('itemsPerPageProjects'), 10) || 10);
	}, []);

	const sortOptions = [
		{ order: "asc", sort: "Name", description: "Name Ascending" },
		{ order: "desc", sort: "Name", description: "Name Descending" },
		{ order: "asc", sort: "Client", description: "Client Ascending" },
		{ order: "desc", sort: "Client", description: "Client Descending" }
	];

	const mapFilter = new Map();

	// mapFilter = {
	// 	"status": {"parentRef": parentRef, "childrenRef": mapChildren},
	// 	"category": {"parentRef": parentRef, "childrenRef": mapChildren}
	// }  

	for (let i = 0; i < Filters.filters.length; i++) {
		const parentRef = React.createRef();
		const mapChildren = new Map();
		for (let j = 0; j < Filters.filters[i].filtersItems.length; j++) {
			const childrenRef = React.createRef();
			mapChildren.set(Filters.filters[i].filtersItems[j].value, childrenRef);
		};
		let valor = { parentRef: parentRef, childrenRef: mapChildren };
		mapFilter.set(Filters.filters[i].value, valor);
	};

    const toast = useToastMessageQueue();

	const fetchProjects = async (term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>) => {
		setLoading(true);
		const response = await api.projectApi.apiVversionProjectSearchGet("1", input.queryTerm, input.currentPage, limit, input.sortValue, input.orderValue, f, {}).then((response) => {
			if (response.data.data === null) {
				setSearchResult({
					totalRecords: null,
					projects: null
				});
			} else {
				setSearchResult({
					totalRecords: response.data?.data?.totalRecords,
					projects: response.data?.data?.queryResult
				});
			};
			setLoading(false);
			console.log("Success");
		}).catch((error) => {
			if (error.response) {
				console.log("Data :", error.response.data);
				console.log("Status :" + error.response.status);
			} else if (error.request) {
				console.log(error.request);
			} else {
				console.log("Error", error.message);
			}
            toast.error({ header: intl.get('projects.toast.error.fetchProjects'), body: error.message });
			setLoading(false);
		});
	};

	useEffect(() => {
		fetchProjects();
	}, [input, limit]);

	const onSearch = (queryString) => {
		setInput({ ...input, queryTerm: queryString, currentPage: 1 });
	};

	const handlePageClick = pageNum => setInput({ ...input, currentPage: pageNum });

	const nextPage = () => setInput({ ...input, currentPage: input.currentPage + 1 });

	const prevPage = () => setInput({ ...input, currentPage: input.currentPage - 1 });

	const initialPage = () => setInput({ ...input, currentPage: 1 });

	const finalPage = () => setInput({ ...input, currentPage: pageCount });

	const handleSort = async (e) => {
		let value = e.target.value;
		let selectedValue = JSON.parse(value);
		let sort = selectedValue.sort;
		let order = selectedValue.order;
		setInput({ ...input, orderValue: order, sortValue: sort, currentPage: 1 });
	};

	const handleFilter = async (e) => {
		let checked = e.target.checked;
		let value = e.target.value;
		let parentValue = e.target.getAttribute("parent");
		let newFilterValue = null;
		if (parentValue === null) {
			let childrenMap = mapFilter.get(value).childrenRef;
			let values: any = Array.from(childrenMap.values());
			if (input.filterValue === null) {
				newFilterValue = { filters: [] };
			} else {
				newFilterValue = input.filterValue;
			}
			if (checked) {
				let index = newFilterValue.filters.findIndex(filter => filter.name === value);
				let keys = Array.from(childrenMap.keys());
				if (index >= 0) {
					let filtersUnselected = [];
					for (let i = 0; i < keys.length; i++) {
						let filterKey = newFilterValue.filters[index].selected.includes(keys[i]);
						if (!filterKey) {
							filtersUnselected.push(keys[i]);
						}
					}
					for (let i = 0; i < filtersUnselected.length; i++) {
						childrenMap.get(filtersUnselected[i]).current.checked = true;
						newFilterValue.filters[index].selected.push(filtersUnselected[i]);
					}
				} else {
					keys.forEach(key => {
						childrenMap.get(key).current.checked = true;
					})
					newFilterValue.filters.push({ name: value, selected: keys });
				}
			} else {
				newFilterValue = input.filterValue;
				values.forEach(element => {
					element.current.checked = false;
				});
				let indexFilter = newFilterValue.filters.findIndex(filter => filter.name === value);
				newFilterValue.filters[indexFilter].selected = [];
			}
			setInput({ ...input, filterValue: newFilterValue, currentPage: 1 });
		} else {
			if (input.filterValue === null) {
				newFilterValue = { filters: [] };
			} else {
				newFilterValue = input.filterValue;
			}
			if (checked) {
				let index = newFilterValue.filters.findIndex(filter => filter.name === parentValue);
				if (index >= 0) {
					newFilterValue.filters[index].selected.push(value);
					let filterItem = Filters.filters.find(filter => filter.value === parentValue);
					if (newFilterValue.filters[index].selected.length === filterItem.filtersItems.length) {
						let parentRefValue = mapFilter.get(parentValue).parentRef;
						parentRefValue.current.checked = true;
					}
				} else {
					newFilterValue.filters.push({ name: parentValue, selected: [value] });
				}
			} else {
				let parentRefValue = mapFilter.get(parentValue).parentRef;
				parentRefValue.current.checked = false;
				newFilterValue = input.filterValue;
				let indexParent = newFilterValue.filters.findIndex(filter => filter.name === parentValue);
				let indexValue = newFilterValue.filters[indexParent].selected.findIndex(filter => filter === value);
				let selected = newFilterValue.filters[indexParent].selected;
				selected.splice(indexValue, 1);
				newFilterValue.filters[indexParent].selected = selected;
			}
			setInput({ ...input, filterValue: newFilterValue, currentPage: 1 });
		};
	};

	const handleClickButtonFilter = () => {
		setInput({ ...input, clicked: !input.clicked, currentPage: 1 });
	};

	return (
		<div className="container">
			<div className="card projects mt-4">
				<div className="card-header">
					<h2 className="title">{intl.get('projects.header')}</h2>
				</div>
				<div className="container card-body">
					<PermissionsGate viewScopes={[SCOPES['projects.read']]} editScopes={[SCOPES['projects.edit']]} viewRoles={[ROLES.projectReader]} editRoles={[ROLES.projectWriter]} RenderError={()=>{return <span>{intl.get('permissionsGate')}</span>}}>
						<div className='row'>
							<div className='' id={`${input.clicked ? 'column-2' : ''}`} >
								<ProjectsNavbar onSearch={onSearch} handleSort={handleSort} input={input} sortOptions={sortOptions} />
								{
									loading === true ?
									<Spinner /> :
									<>
										<ProjectsList searchResult={searchResult} input={input} setInput={setInput} />
										<div className="pagination row w-100">
											<div className="col-10 col-xl-11 d-flex justify-content-center">
												<ProjectPagination 
													handlePageClick={handlePageClick} 
													limit={limit} 
													searchResult={searchResult} 
													nextPage={nextPage} 
													prevPage={prevPage} 
													input={input} 
													pageCount={pageCount} 
													setpageCount={setpageCount} 
													initialPage={initialPage} 
													finalPage={finalPage}
												/>
											</div>
											<div className="col-2 col-xl-1 d-flex justify-content-end">
												<ItemsSelect
													input={input}
													setInput={setInput}
													limit={limit}
													setLimit={setLimit}
													storageKey="itemsPerPageProjects"
												/>
											</div>
										</div>
									</>
								}
							</div>
						</div>
					</PermissionsGate>
				</div>
			</div>
		</div>
	);
}

export default Projects;
import React from "react";
import Address from "./Address";
import Modal, { ModalSizes } from "./Modal";

const ModalAddress = ({ addressId, setAddresses, addresses, addressesId = null, setAddressesId = null, isOpen, closeModal, inputProvider = null, setInputProvider = null, realAddress = null, legalAddress = null, input = null, setInput = null }) => {

    return (
        <Modal isOpen={isOpen} closeModal={closeModal} size={ModalSizes.small} height="auto">
            <Address 
                closeModal={closeModal} 
                addressId={addressId} 
                addresses={addresses} 
                setAddresses={setAddresses} 
                addressesId={addressesId} 
                setAddressesId={setAddressesId} 
                inputProvider={inputProvider} 
                setInputProvider={setInputProvider}
                realAddress={realAddress}
                legalAddress={legalAddress}
                inputCorporate={input}
                setInputCorporate={setInput}
            />
        </Modal>
    )
}

export default ModalAddress;
import React, { createContext, useContext, useEffect, useState } from 'react';
import Api, { Settings } from '../axiosApi/api';
import ErrorTemplateFoundPage from 'components/common/ErrorTemplatePage';
const ApiContext = createContext<Api | null>(null);
const ApiDataContext = createContext<{loginUrl: string, redirectUrl: string}>({loginUrl: '', redirectUrl: ''});

const SETTINGS_ENDPOINT = "https://3ksxno08b0.execute-api.us-east-1.amazonaws.com/prod/tenant-fe-config";

interface ImportMeta {
  env: {
    PROD: boolean;
    VITE_LOGIN_DOMAIN: string;
    VITE_RESPONSE_TYPE: string;
    VITE_CLIENT_ID: string;
    VITE_REDIRECT_URL: string;
  };
}

export default function ApiProvider({ children }) {
  const [api, setApi] = useState<Api|null>(null);
  const [loginUrl, setLoginUrl] = useState<string|null>(null);
  const [redirectUrl, setRedirectUrl] = useState<string|null>(null);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await fetch(SETTINGS_ENDPOINT);
        const remoteSettings: Settings = await response.json();
        const settings: Settings = {
          ...remoteSettings,
          LOGIN_DOMAIN: import.meta.env.PROD? remoteSettings.LOGIN_DOMAIN : import.meta.env.VITE_LOGIN_DOMAIN,
          CLIENT_ID: import.meta.env.PROD? remoteSettings.CLIENT_ID : import.meta.env.VITE_CLIENT_ID,
          REDIRECT_URL: import.meta.env.PROD? remoteSettings.REDIRECT_URL : import.meta.env.VITE_REDIRECT_URL,
          RESPONSE_TYPE: import.meta.env.PROD? remoteSettings.RESPONSE_TYPE : import.meta.env.VITE_RESPONSE_TYPE,
        };
        
        const querySearchParams = new URLSearchParams({
          response_type: settings.RESPONSE_TYPE,
          client_id: settings.CLIENT_ID,
          redirect_uri: settings.REDIRECT_URL,
          state: 'STATE',
          scope: 'openid email'
        }).toString();
        
        const url = new URL('/login', settings.LOGIN_DOMAIN);
        url.search = querySearchParams;
        setLoginUrl(url.toString());
        setRedirectUrl(settings.REDIRECT_URL);
        setApi(new Api(settings));
      } catch (e) {
        setError(true);
      }
    };
    fetchSettings();
  }, []);
  

  if(error) {
    return <ErrorTemplateFoundPage statusCode={500} title="Error" message='Failed to load settings...' />;
  }
  return (api &&
    <ApiContext.Provider value={api}>
      <ApiDataContext.Provider value={{loginUrl, redirectUrl}}>
        {children}
      </ApiDataContext.Provider>
    </ApiContext.Provider>
  );
}

export function useApi() {
  return useContext(ApiContext);
}

export function useApiData() {
  return useContext(ApiDataContext);
}
import React, { FC } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import intl from 'components/i18n/ReactIntlWrapper';
import { sortCollection } from '../../common/utils';
import { ENTITY_TYPE, SORT_TYPES } from '../../common/constants';

type EntitieselectProps = {
  handleEntityChange: (event: any, value: any) => void,
  selectedEntity: any,
  selectedEntityType: any,
  entities: any,
  disabled: boolean,
  input: any,
  idNumber: any
};

const Entitieselect: FC<EntitieselectProps> = ({ entities, selectedEntity, handleEntityChange, disabled, input, idNumber }) => {
  let idEntities = input?.idEntities;
  let filteredEntities = idNumber ? entities?.filter(entity => !idEntities?.includes(entity.id)) : entities;
  const entitiesSortedByName = sortCollection(filteredEntities, "label", SORT_TYPES.ASC);

  return (
    <Autocomplete
      id="entities"
      options={entitiesSortedByName || []}
      getOptionLabel={(option:any) => `${option.label} (${intl.get("entitytype." + ENTITY_TYPE[option.typeId].toLowerCase())})`}
      value={
        idNumber 
          ? input?.entities?.find((entity) => entity.id === selectedEntity) || null
          : entities?.find((entity) => entity.id === selectedEntity) || null
      }
      onChange={handleEntityChange}
      disabled={disabled}
      isOptionEqualToValue={(option:any, value:any) => option.id === value.id}
      renderInput={(params) => (
        <TextField
          {...params}
          label={intl.get('entitieselect.placeholder.entity')}
          variant="outlined"
          fullWidth
        />
      )}
      clearOnEscape
    />
  );
}

export default Entitieselect;
import React, { useState, useEffect, FC, ChangeEvent, Dispatch, SetStateAction } from 'react';
import { useApi } from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import { HiOutlineSave } from 'react-icons/hi';
import RequiredField from '../RequiredField';
import intl from 'components/i18n/ReactIntlWrapper';
import { Row, Col, Input } from 'reactstrap';
import Spinner from '../Spinner';
import { handleAPIError } from '../../common/errorHandler';
import { Persona } from 'axiosApi/models/persona';
import { CreatePersonaCommand } from 'axiosApi/models/create-persona-command';
import { UpdatePersonaCommand } from 'axiosApi/models/update-persona-command';
import PersonaForm from './PersonaForm';
import { CorporateEntity } from 'axiosApi/models/corporate-entity';
import { ListOptionDTO, PersonaDTO } from 'axiosApi/models';

type PersonaProps = {
    persona: PersonaDTO | null,
    closeModal: any,
    inputProvider: any | null,
    setInputProvider: Function | null,
    personaExists: boolean,
    entityId : number;
    idEntityType : number;
    setPersonaExist: Dispatch<SetStateAction<boolean>>;
    corporateEntities : CorporateEntity[];
    personaTypeExist: number;
    personaTypesIds?: number[];
    inputCorporateEntity?: any;
    setInputCorporateEntity?: (value: any) => void;
    showCorporateEntities?: boolean;
};

export type PersonaTypeOption = { id: number; label: string };

const PersonaFC : FC<PersonaProps> = (props) => {

    const {persona = null, personaExists ,setPersonaExist,closeModal, inputProvider, 
    idEntityType, entityId,setInputProvider, corporateEntities, personaTypeExist = 0, personaTypesIds = [], 
    inputCorporateEntity = null, setInputCorporateEntity = null, showCorporateEntities = true} = props; 

    const defaultPersonaInput = {
        name: persona?.name || '',
        taxID: persona?.taxID || null,
        personaType: persona?.personaType || '',
        idPersonaType: persona?.idPersonaType || null,
        idEntity: persona?.idEntity  || entityId,
        idEntityType: persona?.idEntityType || idEntityType,
        corporateEntitiesIds: persona?.corporateEntities?.map(c => c.id) || []
    };

    const [input, setInput] = useState(defaultPersonaInput);

    const api: Api = useApi();

    const [errors, setErrors] = useState({});

    const [personaTypes, setPersonaTypes] = useState<ListOptionDTO[]>([]);

    const [loading, setLoading] = useState<boolean>(false);

    const toast = useToastMessageQueue();

    const fetchPersonaTypeList = async () => {
        setLoading(true);
        await api.invoiceApi.apiVversionListPersonaTypeGet("1").then((response) => {
            if ('queryResult' in response.data.data ) {
                setPersonaTypes(response.data.data.queryResult);
            }
            setLoading(false);
        }).catch((error) => {
            handleAPIError(error, toast, errors);
            setErrors({...errors});
            setLoading(false);
        });
    }

    useEffect(() => {
        fetchPersonaTypeList();
    }, []);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        });
        setErrors(validate({
            ...input,
            [e.target.name]: e.target.value
        }));
    };

    const handlePersonaNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        const inputVal = {
            ...input,
            name: e.target.value,
        };
        setInput({ ...inputVal });
        setErrors(validate({
            ...input,
            name: e.target.value
        }));
    };

    const handlePersonaTypeChange = (e: PersonaTypeOption) => {
        const inputVal = {
            ...input,
            idPersonaType: e.id,
            personaType: e.label
        };
        setInput({ ...inputVal });
        setErrors(validate({
            ...input,
            idPersonaType: e.id,
            personaType: e.label
        }));
    };

    const handleTaxIdChange = (e: ChangeEvent<HTMLInputElement>) => {
        const inputVal = {
            ...input,
            taxID: e.target.value,
        };
        setInput({ ...inputVal });
        setErrors(validate({
            ...input,
            taxID: e.target.value
        }));
    };

    const handleCorporateEntityChange = (e: ChangeEvent<HTMLInputElement>, id: number) => {    
        const { checked, value } = e.target;
        let updatedCorporateEntitiesIds = [...input.corporateEntitiesIds];
        if (checked) {
            if (!updatedCorporateEntitiesIds.includes(parseInt(value))) {
                updatedCorporateEntitiesIds.push(parseInt(value));
            }
        } else {
            updatedCorporateEntitiesIds = updatedCorporateEntitiesIds.filter((entity) => entity !== parseInt(value));
        }      
        const updatedInput = {
            ...input,
            corporateEntitiesIds: updatedCorporateEntitiesIds,
        };
        setInput(updatedInput);
        setErrors(validate(updatedInput));
    }

    const handleSave = async (e) => { 
        setLoading(true);
        e.preventDefault();
        const errors = validate(input);
        if (JSON.stringify(errors) === JSON.stringify({})) {
            if (persona && persona.id) {
                let cmd: UpdatePersonaCommand = {
                    id: persona.id,
                    //TODO CC : commented out as it is not used in the API
                    //idPersonaType: input?.idPersonaType,
                    //idEntity: input?.idEntity,
                    //idEntityType: input?.idEntityType,
                    name: input?.name,
                    taxID: input?.taxID,
                    corporateEntityIds: input.corporateEntitiesIds
                }
                const response = await api.invoiceApi.apiVversionPersonaPut("1", cmd, {}).then((response) => {
                    let editedPersona = response?.data?.data['personaDTO'];
                    if (inputCorporateEntity) {
                        setInputCorporateEntity({...inputCorporateEntity, personas: [editedPersona.id]});
                    }
                    toast.success({ body: intl.get('persona.toast.success.handleSave')});
                    setLoading(false);
                    if (inputProvider !== null) { 
                        const inputVal = {
                            ...cmd,
                            id: editedPersona.id,
                            name: {name: input.name},
                            taxID: {name: input.taxID}
                        };                    
                        setInputProvider({
                            ...inputProvider, 
                            persona: inputVal
                        });
                    }
                    setPersonaExist(!personaExists)
                    closeModal();
                }).catch((error) => {
                    handleAPIError(error, toast, errors);
                    setErrors({...errors});
                    setPersonaExist(true);
                    setLoading(false);
                });
            } else {
                setPersonaExist(true);
                let cmd: CreatePersonaCommand = {
                    idPersonaType: input?.idPersonaType,
                    idEntity: input?.idEntity,
                    idEntityType: input?.idEntityType,
                    name: input?.name,
                    taxID: input?.taxID,
                    corporateEntityIds: input.corporateEntitiesIds
                }
                const response = await api.invoiceApi.apiVversionPersonaPost("1", cmd, {}).then((response) => {
                    let newPersona = response?.data?.data['newPersonaDTO'];
                    if (inputCorporateEntity) {
                        setInputCorporateEntity({...inputCorporateEntity, personas: [newPersona.id]});
                    }
                    toast.success({ body: intl.get('persona.toast.success.handleSave.add')});
                    setLoading(false);
                    setInput(defaultPersonaInput);
                    if (inputProvider !== null) { 
                        const inputVal = {
                            ...cmd,
                            id: newPersona.id,
                            name: {name: input.name},
                            taxID: {name: input.taxID}
                        };                    
                        setInputProvider({
                            ...inputProvider, 
                            persona: inputVal
                        });
                    }
                    setPersonaExist(false)
                    closeModal();
                }).catch((error) => {
                    handleAPIError(error, toast, errors);
                    setErrors({...errors});
                    setLoading(false);
                });
            };
        }
    };

    const handleCancel = () => {
        setErrors({});
        setInput(defaultPersonaInput)
        closeModal();
    };

    const validate = (input) => {
        let errors: any = {};
        if (!input.name) {
            errors.personaName = intl.get('validate.errors.isRequired');
        }
        if (!input.taxID) {
            errors.taxID = intl.get('validate.errors.isRequired');
        }
        if (!input.personaType) {
            errors.personaType = intl.get('validate.errors.isRequired');
        }
        if (!input.idPersonaType) {
            errors.idPersonaType = intl.get('validate.errors.isRequired');
        }
        if (input.corporateEntitiesIds.length == 0 && showCorporateEntities) {
            errors.corporateEntitiesIds = intl.get('validate.errors.isRequired');
        }
        setErrors({ ...errors });
        return Object.keys(errors).length > 0 ? errors : {};
    };

    return (
        <div className='container'>
            <div className='col my-3'>
                <h2 className='text-start mb-3'>{persona?.id ? intl.get('persona.title.edit') : intl.get('persona.title.add')}</h2>
                <div className="row row-address text-start mb-3">
                    <PersonaForm
                        errors={errors}
                        input={input}
                        handleInputChange={handleInputChange}
                        handleTaxIdChange={handleTaxIdChange}
                        handleNameChange={handlePersonaNameChange}
                        handleCorporateEntityChange={handleCorporateEntityChange}
                        selectedPersonaType={input.idPersonaType}
                        personaTypes={personaTypes}
                        handlePersonaTypeChange={handlePersonaTypeChange}
                        corporateEntities={corporateEntities}
                        personaTypeExist={personaTypeExist}
                        personaTypesIds={personaTypesIds}
                        showCorporateEntities={showCorporateEntities}
                    />
                </div>
                <Row>
                    <Col md={4} >
                        <RequiredField/>
                    </Col>
                    <Col md={8} className="d-grid gap-2 d-md-flex justify-content-md-end">
                        {
                            loading === true ?
                            <Spinner small={true} /> :
                            <>
                                <button type="button" className="btn btn-outline-dark" onClick={handleCancel}>{intl.get('cancel.button')}</button>
                                <button type="submit" className="btn btn-primary" onClick={handleSave} disabled={JSON.stringify(errors) !== JSON.stringify({}) || input.name === "" ? true : false}>
                                    <HiOutlineSave className='mb-1'/> {intl.get('save.button')}
                                </button>
                            </>
                        }
                    </Col>
                </Row>
            </div>
        </div>
    )
};

export default PersonaFC;
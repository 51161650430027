import React, { useEffect } from 'react';
import { Form, Row, Col, Button } from 'reactstrap';
import DatePickerDialog from 'components/DatePickerDialog';
import { Theme, useTheme } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import { Client, Job, ListOptionDTO } from 'axiosApi/models';
import { useApi } from '../../api/ApiProvider';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import Api from '../../axiosApi/api';
import intl from 'components/i18n/ReactIntlWrapper';
import { HiOutlineXCircle } from 'react-icons/hi';
import '../../scss/Reports/ReportsFilterComponent.scss';
import { getUserEntity } from 'common/UserEntityProvider';
import { ENTITY_TYPE } from 'common/constants';
import { convertDateAsUTC2 } from 'common/utils';
import TextField from '@mui/material/TextField';
import Chip from "@mui/material/Chip";
import DynamicAutocomplete from '../DynamicAutocomplete';
import { handleAPIError } from '../../common/errorHandler';
import TypeAhead from 'components/common/TypeAhead';

export type ReportsFilterComponentProps = {
    from: Date, 
    to: Date,
    projects: string|null,   
    clients: string|null,
    employees: string|null,
    jobTypes: string|null,
};

export const ReportsFilterComponent = ({handleFilterChange, errors, setErrors}) => {

    const theme = useTheme();

    const ITEM_HEIGHT = 48;

    const ITEM_PADDING_TOP = 2;

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250
            }
        }
    };

    const api: Api = useApi();

    const {clientsApi} = api

    const toast = useToastMessageQueue();

    const refreshData = false;

    const userEntity = getUserEntity();
    
    function getStyles (id: number, selectedItems: string[], theme: Theme) {
        return {
            fontWeight:
            selectedItems.indexOf(id.toString()) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
        };
    };

    const currentDate = new Date(); // Get the current date
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

    const [selectedProjects, setSelectedProjects] = React.useState<ListOptionDTO[]>([]);
    const [projects, setProjects] = React.useState<ListOptionDTO[]>([]);
    const [selectedClients, setSelectedClients] = React.useState<Client[]>([]);
    const [clients, setClients] = React.useState<Client[]>([]);
    const [selectedEmployees, setSelectedEmployees] = React.useState<ListOptionDTO[]>([]);
    const [employees, setEmployees] = React.useState<ListOptionDTO[]>([]);
    const [selectedJobTypes, setSelectedJobTypes] = React.useState<Job[]>([]);
    const [jobTypes, setJobTypes] = React.useState<Job[]>([]);
    const [from, setFrom] = React.useState<Date>(firstDayOfMonth);
    const [to, setTo] = React.useState<Date>(lastDayOfMonth);


    const filterValue = (list: ListOptionDTO[] | Job[]): string => list.map(e => e.id).join(',');

    const handleProjectChange = (e, projects) => {
        setSelectedProjects(projects);
        handleFilterChange({
          from: from, to: to,
          projects: filterValue(projects), clients: filterValue(selectedClients), employees: filterValue(selectedEmployees), jobTypes: filterValue(selectedJobTypes),
        });
        fetchEmployees(projects)
    };

    const handleClientChange = (e, clients) => {
        setClients(clients)
        setSelectedClients(clients)
        handleFilterChange({
          from: from, to: to,
          projects: filterValue(selectedProjects), clients: filterValue(clients), employees: filterValue(selectedEmployees), jobTypes: filterValue(selectedJobTypes),
        });
    };

    const handleEmployeeChange = (e, employees) => {
        setSelectedEmployees(employees);
        handleFilterChange({
          from: from, to: to,
          projects: filterValue(selectedProjects), clients: filterValue(selectedClients), employees: filterValue(employees), jobTypes: filterValue(selectedJobTypes),
        });
    };

    const handleJobTypeChange = (e, jobTypes) => {
        setSelectedJobTypes(jobTypes)
        handleFilterChange({
          from: from, to: to,
          projects: filterValue(selectedProjects), clients: filterValue(selectedClients), employees: filterValue(selectedEmployees), jobTypes: filterValue(jobTypes),
        });
    };

    const handleFrom = (e:string) => {
        setFrom(new Date(e));
        handleFilterChange({
          from: convertDateAsUTC2(new Date(e)), to: convertDateAsUTC2(to),
          projects: filterValue(selectedProjects), clients: filterValue(selectedClients), employees: filterValue(selectedEmployees), jobTypes: filterValue(selectedJobTypes),
        });
    };
  
    const handleTo = (e:string) => {
        setTo(new Date(e));
        handleFilterChange({
          from: convertDateAsUTC2(from), to: convertDateAsUTC2(new Date(e)),
          projects: filterValue(selectedProjects), clients: filterValue(selectedClients), employees: filterValue(selectedEmployees), jobTypes: filterValue(selectedJobTypes),
        });
    };

    const fetchProjects = async () => {
      var response = await api.projectApi
        .apiVversionListProjectsGet("1", {})
        .then((response) => {
        if ("data" in response.data) {
            setProjects(response.data?.data?.queryResult.sort((a,b) => {return (a.groupLabel + a.label).localeCompare(b.groupLabel + b.label);}));
        };
      }).catch((error) => {
        handleAPIError(error, toast, errors);
        setErrors({...errors});
      });
    };

    const fetchJobTypes = async () => {
        const response = await api.jobsApi.apiVversionJobTypeAllGet("1", 0, 1000, {}).then((response) => {
            if (response.data) {
                setJobTypes(response.data?.data?.queryResult.sort((a,b) => {return a.description.localeCompare(b.description);}));
            };
        }).catch((error) => {
          handleAPIError(error, toast, errors)
          setErrors({...errors})                        
        });
    };


    const fetchEmployees = async (projects) => {
      if (projects === "all" || projects.length === 0){
        await api.employeeApi
        .apiVversionListEmployeesGet("1", {})
        .then((response) => {
          if (response.data.data) {
            setEmployees(
              response.data?.data?.queryResult.sort((a, b) => {
                return a.label.localeCompare(b.label);
              })
            );
          };
      }).catch((error) => {
        handleAPIError(error, toast, errors);
        setErrors({...errors});
      });
      }
      else {
        const projectIdsList = projects.map(project => Number(project["id"]))
         await api.employeeApi
      .apiVversionListEmployeesByProjectIdsGet("1", projectIdsList, {})
      .then((response) => {
        if (response.data.data) {
          setEmployees(
            response.data?.data?.queryResult.sort((a, b) => {
              return a.label.localeCompare(b.label);
            })
          );
        };
    }).catch((error) => {
      handleAPIError(error, toast, errors);
      setErrors({...errors});
    });
      }
    };

    useEffect(() => {
        fetchProjects();
        if(userEntity.entityType == ENTITY_TYPE.CLIENT){
          //fetchClients();
          setSelectedClients([{id: userEntity.entityId}]);
        }
        fetchEmployees("all");
        fetchJobTypes();
        handleFilterChange({ from: convertDateAsUTC2(from), to: convertDateAsUTC2(to), projects: filterValue(selectedProjects), clients: filterValue(selectedClients), employees: filterValue(selectedEmployees), jobTypes: filterValue(selectedJobTypes)});
    }, [refreshData]);

    const handleReset = () => {
        handleFilterChange({ from: convertDateAsUTC2(firstDayOfMonth), to: convertDateAsUTC2(lastDayOfMonth), projects: filterValue(selectedProjects), clients: filterValue(selectedClients), employees: filterValue(selectedEmployees), jobTypes: filterValue(selectedJobTypes)})
        setSelectedProjects([]);
        if(userEntity.entityType != ENTITY_TYPE.CLIENT)
            setSelectedClients([]);
        else setSelectedClients([{id: userEntity.entityId}]);
        setSelectedEmployees([]);
        setSelectedJobTypes([]);
        setFrom(firstDayOfMonth);
        setTo(lastDayOfMonth);
        handleFilterChange({ from: convertDateAsUTC2(from), to: convertDateAsUTC2(to), projects: filterValue(selectedProjects), clients: filterValue(selectedClients), employees: filterValue(selectedEmployees), jobTypes: filterValue(selectedJobTypes)});
    };

    return (
      <Form className="my-4">
        <Row>
          <Col md={3}>
            <FormControl sx={{ m: 1, width: "100%" }}>
              <DatePickerDialog
                disabled={false}
                inputName="date"
                className={"timesheet-datepicker"} //+ (//errors.date ? ' timesheet-danger' : '')}
                selected={from}
                setSelected={handleFrom}
                required
              />
            </FormControl>
          </Col>
          <Col md={3}>
            <FormControl sx={{ m: 1, width: "100%" }}>
              <DatePickerDialog
                disabled={false}
                inputName="date"
                className={"timesheet-datepicker"} //+ (//errors.date ? ' timesheet-danger' : '')}
                selected={to}
                setSelected={handleTo}
                required
              />
            </FormControl>
          </Col>
          <Col md={2} className="reset">
            <FormControl sx={{ m: 1, width: "100%" }}>
              <Button
                outline
                className="btn btn-primary col-md-10 col-lg-8 col-xl-6"
                onClick={handleReset}
              >
                <HiOutlineXCircle className="mb-1 me-1" />
                {intl.get("ReportsFilterComponent.button.reset")}
              </Button>
            </FormControl>
          </Col>
        </Row>
        <Row form="true" className="justify-content-around">
          {userEntity.entityType != ENTITY_TYPE.CLIENT && (
            <Col md={3}>
              <FormControl sx={{ m: 1, width: "100%" }}>
                <TypeAhead
                    apiPromise={(searchTerm: string) => clientsApi?.apiVversionClientTypeaheadTextGet(searchTerm, "1")}
                    getOptionLabel={(option: Client | any) => option?.entityName || option?.name}
                    value={selectedClients}
                    disableClearable={true}
                    multiple={true}
                    label= {intl.get("reportsFilterComponent.clients" )}
                    getOptionDisabled={(option) => clients?.some(client => client?.id === option?.id)}
                    initialText={intl.get('projectForm.placeholder.typeAhead.client')}
                    onChange={handleClientChange}
                />
              </FormControl>
            </Col>
          )}
          <Col md={3}>
            <FormControl sx={{ m: 1, width: "100%" }}>
              <DynamicAutocomplete
                getOptionLabel={(option) => option.label}
                groupBy={(project) => project.groupLabel}
                isOptionEqualToValue={(project, option) => project.id === option.id }
                onChange={handleProjectChange}
                options={projects}
                renderInput={(params) => projects ? ( <TextField {...params} label={intl.get("reportsFilterComponent.projects" )} /> ) : ( "" ) }
                renderOption={(props, option) => ( <li {...props} key={option.id.toString()}> {option.label} </li>)}
                renderTags={(value, getTagProps) => value.map((option, index) => ( <Chip key={option.id} label={option.label} color="primary" variant="outlined" {...getTagProps({ index })} />)) }
                value={selectedProjects}
              />
            </FormControl>
          </Col>
          <Col md={3}>
            <FormControl sx={{ m: 1, width: "100%" }}>
              <DynamicAutocomplete
                getOptionLabel={(option) => option.description}
                isOptionEqualToValue={(jobType, option) => jobType.id === option.id }
                onChange={handleJobTypeChange}
                options={jobTypes}
                renderInput={(params) => clients ? ( <TextField {...params} label={intl.get("reportsFilterComponent.jobTypes" )} /> ) : ( "" ) }
                renderOption={(props, option) => ( <li {...props} key={option.id.toString()}> {option.description} </li>)}
                renderTags={(value, getTagProps) => value.map((option, index) => ( <Chip key={option.id} label={option.description} color="primary" variant="outlined" {...getTagProps({ index })} />)) }
                value={selectedJobTypes}
              />
            </FormControl>
          </Col>
          <Col md={3}>
            <FormControl sx={{ m: 1, width: "100%" }}>
              <DynamicAutocomplete
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(employee, option) => employee.id === option.id }
                onChange={handleEmployeeChange}
                options={employees}
                renderInput={(params) => clients ? ( <TextField {...params} label={intl.get("reportsFilterComponent.team" )} /> ) : ( "" ) }
                renderOption={(props, option) => ( <li {...props} key={option.id.toString()}> {option.label} </li>)}
                renderTags={(value, getTagProps) => value.map((option, index) => ( <Chip key={option.id} label={option.label} color="primary" variant="outlined" {...getTagProps({ index })} />)) }
                value={selectedEmployees}
              />
            </FormControl>
          </Col>
          {userEntity.entityType == ENTITY_TYPE.CLIENT && <Col md={3}></Col>}
          <Col md={2} className="reset-md">
            <FormControl sx={{ m: 1, width: "100%" }}>
              <Button
                outline
                className="btn btn-primary col-md-10 col-lg-8 col-xl-6"
                onClick={handleReset}
              >
                <HiOutlineXCircle className="mb-1 me-1" />
                {intl.get("ReportsFilterComponent.button.reset")}
              </Button>
            </FormControl>
          </Col>
        </Row>
      </Form>
    );
}

export default ReportsFilterComponent;
import React from 'react';
import { HiOutlineXCircle } from 'react-icons/hi';
import { TextField } from '@mui/material';
import intl from 'components/i18n/ReactIntlWrapper';

const InvoicesSearchBar = ({ queryString, setQueryString }) => {
    return (
        <TextField 
            size='small'
            placeholder={intl.get('searchBar.placeholder')} 
            aria-label="Search"
            value={queryString}
            onChange={e => setQueryString(e.target.value)}
            InputProps={{
                endAdornment: queryString &&
                <HiOutlineXCircle className='iconButton pointer' onClick={(e) => {
                    setQueryString('');
                }}/>
            }}
        />
    )
}

export default InvoicesSearchBar;
import React, { FC } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import intl from 'components/i18n/ReactIntlWrapper';
import '../../scss/Roles/ScopeSelect.scss';

type ScopeSelectProps = {
  handleScopeChange: (event: any, value: any) => void,
  selectedScope: any,
  scopes: any
};

const ScopeSelect: FC<ScopeSelectProps> = ({ scopes, selectedScope, handleScopeChange }) => {
  return (
    <Autocomplete 
      id="scope"
      options={scopes || []}
      getOptionLabel={(option) => option.scope}
      value={scopes?.find((scope) => scope.id === selectedScope) || null}
      onChange={(event, value) => handleScopeChange(event, value)}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      renderInput={(params) => (
        <TextField
          {...params}
          label={intl.get('ScopeSelect.placeholder.scope')}
          variant="outlined"
          fullWidth
        />
      )}
      clearOnEscape
    />
  );
}

export default ScopeSelect;
import React, { useState, useEffect } from 'react';
import RoleNavbar from './RoleNavbar';
import RoleList from './RoleList';
import Spinner from '../Spinner';
import '../../scss/Roles/Roles.scss';
import { useApi } from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import intl from 'components/i18n/ReactIntlWrapper';
import {USER_STATUS} from '../../common/constants';
import { SecurityRole } from 'axiosApi/models';
import RolePagination from './RolePagination';
import ItemsSelect from '../ItemsSelect';
import { handleAPIError } from 'common/errorHandler';
import ErrorSummary from 'components/common/ErrorSummary';

export type RolesInput = {
    queryTerm?: string | null,
    currentPage?: number,
	sortValue?: string | null,
	orderValue?: string | null,
    idStatus: number,
    entityId: number,
    entityTypeId: number,
    userEntityId: number,
    idScope: number | null
};

export type SearchResult = {
	totalRecords: number | null,
	queryResult: SecurityRole[]
};

const Roles = () => {

    const [input, setInput] = useState<RolesInput | null>({
        queryTerm: "",
        currentPage: 1,
		sortValue: "Name",
		orderValue: "asc",
        idStatus: null,
        entityId: null,
        entityTypeId: null,
        userEntityId: null,
        idScope: null
	});
    
	const [searchResult, setSearchResult] = useState<SearchResult | null>({
        totalRecords: null,
        queryResult: []
	});
    
    const api: Api = useApi();

    const [pageCount, setpageCount] = useState<number>(0);

    const [limit, setLimit] = useState<number>(() => {
		return parseInt(localStorage.getItem('itemsPerPageRoles'), 10) || 10;
	});

    useEffect(() => {
		setLimit(parseInt(localStorage.getItem('itemsPerPageRoles'), 10) || 10);
	}, []);

    const toast = useToastMessageQueue();

    const [scopes, setScopes] = useState(null);

    const [errors, setErrors] = useState(null);

    const [entities, setEntities] = useState(null);

    const [loading, setLoading] = useState<boolean>(false);

    const fetchSecurityRoles = async (term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>) => {
        setLoading(true);
        console.log(input);
        if (input.idScope) {
            f = f || [];
            f.push(`scopes:${input.idScope}`);
        }
        if (input.entityId && input.entityTypeId) {
            f = f || [];
            f.push(`entity:${input.entityId}`);
            f.push(`entitytype:${input.entityTypeId}`);
        }
        if (input.idStatus !== null) {
            f = f || [];
            f.push(`active:${input.idStatus}`);
        }
        const response = await api.employeeApi.apiVversionSecurityRoleSearchGet ("1", input.queryTerm, input.currentPage, limit, input.sortValue, input.orderValue, f, {}).then((response) => {
            if (response.data.data === null) {
                setSearchResult({
                    totalRecords: null,
                    queryResult: null
                });
            } else {
                setSearchResult({
                    totalRecords: response.data?.data?.totalRecords,
                    queryResult: response.data?.data?.queryResult
                });
            };
            setLoading(false);

        }).catch((error) => {
            handleAPIError(error, toast, errors);    
            setErrors({ ...errors }); 
            setLoading(false);
		});
    };

    const fetchScopes = async () => {
        const response = await api.employeeApi.apiVversionScopeAllGet("1", input.currentPage, limit, {}).then((response) => {
            if (response.data.data === null) {
				setScopes({
					totalRecords: null,
					queryResult: null
				});
			} else {
                setScopes({
                    totalRecords: response.data?.data?.totalRecords,
                    queryResult: response.data?.data?.queryResult
                });
            };

        }).catch((error) => {
            handleAPIError(error, toast, errors);    
            setErrors({ ...errors }); 
            setLoading(false);
		});
    };

    const fetchEntities = async () => {
        setLoading(true);
        const response = await api.employeeApi.apiVversionListEntitiesGet("1", {}).then((response) => {
            if (response.data.data === null) {
				setEntities({
					totalRecords: null,
					queryResult: null
				});
			} else {
                setEntities({
                    totalRecords: response.data?.data?.totalRecords,
                    queryResult: response.data?.data?.queryResult
                });
            };
        }).catch((error) => {
            handleAPIError(error, toast, errors);    
            setErrors({ ...errors }); 
            setLoading(false);
		});
    };

    useEffect(() => {
        fetchSecurityRoles();
        fetchScopes();
        fetchEntities();
        //TODO CC: unexistant endpoint?
        //fetchUsers();
	}, [input, limit]);

	const handlePageClick = pageNum => setInput({ ...input, currentPage: pageNum });

	const nextPage = () => setInput({ ...input, currentPage: input.currentPage + 1 });

	const prevPage = () => setInput({ ...input, currentPage: input.currentPage - 1 });

	const initialPage = () => setInput({ ...input, currentPage: 1 });

	const finalPage = () => setInput({ ...input, currentPage: pageCount });

    const onSearch = (queryString, idScope, entityId, entityTypeId, idStatus) => {
		setInput({
            ...input,
            queryTerm: queryString,
            idScope: idScope,
            entityId: entityId,
            entityTypeId: entityTypeId,
            idStatus: idStatus,
            currentPage: 1
        });
	};

    return (
        <div className="container">
            <div className='card mt-4'>
                <div className="card-header">
                    <h2 className="title">{intl.get('roles.header')}</h2>
                    
                </div>
                <div className='card-body'>
                    <div>
                        <ErrorSummary errors={errors} showEverything={false} mode="warning"></ErrorSummary>
                        <RoleNavbar 
                            entities={entities?.queryResult}
                            status={USER_STATUS}
                            scopes={scopes?.queryResult}
                            onSearch={onSearch}
                            input={input}
                        />
                        {
                            loading === true ?
                            <Spinner /> :
                            <>
                                <RoleList searchResult={searchResult} setSearchResult={setSearchResult} setLoading={setLoading}/>
                                <div className="pagination row w-100">
                                    <div className="col-10 col-xl-11 d-flex justify-content-center">
                                        <RolePagination 
                                            handlePageClick={handlePageClick}
                                            limit={limit} 
                                            searchResult={searchResult} 
                                            nextPage={nextPage} 
                                            prevPage={prevPage} 
                                            input={input} 
                                            pageCount={pageCount} 
                                            setpageCount={setpageCount} 
                                            initialPage={initialPage} 
                                            finalPage={finalPage} 
                                        />
                                    </div>
                                    <div className="col-2 col-xl-1 d-flex justify-content-end">
                                        <ItemsSelect 
                                            input={input}
                                            setInput={setInput}
                                            limit={limit} 
                                            setLimit={setLimit} 
                                            storageKey="itemsPerPageRoles"
                                        />
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Roles;
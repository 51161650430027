import React from 'react';
import { Link } from 'react-router';
import ProjectsSearchBar from './ProjectsSearchBar';
import '../../scss/Navbar.scss';
import { HiOutlinePlus } from 'react-icons/hi';
import { Tooltip as ReactTooltip } from "react-tooltip";
import intl from 'components/i18n/ReactIntlWrapper';

const ProjectsNavbar = ({ onSearch, handleSort, input, sortOptions }) => {
    
    return (
        <nav className='navbar container-fluid'>
            <ProjectsSearchBar onSearch={onSearch} />
            <Link to= '/project/new'><button className='btn btn-primary p-1 plusButton' type='button' id='newProject' data-tooltip-id='tooltipButton'><HiOutlinePlus className='plusIcon'/></button></Link>
            <ReactTooltip 
                id='tooltipButton'
                place='left'
                content={intl.get('projectsNavbar.newProject.button')}
            />
        </nav> 
    )
}

export default ProjectsNavbar;
import React, { FC, useState } from 'react';
import Select from 'react-select';
import { useNewInvoice } from './NewInvoice/newInvoiceProvider';

type TaxSelectProps = {
    handleTaxChange: any,
    taxId?: any,
    disabled: boolean,
    isClearable: boolean
};

const TaxSelect: FC<TaxSelectProps> = ({ handleTaxChange, taxId, disabled, isClearable }) => {
    const { taxesValues, isLoading } = useNewInvoice();
    const filteredTaxes = taxesValues?.filter(tax => tax.appliesTo === 2);
    return (
        <div>
            <Select
                id='tax'
                menuPortalTarget={document.body}
                getOptionLabel={e => `${e["name"]} [${e["rate"]}%]`}
                getOptionValue={e => e["id"]}
                placeholder="Taxes"
                value={(filteredTaxes != null) ? (filteredTaxes.find((tax) => tax["id"] === taxId)) : ''}
                onChange={(e) => handleTaxChange(e)}
                isLoading={isLoading}
                options={filteredTaxes}
            />
        </div>
  );
}

export default TaxSelect;

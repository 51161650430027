import React, { useState, useEffect } from 'react';
import Spinner from '../Spinner';
import { useApi } from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import { useToastMessageQueue } from '../ToastMessages/ToastMessageProvider';
import intl from 'components/i18n/ReactIntlWrapper';
import ItemsSelect from '../ItemsSelect';
import InvoicesNavbar from './InvoicesNavbar';
import InvoicesList from './InvoicesList';
import InvoicesPagination from './InvoicesPagination';
import { handleAPIError } from 'common/errorHandler';
import { formatDateFilterInvoices } from '../../common/utils';
import ErrorSummary from 'components/common/ErrorSummary';
import { DateRange } from 'common/constants';

export type InvoicesInput = {
    to: string | null,
    number: string | null,
    date: string | null,
    from: string | null,
    range: DateRange | undefined,
    amount: number | null,
    amounts: object,
    idInvoiceType: number | null,
    idWorkflowState: number | null,
    queryTerm?: string | null,
    currentPage?: number,
    sortValue?: string | null,
    orderValue?: string| null,
};

export type SearchResult = {
	totalRecords: number | null,
	queryResult: any
};

const Invoices = ({ OwnerView = false }) => {

    const [input, setInput] = useState<InvoicesInput | null>({
        to: "",
        number: "",
        date: "",
        from: "",
        range: undefined,
        amount: null,
        amounts: {},
        idInvoiceType: null,
        idWorkflowState: null,
        queryTerm: "",
        currentPage: 1,
		sortValue: "",
		orderValue: "asc",
	});

    useEffect(() => {
        if (input.from && input.to) {
            setInput(prev => ({
                ...prev,
                range: {
                    from: new Date(input.from),
                    to: new Date(input.to),
                }
            }));
        }
    }, [input.from, input.to]);

    const [invoices, setInvoices] = useState<SearchResult | null>({
        totalRecords: null,
        queryResult: null
	});

    const [invoicesOwner, setInvoicesOwner] = useState<SearchResult | null>({
        totalRecords: null,
        queryResult: null
	});

    const [invoiceStatus, setInvoiceStatus] = useState<SearchResult | null>({
        totalRecords: null,
        queryResult: null
    });

    const [loading, setLoading] = useState<boolean>(false);

    const [errors, setErrors] = useState({});

    const api: Api = useApi();

    const toast = useToastMessageQueue();

    const [pageCount, setpageCount] = useState<number>(0);

    const [limit, setLimit] = useState<number>(() => {
		return parseInt(localStorage.getItem('itemsPerPageTimeOff'), 10) || 10;
	});

    useEffect(() => {
		setLimit(parseInt(localStorage.getItem('itemsPerPageTimeOff'), 10) || 10);
	}, []);

    const fetchInvoices = async (term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>) => {
        setLoading(true);
        if (input.idWorkflowState) {
            f = [`idworkflowstate:${input.idWorkflowState}`];
        }
        if (input.range?.from) {
            let from = formatDateFilterInvoices(input.range.from);
            f = f || [];
            f.push(`startdate:${from}`);
        }
        if(input.range?.to){
            let to = formatDateFilterInvoices(input.range.to);
            f = f || [];
            f.push(`enddate:${to}`);
        }
        if (Number(input.amounts["min"])){
            f = f || [];
            f.push(`amountabove:${Number(input.amounts["min"])}`);
        }
        if (Number(input.amounts["max"])){
            f = f || [];
            f.push(`amountbelow:${Number(input.amounts["max"])}`);
        }
        const response = await api.invoiceApi.apiVversionInvoiceSearchIssuedGet("1", input.queryTerm, input.currentPage, limit, input.sortValue, input.orderValue, f, {}).then((response) => {
            if (response.data.data.queryResult) {
                setInvoices({
                    totalRecords: response.data?.data?.totalRecords,
                    queryResult: response.data?.data?.queryResult
                });
            } else {
                setInvoices({
                    totalRecords: null,
                    queryResult: null
                });
            };
            console.log("Success");
        }).catch((error) => {
            handleAPIError(error, toast, errors);
            setErrors({ ...errors });
        }).finally(() => {
            setLoading(false);
        })
    };

    const fetchInvoicesOwner = (term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>) => {
        setLoading(true);
        if (input.idWorkflowState) {
            f = [`idworkflowstate:${input.idWorkflowState}`];
        }
        if (input.range?.from) {
            let from = formatDateFilterInvoices(input.range.from);
            f = f || [];
            f.push(`startdate:${from}`);
        }
        if(input.range?.to){
            let to = formatDateFilterInvoices(input.range.to);
            f = f || [];
            f.push(`enddate:${to}`);
        }
        if (Number(input.amounts["min"])){
            f = f || [];
            f.push(`amountabove:${Number(input.amounts["min"])}`);
        }
        if (Number(input.amounts["max"])){
            f = f || [];
            f.push(`amountbelow:${Number(input.amounts["max"])}`);
        }
        api.invoiceApi.apiVversionInvoiceSearchGet ("1", input.queryTerm, input.currentPage, limit, input.sortValue, input.orderValue, f, {}).then((response) => {
            if (response.data.data.queryResult) {
                setInvoicesOwner({
                    totalRecords: response.data?.data?.totalRecords,
                    queryResult: response.data?.data?.queryResult
                });
            } else {
                setInvoicesOwner({
                    totalRecords: null,
                    queryResult: null
                });
            };
            console.log("Success");
        }).catch((error) => {
            handleAPIError(error, toast, errors);
            setErrors({ ...errors });
        }).finally(() => {
            setLoading(false);
        })
    }

    const fetchInvoicesStatus = async () => {
        const response = await api.workflowApi.apiVversionModuleWorkflowStatesModuleIdGet(4, "1").then((response) => {
        if (response.data.data.queryResult) {
            setInvoiceStatus({
                totalRecords: response.data?.data?.totalRecords,
                queryResult: response.data?.data?.queryResult
            });
        };
        console.log("Success");
        }).catch((error) => {
            handleAPIError(error, toast, errors);
            setErrors({ ...errors });
        });
    };

    useEffect(() => {
        if (OwnerView) {
            fetchInvoicesOwner();
        } else {
            fetchInvoices();
        }
        fetchInvoicesStatus();
	}, [input, limit, OwnerView]);

    const handlePageClick = pageNum => setInput({ ...input, currentPage: pageNum });

	const nextPage = () => setInput({ ...input, currentPage: input.currentPage + 1 });

	const prevPage = () => setInput({ ...input, currentPage: input.currentPage - 1 });

	const initialPage = () => setInput({ ...input, currentPage: 1 });

	const finalPage = () => setInput({ ...input, currentPage: pageCount });

    const onSearch = (queryString, idStatus, range, amounts) => {
        setErrors([]);
		setInput({ ...input, queryTerm: queryString, idWorkflowState: idStatus, range: range, currentPage: 1, amounts: amounts });
	};

    return (
        <div className="container">
            <div className='card mt-4'>
                <div className="card-header">
                    {
                        OwnerView ?
                        <h2 className="title">{intl.get('mainNavigation.invoice')}</h2> :
                        <h2 className="title">{intl.get('mainNavigation.invoice.myInvoices')}</h2>
                    }
                </div>
                <div className='card-body'>
                    <div>
                        <InvoicesNavbar
                            invoiceStatus={invoiceStatus?.queryResult}
                            onSearch={onSearch}
                        />
                        {
                            loading === true ?
                            <Spinner /> :
                            <>
                                <div className="col-md-12">
                                    <ErrorSummary errors={errors} showEverything={false} mode="warning"></ErrorSummary>
                                </div>
                                <InvoicesList searchResult={OwnerView ? invoicesOwner : invoices} OwnerView={OwnerView} />
                                <div className="pagination row w-100">
                                    <div className="col-10 col-xl-11 d-flex justify-content-center">
                                        <InvoicesPagination OwnerView={OwnerView} handlePageClick={handlePageClick} limit={limit} searchResult={OwnerView ? invoicesOwner : invoices} nextPage={nextPage} prevPage={prevPage} input={input} pageCount={pageCount} setpageCount={setpageCount} initialPage={initialPage} finalPage={finalPage} />
                                    </div>
                                    <div className="col-2 col-xl-1 d-flex justify-content-end">
                                        <ItemsSelect
                                            input={input}
                                            setInput={setInput}
                                            limit={limit}
                                            setLimit={setLimit}
                                            storageKey="itemsPerPageInvoices"
                                        />
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Invoices;

import React, { FC } from 'react';
import Select from 'react-select';
import 'scss/Project/ClientSelect.scss';
import intl from 'components/i18n/ReactIntlWrapper';
import { sortCollection } from '../../common/utils';
import { SORT_TYPES } from '../../common/constants';
import { Provider } from 'axiosApi/models';

type CorporateEntitySelectProps = {
  handleCorporateEntityChange: any,
  selectedCorporateEntity: any,
  corporateEntities: any,
  disabled: any,
  newUser: boolean,
  id: any,
  securityRoles: any,
  edit: boolean,
  setHiddenRole: any,
};

const CorporateEntitySelect: FC<CorporateEntitySelectProps> = ({ handleCorporateEntityChange, corporateEntities, selectedCorporateEntity, disabled, newUser, id, securityRoles, edit, setHiddenRole }) => {

  const corporateEntitiesSortedByName = sortCollection(corporateEntities, "name", SORT_TYPES.ASC);

  const customStyles = {
    control: base => ({
      ...base,
      height: 56,
      minHeight: 56
    }),
    menu: (base) => ({
      ...base,
      maxHeight: '18.7rem',
    })
  };

  const handleChange = (selectedOption: any) => {
    handleCorporateEntityChange(selectedOption, id);
  };

  const handleMenuOpen = () => {
    setHiddenRole(true);
  };

  const handleMenuClose = () => {
    setHiddenRole(false);
  };

  return (
    <Select
      id='idCorporateEntity'
      name='idCorporateEntity'
      getOptionLabel={e => e.entityName ? `${e.entityName}` : e.name}
      getOptionValue={e => e.id}
      placeholder={intl.get('corporateEntitySelect.placeholder.corporateEntity')}
      value={edit ? ((corporateEntities != null && selectedCorporateEntity != null) ? (corporateEntities?.find((provider: Provider) => provider.id === selectedCorporateEntity)) : '') : disabled ? '' : ((corporateEntities != null && selectedCorporateEntity != null) ? (corporateEntities?.find((provider: Provider) => provider.id === selectedCorporateEntity)) : '')}
      onChange={handleChange}
      isLoading={corporateEntities == null}
      options={corporateEntitiesSortedByName}
      isDisabled={disabled}
      styles={newUser ? customStyles : undefined}
      onMenuOpen={handleMenuOpen}
      onMenuClose={handleMenuClose}
    />
  );
}

export default CorporateEntitySelect;
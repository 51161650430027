import { AllocationType } from "components/Project/AllocationTypeSelect";

export const MIN_BEFORE_EXPIRATION = 5 * 60;
export const PIECOLORS =["#4E79A7","#A0CBE8","#F28E2B","#FFBE7D","#59A14F","#8CD17D","#B6992D","#F1CE63","#499894","#86BCB6","#E15759","#FF9D9A","#79706E","#BAB0AC","#D37295","#FABFD2","#B07AA1","#D4A6C8","#9D7660","#D7B5A6"];
export const ALLOCATION_TYPES:AllocationType[] = [{id:1, name:'%'  },{id:2, name: 'hours' }];
export const SORT_TYPES = {'ASC': 1, 'DESC': -1};
export const USER_STATUS = [{id: 1, active: true}, {id: 2, active: false}];

export enum ENTITY_TYPE { // Not const because we need reverse lookup.
    ANY=0,
    EMPLOYEE=1,
    CLIENT=2,
    PROVIDER=3,
    CORPORATE_ENTITY=4
};
Object.freeze(ENTITY_TYPE); // We freeze it instead (so it's still immutable).

export const ENTITY_TYPE_NAMES: Record<number, string> = { // Because ENTITY_TYPE is a constant there is 
    [ENTITY_TYPE.ANY]: "ANY",
    [ENTITY_TYPE.EMPLOYEE]: "EMPLOYEE",
    [ENTITY_TYPE.CLIENT]: "CLIENT",
    [ENTITY_TYPE.PROVIDER]: "PROVIDER",
    [ENTITY_TYPE.CORPORATE_ENTITY]: "CORPORATE_ENTITY"
};

export type DateRange = {
    from: Date | undefined;
    to?: Date | undefined;
};

export const enum WORKFLOW_MODULES{
    COMPLETE_WORKFLOW = 1,
    TIME_OFF = 2,
    ACCOUNTS_PAYABLE = 4
}
import React from 'react';
import intl from 'components/i18n/ReactIntlWrapper';
import { WorkflowProvider } from 'components/Workflow/WorkflowContext';
import InvoicesRow from './InvoicesRow';
import { formatDateInvoices } from '../../common/utils';

const InvoicesList = ({ searchResult, OwnerView }) => {

    return (
        <div className="table-responsive my-2">
            <table className='table table-hover'>
                <thead>
                    <tr>
                        {
                            OwnerView ?
                            <th role='button' scope='col'>{intl.get('invoicesList.name')}</th> :
                            null
                        }
                        <th role='button' scope="col">{intl.get('invoicesList.date')}</th>
                        <th role='button' scope="col">{intl.get('invoicesList.invoice.number')}</th>
                        <th role='button' scope="col">{intl.get('invoicesList.invoice.id')}</th>
                        <th role='button' scope="col">{intl.get('invoicesList.amount')}</th>
                        <th role='button' scope="col">{intl.get('invoicesList.status')}</th>
                        <th role='button' scope="col">{intl.get('invoicesList.actions')}</th>
                        <th role='button' scope="col" style={{ textAlign: 'center' }}>{intl.get('invoicesList.detail')}</th>
                    </tr>
                </thead>
                {
                    searchResult?.queryResult?.length > 0 ?
                    <tbody><WorkflowProvider moduleId={4}>
                        {
                            searchResult.queryResult?.map((r,i) => (
                                <InvoicesRow
                                    className={i%2>0?'':'odd'}
                                    key={r.id}
                                    date={formatDateInvoices(r.date)}
                                    from={r.from}
                                    to={r.to}
                                    invoiceNumber={r.number}
                                    invoiceID={r.id}
                                    amount={r.amount}
                                    status={r.workflowState?.labelkey ? intl.get(r.workflowState?.labelkey) : r.workflowState?.name}
                                    idWorkflowState={r.idWorkflowState}
                                    idInvoiceType={r.idInvoiceType}
                                    fileURI={r.fileURI}
                                    addressFrom={r.addressFrom}
                                    addressTo={r.addressTo}
                                    idFrom={r.idFrom}
                                    idTo={r.idTo}
                                    idCurrency={r.idCurrency}
                                    invoiceItems={r.invoiceItems}
                                    invoiceTaxes={r.invoiceTaxes}
                                    OwnerView={OwnerView}
                                />
                            ))
                        }</WorkflowProvider>
                    </tbody> :
                    <tbody className='aling-center mb-0'>
                        <tr>
                            <td colSpan={12} className='mb-0' style={{cursor: "default"}}>{intl.get('invoicesList.notFound')}</td>
                        </tr>
                    </tbody>
                }
            </table>
        </div>
    )
}

export default InvoicesList;

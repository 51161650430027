import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import ContactTypeSelect from './ContactTypeSelect';
import '../scss/ContactForm.scss';
import intl from 'components/i18n/ReactIntlWrapper';
import ErrorSummary from 'components/common/ErrorSummary';

const ContactForm = ({ errors, input, handleInputChange, contactTypes, selectedContactTypes, handleContactTypeChange }) => {

    // Helper function to create label with error handling
    const renderLabel = (labelText, errorKey) => (
        <Typography 
            component="label" 
            sx={{ 
                display: 'block', 
                mb: 1, 
                color: errors[errorKey] ? 'error.main' : 'text.primary'
            }}
        >
            {labelText} {errors[errorKey] && (
                <Typography component="span" color="error">
                    {errors[errorKey]}
                </Typography>
            )}
        </Typography>
    );

    return (
        <Box component="form" sx={{ width: '100%' }}>
            <Box sx={{ mb: 2 }}>
                <Grid container>
                    <Grid xs={12}>
                        <ErrorSummary errors={errors} showEverything={true} />
                    </Grid>
                </Grid>
            </Box>
            
            <Box sx={{ mb: 2 }}>
                <Grid container spacing={2}>
                    <Grid xs={12} md={6}>
                        {renderLabel(intl.get('contactForm.name'), 'contactName')}
                        <TextField 
                            fullWidth
                            id="Name"
                            name="contactName"
                            placeholder={intl.get('contactForm.placeholder.name')}
                            value={input.contactName}
                            onChange={handleInputChange}
                            error={!!errors.contactName}
                            inputProps={{ maxLength: 45 }}
                            size="small"
                        />
                    </Grid>
                    <Grid xs={12} md={6}>
                        {renderLabel(intl.get('contactForm.email'), 'emailAddress')}
                        <TextField 
                            fullWidth
                            id="Email"
                            name="emailAddress"
                            placeholder={intl.get('contactForm.placeholder.email')}
                            value={input.emailAddress}
                            onChange={handleInputChange}
                            error={!!errors.emailAddress}
                            inputProps={{ maxLength: 45 }}
                            size="small"
                        />
                    </Grid>
                </Grid>
            </Box>
            
            <Box sx={{ mb: 2 }}>
                <Grid container spacing={2}>
                    <Grid xs={12} md={6}>
                        {renderLabel(intl.get('contactForm.phone1'), '')}
                        <TextField 
                            fullWidth
                            id="Phone1"
                            name="phone1"
                            type="tel"
                            placeholder={intl.get('contactForm.placeholder.phone1')}
                            value={input.phone1}
                            onChange={handleInputChange}
                            inputProps={{ maxLength: 45 }}
                            size="small"
                        />
                    </Grid>
                    <Grid xs={12} md={6}>
                        {renderLabel(intl.get('contactForm.phone2'), '')}
                        <TextField 
                            fullWidth
                            id="Phone2"
                            name="phone2"
                            type="tel"
                            placeholder={intl.get('contactForm.placeholder.phone2')}
                            value={input.phone2}
                            onChange={handleInputChange}
                            inputProps={{ maxLength: 45 }}
                            size="small"
                        />
                    </Grid>
                </Grid>
            </Box>
            
            <Box sx={{ mb: 2 }}>
                <Grid container spacing={2}>
                    <Grid xs={12} md={6}>
                        {renderLabel(intl.get('contactForm.role'), 'role')}
                        <TextField 
                            fullWidth
                            id="Role"
                            name="role"
                            placeholder={intl.get('contactForm.placeholder.role')}
                            value={input.role}
                            onChange={handleInputChange}
                            error={!!errors.role}
                            inputProps={{ maxLength: 45 }}
                            size="small"
                        />
                    </Grid>
                    <Grid xs={12} md={6}>
                        {renderLabel(intl.get('contactForm.contactType'), 'idContactType')}
                        <FormControl fullWidth>
                            <ContactTypeSelect
                                contactTypes={contactTypes}
                                selectedContactTypes={selectedContactTypes}
                                handleContactTypeChange={handleContactTypeChange}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default ContactForm;

const corporateEntityAddressTypesData = {
    "data": {
      "queryResult": [
        {
          "id": 1,
          "label": "Real Address",
          "groupLabel": null
        },
        {
          "id": 2,
          "label": "Legal Address",
          "groupLabel": null
        }
      ],
      "totalRecords": 2
    },
    "errors": null
}

export default corporateEntityAddressTypesData;
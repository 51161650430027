import React, { useState } from 'react';
import { HiSearch, HiOutlineXCircle } from 'react-icons/hi';
import '../../scss/User/UserSearchBar.scss';
import intl from 'components/i18n/ReactIntlWrapper';

const UserSearchBar = ({ onSearch }) => {

    const [queryString, setQueryString] = useState<string>('');

    return (
        <form className="d-flex col-5">
            <div className="input-group">
                <input 
                    type="text" 
                    className="form-control" 
                    placeholder={intl.get('searchBar.placeholder')} 
                    aria-label="Search"
                    value={queryString}
                    onChange={e => setQueryString(e.target.value)}
                />
                {
                    queryString ?
                    <button 
                        className="btn btn-outline-secondary close p-0" 
                        type="button"
                        onClick={(e) => {
                            setQueryString('');
                            onSearch('');
                        }}
                    >
                        <HiOutlineXCircle className='ms-2 iconButton'/>
                    </button> :
                    null
                }
                <button 
                    className="btn btn-outline-secondary close p-0" 
                    type="submit"
                    onClick={(e) => {
                        e.preventDefault();
                        onSearch(queryString);
                    }}
                >
                    <HiSearch className='mx-2 iconButton'/>
                </button>
            </div>
        </form>
    )
}

export default UserSearchBar;
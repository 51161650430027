import { Backdrop, Box } from '@mui/material';
import React, { createContext, useContext, useEffect, useState } from 'react'
import ReactLoading from 'react-loading';
import { useDebounce } from '@uidotdev/usehooks'

export const LayoutContext = createContext({
    loading: false,
    setLoading: (loading: boolean) => { },
});

const DELAY = 1000;

export default function LayoutProvider({ children }) {
    const [loading, setLoading] = useState(true);
    const [showLoading, setShowLoading] = useState(true);
    const debouncedLoading = useDebounce(loading, DELAY);
    useEffect(() => {
        if(loading){
            setShowLoading(true);
        }else{
            if(!debouncedLoading){
                setShowLoading(false);
            }
        }
    }, [loading, debouncedLoading]);

    return (
        <LayoutContext.Provider value={{
            loading: showLoading,
            setLoading: setLoading
        }}>
            <Backdrop
                sx={(theme) => ({ zIndex: theme.zIndex.drawer + 9999 })}
                open={showLoading} >
                    <Box className="loading-background">
                        <Box className="loading-bar" >
                            <ReactLoading type={"bars"} color={"#005BAF"} height={100} width={100} />
                        </Box>
                    </Box>
            </Backdrop>
            {children}
        </LayoutContext.Provider>
    );
}

export function useLayout() {
  return useContext(LayoutContext);
}
import React from 'react';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { HiOutlineXCircle } from 'react-icons/hi';
import intl from 'components/i18n/ReactIntlWrapper';

const RoleSearchBar = ({ queryString, setQueryString }) => {
    return (
        <TextField
            fullWidth
            size="small"
            variant="outlined"
            placeholder={intl.get('roleSearchBar.placeholder')}
            value={queryString}
            onChange={(e) => setQueryString(e.target.value)}
            InputProps={{
                endAdornment: queryString ? (
                    <InputAdornment position="end">
                        <IconButton onClick={() => setQueryString('')}>
                            <HiOutlineXCircle />
                        </IconButton>
                    </InputAdornment>
                ) : null,
            }}
        />
    );
};

export default RoleSearchBar;

import React from "react";
import { RouteProps } from "react-router";
import { ENTITY_TYPE } from "common/constants";
import { DashboardComponent } from "components/Dashboard/DashboardComponent";
import { TimesheetComponent } from "components/Timesheet/TimesheetComponent";
import { ReportsComponent } from "components/Reports/ReportsComponent";
import { BillingReportComponent } from "components/Reports/BillingReportComponent";
import { ClientBillingReportComponent } from "components/Reports/ClientBillingReportComponent";
import { ClientTimesheetReportComponent } from "components/Reports/ClientTimesheetReportComponent";
import Clients from 'components/Clients';
import NewInvoice from 'components/NewInvoice/NewInvoice';
import ClientDetail from "components/ClientDetail";
import Employees from "components/Employee/Employee";
import EmployeeDetail from "components/Employee/EmployeeDetail";
import Projects from "components/Project/Projects";
import ProjectDetail from "components/Project/ProjectDetail";
import Providers from "components/Provider/Providers";
import ProviderDetail from "components/Provider/ProviderDetail";
import Workflows from "components/Workflow/Workflows";
import NewUser from "components/User/NewUser";
import Roles from "components/Roles/Roles";
import RoleDetail from "components/Roles/RoleDetail";
import UserDetail from "components/User/UserDetail";
import Users from "components/User/Users";
import TimeOff from "components/Time Off/TimeOff";
import TimeOffRequest from "components/Time Off/TimeOffRequest";
import HRCalendar from "components/Time Off/HRCalendar";
import WorkflowAdmin from "components/WorkflowAdmin";
import Invoices from "components/Invoice/Invoices";
import CorporateEntities from "components/Corporate Entities/CorporateEntities";
import TranslationsPage from "components/Translation/TranslationPage";
import CorporateEntityDetail from "components/Corporate Entities/CorporateEntityDetail";

export type RoutePermissionDefinition = RouteProps & {
  scopes?: string[];
  entityTypes?: ENTITY_TYPE[];
}

const ROUTES: RoutePermissionDefinition[] = [
  { path: "/",                                      element: <DashboardComponent />,                      entityTypes: [ENTITY_TYPE.EMPLOYEE] },
  { path: "/clients",                               element: <Clients />,                                 entityTypes: [ENTITY_TYPE.EMPLOYEE] },
  { path: "/client/new",                            element: <ClientDetail />,                            entityTypes: [ENTITY_TYPE.EMPLOYEE] },
  { path: "/client/detail/:id",                     element: <ClientDetail />,                            entityTypes: [ENTITY_TYPE.EMPLOYEE] },
  { path: "/projects",                              element: <Projects />,                                entityTypes: [ENTITY_TYPE.EMPLOYEE] },
  { path: "/project/new",                           element: <ProjectDetail />,                           entityTypes: [ENTITY_TYPE.EMPLOYEE] },
  { path: "/project/detail/:idProject",             element: <ProjectDetail />,                           entityTypes: [ENTITY_TYPE.EMPLOYEE] },
  { path: "/employees",                             element: <Employees />,                               entityTypes: [ENTITY_TYPE.EMPLOYEE] },
  { path: "/employee/new",                          element: <EmployeeDetail />,                          entityTypes: [ENTITY_TYPE.EMPLOYEE] },
  { path: "/employee/detail/:id",                   element: <EmployeeDetail />,                          entityTypes: [ENTITY_TYPE.EMPLOYEE] },
  
  { path: "/timesheet",                             element: <TimesheetComponent />,                      entityTypes: [ENTITY_TYPE.ANY] },
  { path: "/reports/billing",                       element: <BillingReportComponent />,                  entityTypes: [ENTITY_TYPE.ANY] },
  { path: "/reports/timesheet",                     element: <ReportsComponent />,                        entityTypes: [ENTITY_TYPE.ANY] },

  { path: "/reports/billing/client",                element: <ClientBillingReportComponent />,            entityTypes: [ENTITY_TYPE.CLIENT, ENTITY_TYPE.ANY] },
  { path: "/reports/timesheet/client",              element: <ClientTimesheetReportComponent />,          entityTypes: [ENTITY_TYPE.CLIENT, ENTITY_TYPE.ANY] },

  { path: "/providers",                             element: <Providers />,                               entityTypes: [ENTITY_TYPE.EMPLOYEE, ENTITY_TYPE.ANY] },
  { path: "/provider/new",                          element: <ProviderDetail />,                          entityTypes: [ENTITY_TYPE.EMPLOYEE, ENTITY_TYPE.ANY] },
  { path: "/provider/detail/:id",                   element: <ProviderDetail />,                          entityTypes: [ENTITY_TYPE.EMPLOYEE, ENTITY_TYPE.ANY] },
  { path: "/users",                                 element: <Users />,                                   entityTypes: [ENTITY_TYPE.EMPLOYEE, ENTITY_TYPE.ANY] },
  { path: "/user/new",                              element: <NewUser />,                                 entityTypes: [ENTITY_TYPE.EMPLOYEE, ENTITY_TYPE.ANY] },
  { path: "/user/detail/:id",                       element: <UserDetail />,                              entityTypes: [ENTITY_TYPE.EMPLOYEE, ENTITY_TYPE.ANY] },
  { path: "/roles",                                 element: <Roles />,                                   entityTypes: [ENTITY_TYPE.EMPLOYEE, ENTITY_TYPE.ANY] },
  { path: "/role/new",                              element: <RoleDetail />,                              entityTypes: [ENTITY_TYPE.EMPLOYEE, ENTITY_TYPE.ANY] },
  { path: "/role/detail/:id",                       element: <RoleDetail />,                              entityTypes: [ENTITY_TYPE.EMPLOYEE, ENTITY_TYPE.ANY] },

  { path: "/timeOff",                               element: <TimeOff />,                                 entityTypes: [ENTITY_TYPE.EMPLOYEE] },
  { path: "/timeOffHR",                             element: <TimeOff HRView={true} />,                   entityTypes: [ENTITY_TYPE.EMPLOYEE] },
  { path: "/timeOff/request",                       element: <TimeOffRequest HRView={false} />,           entityTypes: [ENTITY_TYPE.EMPLOYEE] },
  { path: "/timeOff/requestHR",                     element: <TimeOffRequest HRView={true} />,            entityTypes: [ENTITY_TYPE.EMPLOYEE] },
  { path: "/timeOffRequest/detail/:id",             element: <TimeOffRequest HRView={false} />,           entityTypes: [ENTITY_TYPE.EMPLOYEE] },
  { path: "/timeOffRequestHR/detail/:id",           element: <TimeOffRequest HRView={true} />,            entityTypes: [ENTITY_TYPE.EMPLOYEE] },
  { path: "/Calendar",                              element: <HRCalendar />,                              entityTypes: [ENTITY_TYPE.EMPLOYEE] },
  { path: "/CalendarHR",                            element: <HRCalendar HRView={true} />,                entityTypes: [ENTITY_TYPE.EMPLOYEE] },
  { path: "/Calendar/Holidays",                     element: <HRCalendar />,                              entityTypes: [ENTITY_TYPE.EMPLOYEE] },
  { path: "/workflows",                             element: <Workflows />,                               entityTypes: [ENTITY_TYPE.EMPLOYEE] },
  { path: "/workflow/:id",                          element: <WorkflowAdmin />,                           entityTypes: [ENTITY_TYPE.EMPLOYEE] },
  { path: "/myInvoices",                            element: <Invoices />,                                entityTypes: [ENTITY_TYPE.EMPLOYEE] },
  { path: "/invoices",                              element: <Invoices OwnerView={true} />,               entityTypes: [ENTITY_TYPE.EMPLOYEE] },
  { path: "/corporateEntities",                     element: <CorporateEntities />,                       entityTypes: [ENTITY_TYPE.EMPLOYEE] },
  { path: "/corporateEntities/new",                 element: <CorporateEntityDetail />,                   entityTypes: [ENTITY_TYPE.EMPLOYEE] },
  { path: "/corporateEntities/detail/:id",          element: <CorporateEntityDetail />,                   entityTypes: [ENTITY_TYPE.EMPLOYEE] },
  { path: "/corporatesEntities",                    element: <CorporateEntities />,                       entityTypes: [ENTITY_TYPE.EMPLOYEE] },
  { path: "/translations",                          element: <TranslationsPage/>,                          entityTypes: [ENTITY_TYPE.EMPLOYEE] },
  { path: "/newinvoice",                            element: <NewInvoice />,                              entityTypes: [ENTITY_TYPE.EMPLOYEE] },
];

export default ROUTES;
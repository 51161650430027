/* tslint:disable */
/* eslint-disable */
/**
 * Intranet - Employee API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { ActivateSecurityRoleCommand } from '../models';
// @ts-ignore
import type { BooleanResponse } from '../models';
// @ts-ignore
import type { CreateEmployeeCommand } from '../models';
// @ts-ignore
import type { CreateProviderCommand } from '../models';
// @ts-ignore
import type { CreateSecurityRoleCommand } from '../models';
// @ts-ignore
import type { CreateUserCommand } from '../models';
// @ts-ignore
import type { DisplayNameDTOSearchResponseResponse } from '../models';
// @ts-ignore
import type { EmployeeByIdResponseResponse } from '../models';
// @ts-ignore
import type { EmployeeListResponse } from '../models';
// @ts-ignore
import type { EmployeeResponse } from '../models';
// @ts-ignore
import type { EmployeeSearchResponseResponse } from '../models';
// @ts-ignore
import type { EntityListOptionDTOSearchResponseResponse } from '../models';
// @ts-ignore
import type { ListOptionDTOSearchResponseResponse } from '../models';
// @ts-ignore
import type { ProviderResponse } from '../models';
// @ts-ignore
import type { ProviderSearchResponseResponse } from '../models';
// @ts-ignore
import type { ScopeDTOSearchResponseResponse } from '../models';
// @ts-ignore
import type { SecurityRoleDTOResponse } from '../models';
// @ts-ignore
import type { SecurityRoleDTOSearchResponseResponse } from '../models';
// @ts-ignore
import type { SecurityRoleResponse } from '../models';
// @ts-ignore
import type { StatusCodeResult } from '../models';
// @ts-ignore
import type { StringResponse } from '../models';
// @ts-ignore
import type { UpdateEmployeeCommand } from '../models';
// @ts-ignore
import type { UpdateProviderCommand } from '../models';
// @ts-ignore
import type { UpdateSecurityRoleCommand } from '../models';
// @ts-ignore
import type { UpdateUserCommand } from '../models';
// @ts-ignore
import type { UpdateUserSecurityRolesCommand } from '../models';
// @ts-ignore
import type { UserByIdResponseListResponse } from '../models';
// @ts-ignore
import type { UserByIdResponseResponse } from '../models';
// @ts-ignore
import type { UserEntityDetailsListResponse } from '../models';
// @ts-ignore
import type { UserEntityDetailsSearchResponseResponse } from '../models';
// @ts-ignore
import type { UserListResponse } from '../models';
// @ts-ignore
import type { UserResponse } from '../models';
// @ts-ignore
import type { UserSearchResponseResponse } from '../models';
/**
 * EmployeeApi - axios parameter creator
 * @export
 */
export const EmployeeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets the list of all Employees
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionEmployeeAllGet: async (version: string, pageNum?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionEmployeeAllGet', 'version', version)
            const localVarPath = `/api/v{version}/Employee/all`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets an Employee by emailAddress
         * @param {string} emailAddress 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionEmployeeEmailAddressEmailAddressGet: async (emailAddress: string, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailAddress' is not null or undefined
            assertParamExists('apiVversionEmployeeEmailAddressEmailAddressGet', 'emailAddress', emailAddress)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionEmployeeEmailAddressEmailAddressGet', 'version', version)
            const localVarPath = `/api/v{version}/Employee/emailAddress/{emailAddress}`
                .replace(`{${"emailAddress"}}`, encodeURIComponent(String(emailAddress)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a Employee by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionEmployeeIdDelete: async (id: number, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVversionEmployeeIdDelete', 'id', id)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionEmployeeIdDelete', 'version', version)
            const localVarPath = `/api/v{version}/Employee/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets an Employee by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionEmployeeIdGet: async (id: number, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVversionEmployeeIdGet', 'id', id)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionEmployeeIdGet', 'version', version)
            const localVarPath = `/api/v{version}/Employee/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a New Employee.
         * @param {string} version 
         * @param {CreateEmployeeCommand} [createEmployeeCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionEmployeePost: async (version: string, createEmployeeCommand?: CreateEmployeeCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionEmployeePost', 'version', version)
            const localVarPath = `/api/v{version}/Employee`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEmployeeCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an existing Employee
         * @param {string} version 
         * @param {UpdateEmployeeCommand} [updateEmployeeCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionEmployeePut: async (version: string, updateEmployeeCommand?: UpdateEmployeeCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionEmployeePut', 'version', version)
            const localVarPath = `/api/v{version}/Employee`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEmployeeCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Searchs a Employee
         * @param {string} version 
         * @param {string} [term] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {Array<string>} [f] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionEmployeeSearchGet: async (version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionEmployeeSearchGet', 'version', version)
            const localVarPath = `/api/v{version}/Employee/search`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (f) {
                localVarQueryParameter['f'] = f;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all Employees by typeahead
         * @param {string} text 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionEmployeeTypeaheadTextGet: async (text: string, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'text' is not null or undefined
            assertParamExists('apiVversionEmployeeTypeaheadTextGet', 'text', text)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionEmployeeTypeaheadTextGet', 'version', version)
            const localVarPath = `/api/v{version}/Employee/typeahead/{text}`
                .replace(`{${"text"}}`, encodeURIComponent(String(text)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of employees according to projectId.
         * @param {string} version 
         * @param {Array<number>} [projectIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionListEmployeesByProjectIdsGet: async (version: string, projectIds?: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionListEmployeesByProjectIdsGet', 'version', version)
            const localVarPath = `/api/v{version}/List/employeesByProjectIds`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectIds) {
                localVarQueryParameter['projectIds'] = projectIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of employees according to user entity based visibility rules.
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionListEmployeesGet: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionListEmployeesGet', 'version', version)
            const localVarPath = `/api/v{version}/List/employees`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of employees type-ahead according to user entity based visibility rules.
         * @param {string} version 
         * @param {string} [term] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionListEmployeesTypeAheadGet: async (version: string, term?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionListEmployeesTypeAheadGet', 'version', version)
            const localVarPath = `/api/v{version}/List/employeesTypeAhead`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets entities (as in the entities in userentity)
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionListEntitiesGet: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionListEntitiesGet', 'version', version)
            const localVarPath = `/api/v{version}/List/entities`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of providers according to user entity based visibility rules.
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionListProvidersGet: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionListProvidersGet', 'version', version)
            const localVarPath = `/api/v{version}/List/providers`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of providers type-ahead according to user entity based visibility rules.
         * @param {string} version 
         * @param {string} [term] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionListProvidersTypeAheadGet: async (version: string, term?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionListProvidersTypeAheadGet', 'version', version)
            const localVarPath = `/api/v{version}/List/providersTypeAhead`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of scopes.
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionListScopesGet: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionListScopesGet', 'version', version)
            const localVarPath = `/api/v{version}/List/scopes`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of users.
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionListUsersGet: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionListUsersGet', 'version', version)
            const localVarPath = `/api/v{version}/List/users`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns current user information
         * @param {string} version 
         * @param {string} [emailAddress] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionLoginEntitiesGet: async (version: string, emailAddress?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionLoginEntitiesGet', 'version', version)
            const localVarPath = `/api/v{version}/Login/entities`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (emailAddress !== undefined) {
                localVarQueryParameter['emailAddress'] = emailAddress;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Receives Cognito Callback and issues security tokens
         * @param {string} version 
         * @param {string} [authorizationCode] 
         * @param {string} [redirectUri] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionLoginPost: async (version: string, authorizationCode?: string, redirectUri?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionLoginPost', 'version', version)
            const localVarPath = `/api/v{version}/Login`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorizationCode !== undefined) {
                localVarQueryParameter['authorizationCode'] = authorizationCode;
            }

            if (redirectUri !== undefined) {
                localVarQueryParameter['redirectUri'] = redirectUri;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary refreshes security token
         * @param {string} version 
         * @param {string} [refreshToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionLoginRefreshPost: async (version: string, refreshToken?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionLoginRefreshPost', 'version', version)
            const localVarPath = `/api/v{version}/Login/refresh`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (refreshToken !== undefined) {
                localVarQueryParameter['refreshToken'] = refreshToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the list of all Providers
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionProviderAllGet: async (version: string, pageNum?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionProviderAllGet', 'version', version)
            const localVarPath = `/api/v{version}/Provider/all`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes an existing employee association to a Provider, by id
         * @param {number} id 
         * @param {number} idEmployee 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionProviderIdAssociateIdEmployeeDelete: async (id: number, idEmployee: number, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVversionProviderIdAssociateIdEmployeeDelete', 'id', id)
            // verify required parameter 'idEmployee' is not null or undefined
            assertParamExists('apiVversionProviderIdAssociateIdEmployeeDelete', 'idEmployee', idEmployee)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionProviderIdAssociateIdEmployeeDelete', 'version', version)
            const localVarPath = `/api/v{version}/Provider/{id}/associate/{idEmployee}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"idEmployee"}}`, encodeURIComponent(String(idEmployee)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Asociates an existing employee to a Provider, by id
         * @param {number} id 
         * @param {number} idEmployee 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionProviderIdAssociateIdEmployeePost: async (id: number, idEmployee: number, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVversionProviderIdAssociateIdEmployeePost', 'id', id)
            // verify required parameter 'idEmployee' is not null or undefined
            assertParamExists('apiVversionProviderIdAssociateIdEmployeePost', 'idEmployee', idEmployee)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionProviderIdAssociateIdEmployeePost', 'version', version)
            const localVarPath = `/api/v{version}/Provider/{id}/associate/{idEmployee}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"idEmployee"}}`, encodeURIComponent(String(idEmployee)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a Provider by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionProviderIdDelete: async (id: number, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVversionProviderIdDelete', 'id', id)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionProviderIdDelete', 'version', version)
            const localVarPath = `/api/v{version}/Provider/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets an Providers by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionProviderIdGet: async (id: number, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVversionProviderIdGet', 'id', id)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionProviderIdGet', 'version', version)
            const localVarPath = `/api/v{version}/Provider/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a New Provider.
         * @param {string} version 
         * @param {CreateProviderCommand} [createProviderCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionProviderPost: async (version: string, createProviderCommand?: CreateProviderCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionProviderPost', 'version', version)
            const localVarPath = `/api/v{version}/Provider`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProviderCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an existing Provider.
         * @param {string} version 
         * @param {UpdateProviderCommand} [updateProviderCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionProviderPut: async (version: string, updateProviderCommand?: UpdateProviderCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionProviderPut', 'version', version)
            const localVarPath = `/api/v{version}/Provider`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProviderCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Searchs a Provider
         * @param {string} version 
         * @param {string} [term] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {Array<string>} [f] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionProviderSearchGet: async (version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionProviderSearchGet', 'version', version)
            const localVarPath = `/api/v{version}/Provider/search`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (f) {
                localVarQueryParameter['f'] = f;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the list of all scopes
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionScopeAllGet: async (version: string, pageNum?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionScopeAllGet', 'version', version)
            const localVarPath = `/api/v{version}/Scope/all`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Activates/De-Activates a an existing Security Role.
         * @param {string} version 
         * @param {ActivateSecurityRoleCommand} [activateSecurityRoleCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSecurityRoleActivatePut: async (version: string, activateSecurityRoleCommand?: ActivateSecurityRoleCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionSecurityRoleActivatePut', 'version', version)
            const localVarPath = `/api/v{version}/SecurityRole/activate`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activateSecurityRoleCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the list of all SecurityRoles
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSecurityRoleAllGet: async (version: string, pageNum?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionSecurityRoleAllGet', 'version', version)
            const localVarPath = `/api/v{version}/SecurityRole/all`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a Security Role by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSecurityRoleIdDelete: async (id: number, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVversionSecurityRoleIdDelete', 'id', id)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionSecurityRoleIdDelete', 'version', version)
            const localVarPath = `/api/v{version}/SecurityRole/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets an SecurityRoles by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSecurityRoleIdGet: async (id: number, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVversionSecurityRoleIdGet', 'id', id)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionSecurityRoleIdGet', 'version', version)
            const localVarPath = `/api/v{version}/SecurityRole/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a New Security Role.
         * @param {string} version 
         * @param {CreateSecurityRoleCommand} [createSecurityRoleCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSecurityRolePost: async (version: string, createSecurityRoleCommand?: CreateSecurityRoleCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionSecurityRolePost', 'version', version)
            const localVarPath = `/api/v{version}/SecurityRole`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSecurityRoleCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an existing Security Role.
         * @param {string} version 
         * @param {UpdateSecurityRoleCommand} [updateSecurityRoleCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSecurityRolePut: async (version: string, updateSecurityRoleCommand?: UpdateSecurityRoleCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionSecurityRolePut', 'version', version)
            const localVarPath = `/api/v{version}/SecurityRole`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSecurityRoleCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Searchs a Security Role
         * @param {string} version 
         * @param {string} [term] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {Array<string>} [f] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSecurityRoleSearchGet: async (version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionSecurityRoleSearchGet', 'version', version)
            const localVarPath = `/api/v{version}/SecurityRole/search`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (f) {
                localVarQueryParameter['f'] = f;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the list of all User
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserAllGet: async (version: string, pageNum?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionUserAllGet', 'version', version)
            const localVarPath = `/api/v{version}/User/all`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a User by emailAddress
         * @param {string} emailAddress 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserEmailAddressEmailAddressGet: async (emailAddress: string, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailAddress' is not null or undefined
            assertParamExists('apiVversionUserEmailAddressEmailAddressGet', 'emailAddress', emailAddress)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionUserEmailAddressEmailAddressGet', 'version', version)
            const localVarPath = `/api/v{version}/User/emailAddress/{emailAddress}`
                .replace(`{${"emailAddress"}}`, encodeURIComponent(String(emailAddress)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a User by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserIdDelete: async (id: number, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVversionUserIdDelete', 'id', id)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionUserIdDelete', 'version', version)
            const localVarPath = `/api/v{version}/User/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets an User by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserIdGet: async (id: number, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVversionUserIdGet', 'id', id)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionUserIdGet', 'version', version)
            const localVarPath = `/api/v{version}/User/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the assigned security roles for a specific user. Payload requieres the UserId and the list of roles to be assigned.
         * @param {number} id 
         * @param {string} version 
         * @param {UpdateUserSecurityRolesCommand} [updateUserSecurityRolesCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserIdSecurityrolesPut: async (id: number, version: string, updateUserSecurityRolesCommand?: UpdateUserSecurityRolesCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVversionUserIdSecurityrolesPut', 'id', id)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionUserIdSecurityrolesPut', 'version', version)
            const localVarPath = `/api/v{version}/User/{id}/securityroles`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserSecurityRolesCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a New User.
         * @param {string} version 
         * @param {CreateUserCommand} [createUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserPost: async (version: string, createUserCommand?: CreateUserCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionUserPost', 'version', version)
            const localVarPath = `/api/v{version}/User`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an existing User
         * @param {string} version 
         * @param {UpdateUserCommand} [updateUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserPut: async (version: string, updateUserCommand?: UpdateUserCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionUserPut', 'version', version)
            const localVarPath = `/api/v{version}/User`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Searchs a User
         * @param {string} version 
         * @param {string} [term] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {Array<string>} [f] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserSearchGet: async (version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionUserSearchGet', 'version', version)
            const localVarPath = `/api/v{version}/User/search`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (f) {
                localVarQueryParameter['f'] = f;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all Users by typeahead
         * @param {string} text 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserTypeaheadTextGet: async (text: string, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'text' is not null or undefined
            assertParamExists('apiVversionUserTypeaheadTextGet', 'text', text)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionUserTypeaheadTextGet', 'version', version)
            const localVarPath = `/api/v{version}/User/typeahead/{text}`
                .replace(`{${"text"}}`, encodeURIComponent(String(text)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a UserEntity by userEntityId
         * @param {number} idUserEntity 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserUserEntityIdUserEntityDelete: async (idUserEntity: number, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idUserEntity' is not null or undefined
            assertParamExists('apiVversionUserUserEntityIdUserEntityDelete', 'idUserEntity', idUserEntity)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionUserUserEntityIdUserEntityDelete', 'version', version)
            const localVarPath = `/api/v{version}/User/userEntity/{idUserEntity}`
                .replace(`{${"idUserEntity"}}`, encodeURIComponent(String(idUserEntity)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of employees according to user entity based visibility rules.
         * @param {string} version 
         * @param {string} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserUserentitiesDisplaynameGet: async (version: string, ids?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionUserUserentitiesDisplaynameGet', 'version', version)
            const localVarPath = `/api/v{version}/User/userentities/displayname`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (ids !== undefined) {
                localVarQueryParameter['ids'] = ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the list of all UserEntities
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserUserentitiesGet: async (version: string, pageNum?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionUserUserentitiesGet', 'version', version)
            const localVarPath = `/api/v{version}/User/userentities`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmployeeApi - functional programming interface
 * @export
 */
export const EmployeeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmployeeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets the list of all Employees
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionEmployeeAllGet(version: string, pageNum?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeSearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionEmployeeAllGet(version, pageNum, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.apiVversionEmployeeAllGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets an Employee by emailAddress
         * @param {string} emailAddress 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionEmployeeEmailAddressEmailAddressGet(emailAddress: string, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionEmployeeEmailAddressEmailAddressGet(emailAddress, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.apiVversionEmployeeEmailAddressEmailAddressGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes a Employee by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionEmployeeIdDelete(id: number, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionEmployeeIdDelete(id, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.apiVversionEmployeeIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets an Employee by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionEmployeeIdGet(id: number, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeByIdResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionEmployeeIdGet(id, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.apiVversionEmployeeIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Creates a New Employee.
         * @param {string} version 
         * @param {CreateEmployeeCommand} [createEmployeeCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionEmployeePost(version: string, createEmployeeCommand?: CreateEmployeeCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionEmployeePost(version, createEmployeeCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.apiVversionEmployeePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates an existing Employee
         * @param {string} version 
         * @param {UpdateEmployeeCommand} [updateEmployeeCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionEmployeePut(version: string, updateEmployeeCommand?: UpdateEmployeeCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionEmployeePut(version, updateEmployeeCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.apiVversionEmployeePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Searchs a Employee
         * @param {string} version 
         * @param {string} [term] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {Array<string>} [f] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionEmployeeSearchGet(version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeSearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionEmployeeSearchGet(version, term, pageNum, pageSize, sort, order, f, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.apiVversionEmployeeSearchGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets all Employees by typeahead
         * @param {string} text 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionEmployeeTypeaheadTextGet(text: string, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionEmployeeTypeaheadTextGet(text, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.apiVversionEmployeeTypeaheadTextGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets a list of employees according to projectId.
         * @param {string} version 
         * @param {Array<number>} [projectIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionListEmployeesByProjectIdsGet(version: string, projectIds?: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListOptionDTOSearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionListEmployeesByProjectIdsGet(version, projectIds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.apiVversionListEmployeesByProjectIdsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets a list of employees according to user entity based visibility rules.
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionListEmployeesGet(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListOptionDTOSearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionListEmployeesGet(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.apiVversionListEmployeesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets a list of employees type-ahead according to user entity based visibility rules.
         * @param {string} version 
         * @param {string} [term] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionListEmployeesTypeAheadGet(version: string, term?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListOptionDTOSearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionListEmployeesTypeAheadGet(version, term, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.apiVversionListEmployeesTypeAheadGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets entities (as in the entities in userentity)
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionListEntitiesGet(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityListOptionDTOSearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionListEntitiesGet(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.apiVversionListEntitiesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets a list of providers according to user entity based visibility rules.
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionListProvidersGet(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListOptionDTOSearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionListProvidersGet(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.apiVversionListProvidersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets a list of providers type-ahead according to user entity based visibility rules.
         * @param {string} version 
         * @param {string} [term] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionListProvidersTypeAheadGet(version: string, term?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListOptionDTOSearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionListProvidersTypeAheadGet(version, term, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.apiVversionListProvidersTypeAheadGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets a list of scopes.
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionListScopesGet(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListOptionDTOSearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionListScopesGet(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.apiVversionListScopesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets a list of users.
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionListUsersGet(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListOptionDTOSearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionListUsersGet(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.apiVversionListUsersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary returns current user information
         * @param {string} version 
         * @param {string} [emailAddress] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionLoginEntitiesGet(version: string, emailAddress?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserEntityDetailsListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionLoginEntitiesGet(version, emailAddress, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.apiVversionLoginEntitiesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Receives Cognito Callback and issues security tokens
         * @param {string} version 
         * @param {string} [authorizationCode] 
         * @param {string} [redirectUri] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionLoginPost(version: string, authorizationCode?: string, redirectUri?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionLoginPost(version, authorizationCode, redirectUri, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.apiVversionLoginPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary refreshes security token
         * @param {string} version 
         * @param {string} [refreshToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionLoginRefreshPost(version: string, refreshToken?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionLoginRefreshPost(version, refreshToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.apiVversionLoginRefreshPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets the list of all Providers
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionProviderAllGet(version: string, pageNum?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderSearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionProviderAllGet(version, pageNum, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.apiVversionProviderAllGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes an existing employee association to a Provider, by id
         * @param {number} id 
         * @param {number} idEmployee 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionProviderIdAssociateIdEmployeeDelete(id: number, idEmployee: number, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionProviderIdAssociateIdEmployeeDelete(id, idEmployee, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.apiVversionProviderIdAssociateIdEmployeeDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Asociates an existing employee to a Provider, by id
         * @param {number} id 
         * @param {number} idEmployee 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionProviderIdAssociateIdEmployeePost(id: number, idEmployee: number, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionProviderIdAssociateIdEmployeePost(id, idEmployee, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.apiVversionProviderIdAssociateIdEmployeePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes a Provider by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionProviderIdDelete(id: number, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionProviderIdDelete(id, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.apiVversionProviderIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets an Providers by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionProviderIdGet(id: number, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionProviderIdGet(id, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.apiVversionProviderIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Creates a New Provider.
         * @param {string} version 
         * @param {CreateProviderCommand} [createProviderCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionProviderPost(version: string, createProviderCommand?: CreateProviderCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionProviderPost(version, createProviderCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.apiVversionProviderPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates an existing Provider.
         * @param {string} version 
         * @param {UpdateProviderCommand} [updateProviderCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionProviderPut(version: string, updateProviderCommand?: UpdateProviderCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionProviderPut(version, updateProviderCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.apiVversionProviderPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Searchs a Provider
         * @param {string} version 
         * @param {string} [term] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {Array<string>} [f] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionProviderSearchGet(version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderSearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionProviderSearchGet(version, term, pageNum, pageSize, sort, order, f, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.apiVversionProviderSearchGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets the list of all scopes
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionScopeAllGet(version: string, pageNum?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScopeDTOSearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionScopeAllGet(version, pageNum, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.apiVversionScopeAllGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Activates/De-Activates a an existing Security Role.
         * @param {string} version 
         * @param {ActivateSecurityRoleCommand} [activateSecurityRoleCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionSecurityRoleActivatePut(version: string, activateSecurityRoleCommand?: ActivateSecurityRoleCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecurityRoleDTOResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionSecurityRoleActivatePut(version, activateSecurityRoleCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.apiVversionSecurityRoleActivatePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets the list of all SecurityRoles
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionSecurityRoleAllGet(version: string, pageNum?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecurityRoleDTOSearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionSecurityRoleAllGet(version, pageNum, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.apiVversionSecurityRoleAllGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes a Security Role by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionSecurityRoleIdDelete(id: number, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionSecurityRoleIdDelete(id, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.apiVversionSecurityRoleIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets an SecurityRoles by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionSecurityRoleIdGet(id: number, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecurityRoleDTOResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionSecurityRoleIdGet(id, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.apiVversionSecurityRoleIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Creates a New Security Role.
         * @param {string} version 
         * @param {CreateSecurityRoleCommand} [createSecurityRoleCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionSecurityRolePost(version: string, createSecurityRoleCommand?: CreateSecurityRoleCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecurityRoleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionSecurityRolePost(version, createSecurityRoleCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.apiVversionSecurityRolePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates an existing Security Role.
         * @param {string} version 
         * @param {UpdateSecurityRoleCommand} [updateSecurityRoleCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionSecurityRolePut(version: string, updateSecurityRoleCommand?: UpdateSecurityRoleCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecurityRoleDTOResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionSecurityRolePut(version, updateSecurityRoleCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.apiVversionSecurityRolePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Searchs a Security Role
         * @param {string} version 
         * @param {string} [term] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {Array<string>} [f] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionSecurityRoleSearchGet(version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecurityRoleDTOSearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionSecurityRoleSearchGet(version, term, pageNum, pageSize, sort, order, f, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.apiVversionSecurityRoleSearchGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets the list of all User
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionUserAllGet(version: string, pageNum?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionUserAllGet(version, pageNum, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.apiVversionUserAllGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets a User by emailAddress
         * @param {string} emailAddress 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionUserEmailAddressEmailAddressGet(emailAddress: string, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserByIdResponseListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionUserEmailAddressEmailAddressGet(emailAddress, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.apiVversionUserEmailAddressEmailAddressGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes a User by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionUserIdDelete(id: number, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionUserIdDelete(id, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.apiVversionUserIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets an User by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionUserIdGet(id: number, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserByIdResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionUserIdGet(id, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.apiVversionUserIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates the assigned security roles for a specific user. Payload requieres the UserId and the list of roles to be assigned.
         * @param {number} id 
         * @param {string} version 
         * @param {UpdateUserSecurityRolesCommand} [updateUserSecurityRolesCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionUserIdSecurityrolesPut(id: number, version: string, updateUserSecurityRolesCommand?: UpdateUserSecurityRolesCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserByIdResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionUserIdSecurityrolesPut(id, version, updateUserSecurityRolesCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.apiVversionUserIdSecurityrolesPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Creates a New User.
         * @param {string} version 
         * @param {CreateUserCommand} [createUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionUserPost(version: string, createUserCommand?: CreateUserCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionUserPost(version, createUserCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.apiVversionUserPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates an existing User
         * @param {string} version 
         * @param {UpdateUserCommand} [updateUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionUserPut(version: string, updateUserCommand?: UpdateUserCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionUserPut(version, updateUserCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.apiVversionUserPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Searchs a User
         * @param {string} version 
         * @param {string} [term] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {Array<string>} [f] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionUserSearchGet(version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionUserSearchGet(version, term, pageNum, pageSize, sort, order, f, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.apiVversionUserSearchGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets all Users by typeahead
         * @param {string} text 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionUserTypeaheadTextGet(text: string, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionUserTypeaheadTextGet(text, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.apiVversionUserTypeaheadTextGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes a UserEntity by userEntityId
         * @param {number} idUserEntity 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionUserUserEntityIdUserEntityDelete(idUserEntity: number, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionUserUserEntityIdUserEntityDelete(idUserEntity, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.apiVversionUserUserEntityIdUserEntityDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets a list of employees according to user entity based visibility rules.
         * @param {string} version 
         * @param {string} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionUserUserentitiesDisplaynameGet(version: string, ids?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DisplayNameDTOSearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionUserUserentitiesDisplaynameGet(version, ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.apiVversionUserUserentitiesDisplaynameGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets the list of all UserEntities
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionUserUserentitiesGet(version: string, pageNum?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserEntityDetailsSearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionUserUserentitiesGet(version, pageNum, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.apiVversionUserUserentitiesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EmployeeApi - factory interface
 * @export
 */
export const EmployeeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmployeeApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets the list of all Employees
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionEmployeeAllGet(version: string, pageNum?: number, pageSize?: number, options?: RawAxiosRequestConfig): AxiosPromise<EmployeeSearchResponseResponse> {
            return localVarFp.apiVversionEmployeeAllGet(version, pageNum, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets an Employee by emailAddress
         * @param {string} emailAddress 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionEmployeeEmailAddressEmailAddressGet(emailAddress: string, version: string, options?: RawAxiosRequestConfig): AxiosPromise<EmployeeResponse> {
            return localVarFp.apiVversionEmployeeEmailAddressEmailAddressGet(emailAddress, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a Employee by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionEmployeeIdDelete(id: number, version: string, options?: RawAxiosRequestConfig): AxiosPromise<BooleanResponse> {
            return localVarFp.apiVversionEmployeeIdDelete(id, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets an Employee by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionEmployeeIdGet(id: number, version: string, options?: RawAxiosRequestConfig): AxiosPromise<EmployeeByIdResponseResponse> {
            return localVarFp.apiVversionEmployeeIdGet(id, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a New Employee.
         * @param {string} version 
         * @param {CreateEmployeeCommand} [createEmployeeCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionEmployeePost(version: string, createEmployeeCommand?: CreateEmployeeCommand, options?: RawAxiosRequestConfig): AxiosPromise<EmployeeResponse> {
            return localVarFp.apiVversionEmployeePost(version, createEmployeeCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an existing Employee
         * @param {string} version 
         * @param {UpdateEmployeeCommand} [updateEmployeeCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionEmployeePut(version: string, updateEmployeeCommand?: UpdateEmployeeCommand, options?: RawAxiosRequestConfig): AxiosPromise<EmployeeResponse> {
            return localVarFp.apiVversionEmployeePut(version, updateEmployeeCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Searchs a Employee
         * @param {string} version 
         * @param {string} [term] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {Array<string>} [f] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionEmployeeSearchGet(version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<EmployeeSearchResponseResponse> {
            return localVarFp.apiVversionEmployeeSearchGet(version, term, pageNum, pageSize, sort, order, f, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all Employees by typeahead
         * @param {string} text 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionEmployeeTypeaheadTextGet(text: string, version: string, options?: RawAxiosRequestConfig): AxiosPromise<EmployeeListResponse> {
            return localVarFp.apiVversionEmployeeTypeaheadTextGet(text, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of employees according to projectId.
         * @param {string} version 
         * @param {Array<number>} [projectIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionListEmployeesByProjectIdsGet(version: string, projectIds?: Array<number>, options?: RawAxiosRequestConfig): AxiosPromise<ListOptionDTOSearchResponseResponse> {
            return localVarFp.apiVversionListEmployeesByProjectIdsGet(version, projectIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of employees according to user entity based visibility rules.
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionListEmployeesGet(version: string, options?: RawAxiosRequestConfig): AxiosPromise<ListOptionDTOSearchResponseResponse> {
            return localVarFp.apiVversionListEmployeesGet(version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of employees type-ahead according to user entity based visibility rules.
         * @param {string} version 
         * @param {string} [term] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionListEmployeesTypeAheadGet(version: string, term?: string, options?: RawAxiosRequestConfig): AxiosPromise<ListOptionDTOSearchResponseResponse> {
            return localVarFp.apiVversionListEmployeesTypeAheadGet(version, term, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets entities (as in the entities in userentity)
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionListEntitiesGet(version: string, options?: RawAxiosRequestConfig): AxiosPromise<EntityListOptionDTOSearchResponseResponse> {
            return localVarFp.apiVversionListEntitiesGet(version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of providers according to user entity based visibility rules.
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionListProvidersGet(version: string, options?: RawAxiosRequestConfig): AxiosPromise<ListOptionDTOSearchResponseResponse> {
            return localVarFp.apiVversionListProvidersGet(version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of providers type-ahead according to user entity based visibility rules.
         * @param {string} version 
         * @param {string} [term] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionListProvidersTypeAheadGet(version: string, term?: string, options?: RawAxiosRequestConfig): AxiosPromise<ListOptionDTOSearchResponseResponse> {
            return localVarFp.apiVversionListProvidersTypeAheadGet(version, term, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of scopes.
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionListScopesGet(version: string, options?: RawAxiosRequestConfig): AxiosPromise<ListOptionDTOSearchResponseResponse> {
            return localVarFp.apiVversionListScopesGet(version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of users.
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionListUsersGet(version: string, options?: RawAxiosRequestConfig): AxiosPromise<ListOptionDTOSearchResponseResponse> {
            return localVarFp.apiVversionListUsersGet(version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns current user information
         * @param {string} version 
         * @param {string} [emailAddress] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionLoginEntitiesGet(version: string, emailAddress?: string, options?: RawAxiosRequestConfig): AxiosPromise<UserEntityDetailsListResponse> {
            return localVarFp.apiVversionLoginEntitiesGet(version, emailAddress, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Receives Cognito Callback and issues security tokens
         * @param {string} version 
         * @param {string} [authorizationCode] 
         * @param {string} [redirectUri] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionLoginPost(version: string, authorizationCode?: string, redirectUri?: string, options?: RawAxiosRequestConfig): AxiosPromise<StringResponse> {
            return localVarFp.apiVversionLoginPost(version, authorizationCode, redirectUri, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary refreshes security token
         * @param {string} version 
         * @param {string} [refreshToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionLoginRefreshPost(version: string, refreshToken?: string, options?: RawAxiosRequestConfig): AxiosPromise<StringResponse> {
            return localVarFp.apiVversionLoginRefreshPost(version, refreshToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the list of all Providers
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionProviderAllGet(version: string, pageNum?: number, pageSize?: number, options?: RawAxiosRequestConfig): AxiosPromise<ProviderSearchResponseResponse> {
            return localVarFp.apiVversionProviderAllGet(version, pageNum, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes an existing employee association to a Provider, by id
         * @param {number} id 
         * @param {number} idEmployee 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionProviderIdAssociateIdEmployeeDelete(id: number, idEmployee: number, version: string, options?: RawAxiosRequestConfig): AxiosPromise<ProviderResponse> {
            return localVarFp.apiVversionProviderIdAssociateIdEmployeeDelete(id, idEmployee, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Asociates an existing employee to a Provider, by id
         * @param {number} id 
         * @param {number} idEmployee 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionProviderIdAssociateIdEmployeePost(id: number, idEmployee: number, version: string, options?: RawAxiosRequestConfig): AxiosPromise<ProviderResponse> {
            return localVarFp.apiVversionProviderIdAssociateIdEmployeePost(id, idEmployee, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a Provider by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionProviderIdDelete(id: number, version: string, options?: RawAxiosRequestConfig): AxiosPromise<BooleanResponse> {
            return localVarFp.apiVversionProviderIdDelete(id, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets an Providers by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionProviderIdGet(id: number, version: string, options?: RawAxiosRequestConfig): AxiosPromise<ProviderResponse> {
            return localVarFp.apiVversionProviderIdGet(id, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a New Provider.
         * @param {string} version 
         * @param {CreateProviderCommand} [createProviderCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionProviderPost(version: string, createProviderCommand?: CreateProviderCommand, options?: RawAxiosRequestConfig): AxiosPromise<ProviderResponse> {
            return localVarFp.apiVversionProviderPost(version, createProviderCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an existing Provider.
         * @param {string} version 
         * @param {UpdateProviderCommand} [updateProviderCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionProviderPut(version: string, updateProviderCommand?: UpdateProviderCommand, options?: RawAxiosRequestConfig): AxiosPromise<ProviderResponse> {
            return localVarFp.apiVversionProviderPut(version, updateProviderCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Searchs a Provider
         * @param {string} version 
         * @param {string} [term] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {Array<string>} [f] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionProviderSearchGet(version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<ProviderSearchResponseResponse> {
            return localVarFp.apiVversionProviderSearchGet(version, term, pageNum, pageSize, sort, order, f, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the list of all scopes
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionScopeAllGet(version: string, pageNum?: number, pageSize?: number, options?: RawAxiosRequestConfig): AxiosPromise<ScopeDTOSearchResponseResponse> {
            return localVarFp.apiVversionScopeAllGet(version, pageNum, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Activates/De-Activates a an existing Security Role.
         * @param {string} version 
         * @param {ActivateSecurityRoleCommand} [activateSecurityRoleCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSecurityRoleActivatePut(version: string, activateSecurityRoleCommand?: ActivateSecurityRoleCommand, options?: RawAxiosRequestConfig): AxiosPromise<SecurityRoleDTOResponse> {
            return localVarFp.apiVversionSecurityRoleActivatePut(version, activateSecurityRoleCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the list of all SecurityRoles
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSecurityRoleAllGet(version: string, pageNum?: number, pageSize?: number, options?: RawAxiosRequestConfig): AxiosPromise<SecurityRoleDTOSearchResponseResponse> {
            return localVarFp.apiVversionSecurityRoleAllGet(version, pageNum, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a Security Role by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSecurityRoleIdDelete(id: number, version: string, options?: RawAxiosRequestConfig): AxiosPromise<BooleanResponse> {
            return localVarFp.apiVversionSecurityRoleIdDelete(id, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets an SecurityRoles by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSecurityRoleIdGet(id: number, version: string, options?: RawAxiosRequestConfig): AxiosPromise<SecurityRoleDTOResponse> {
            return localVarFp.apiVversionSecurityRoleIdGet(id, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a New Security Role.
         * @param {string} version 
         * @param {CreateSecurityRoleCommand} [createSecurityRoleCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSecurityRolePost(version: string, createSecurityRoleCommand?: CreateSecurityRoleCommand, options?: RawAxiosRequestConfig): AxiosPromise<SecurityRoleResponse> {
            return localVarFp.apiVversionSecurityRolePost(version, createSecurityRoleCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an existing Security Role.
         * @param {string} version 
         * @param {UpdateSecurityRoleCommand} [updateSecurityRoleCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSecurityRolePut(version: string, updateSecurityRoleCommand?: UpdateSecurityRoleCommand, options?: RawAxiosRequestConfig): AxiosPromise<SecurityRoleDTOResponse> {
            return localVarFp.apiVversionSecurityRolePut(version, updateSecurityRoleCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Searchs a Security Role
         * @param {string} version 
         * @param {string} [term] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {Array<string>} [f] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSecurityRoleSearchGet(version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<SecurityRoleDTOSearchResponseResponse> {
            return localVarFp.apiVversionSecurityRoleSearchGet(version, term, pageNum, pageSize, sort, order, f, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the list of all User
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserAllGet(version: string, pageNum?: number, pageSize?: number, options?: RawAxiosRequestConfig): AxiosPromise<UserSearchResponseResponse> {
            return localVarFp.apiVversionUserAllGet(version, pageNum, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a User by emailAddress
         * @param {string} emailAddress 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserEmailAddressEmailAddressGet(emailAddress: string, version: string, options?: RawAxiosRequestConfig): AxiosPromise<UserByIdResponseListResponse> {
            return localVarFp.apiVversionUserEmailAddressEmailAddressGet(emailAddress, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a User by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserIdDelete(id: number, version: string, options?: RawAxiosRequestConfig): AxiosPromise<BooleanResponse> {
            return localVarFp.apiVversionUserIdDelete(id, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets an User by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserIdGet(id: number, version: string, options?: RawAxiosRequestConfig): AxiosPromise<UserByIdResponseResponse> {
            return localVarFp.apiVversionUserIdGet(id, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the assigned security roles for a specific user. Payload requieres the UserId and the list of roles to be assigned.
         * @param {number} id 
         * @param {string} version 
         * @param {UpdateUserSecurityRolesCommand} [updateUserSecurityRolesCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserIdSecurityrolesPut(id: number, version: string, updateUserSecurityRolesCommand?: UpdateUserSecurityRolesCommand, options?: RawAxiosRequestConfig): AxiosPromise<UserByIdResponseResponse> {
            return localVarFp.apiVversionUserIdSecurityrolesPut(id, version, updateUserSecurityRolesCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a New User.
         * @param {string} version 
         * @param {CreateUserCommand} [createUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserPost(version: string, createUserCommand?: CreateUserCommand, options?: RawAxiosRequestConfig): AxiosPromise<UserResponse> {
            return localVarFp.apiVversionUserPost(version, createUserCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an existing User
         * @param {string} version 
         * @param {UpdateUserCommand} [updateUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserPut(version: string, updateUserCommand?: UpdateUserCommand, options?: RawAxiosRequestConfig): AxiosPromise<UserResponse> {
            return localVarFp.apiVversionUserPut(version, updateUserCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Searchs a User
         * @param {string} version 
         * @param {string} [term] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {Array<string>} [f] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserSearchGet(version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<UserSearchResponseResponse> {
            return localVarFp.apiVversionUserSearchGet(version, term, pageNum, pageSize, sort, order, f, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all Users by typeahead
         * @param {string} text 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserTypeaheadTextGet(text: string, version: string, options?: RawAxiosRequestConfig): AxiosPromise<UserListResponse> {
            return localVarFp.apiVversionUserTypeaheadTextGet(text, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a UserEntity by userEntityId
         * @param {number} idUserEntity 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserUserEntityIdUserEntityDelete(idUserEntity: number, version: string, options?: RawAxiosRequestConfig): AxiosPromise<BooleanResponse> {
            return localVarFp.apiVversionUserUserEntityIdUserEntityDelete(idUserEntity, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of employees according to user entity based visibility rules.
         * @param {string} version 
         * @param {string} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserUserentitiesDisplaynameGet(version: string, ids?: string, options?: RawAxiosRequestConfig): AxiosPromise<DisplayNameDTOSearchResponseResponse> {
            return localVarFp.apiVversionUserUserentitiesDisplaynameGet(version, ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the list of all UserEntities
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserUserentitiesGet(version: string, pageNum?: number, pageSize?: number, options?: RawAxiosRequestConfig): AxiosPromise<UserEntityDetailsSearchResponseResponse> {
            return localVarFp.apiVversionUserUserentitiesGet(version, pageNum, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmployeeApi - object-oriented interface
 * @export
 * @class EmployeeApi
 * @extends {BaseAPI}
 */
export class EmployeeApi extends BaseAPI {
    /**
     * 
     * @summary Gets the list of all Employees
     * @param {string} version 
     * @param {number} [pageNum] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public apiVversionEmployeeAllGet(version: string, pageNum?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).apiVversionEmployeeAllGet(version, pageNum, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets an Employee by emailAddress
     * @param {string} emailAddress 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public apiVversionEmployeeEmailAddressEmailAddressGet(emailAddress: string, version: string, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).apiVversionEmployeeEmailAddressEmailAddressGet(emailAddress, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a Employee by id
     * @param {number} id 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public apiVversionEmployeeIdDelete(id: number, version: string, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).apiVversionEmployeeIdDelete(id, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets an Employee by id
     * @param {number} id 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public apiVversionEmployeeIdGet(id: number, version: string, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).apiVversionEmployeeIdGet(id, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a New Employee.
     * @param {string} version 
     * @param {CreateEmployeeCommand} [createEmployeeCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public apiVversionEmployeePost(version: string, createEmployeeCommand?: CreateEmployeeCommand, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).apiVversionEmployeePost(version, createEmployeeCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates an existing Employee
     * @param {string} version 
     * @param {UpdateEmployeeCommand} [updateEmployeeCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public apiVversionEmployeePut(version: string, updateEmployeeCommand?: UpdateEmployeeCommand, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).apiVversionEmployeePut(version, updateEmployeeCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Searchs a Employee
     * @param {string} version 
     * @param {string} [term] 
     * @param {number} [pageNum] 
     * @param {number} [pageSize] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {Array<string>} [f] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public apiVversionEmployeeSearchGet(version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).apiVversionEmployeeSearchGet(version, term, pageNum, pageSize, sort, order, f, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all Employees by typeahead
     * @param {string} text 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public apiVversionEmployeeTypeaheadTextGet(text: string, version: string, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).apiVversionEmployeeTypeaheadTextGet(text, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of employees according to projectId.
     * @param {string} version 
     * @param {Array<number>} [projectIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public apiVversionListEmployeesByProjectIdsGet(version: string, projectIds?: Array<number>, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).apiVversionListEmployeesByProjectIdsGet(version, projectIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of employees according to user entity based visibility rules.
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public apiVversionListEmployeesGet(version: string, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).apiVversionListEmployeesGet(version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of employees type-ahead according to user entity based visibility rules.
     * @param {string} version 
     * @param {string} [term] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public apiVversionListEmployeesTypeAheadGet(version: string, term?: string, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).apiVversionListEmployeesTypeAheadGet(version, term, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets entities (as in the entities in userentity)
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public apiVversionListEntitiesGet(version: string, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).apiVversionListEntitiesGet(version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of providers according to user entity based visibility rules.
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public apiVversionListProvidersGet(version: string, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).apiVversionListProvidersGet(version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of providers type-ahead according to user entity based visibility rules.
     * @param {string} version 
     * @param {string} [term] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public apiVversionListProvidersTypeAheadGet(version: string, term?: string, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).apiVversionListProvidersTypeAheadGet(version, term, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of scopes.
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public apiVversionListScopesGet(version: string, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).apiVversionListScopesGet(version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of users.
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public apiVversionListUsersGet(version: string, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).apiVversionListUsersGet(version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns current user information
     * @param {string} version 
     * @param {string} [emailAddress] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public apiVversionLoginEntitiesGet(version: string, emailAddress?: string, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).apiVversionLoginEntitiesGet(version, emailAddress, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Receives Cognito Callback and issues security tokens
     * @param {string} version 
     * @param {string} [authorizationCode] 
     * @param {string} [redirectUri] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public apiVversionLoginPost(version: string, authorizationCode?: string, redirectUri?: string, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).apiVversionLoginPost(version, authorizationCode, redirectUri, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary refreshes security token
     * @param {string} version 
     * @param {string} [refreshToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public apiVversionLoginRefreshPost(version: string, refreshToken?: string, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).apiVversionLoginRefreshPost(version, refreshToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the list of all Providers
     * @param {string} version 
     * @param {number} [pageNum] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public apiVversionProviderAllGet(version: string, pageNum?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).apiVversionProviderAllGet(version, pageNum, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes an existing employee association to a Provider, by id
     * @param {number} id 
     * @param {number} idEmployee 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public apiVversionProviderIdAssociateIdEmployeeDelete(id: number, idEmployee: number, version: string, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).apiVversionProviderIdAssociateIdEmployeeDelete(id, idEmployee, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Asociates an existing employee to a Provider, by id
     * @param {number} id 
     * @param {number} idEmployee 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public apiVversionProviderIdAssociateIdEmployeePost(id: number, idEmployee: number, version: string, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).apiVversionProviderIdAssociateIdEmployeePost(id, idEmployee, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a Provider by id
     * @param {number} id 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public apiVversionProviderIdDelete(id: number, version: string, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).apiVversionProviderIdDelete(id, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets an Providers by id
     * @param {number} id 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public apiVversionProviderIdGet(id: number, version: string, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).apiVversionProviderIdGet(id, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a New Provider.
     * @param {string} version 
     * @param {CreateProviderCommand} [createProviderCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public apiVversionProviderPost(version: string, createProviderCommand?: CreateProviderCommand, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).apiVversionProviderPost(version, createProviderCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates an existing Provider.
     * @param {string} version 
     * @param {UpdateProviderCommand} [updateProviderCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public apiVversionProviderPut(version: string, updateProviderCommand?: UpdateProviderCommand, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).apiVversionProviderPut(version, updateProviderCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Searchs a Provider
     * @param {string} version 
     * @param {string} [term] 
     * @param {number} [pageNum] 
     * @param {number} [pageSize] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {Array<string>} [f] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public apiVversionProviderSearchGet(version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).apiVversionProviderSearchGet(version, term, pageNum, pageSize, sort, order, f, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the list of all scopes
     * @param {string} version 
     * @param {number} [pageNum] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public apiVversionScopeAllGet(version: string, pageNum?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).apiVversionScopeAllGet(version, pageNum, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Activates/De-Activates a an existing Security Role.
     * @param {string} version 
     * @param {ActivateSecurityRoleCommand} [activateSecurityRoleCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public apiVversionSecurityRoleActivatePut(version: string, activateSecurityRoleCommand?: ActivateSecurityRoleCommand, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).apiVversionSecurityRoleActivatePut(version, activateSecurityRoleCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the list of all SecurityRoles
     * @param {string} version 
     * @param {number} [pageNum] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public apiVversionSecurityRoleAllGet(version: string, pageNum?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).apiVversionSecurityRoleAllGet(version, pageNum, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a Security Role by id
     * @param {number} id 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public apiVversionSecurityRoleIdDelete(id: number, version: string, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).apiVversionSecurityRoleIdDelete(id, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets an SecurityRoles by id
     * @param {number} id 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public apiVversionSecurityRoleIdGet(id: number, version: string, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).apiVversionSecurityRoleIdGet(id, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a New Security Role.
     * @param {string} version 
     * @param {CreateSecurityRoleCommand} [createSecurityRoleCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public apiVversionSecurityRolePost(version: string, createSecurityRoleCommand?: CreateSecurityRoleCommand, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).apiVversionSecurityRolePost(version, createSecurityRoleCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates an existing Security Role.
     * @param {string} version 
     * @param {UpdateSecurityRoleCommand} [updateSecurityRoleCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public apiVversionSecurityRolePut(version: string, updateSecurityRoleCommand?: UpdateSecurityRoleCommand, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).apiVversionSecurityRolePut(version, updateSecurityRoleCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Searchs a Security Role
     * @param {string} version 
     * @param {string} [term] 
     * @param {number} [pageNum] 
     * @param {number} [pageSize] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {Array<string>} [f] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public apiVversionSecurityRoleSearchGet(version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).apiVversionSecurityRoleSearchGet(version, term, pageNum, pageSize, sort, order, f, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the list of all User
     * @param {string} version 
     * @param {number} [pageNum] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public apiVversionUserAllGet(version: string, pageNum?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).apiVversionUserAllGet(version, pageNum, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a User by emailAddress
     * @param {string} emailAddress 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public apiVversionUserEmailAddressEmailAddressGet(emailAddress: string, version: string, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).apiVversionUserEmailAddressEmailAddressGet(emailAddress, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a User by id
     * @param {number} id 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public apiVversionUserIdDelete(id: number, version: string, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).apiVversionUserIdDelete(id, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets an User by id
     * @param {number} id 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public apiVversionUserIdGet(id: number, version: string, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).apiVversionUserIdGet(id, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the assigned security roles for a specific user. Payload requieres the UserId and the list of roles to be assigned.
     * @param {number} id 
     * @param {string} version 
     * @param {UpdateUserSecurityRolesCommand} [updateUserSecurityRolesCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public apiVversionUserIdSecurityrolesPut(id: number, version: string, updateUserSecurityRolesCommand?: UpdateUserSecurityRolesCommand, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).apiVversionUserIdSecurityrolesPut(id, version, updateUserSecurityRolesCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a New User.
     * @param {string} version 
     * @param {CreateUserCommand} [createUserCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public apiVversionUserPost(version: string, createUserCommand?: CreateUserCommand, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).apiVversionUserPost(version, createUserCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates an existing User
     * @param {string} version 
     * @param {UpdateUserCommand} [updateUserCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public apiVversionUserPut(version: string, updateUserCommand?: UpdateUserCommand, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).apiVversionUserPut(version, updateUserCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Searchs a User
     * @param {string} version 
     * @param {string} [term] 
     * @param {number} [pageNum] 
     * @param {number} [pageSize] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {Array<string>} [f] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public apiVversionUserSearchGet(version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).apiVversionUserSearchGet(version, term, pageNum, pageSize, sort, order, f, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all Users by typeahead
     * @param {string} text 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public apiVversionUserTypeaheadTextGet(text: string, version: string, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).apiVversionUserTypeaheadTextGet(text, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a UserEntity by userEntityId
     * @param {number} idUserEntity 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public apiVversionUserUserEntityIdUserEntityDelete(idUserEntity: number, version: string, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).apiVversionUserUserEntityIdUserEntityDelete(idUserEntity, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of employees according to user entity based visibility rules.
     * @param {string} version 
     * @param {string} [ids] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public apiVversionUserUserentitiesDisplaynameGet(version: string, ids?: string, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).apiVversionUserUserentitiesDisplaynameGet(version, ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the list of all UserEntities
     * @param {string} version 
     * @param {number} [pageNum] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public apiVversionUserUserentitiesGet(version: string, pageNum?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).apiVversionUserUserentitiesGet(version, pageNum, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}


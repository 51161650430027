/* tslint:disable */
/* eslint-disable */
/**
 * Intranet - ContactInfo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { BooleanResponse } from '../models';
// @ts-ignore
import type { ContactInfoListResponse } from '../models';
// @ts-ignore
import type { ContactInfoResponse } from '../models';
// @ts-ignore
import type { CreateContactInfoCommand } from '../models';
// @ts-ignore
import type { ListOptionDTOSearchResponseResponse } from '../models';
// @ts-ignore
import type { StatusCodeResult } from '../models';
// @ts-ignore
import type { StringResponse } from '../models';
// @ts-ignore
import type { UpdateContactInfoCommand } from '../models';
/**
 * ContactinfoApi - axios parameter creator
 * @export
 */
export const ContactinfoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes an ContactInfo by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionContactInfoContactinfoIdDelete: async (id: number, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVversionContactInfoContactinfoIdDelete', 'id', id)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionContactInfoContactinfoIdDelete', 'version', version)
            const localVarPath = `/api/v{version}/ContactInfo/contactinfo/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Shows ContactInfo By Id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionContactInfoContactinfoIdGet: async (id: number, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVversionContactInfoContactinfoIdGet', 'id', id)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionContactInfoContactinfoIdGet', 'version', version)
            const localVarPath = `/api/v{version}/ContactInfo/contactinfo/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a ContactInfo
         * @param {string} version 
         * @param {CreateContactInfoCommand} [createContactInfoCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionContactInfoContactinfoPost: async (version: string, createContactInfoCommand?: CreateContactInfoCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionContactInfoContactinfoPost', 'version', version)
            const localVarPath = `/api/v{version}/ContactInfo/contactinfo`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createContactInfoCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a existing ContactInfo
         * @param {string} version 
         * @param {UpdateContactInfoCommand} [updateContactInfoCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionContactInfoContactinfoPut: async (version: string, updateContactInfoCommand?: UpdateContactInfoCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionContactInfoContactinfoPut', 'version', version)
            const localVarPath = `/api/v{version}/ContactInfo/contactinfo`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateContactInfoCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of ContactInfos By Id
         * @param {string} version 
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionContactInfoContactinfosGet: async (version: string, ids?: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionContactInfoContactinfosGet', 'version', version)
            const localVarPath = `/api/v{version}/ContactInfo/contactinfos`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (ids) {
                localVarQueryParameter['Ids'] = ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary contactType ListOptionDto
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionListContactTypeGet: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionListContactTypeGet', 'version', version)
            const localVarPath = `/api/v{version}/List/contactType`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContactinfoApi - functional programming interface
 * @export
 */
export const ContactinfoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContactinfoApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Deletes an ContactInfo by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionContactInfoContactinfoIdDelete(id: number, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionContactInfoContactinfoIdDelete(id, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContactinfoApi.apiVversionContactInfoContactinfoIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Shows ContactInfo By Id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionContactInfoContactinfoIdGet(id: number, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionContactInfoContactinfoIdGet(id, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContactinfoApi.apiVversionContactInfoContactinfoIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Creates a ContactInfo
         * @param {string} version 
         * @param {CreateContactInfoCommand} [createContactInfoCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionContactInfoContactinfoPost(version: string, createContactInfoCommand?: CreateContactInfoCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionContactInfoContactinfoPost(version, createContactInfoCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContactinfoApi.apiVversionContactInfoContactinfoPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates a existing ContactInfo
         * @param {string} version 
         * @param {UpdateContactInfoCommand} [updateContactInfoCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionContactInfoContactinfoPut(version: string, updateContactInfoCommand?: UpdateContactInfoCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionContactInfoContactinfoPut(version, updateContactInfoCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContactinfoApi.apiVversionContactInfoContactinfoPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets a list of ContactInfos By Id
         * @param {string} version 
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionContactInfoContactinfosGet(version: string, ids?: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactInfoListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionContactInfoContactinfosGet(version, ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContactinfoApi.apiVversionContactInfoContactinfosGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary contactType ListOptionDto
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionListContactTypeGet(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListOptionDTOSearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionListContactTypeGet(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContactinfoApi.apiVversionListContactTypeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ContactinfoApi - factory interface
 * @export
 */
export const ContactinfoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContactinfoApiFp(configuration)
    return {
        /**
         * 
         * @summary Deletes an ContactInfo by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionContactInfoContactinfoIdDelete(id: number, version: string, options?: RawAxiosRequestConfig): AxiosPromise<BooleanResponse> {
            return localVarFp.apiVversionContactInfoContactinfoIdDelete(id, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Shows ContactInfo By Id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionContactInfoContactinfoIdGet(id: number, version: string, options?: RawAxiosRequestConfig): AxiosPromise<ContactInfoResponse> {
            return localVarFp.apiVversionContactInfoContactinfoIdGet(id, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a ContactInfo
         * @param {string} version 
         * @param {CreateContactInfoCommand} [createContactInfoCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionContactInfoContactinfoPost(version: string, createContactInfoCommand?: CreateContactInfoCommand, options?: RawAxiosRequestConfig): AxiosPromise<ContactInfoResponse> {
            return localVarFp.apiVversionContactInfoContactinfoPost(version, createContactInfoCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a existing ContactInfo
         * @param {string} version 
         * @param {UpdateContactInfoCommand} [updateContactInfoCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionContactInfoContactinfoPut(version: string, updateContactInfoCommand?: UpdateContactInfoCommand, options?: RawAxiosRequestConfig): AxiosPromise<ContactInfoResponse> {
            return localVarFp.apiVversionContactInfoContactinfoPut(version, updateContactInfoCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of ContactInfos By Id
         * @param {string} version 
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionContactInfoContactinfosGet(version: string, ids?: Array<number>, options?: RawAxiosRequestConfig): AxiosPromise<ContactInfoListResponse> {
            return localVarFp.apiVversionContactInfoContactinfosGet(version, ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary contactType ListOptionDto
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionListContactTypeGet(version: string, options?: RawAxiosRequestConfig): AxiosPromise<ListOptionDTOSearchResponseResponse> {
            return localVarFp.apiVversionListContactTypeGet(version, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContactinfoApi - object-oriented interface
 * @export
 * @class ContactinfoApi
 * @extends {BaseAPI}
 */
export class ContactinfoApi extends BaseAPI {
    /**
     * 
     * @summary Deletes an ContactInfo by id
     * @param {number} id 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactinfoApi
     */
    public apiVversionContactInfoContactinfoIdDelete(id: number, version: string, options?: RawAxiosRequestConfig) {
        return ContactinfoApiFp(this.configuration).apiVversionContactInfoContactinfoIdDelete(id, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Shows ContactInfo By Id
     * @param {number} id 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactinfoApi
     */
    public apiVversionContactInfoContactinfoIdGet(id: number, version: string, options?: RawAxiosRequestConfig) {
        return ContactinfoApiFp(this.configuration).apiVversionContactInfoContactinfoIdGet(id, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a ContactInfo
     * @param {string} version 
     * @param {CreateContactInfoCommand} [createContactInfoCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactinfoApi
     */
    public apiVversionContactInfoContactinfoPost(version: string, createContactInfoCommand?: CreateContactInfoCommand, options?: RawAxiosRequestConfig) {
        return ContactinfoApiFp(this.configuration).apiVversionContactInfoContactinfoPost(version, createContactInfoCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a existing ContactInfo
     * @param {string} version 
     * @param {UpdateContactInfoCommand} [updateContactInfoCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactinfoApi
     */
    public apiVversionContactInfoContactinfoPut(version: string, updateContactInfoCommand?: UpdateContactInfoCommand, options?: RawAxiosRequestConfig) {
        return ContactinfoApiFp(this.configuration).apiVversionContactInfoContactinfoPut(version, updateContactInfoCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of ContactInfos By Id
     * @param {string} version 
     * @param {Array<number>} [ids] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactinfoApi
     */
    public apiVversionContactInfoContactinfosGet(version: string, ids?: Array<number>, options?: RawAxiosRequestConfig) {
        return ContactinfoApiFp(this.configuration).apiVversionContactInfoContactinfosGet(version, ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary contactType ListOptionDto
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactinfoApi
     */
    public apiVversionListContactTypeGet(version: string, options?: RawAxiosRequestConfig) {
        return ContactinfoApiFp(this.configuration).apiVversionListContactTypeGet(version, options).then((request) => request(this.axios, this.basePath));
    }
}


import React, { FC } from 'react';
import Select from 'react-select';
import { Country } from '../axiosApi/models';
import intl from 'components/i18n/ReactIntlWrapper';
import { sortCollection } from '../common/utils';
import { SORT_TYPES } from '../common/constants';

type CountrySelectProps = {
  handleCountryChange: any,
  selectedCountry: any,
  countries: any,
  inputCountry: any,
  isClearable?: boolean,
  disabled?: boolean,
};

const CountrySelect: FC<CountrySelectProps> = ({ handleCountryChange, selectedCountry, countries, inputCountry, isClearable = false, disabled = false }) => {

  const countriesSortedByName = sortCollection(countries, "label", SORT_TYPES.ASC);

  const customStyles = {
    dropdownIndicator: (base: any) => ({
      ...base,
      cursor: 'pointer',
    }),
    menuPortal: (base: any) => ({
      ...base,
      zIndex: 9999, 
    }),
  };

  return (
    <Select
      id="addressCountry"
      styles={customStyles}
      name="addressCountry"
      getOptionLabel={e => intl.get(e.labelKey)}
      getOptionValue={e => e.id}
      placeholder={intl.get('addressForm.placeholder.country')}
      value={countries != null && selectedCountry != null && inputCountry != ''  ? countries.find((country: Country) => country.labelKey === selectedCountry) : ''}
      onChange={handleCountryChange}
      isLoading={countries == null}
      options={countriesSortedByName}
      isClearable={isClearable}
      menuPortalTarget={document.body}
      isDisabled={disabled}
    />
  );
}

export default CountrySelect;

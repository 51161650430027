import React, { FC } from 'react';
import Select from 'react-select';
import intl from 'components/i18n/ReactIntlWrapper';

type RequestStatusSelectProps = {
  requestStatus: any,
  selectedStatus: any,
  handleStatusChange: any,
  isClearable: boolean
};

const RequestStatusSelect: FC<RequestStatusSelectProps> = ({ requestStatus, selectedStatus, handleStatusChange, isClearable }) => {

  if(requestStatus)  requestStatus = requestStatus.filter(elem => elem.label != '')

    const customStyles = {
      dropdownIndicator: (base: any) => ({
        ...base,
        cursor: 'pointer',
      }),
      menuPortal: (base: any) => ({
        ...base,
        zIndex: 9999, 
      }),
    };

  return (
    <>
      <Select
        styles={customStyles}
        id='requestStatus'
        name='requestStatus'
        getOptionLabel={e => intl.get(e.label)}
        getOptionValue={e => e.id}
        placeholder={intl.get('requestStatusSelect.placeholder')}
        value={(requestStatus != null && selectedStatus != null) ? (requestStatus.find((status) => status.id === selectedStatus)) : ''}
        onChange={handleStatusChange}
        isLoading={requestStatus == null}
        options={requestStatus}
        isClearable={isClearable}
        menuPortalTarget={document.body}
      />
    </>
  );
}

export default RequestStatusSelect;

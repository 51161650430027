import React, { FC } from 'react';
import intl from 'components/i18n/ReactIntlWrapper';
import { Select as Selec, MenuItem, FormControl, InputLabel } from '@mui/material';

type InvoicesStatusSelectProps = {
  invoiceStatus: any,
  selectedStatus: any,
  handleStatusChange: any,
  isClearable: boolean
};

const InvoicesStatusSelect: FC<InvoicesStatusSelectProps> = ({ invoiceStatus, selectedStatus, handleStatusChange, isClearable }) => {

  const dropdownIndicatorStyles = (base, state) => {
    let changes = {
      cursor: 'pointer'
    };
    return Object.assign(base, changes);
  };

  const containerStyles = (base, state) => {
    let changes = {
      width: "100%",
    };
    return Object.assign(base, changes);
  };

  return (
    <>
    <FormControl  
        sx={{ 
              minWidth: {
                sm:104,
                md:104
              },
            }}>
      <InputLabel id="invoice-status-label">{intl.get('invoicesStatusSelect.status')}</InputLabel>
      <Selec
        labelId="invoice-status-label"
        id="invoiceStatus"
        value={selectedStatus}
        onChange={handleStatusChange}
        label={intl.get('invoicesStatusSelect.status')}
      > 
         <MenuItem value={null}>
            <em>Status</em>
          </MenuItem>
        {invoiceStatus && invoiceStatus.map((status, index) => (
          <MenuItem key={index} value={status.id}>
            {intl.get(status.labelKey)}
          </MenuItem>
        ))}
      </Selec>
    </FormControl>
    </>
  );
}

export default InvoicesStatusSelect;

import React from 'react';
import intl from 'components/i18n/ReactIntlWrapper';
import { Row, Col } from 'reactstrap';
import { HiOutlineTrash } from 'react-icons/hi';
import EmployeeSelect from '../Project/EmployeeSelect';
import UserSelectRole from './UserSelectRole';
import { confirmAlert } from 'react-confirm-alert';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';

const UserAsEmployee = ({ handleEmployeeChange, employees, input, setInput, handleRoleChange, roles, idFe, securityRoles, hiddenRole, setHiddenRole }) => {

    const toast = useToastMessageQueue();

    const selectedEmployee = input?.userDetails?.filter(employee => employee.idFe === idFe);

    const deleteUserAsEmployee = () => {
        confirmAlert({
            title: intl.get('delete.modal.title'),
            message: intl.get('delete.modal.userAsEmployee.message'),
            buttons: [
                {
                    label: intl.get('delete.modal.cancel.button'),
                    onClick: () => { }
                },
                {
                    label: intl.get('delete.modal.delete.button'),
                    onClick: () => {
                        const updatedUserDetails = input.userDetails.filter(user => user.idFe !== idFe);
                        setInput((prevInput) => ({
                            ...prevInput,
                            userDetails: updatedUserDetails
                        }));
                        toast.success({ body: intl.get('userAsEmployee.toast.success.handleDelete')});
                    },
                }
            ]
        });
    };

    return (
        <Row className='mb-2'>
            <Col md={6}>
                <EmployeeSelect
                    handleEmployeeChange={handleEmployeeChange}
                    employees={employees}
                    selectedEmployee={selectedEmployee[0]?.entityId}
                    disabled={false}
                    newUser={true}
                    id={idFe}
                    securityRoles={securityRoles}
                    edit={false}
                    setHiddenRole={setHiddenRole}
                />
            </Col>
            <Col md={6} className='mt-2 mt-md-0'>
                <Row>
                    <Col md={11} className='mb-1 mb-md-0'>
                        <UserSelectRole
                            handleRoleChange={handleRoleChange}
                            selectedRoles={securityRoles}
                            roles={roles}
                            disabled={false}
                            id={idFe}
                            hiddenRole={hiddenRole}
                        />
                    </Col>
                    <Col md={1}>
                        <a title={intl.get('employeeRow.deleteButton.title')} className='td-icon' role="button" onClick={deleteUserAsEmployee}>
                            <HiOutlineTrash className='iconTrash'/>
                        </a>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default UserAsEmployee;

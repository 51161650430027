import React, { useEffect, useState } from 'react';
import intl from 'components/i18n/ReactIntlWrapper';
import { FaSearch } from "react-icons/fa";
import { Link, useLocation } from 'react-router';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { HiOutlinePlus } from 'react-icons/hi';
import InvoicesSearchBar from './InvoicesSearchBar';
import InvoicesStatusSelect from './InvoicesStatusSelect';
import InvoicesAmountRange from './InvoicesAmountRange';
import DateRangePickerDialog from 'components/DateRangePickerDialog';
import Grid from '@mui/material/Grid';
import SearchButton from 'components/common/SearchButton';
import PlusButton from 'components/common/PlusButton';

const InvoicesNavbar = ({ onSearch, invoiceStatus }) => {

    const [queryString, setQueryString] = useState<string>('');
    const [amounts, setAmounts] = useState({
        "min": "",
        "max": ""
    })
    const [idStatus, setIdStatus] = useState(null);
    const [range, setRange] = useState(null);
    const location = useLocation();

    const handleSearchButton = () => {
        if(range?.from){
            range?.from == "Invalid Date" ? range.from = null : range.from 
        }
        if(range?.to){
            range?.to == "Invalid Date" ? range.to = null : range.to 
        }
        onSearch(queryString, idStatus, range, amounts);
    };

    const handleStatusChange = (e) => {
        if (e !== null) {
            setIdStatus(e.target.value);
        } else {
            setIdStatus(null);
        }
    };

    useEffect(() => {
        setRange(null);
        setIdStatus(null);
        setQueryString('');
        setAmounts({
            "min": "",
            "max": ""
        })
      }, [location.pathname]);

   
    return (<>
        <Grid container gap={1}>
            <Grid md >
                <InvoicesSearchBar 
                    queryString={queryString} 
                    setQueryString={setQueryString}
                />
            </Grid>
            <Grid md={3.6}>
                <DateRangePickerDialog
                    disabled={false}
                    inputName="dateRange"
                    className='timesheet-datepicker'
                    selected={range}
                    setRange={setRange}
                    required
                    gap={1}
                />
            </Grid>
            <Grid md={1} >
                <InvoicesStatusSelect 
                    invoiceStatus={invoiceStatus} 
                    selectedStatus={idStatus} 
                    handleStatusChange={handleStatusChange} 
                    isClearable={true} 
                />
            </Grid>
            <Grid md={3} >
                <InvoicesAmountRange 
                    amounts={amounts} 
                    setAmounts={setAmounts}
                    gap={1}
                /> 
            </Grid>
           <Grid md={1} >
                <SearchButton clickEvent={handleSearchButton}/>
           </Grid>
           <Grid md={1} >
                <PlusButton link='/newinvoice' tooltip="invoice.new"/>
            </Grid>
        </Grid>
    </>);
}

export default InvoicesNavbar;

import React, {useState} from 'react';
import { useNavigate } from 'react-router';
import { useApi } from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import { useToastMessageQueue } from '../ToastMessages/ToastMessageProvider';
import intl from 'components/i18n/ReactIntlWrapper';
import '../../scss/Time Off/TimeOffRow.scss';
import Chip from '@mui/material/Chip';
import { HiDocument, HiEye } from 'react-icons/hi';
import WorkflowActionsBar from '../Workflow/WorkflowActionsBar';
import { Typography, useTheme } from '@mui/material';
import { handleAPIError } from 'common/errorHandler';

const TimeOffRow = ({ color , requestTypeLabel, className, id, employee, statusLabelKey, from, to, date, idWorkflowState, title, idEmployee, changeLog, idTimeOffRequestType, fetchRequestsHR, fetchRequestsSimpleUser, HRView }) => {

    const api: Api = useApi();

    const toast = useToastMessageQueue();

    const navigate = useNavigate();

    const theme = useTheme();

    const [comments, setComments] = useState({
        comments: ""
    });

    const [errors, setErrors] = useState({}); // FIXME: -

    const [loading, setLoading] = useState<boolean>(false);

    ////const color = status === "Pending" || status === "Pendiente" ? "warning" : status === "Approved" || status === "Aprobado" ? "success" : status === "Observed" || status === "Observado" ? "default" : status === "Open" || status === "Abierto" ? "primary" : status === "Reopen" || status === "Reabrir" ? "secondary" : "error";

    const handleTimeOffDetail = (HRView: boolean) => {
        if (HRView) {
            navigate(`/timeOffRequestHR/detail/${id}`);
        } else {
            navigate(`/timeOffRequest/detail/${id}`);
        }
    };

    function formatDateISO(dateString) {
        const [day, month, year] = dateString.split('/');
        const dateISO = new Date(`${year}-${month}-${day}T00:00:00`);
        return dateISO;
    };

    const handleSave = async (stateId, comments) => {

        toast.success({ body: intl.get('calendarEvent.toast.success.handleSave')});
        setLoading(false);
        if (HRView) {
            fetchRequestsHR();
        } else {
            fetchRequestsSimpleUser();
        }
    };



    return (
        <tr className={className} style={{cursor: "default"}}>
            <td>{employee}</td>
            <td>
                <Chip
                    variant='outlined'
                    className={`border px-4 py-2`}
                    label={<Typography className='statusLabel'>{intl.get(statusLabelKey)}</Typography>}
                    sx={{backgroundColor: color?color:'#fff', color: theme.palette.getContrastText(color?color:'#fff')}}
                />
            </td>
            <td>{intl.get(requestTypeLabel)}</td>
            <td>{date}</td>
            <td className='text-center'>
                <WorkflowActionsBar viewMode='icons' onActionClick={handleSave}  id={id} stateId={idWorkflowState}  loading={loading}/>
            </td>
            <td className='text-center'>
                <a className='td-icon mx-1' style={{cursor: "pointer"}} title={intl.get('timeOffRow.detailButton.title')}>
                    <HiEye onClick={() => handleTimeOffDetail(HRView)}/>
                </a>
            </td>
        </tr>
    )
}

export default TimeOffRow;

import { IconButton, TextField } from '@mui/material'
import Api from 'axiosApi/api';
import React, { useEffect, useRef, useState } from 'react'
import { BiSave } from 'react-icons/bi'
import { useApi } from 'api/ApiProvider'
import { LocaleDto } from 'axiosApi/models';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import Spinner from 'components/Spinner';
import intl from 'components/i18n/ReactIntlWrapper';

type TranslationInputProps = {
    inputRef?: React.RefObject<HTMLInputElement>;
    localeDto: LocaleDto;
    language: string;
    disabled?: boolean;
    onSaveComplete?: () => void;
    onDirtyChange?: (dirty: boolean) => void;
}

export default function TranslationInput({
    inputRef: inputRefParam,
    localeDto,
    language,
    disabled,
    onDirtyChange,
    onSaveComplete
}:TranslationInputProps) {
    const inputRef = inputRefParam || useRef<HTMLInputElement>(null);
    const [loading, setLoading] = useState(false);
    const [dirty, setDirty] = useState(false);
    const api: Api = useApi();
    const toast = useToastMessageQueue();

    useEffect(() => {
        onDirtyChange && onDirtyChange(dirty);
    }, [dirty]);

    const handleSave = () => {
        setLoading(true);

        const localeDtoToSave: LocaleDto = {
            key: localeDto.key,
            localeTranslationDtos: localeDto.localeTranslationDtos.map(t => {
                if(t.language === language){
                    return {
                        ...t,
                        value: inputRef.current?.value
                    }
                }
                return t;
            })
        }

        //check if language exists in localeDto.localeTranslationDtos
        if(!localeDto.localeTranslationDtos.some(t => t.language === language)){
            localeDtoToSave.localeTranslationDtos.push({
                language,
                value: inputRef.current?.value
            });
        }
        
        api.localeApi.apiVversionLocalePut("1", {
            locale: localeDtoToSave,
        }).then(() => {
            setDirty(false);
            toast.success({ body: intl.get('locale.toast.success.save') });
            onSaveComplete && onSaveComplete();
        }).catch((error) => {
            toast.error({ header: 'Error', body: 'Error saving translation' });
            toast.error({ body: intl.get('locale.toast.error.save') });
        }).finally(() => {
            setLoading(false);
        });
    }

    const defaultValue = localeDto?.localeTranslationDtos?.find(t => t.language === language)?.value;

    /*
    const checkUnsavedChanges = () => {
        if(dirty){
            confirmAlert({
                title: 'Unsaved Changes',
                message: intl.get("confirm.modal.locale.message"),
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            setDirty(false);
                            if (inputRef.current) {
                                inputRef.current.value = defaultValue;
                            }
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => {
                            inputRef.current?.focus();
                        }
                    }
                ]
            });
        }
    }
    */

    return (<>
        {loading&&
            <Spinner/>
        }
        <TextField
            disabled={loading || disabled}
            inputRef={inputRef}
            className='borders-only-on-hover hide-icons-on-hover w-100'
            defaultValue={defaultValue}
            onChange={() => {
                console.log(inputRef.current?.value, defaultValue);
                setDirty(inputRef.current?.value !== defaultValue);
            }}
            InputProps={{
                endAdornment: <IconButton onMouseDown={(e) => e.preventDefault()} color='primary' disabled={!dirty || loading} onClick={handleSave}><BiSave/></IconButton> 
            }}
        />
    </>
    )
}

import React, {useState} from 'react';
import { useNavigate } from 'react-router';
import { useApi } from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import { useToastMessageQueue } from '../ToastMessages/ToastMessageProvider';
import { UpdateInvoiceCommand } from 'axiosApi/models/update-invoice-command';
import intl from 'components/i18n/ReactIntlWrapper';
import '../../scss/Invoice/InvoicesRow.scss';
import Chip from '@mui/material/Chip';
import { HiDocument, HiEye } from 'react-icons/hi';
import WorkflowActionsBar from '../Workflow/WorkflowActionsBar';
import { handleAPIError } from 'common/errorHandler';

const InvoicesRow = ({ className, date, from, to, invoiceNumber, invoiceID, amount, status, idWorkflowState, idInvoiceType, addressFrom, addressTo, idFrom, idTo, idCurrency, invoiceItems, fileURI, invoiceTaxes, OwnerView }) => {

    const api: Api = useApi();

    const toast = useToastMessageQueue();

    const navigate = useNavigate();

    const [errors, setErrors] = useState({});

    const [loading, setLoading] = useState<boolean>(false);

    const color = status === "Pending" || status === "Pendiente" ? "warning" : status === "Approved" || status === "Aprobado" ? "success" : status === "Observed" || status === "Observado" ? "default" : status === "Open" || status === "Abierto" ? "primary" : status === "Reopen" || status === "Reabrir" ? "secondary" : "error";

    const handleInvoiceDetail = (OwnerView: boolean) => {
        if (OwnerView) {
            navigate(`/invoiceOwner/detail/${invoiceID}`);
        } else {
            navigate(`/invoice/detail/${invoiceID}`);
        }
    };

    const handleSave = () => {};

    return (
        <tr className={className} style={{cursor: "default"}}>
            {
                OwnerView ?
                <td>{from}</td> :
                null
            }
            <td>{date}</td>
            <td>{invoiceNumber}</td>
            <td>{invoiceID}</td>
            <td>{`$${amount}`}</td>
            <td>
                <Chip label={status} color={color} className={color === "default" ? 'observed' : ''}/>
            </td>
            <td className='text-center'>
                <WorkflowActionsBar viewMode='icons' onActionClick={handleSave} id={invoiceID} stateId={idWorkflowState} loading={loading}/>
            </td>
            <td className='text-center'>
                <a className='td-icon mx-1' title="Detail" style={{cursor: "pointer"}}>
                    <HiEye onClick={() => handleInvoiceDetail(OwnerView)}/>
                </a>
            </td>
        </tr>
    )
}

export default InvoicesRow;

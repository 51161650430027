import React, { memo, useEffect, useState } from 'react'
import { getSmoothStepPath, getBezierPath, EdgeLabelRenderer, useInternalNode, EdgeProps, Edge } from '@xyflow/react'
import { getEdgeParams } from '../utils-ts'
import { IconPickerItem } from 'react-icons-picker'
import { Box, Paper, Typography } from '@mui/material'
import intl from 'components/i18n/ReactIntlWrapper'

export type FloatingEdgeType = Edge<{
    actionName: string
    labelKey: string
    extraAttributes: any,
    AllowComments: boolean,
    Icon: string
    DisplayColor: string, 
    scope: number|null
}, 'floating'>;

const FloatingEdge = memo(({ id, source, target, markerEnd, style, data }: EdgeProps<FloatingEdgeType>) => {
    const sourceNode = useInternalNode(source);
    const targetNode = useInternalNode(target);
    
    if (!sourceNode || !targetNode) {
        return null
    }

    const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(sourceNode, targetNode)
    const [edgePath] = getSmoothStepPath({
        sourceX: sx,
        sourceY: sy,
        sourcePosition: sourcePos,
        targetX: tx,
        targetY: ty,
        targetPosition: targetPos
    })
    
    FloatingEdge.displayName = 'FloatingEdge'

    const labelX = (sx + tx) / 2
    const labelY = (sy + ty) / 2

    const [icon, setIcon] = useState<string>('')

    /* Workaroud to refresh icon when color changes */
    useEffect(() => {
        if(icon!==''){
            setIcon('')
        }
    }, [data?.DisplayColor])
    useEffect(() => {
        if(icon===''){
            setIcon(data?.Icon ? data?.Icon : '')
        }
    }, [icon])

    return (
        <>
            <path id={id} className="react-flow__edge-path" d={edgePath} markerEnd={markerEnd} style={style} />
            {!data?.extraAttributes?.virtualInitialEdge && !data?.extraAttributes?.virtualFinalEdge && (
                <EdgeLabelRenderer>
                    <Box
                        style={{
                            position: 'absolute',
                            fontSize: 12,
                            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                            pointerEvents: 'all',
                            padding: '5px',
                        }}
                    >
                        <Paper variant='outlined' className="w-100 d-flex flex-column justify-content-center align-items-center rounded-pill px-4 py-1">
                            <Typography style={{fontSize: 12}}>{data?.labelKey && intl.get(data?.labelKey)|| data?.actionName}</Typography>
                            
                            {data?.Icon&&
                                <IconPickerItem
                                    size={18}
                                    value={icon}
                                    color={data.DisplayColor}
                                />
                            }
                        </Paper>
                    </Box>
                </EdgeLabelRenderer>
            )}
        </>
    )
})
FloatingEdge.displayName = 'FloatingEdge';
export default FloatingEdge;
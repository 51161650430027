import React, { useState } from 'react';
import intl from 'components/i18n/ReactIntlWrapper';
import { FaSearch } from "react-icons/fa";
import { Link } from 'react-router';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { HiOutlinePlus } from 'react-icons/hi';
import CorporateEntitiesSearchBar from './CorporateEntitiesSearchBar';
import CountrySelect from '../CountrySelect';

const CorporateEntitiesNavbar = ({ onSearch, countries }) => {

    const [queryString, setQueryString] = useState<string>('');
    
    const [country, setCountry] = useState({
        id: null,
        name: '',
        labelKey: ''
    });

    const handleSearchButton = () => {
        onSearch(queryString, country.id);
    };

    const handleCountryChange = (e) => {
        if (e !== null) {
            setCountry({
                id: e.id,
                name: intl.get(e.labelKey),
                labelKey: e.labelKey
            });
        } else {
            setCountry({
                id: null,
                name: '',
                labelKey: ''
            });
        }
    };

    return (
        <nav className='navbar container-fluid'>
            <div className='col-11 filters gap-3'>
                <CorporateEntitiesSearchBar queryString={queryString} setQueryString={setQueryString}/>
                <CountrySelect
                    handleCountryChange={handleCountryChange}
                    selectedCountry={country.labelKey}
                    countries={countries}
                    inputCountry={country.name}
                    isClearable={true}
                />
                <button type="button" className="btn btn-outline-primary" onClick={handleSearchButton} ><FaSearch className="mb-1"></FaSearch>&nbsp;Search</button>
            </div>
            <div className='col-1 newRole'>
                <Link to= '/corporateEntities/new'><button className='btn btn-primary p-1 plusButton' type='button' id='newCorporateEntity' data-tooltip-id='tooltipButton'><HiOutlinePlus className='plusIcon'/></button></Link>
                <ReactTooltip 
                    id='tooltipButton'
                    place='left'
                    content="New Corporate Entity"
                />
            </div>
        </nav>
    )
};

export default CorporateEntitiesNavbar;
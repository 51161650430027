import React, { FC } from 'react';
import Select from 'react-select';
import intl from 'components/i18n/ReactIntlWrapper';

type ContactTypeSelectProps = {
  handleContactTypeChange: any,
  selectedContactTypes: any,
  contactTypes: any,
};

const ContactTypeSelect: FC<ContactTypeSelectProps> = ({ contactTypes, selectedContactTypes, handleContactTypeChange }) => {
  
  return (
    <Select
      id="contactType"
      name="contactType"
      getOptionLabel={e => intl.get(e.label)}
      getOptionValue={e => e.id}
      placeholder={intl.get('contactForm.placeholder.contactType')}
      value={(contactTypes != null && selectedContactTypes != null) ? (contactTypes.find((contactType) => contactType.id === selectedContactTypes)) : ''}
      onChange={handleContactTypeChange}
      isLoading={contactTypes == null}
      options={contactTypes}
    />
  );
}

export default ContactTypeSelect;
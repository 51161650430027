import React, { useState, useEffect, FC } from 'react';
import ContactForm from './ContactForm';
import '../scss/ContactInfo.scss';
import { useApi } from '../api/ApiProvider';
import Api from '../axiosApi/api';
import { CreateContactInfoCommand, ListOptionDTO, ListOptionDTOSearchResponse, ListOptionDTOSearchResponseResponse, UpdateContactInfoCommand } from 'axiosApi/models';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import { HiOutlineSave } from 'react-icons/hi';
import RequiredField from './RequiredField';
import intl from 'components/i18n/ReactIntlWrapper';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Spinner from './Spinner';
import { handleAPIError } from '../common/errorHandler';
import { AxiosResponse } from 'axios';

type ContactInfoProps = {
    contactInfoId: number | null,
    contactInfos: any,
    setContactInfos: any,
    contactInfosId: any,
    setContactInfosId: any
    closeModal: any,
};

const ContactInfo: FC<ContactInfoProps> = ({ contactInfoId, contactInfos, setContactInfos, contactInfosId, setContactInfosId, closeModal }) => {

    const defaultContactInfoInput = {
        contactType: '',
        idContactType: null,
        contactName: '',
        emailAddress: '',
        phone1: '',
        phone2: '',
        role: '',
    };

    const [input, setInput] = useState(defaultContactInfoInput);

    const api: Api = useApi();

    const [errors, setErrors] = useState({});

    const [contactTypes, setContactTypes] = useState<ListOptionDTO[]>([]);

    const [loading, setLoading] = useState<boolean>(false);

    const toast = useToastMessageQueue();

    const fetchContactInfo = async (contactInfoId: number) => {
        if (contactInfoId === null) {
            setInput(defaultContactInfoInput);
        } else{
            const response = await api.contactinfoApi.apiVversionContactInfoContactinfoIdGet(contactInfoId, "1", {}).then((response) => {
                if(response.data) {
                    setInput({
                        contactType: response.data?.data?.contactType?.name,
                        idContactType: response.data?.data?.contactType?.id,
                        contactName: response.data?.data?.contactName,
                        emailAddress: response.data?.data?.emailAddress,
                        phone1: response.data?.data?.phone1,
                        phone2: response.data?.data?.phone2,
                        role: response.data?.data?.role,
                    });
                };
            }).catch((error) => {
                handleAPIError(error, toast, errors);
                setErrors({...errors});
            });
        }
    };
    

    const fetchContactTypeList = async () => {
        setLoading(true);
        await api.contactinfoApi.apiVversionListContactTypeGet("1").then((response: AxiosResponse<ListOptionDTOSearchResponse | ListOptionDTOSearchResponseResponse>) => {
            if (response.data && 'queryResult' in response.data) {
                setContactTypes(response.data.queryResult);
            } 
            else if (response.data && 'data' in response.data && 'queryResult' in response.data.data) {
                setContactTypes(response.data.data.queryResult);
            }
        }).catch((error) => {
            handleAPIError(error, toast, errors);
            setErrors({...errors});
        }).finally(() => {
            setLoading(false);
        });
    };

    useEffect(() => {
        fetchContactInfo(contactInfoId);
        fetchContactTypeList();
    }, [contactInfoId]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let validatedValue = value;
    
        if (name === "contactName" && value.length > 45) {
            validatedValue = value.slice(0, 45);
        }
    
        if ((name === "phone1" || name === "phone2") && !/^[\d+]*$/.test(value)) {
            return;
        }
    
        setInput({
            ...input,
            [name]: validatedValue,
        });
    
        setErrors({});
    };

    const handleContactTypeChange = (e) => {
        const inputVal = {
            ...input,
            idContactType: e.id,
            contactType: intl.get(e.label)
        };
        setInput({ ...inputVal });
        setErrors({});
    };

    const validate = (input) => {
        let errors: any = {};
        if (!input.contactName) {
            errors.contactName = intl.get('validate.errors.isRequired');
        };
        if (!input.idContactType) {
            errors.idContactType = intl.get('validate.errors.isRequired');
        };
        if (!input.emailAddress) {
            errors.emailAddress = intl.get('validate.errors.isRequired');
        } else if (!/\S+@\S+\.\S+/.test(input.emailAddress)) {
            errors.emailAddress = intl.get('validate.errors.isInvalid');
        };
        if (!input.role) {
            errors.role = intl.get('validate.errors.isRequired');
        };
        setErrors({ ...errors });
        return Object.keys(errors).length > 0 ? errors : {};
    };

    const handleSave = async (e) => {
        e.preventDefault();
        const errors = validate(input);
        if (JSON.stringify(errors) === JSON.stringify({})) {
            if (contactInfoId) {
                let cmd: UpdateContactInfoCommand = {
                    phone1: input?.phone1,
                    phone2: input?.phone2,
                    role: input?.role,
                    idContactType: input?.idContactType,
                    contactName: input?.contactName,
                    emailAddress: input?.emailAddress,
                    id: contactInfoId
                };
                setLoading(true);
                const response = await api.contactinfoApi.apiVversionContactInfoContactinfoPut("1", cmd, {}).then((response) => {
                    let contactEdited = response.data.data;
                    let copyContactInfos = contactInfos;
                    let newContactInfos = copyContactInfos.filter(contact => contact.id != contactInfoId);
                    setContactInfos([...newContactInfos, contactEdited]);
                    toast.success({ body: intl.get('contactInfo.toast.success.handleSave')});
                    closeModal();
                }).catch((error) => {
                    handleAPIError(error, toast, errors);
                    setErrors({...errors});
                }).finally(() => {
                    setLoading(false);
                });
            } else{
                let cmd: CreateContactInfoCommand = {
                    phone1: input?.phone1,
                    phone2: input?.phone2,
                    role: input?.role,
                    idContactType: input?.idContactType,
                    contactName: input?.contactName,
                    emailAddress: input?.emailAddress,
                };
                setLoading(true);
                const response = await api.contactinfoApi.apiVversionContactInfoContactinfoPost("1", cmd, {}).then((response) => {
                    let newContactInfo = response.data.data;
                    setContactInfos([...contactInfos, newContactInfo]);
                    const newContactInfoId = contactInfosId.includes(newContactInfo.id);
                    if(!newContactInfoId) {
                        setContactInfosId([...contactInfosId, newContactInfo.id]);
                    };
                    toast.success({ body: intl.get('contactInfo.toast.success.handleSave.add')});
                    setInput(defaultContactInfoInput);
                    closeModal();
                }).catch((error) => {
                    handleAPIError(error, toast, errors);
                    setErrors({...errors});
                }).finally(() => {
                    setLoading(false);
                });
            };
        }
    };

    const handleCancel = () => {
        fetchContactInfo(contactInfoId);
        setErrors({});
        closeModal();
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ my: 3 }}>
                <h2 style={{ textAlign: 'start', marginBottom: '1rem' }}>
                    {contactInfoId ? intl.get('contactInfo.title.edit') : intl.get('contactInfo.title.add')}
                </h2>
                <Grid container sx={{ textAlign: 'start', mb: 3 }}>
                    <ContactForm
                        errors={errors} 
                        input={input} 
                        handleInputChange={handleInputChange}
                        contactTypes={contactTypes}
                        selectedContactTypes={input.idContactType}
                        handleContactTypeChange={handleContactTypeChange}
                    />
                </Grid>
                <Grid container spacing={2}>
                    <Grid xs={12} md={4}>
                        <RequiredField />
                    </Grid>
                    <Grid xs={12} md={8}>
                        <Stack direction="row" spacing={2} justifyContent="flex-end">
                            {
                                loading === true ?
                                <Spinner small={true} /> :
                                <>
                                    <Button 
                                        variant="outlined" 
                                        color="inherit" 
                                        onClick={handleCancel}
                                    >
                                        {intl.get('cancel.button')}
                                    </Button>
                                    <Button 
                                        variant="contained" 
                                        color="primary" 
                                        onClick={handleSave} 
                                        startIcon={<HiOutlineSave />}
                                        disabled={JSON.stringify(errors) !== JSON.stringify({}) || input.contactName === "" ? true : false}
                                    >
                                        {intl.get('save.button')}
                                    </Button>
                                </>
                            }
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default ContactInfo;
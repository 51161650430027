import React, { useEffect, useState } from 'react';
import intl from 'components/i18n/ReactIntlWrapper';
import { HiOutlinePlus } from 'react-icons/hi';
import { FaSearch } from "react-icons/fa";
import { Tooltip as ReactTooltip } from "react-tooltip";
import CountrySelect from '../../components/CountrySelect';
import ModalCalendar from './ModalCalendar';
import RequestSelect from './RequestSelect';
import TimeOffSearchBar from './TimeOffSearchBar';
import { PermissionsGate}  from '../PermissionsGate';
import { SCOPES } from 'common/permissions';
import { ENTITY_TYPE } from 'common/constants';
import RequestStatusSelect from './RequestStatusSelect';
import { useWorkflow } from 'components/Workflow/WorkflowContext';

const HRCalendarNavbar = ({ handleCountryChange, selectedCountry, countries, input, handleCalendarEventDetailOpen, isCalendarEventDetailOpen, closeCalendarEventDetail, nonWorkingDay, requestType, requestStatus,onSearch, updateEvents, HRView }) => {

    const [idRequest, setIdRequest] = useState(null);
    const [idStatus, setIdStatus] = useState(null);
    const [queryString, setQueryString] = useState<string>('');
    const [workflowLabelKeys, setWorkflowLabelKeys] = useState(null);
    const { workflow } = useWorkflow();
    
    useEffect(() => {
        setWorkflowLabelKeys(
            workflow?.states?.map(state => ({ label : state.labelKey, id : state.id }))
        )
    },[workflow])
    const handleSearchButton = () => {
        onSearch(queryString, idStatus, idRequest);
    };

    const handleRequestChange = (e) => {        
        if (e !== null) {
            setIdRequest(e.id);
        } else {
            setIdRequest(null);
        }
    };

    const handleStatusChange = (e) => {
        if (e !== null) {
            setIdStatus(e.id);
        } else {
            setIdStatus(null);
        }
    };

    return (
        <nav className={nonWorkingDay ? 'navbar container-fluid mb-5' : 'navbar container-fluid filters gap-3 mb-5'}>
            {
                nonWorkingDay ?
                <>
                    <CountrySelect 
                        handleCountryChange={handleCountryChange}
                        selectedCountry={selectedCountry}
                        countries={countries}
                        inputCountry={input.country.name}
                        isClearable={true}
                    />
                    <PermissionsGate viewScopes={[SCOPES['nonworkingday.edit'],SCOPES['nonworkingday.add']]} editScopes={[SCOPES['nonworkingday.edit'],SCOPES['nonworkingday.add']]} entityType={ENTITY_TYPE.EMPLOYEE} viewRoles={[]} editRoles={[]} >
                        <button className='btn btn-primary p-1 plusButton' type='button' id='addHoliday' data-tooltip-id='tooltipButton' onClick={handleCalendarEventDetailOpen} ><HiOutlinePlus className='plusIcon'/></button>
                        <ReactTooltip 
                            id='tooltipButton'
                            place='left'
                            content={intl.get('timeOffCalendarNavbar.addHoliday.button')}
                        />
                    </PermissionsGate>
                    <ModalCalendar isOpen={isCalendarEventDetailOpen} closeModal={closeCalendarEventDetail} eventId={null} updateEvents={updateEvents}/>
                </>
                :
                <>{HRView ? 
                    <PermissionsGate viewScopes={[SCOPES['timeoff.rrhh']]} editScopes={[SCOPES['timeoff.rrhh']]} entityType={ENTITY_TYPE.EMPLOYEE} viewRoles={[]} editRoles={[]} >
                        <TimeOffSearchBar queryString={queryString} setQueryString={setQueryString}/>
                    </PermissionsGate>
                    :
                    null 
                }
                    <RequestSelect requestType={requestType} selectedRequest={idRequest} handleRequestChange={handleRequestChange} />
                    <RequestStatusSelect requestStatus={workflowLabelKeys} selectedStatus={idStatus} handleStatusChange={handleStatusChange} isClearable={true} />

                    <button type="button" className="btn btn-outline-primary" onClick={handleSearchButton} ><FaSearch className="mb-1"></FaSearch>&nbsp;{intl.get('jiraIssueSearch.search.button')}</button>
                </>
            }
        </nav>
    )
}

export default HRCalendarNavbar;

import React, { useState, useEffect } from 'react';
import WorkflowNavbar from './WorkflowNavbar';
import WorkflowPagination from './WorkflowPagination';
import WorkflowList from './WorkflowList';
import Spinner from '../Spinner';
import '../../scss/Workflow/Workflow.scss';
import { useApi } from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import {PermissionsGate } from '../PermissionsGate';
import { SCOPES, ROLES } from "../../common/permissions";
import { Workflow } from '../../axiosApi/models';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import intl from 'components/i18n/ReactIntlWrapper';
import ItemsSelect from '../ItemsSelect';
import { handleAPIError } from 'common/errorHandler';
import ErrorSummary from 'components/common/ErrorSummary';

export type WorkflowInput = {
	queryTerm?: string | null,
	sortValue?: string | null,
	orderValue?: string | null,
	filterValue?: null,
	currentPage?: number,
	clicked?: boolean
};

export type SearchResult = {
	totalRecords: number | null,
	workflows: Workflow[]
};

const Providers = () => {

	const [input, setInput] = useState<WorkflowInput | null>({
		queryTerm: "",
		sortValue: "Name",
		orderValue: "asc",
		filterValue: null,
		currentPage: 1,
		clicked: false,
	});

	const api: Api = useApi();

	const [searchResult, setSearchResult] = useState<SearchResult | null>({
		totalRecords: null,
		workflows: []
	});

	const [loading, setLoading] = useState<boolean>(false);
	const [errors, setErrors] = useState({});
	const [pageCount, setpageCount] = useState<number>(0);

	const [limit, setLimit] = useState<number>(() => {
		return parseInt(localStorage.getItem('itemsPerPageWorkflows'), 10) || 10;
	});

	useEffect(() => {
		setLimit(parseInt(localStorage.getItem('itemsPerPageWorkflows'), 10) || 10);
	}, []);

	const toast = useToastMessageQueue();

	const fetchWorkflows = async () => {
		setLoading(true);
		if (input.queryTerm === ""){
			await api.workflowApi.apiVversionWorkflowAllGet("1", input.currentPage, limit).then((response) => {
				if(response.data.data === null) {
					setSearchResult({
						totalRecords: null,
						workflows: null
					});
				} else {
					setSearchResult({
						totalRecords: response.data?.data?.totalRecords,
						workflows: response.data?.data?.queryResult
					});
				};
				setErrors({});
			}).catch((error)=>{
				handleAPIError(error, toast, errors);
				setErrors({ ...errors });
				setLoading(false);
			});
		} else {
			await api.workflowApi.apiVversionWorkflowSearchGet("1", input.queryTerm, input.currentPage, 10, input.sortValue, input.orderValue).then((response) => {
			if(response.data.data === null) {
				setSearchResult({
					totalRecords: null,
					workflows: null
				});
			} else {
				setSearchResult({
					totalRecords: response.data?.data?.totalRecords,
					workflows: response.data?.data?.queryResult
				});
			};
			setErrors({});
			console.log("Success");
		}).catch((error)=>{
			setLoading(false);
            handleAPIError(error, toast, errors);
            setErrors({ ...errors });
        });
		}
		setLoading(false);
	};

	useEffect(() => {
        fetchWorkflows();
    }, [input, limit]);

	const onSearch = (queryString) => {
		setInput({ ...input, queryTerm: queryString, currentPage: 1 });
	};

	const handlePageClick = pageNum => setInput({ ...input, currentPage: pageNum });

	const nextPage = () => setInput({ ...input, currentPage: input.currentPage + 1 });

	const prevPage = () => setInput({ ...input, currentPage: input.currentPage - 1 });

	const initialPage = () => setInput({ ...input, currentPage: 1 });

	const finalPage = () => setInput({ ...input, currentPage: pageCount });

    return (
        <div className="container">
			<div className='card mt-4'>
				<div className="card-header">
					<h2 className="title">{intl.get('workflows.header')}</h2>
				</div>
				<div className='card-body'>
					<ErrorSummary errors={errors} showEverything={true} ></ErrorSummary>
                    <PermissionsGate viewScopes={[SCOPES['workflows.read']]} editScopes={[SCOPES['workflows.edit']]} viewRoles={null} editRoles={null} RenderError={()=>{return <span>{intl.get('permissionsGate')}</span>}}>
						<div className='row'>
							<div className='' id={`${input.clicked ? 'column-2' : ''}`} >
								<WorkflowNavbar onSearch={onSearch}/>
								{
									loading === true ?
									<Spinner /> :
									<>
										<WorkflowList searchResult={searchResult} input={input} setInput={setInput} />
										<div className="pagination row w-100">
											<div className="col-10 col-xl-11 d-flex justify-content-center">
												<WorkflowPagination
													handlePageClick={handlePageClick}
													limit={limit} searchResult={searchResult}
													nextPage={nextPage} prevPage={prevPage}
													input={input} pageCount={pageCount}
													setpageCount={setpageCount}
													initialPage={initialPage}
													finalPage={finalPage}
												/>
											</div>
											<div className="col-2 col-xl-1 d-flex justify-content-end">
												<ItemsSelect
													input={input}
													setInput={setInput}
													limit={limit}
													setLimit={setLimit}
													storageKey="itemsPerPageProviders"
												/>
											</div>
										</div>
									</>
								}
							</div>
						</div>
                	</PermissionsGate>
				</div>
			</div>
		</div>
    )
}

export default Providers;

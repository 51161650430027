import React from "react";
import { JiraIssue } from "axiosApi/models";
import JiraIssueRow from "./JiraIssueRow";
import { Input } from "reactstrap";
import JiraIssuesListPagination from "./JiraIssuesListPagination";
import Spinner from '../Spinner';
import intl from 'components/i18n/ReactIntlWrapper';

export type JiraIssuesListProps = {
    jiraIssues: JiraIssue[],
    handleIssuesAdd: Function,
    handleIssueSelect: Function,
    handleIssueSelectAll: Function,
    isIssueSelected: Function,
    isIssueSelectedAll: Function,
    setPageNum: Function,
    pageNum: number,
    pageSize: number,
    totalRecords: number,
    loading: boolean
}
const JiraIssuesList = ({ jiraIssues, handleIssuesAdd, handleIssueSelect, handleIssueSelectAll, isIssueSelected, isIssueSelectedAll, setPageNum, pageNum, pageSize, totalRecords, loading }: JiraIssuesListProps) => {

    const showSpinner = loading || !jiraIssues;
    return (
        <>
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th scope="col"><Input type="checkbox" checked={isIssueSelectedAll()} onChange={(e) => { handleIssueSelectAll(e.currentTarget.checked) }}  ></Input></th>
                        <th scope="col">{intl.get('jiraIssuesList.table.thead.issue')}</th>
                        <th scope="col">{intl.get('jiraIssuesList.table.thead.summary')}</th>
                        <th scope="col">{intl.get('jiraIssuesList.table.thead.jira')}</th>
                        <th scope="col" style={{ textAlign: 'center' }}>{intl.get('jiraIssuesList.table.thead.actions')}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        showSpinner ? <tr><td colSpan={5}><Spinner /></td></tr> : jiraIssues.map((issue) => (
                            <JiraIssueRow key={issue.idJira}
                                jiraKey={issue.key}
                                idJira={issue.idJira}
                                summary={issue.summary}
                                handleIssueAdd={handleIssuesAdd}
                                handleIssueSelect={handleIssueSelect}
                                isIssueSelected={isIssueSelected}
                            />
                        ))
                    }
                    {
                       !showSpinner && totalRecords == 0 && <tr><td colSpan={5} className="text-center py-2 text-bold"><strong>{intl.get('jiraIssuesList.noResults')}</strong></td></tr>
                    }
                    {
                        !showSpinner && totalRecords > 0 && jiraIssues && jiraIssues.length == 0 && <tr><td colSpan={5} className="text-center py-2 text-bold"><strong>{intl.get('jiraIssuesList.alreadyAdded')}</strong></td></tr>
                    }

                </tbody>
            </table>
            <JiraIssuesListPagination setPageNum={setPageNum} pageNum={pageNum} pageSize={pageSize} totalRecords={totalRecords} ></JiraIssuesListPagination>
        </>
    )
}

export default JiraIssuesList;
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';
import ProviderForm from './ProviderForm';
import '../../scss/Provider/ProviderDetail.scss';
import { useApi } from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import { CreateProviderCommand, UpdateProviderCommand } from 'axiosApi/models';
import {PermissionsGate} from '../../components/PermissionsGate';
import { SCOPES, ROLES } from "../../common/permissions"; 
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import { ButtonWithOptions, ButtonWithOptionsType } from "components/common/ButtonWithOptions";
import { HiArrowLeft, HiOutlineSave, HiPencilAlt } from 'react-icons/hi';
import RequiredField from '../RequiredField';
import intl from 'components/i18n/ReactIntlWrapper';
import { Address } from '../../axiosApi/models';
import AddressCompact from 'components/AddressCompact';
import Spinner from 'components/Spinner';
import PersonaCompact from 'components/Persona/PersonaCompact';
import { ENTITY_TYPE } from 'common/constants';

export type ProviderDetailInput = {
    name?: string | null,
    address: Address | null,
};

const ProviderDetail = () => {

    const [input, setInput] = useState<ProviderDetailInput | null>({
        name: '',
        address: {}
    });

    const api: Api = useApi();

    const navigate = useNavigate();

    const toast = useToastMessageQueue();

    const { id } = useParams();
    const idNumber = parseInt(id);

    const [addressId, setAddressId] = useState<number[]>([]);

    const [errors, setErrors] = useState({});

    const [disabled, setDisabled] = useState(false);

    const [disabledNew, setDisabledNew] = useState(false);

    const [loading, setLoading] = useState<boolean>(false);
    
    const [personaExist, setPersonaExist] = useState<boolean>(false);

    const fetchProviderDetail = async (idNumber) => {
        const response = await api.employeeApi.apiVversionProviderIdGet(idNumber, "1").then((response) => {
            if (response.data) {
                setInput({
                    name: response.data?.data?.name,
                    address: response.data?.data?.address,
                });
                setAddressId([response.data?.data?.idAddress])
                console.log("Success");
            };
        }).catch((error) => {
            if (error.response) {
                console.log("Data :", error.response.data);
                console.log("Status :" + error.response.status);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log("Error", error.message);
            }
            toast.error({ header: intl.get('providerDetail.toast.error.fetchProviderDetail'), body: error.message });
        });
    };

    useEffect(() => {
        if (idNumber) {
            fetchProviderDetail(idNumber);
            setDisabled(true);
        };
        setDisabledNew(true);
    }, [idNumber]);

    const handleInputChange = function (e) {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        });
        setErrors(validate({
            ...input,
            [e.target.name]: e.target.value
        }));
    };

    const validate = function (input: ProviderDetailInput) {
        setDisabledNew(false);
        let errors: any = {};
        if (!input.name) {
            errors.name = intl.get('validate.errors.isRequired');
        }
        return errors;
    };

    const handleSubmit = async (saveAndEdit: boolean = false) => {
        const errors = validate(input);
        setErrors(errors);
        if (Object.keys(errors).length === 0) {
            setLoading(true);
            if (idNumber) {
                let cmd: UpdateProviderCommand = {
                    name: input.name,
                    idAddress: input.address?.id,
                    id: idNumber
                };
                const response = await api.employeeApi.apiVversionProviderPut("1", cmd, {}).then((response) => {
                    if (saveAndEdit)
                        setDisabled(true);
                    else
                        navigate('/providers');
                    toast.success({ body: intl.get('providerDetail.toast.success.handleSubmit')});
                    setLoading(false);
                }).catch((error) => {
                    if (error.response) {
                        console.log("Data :", error.response.data);
                        console.log("Status :" + error.response.status);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log("Error", error.message);
                    }
                    toast.error({ header: intl.get('providerDetail.toast.error.handleSubmit'), body: error.message });
                    setLoading(false);
                });
            } else {
                let cmd: CreateProviderCommand = {
                    name: input.name
                };
                const response = await api.employeeApi.apiVversionProviderPost("1", cmd, {}).then((response) => {
                    toast.success({ body: intl.get('providerDetail.toast.success.handleSubmit.add')});
                    setLoading(false);
                    if (saveAndEdit)
                        navigate(`/provider/detail/${response.data.data.id}`);
                    else
                        navigate('/providers');
                }).catch((error) => {
                    if (error.response) {
                        console.log("Data :", error.response.data);
                        console.log("Status :" + error.response.status);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log("Error", error.message);
                    }
                    toast.error({ header: intl.get('providerDetail.toast.error.handleSubmit.add'), body: error.message });
                    setLoading(false);
                });
            }
        }
    };
    
    const handleBackClick = (e) => {
        navigate('/providers');
    };

    const handleSaveClick = () => {
        handleSubmit(true);
    };

    const handleSaveCloseClick = () => {
        handleSubmit(false);
    };

    const handleEditMode = (e) => {
        e.preventDefault();
        console.log('handleEditMode', input, disabled);
        if (idNumber) {
            fetchProviderDetail(idNumber);
        }
        setErrors({});
        setDisabled(!disabled);
    };

    return (
        <div className='container'>
            <div className='card mt-4'>
                <div className="card-header">
                    {
                        idNumber ? 
                        <>
                            <h2 className="title mb-0">{intl.get('providerDetail.header')}</h2>
                        </> :
                        <h2 className="title mb-0">{intl.get('newProvider.header')}</h2>
                    }
                </div>
                <div className='card-body'>
                    <ProviderForm errors={errors} input={input} handleInputChange={handleInputChange} disabled={disabled} />
                    <RequiredField/>
                    {
                        !!idNumber &&
                        <>
                        <div className='card'>
                        <PersonaCompact
                            setPersonaExist={setPersonaExist}
                            personaExist={personaExist}
                            entityId={idNumber}
                            idEntityType={ENTITY_TYPE.PROVIDER} 
                            disabled={disabled} 
                            editScopes= {[SCOPES['providers.edit']]}
                            deleteScopes= {[SCOPES['providers.edit']]}
                        />
                    </div>
                        <div className='card my-3'>
                            <AddressCompact 
                                inputProvider={input} 
                                setInputProvider={setInput} 
                                allowOne={addressId.length > 0 && addressId[0] !== null ? true : false} 
                                addressesId={addressId} 
                                setAddressesId={setAddressId} 
                                personaExist={personaExist} 
                                disabled={disabled} 
                                editScopes={[SCOPES['providers.edit']]} 
                                deleteScopes={[SCOPES['providers.edit']]}
                            />
                        </div>
                        </>
                    }
                    <div className='d-flex justify-content-end my-3'>
                        {
                            !idNumber && !disabled ?
                            <>
                                {
                                    loading === true ?
                                    <Spinner small={true} /> :
                                    <>
                                        <button type='button' className='btn btn-outline-dark me-2' id='return' onClick={handleBackClick}>
                                            <HiArrowLeft/>
                                        </button>
                                        <PermissionsGate  viewScopes={[SCOPES['providers.edit']]} editScopes={[SCOPES['providers.edit']]} editRoles={null} viewRoles={null}>
                                            <ButtonWithOptions options={[/*{ title: 'Save', onClick: handleSaveClick, disabled: false, type: ButtonWithOptionsType.item }, */{ title: `${intl.get('save&close.button')}`, onClick: handleSaveCloseClick, disabled: Object.keys(errors).length > 0 || disabledNew, type: ButtonWithOptionsType.item }]} outline={true} disabled={Object.keys(errors).length > 0 || disabledNew} className="btn btn-primary" onClick={handleSaveClick} ><HiOutlineSave className='mb-1'/> {intl.get('save.button')}</ButtonWithOptions>
                                            {/* <button type='button' className='btn btn-primary' id='btn-detail' onClick={(e) => { e.preventDefault(); handleSubmit(); }} disabled={JSON.stringify(errors) === JSON.stringify({}) && disabled !== true ? false : true}><HiOutlineSave/> Save</button>
                                            <button type='button' className='btn btn-primary' id='btn-detail' onClick={(e) => { e.preventDefault(); handleSubmit(true); }} disabled={JSON.stringify(errors) === JSON.stringify({}) && disabled !== true ? false : true}><HiOutlineSave/> Save & Edit</button> */}
                                        </PermissionsGate>
                                    </>

                                }
                            </> : null
                        }
                        {
                            idNumber && disabled ?
                            <>
                                <button type='button' className='btn btn-outline-dark me-2' id='return' onClick={handleBackClick}>
                                    <HiArrowLeft/>
                                </button>
                                <button type='button' className='btn btn-primary me-2' id='edit' onClick={handleEditMode}>   
                                    <HiPencilAlt className='mb-1' /> {intl.get('edit.button')}
                                </button>
                            </> : null
                        }
                        {
                            idNumber && !disabled ?
                            <>
                                {
                                    loading === true ?
                                    <Spinner small={true} /> :
                                    <>
                                        <button type='button' className='btn btn-outline-dark me-2' id='edit' onClick={handleEditMode}>   
                                            {intl.get('cancel.button')}
                                        </button>
                                        <PermissionsGate  viewScopes={[SCOPES['providers.edit']]} editScopes={[SCOPES['providers.edit']]} editRoles={null} viewRoles={null}>
                                            <ButtonWithOptions options={[/*{ title: 'Save', onClick: handleSaveClick, disabled: false, type: ButtonWithOptionsType.item }, */{ title: `${intl.get('save&close.button')}`, onClick: handleSaveCloseClick, disabled: Object.keys(errors).length > 0 || disabled, type: ButtonWithOptionsType.item }]} outline={true} disabled={Object.keys(errors).length > 0 || disabled} className="btn btn-primary" onClick={handleSaveClick} ><HiOutlineSave className='mb-1'/> {intl.get('save.button')}</ButtonWithOptions>
                                            {/* <button type='button' className='btn btn-primary' id='btn-detail' onClick={(e) => { e.preventDefault(); handleSubmit(); }} disabled={JSON.stringify(errors) === JSON.stringify({}) && disabled !== true ? false : true}><HiOutlineSave/> Save</button>
                                            <button type='button' className='btn btn-primary' id='btn-detail' onClick={(e) => { e.preventDefault(); handleSubmit(true); }} disabled={JSON.stringify(errors) === JSON.stringify({}) && disabled !== true ? false : true}><HiOutlineSave/> Save & Edit</button> */}
                                        </PermissionsGate> 
                                    </>
                                }
                            </> : null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProviderDetail;
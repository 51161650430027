
import React from 'react';
import '../../scss/NewInvoice.scss';
import { NewInvoiceProvider } from './newInvoiceProvider';
import NewInvoiceMain from './NewInvoiceMain';
import { WorkflowProvider } from 'components/Workflow/WorkflowContext';

const NewInvoice = () => {
    return (
        <NewInvoiceProvider>
            <WorkflowProvider  moduleId={3}>
                <NewInvoiceMain />
            </WorkflowProvider>
        </NewInvoiceProvider>
    )
};

export default NewInvoice;

/* tslint:disable */
/* eslint-disable */
/**
 * Intranet - Workflow API
 * Workflows
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { BooleanResponse } from '../models';
// @ts-ignore
import type { CreateCommand } from '../models';
// @ts-ignore
import type { DeleteCommand } from '../models';
// @ts-ignore
import type { ListOptionDTOSearchResponseResponse } from '../models';
// @ts-ignore
import type { LogEntrySearchResponseResponse } from '../models';
// @ts-ignore
import type { ModuleSearchResponseResponse } from '../models';
// @ts-ignore
import type { StringResponse } from '../models';
// @ts-ignore
import type { UpdateCommand } from '../models';
// @ts-ignore
import type { UpdateStateCommand } from '../models';
// @ts-ignore
import type { WorkflowDetailsResponse } from '../models';
// @ts-ignore
import type { WorkflowDetailsSearchResponseResponse } from '../models';
// @ts-ignore
import type { WorkflowResponse } from '../models';
// @ts-ignore
import type { WorkflowStateDTOSearchResponseResponse } from '../models';
/**
 * WorkflowApi - axios parameter creator
 * @export
 */
export const WorkflowApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary requestStatus ListOptionDto
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionListRequestStatusGet: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionListRequestStatusGet', 'version', version)
            const localVarPath = `/api/v{version}/List/requestStatus`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all the items in a list.
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionModuleAllGet: async (version: string, pageNum?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionModuleAllGet', 'version', version)
            const localVarPath = `/api/v{version}/Module/all`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets WorkFlowStates by Module Id.
         * @param {number} moduleId 
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionModuleWorkflowStatesModuleIdGet: async (moduleId: number, version: string, pageNum?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'moduleId' is not null or undefined
            assertParamExists('apiVversionModuleWorkflowStatesModuleIdGet', 'moduleId', moduleId)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionModuleWorkflowStatesModuleIdGet', 'version', version)
            const localVarPath = `/api/v{version}/Module/workflowStates/{moduleId}`
                .replace(`{${"moduleId"}}`, encodeURIComponent(String(moduleId)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all the items in a list.
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowAllGet: async (version: string, pageNum?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionWorkflowAllGet', 'version', version)
            const localVarPath = `/api/v{version}/Workflow/all`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an existing Workflow
         * @param {string} version 
         * @param {DeleteCommand} [deleteCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowDelete: async (version: string, deleteCommand?: DeleteCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionWorkflowDelete', 'version', version)
            const localVarPath = `/api/v{version}/Workflow`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a detailed workflow .
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowIdGet: async (id: number, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVversionWorkflowIdGet', 'id', id)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionWorkflowIdGet', 'version', version)
            const localVarPath = `/api/v{version}/Workflow/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the inital state for a worfklow
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowIdInitialGet: async (id: number, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVversionWorkflowIdInitialGet', 'id', id)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionWorkflowIdInitialGet', 'version', version)
            const localVarPath = `/api/v{version}/Workflow/{id}/initial`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all the workflows for a given module Id.
         * @param {string} version 
         * @param {number} [idModule] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowIdModuleGet: async (version: string, idModule?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionWorkflowIdModuleGet', 'version', version)
            const localVarPath = `/api/v{version}/Workflow/idModule`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idModule !== undefined) {
                localVarQueryParameter['idModule'] = idModule;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a detailed workflow .
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowIdScopedGet: async (id: number, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVversionWorkflowIdScopedGet', 'id', id)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionWorkflowIdScopedGet', 'version', version)
            const localVarPath = `/api/v{version}/Workflow/{id}/scoped`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the possible states given the current state
         * @param {number} idState 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowIdStateIdStateGet: async (idState: number, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idState' is not null or undefined
            assertParamExists('apiVversionWorkflowIdStateIdStateGet', 'idState', idState)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionWorkflowIdStateIdStateGet', 'version', version)
            const localVarPath = `/api/v{version}/Workflow/idState/{idState}`
                .replace(`{${"idState"}}`, encodeURIComponent(String(idState)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an existing Workflow
         * @param {number} idModule 
         * @param {number} idEntity 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowLogsIdModuleIdEntityGet: async (idModule: number, idEntity: number, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idModule' is not null or undefined
            assertParamExists('apiVversionWorkflowLogsIdModuleIdEntityGet', 'idModule', idModule)
            // verify required parameter 'idEntity' is not null or undefined
            assertParamExists('apiVversionWorkflowLogsIdModuleIdEntityGet', 'idEntity', idEntity)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionWorkflowLogsIdModuleIdEntityGet', 'version', version)
            const localVarPath = `/api/v{version}/Workflow/logs/{idModule}/{idEntity}`
                .replace(`{${"idModule"}}`, encodeURIComponent(String(idModule)))
                .replace(`{${"idEntity"}}`, encodeURIComponent(String(idEntity)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a detailed workflow .
         * @param {number} moduleId 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowModuleIdActiveScopedGet: async (moduleId: number, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'moduleId' is not null or undefined
            assertParamExists('apiVversionWorkflowModuleIdActiveScopedGet', 'moduleId', moduleId)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionWorkflowModuleIdActiveScopedGet', 'version', version)
            const localVarPath = `/api/v{version}/Workflow/{moduleId}/active/scoped`
                .replace(`{${"moduleId"}}`, encodeURIComponent(String(moduleId)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an existing Workflow
         * @param {string} version 
         * @param {CreateCommand} [createCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowPost: async (version: string, createCommand?: CreateCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionWorkflowPost', 'version', version)
            const localVarPath = `/api/v{version}/Workflow`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an existing Workflow
         * @param {string} version 
         * @param {UpdateCommand} [updateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowPut: async (version: string, updateCommand?: UpdateCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionWorkflowPut', 'version', version)
            const localVarPath = `/api/v{version}/Workflow`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search workflow
         * @param {string} version 
         * @param {string} [term] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowSearchGet: async (version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionWorkflowSearchGet', 'version', version)
            const localVarPath = `/api/v{version}/Workflow/search`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the states for a module by module name.
         * @param {string} moduleName 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowStatesByModuleNameModuleNameGet: async (moduleName: string, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'moduleName' is not null or undefined
            assertParamExists('apiVversionWorkflowStatesByModuleNameModuleNameGet', 'moduleName', moduleName)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionWorkflowStatesByModuleNameModuleNameGet', 'version', version)
            const localVarPath = `/api/v{version}/Workflow/statesByModuleName/{moduleName}`
                .replace(`{${"moduleName"}}`, encodeURIComponent(String(moduleName)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the possible transitions given the current state
         * @param {number} idState 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowTransitionsIdStateGet: async (idState: number, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idState' is not null or undefined
            assertParamExists('apiVversionWorkflowTransitionsIdStateGet', 'idState', idState)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionWorkflowTransitionsIdStateGet', 'version', version)
            const localVarPath = `/api/v{version}/Workflow/transitions/{idState}`
                .replace(`{${"idState"}}`, encodeURIComponent(String(idState)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an existing Workflow
         * @param {string} version 
         * @param {UpdateStateCommand} [updateStateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowUpdateStatePost: async (version: string, updateStateCommand?: UpdateStateCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionWorkflowUpdateStatePost', 'version', version)
            const localVarPath = `/api/v{version}/Workflow/updateState`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateStateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkflowApi - functional programming interface
 * @export
 */
export const WorkflowApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkflowApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary requestStatus ListOptionDto
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionListRequestStatusGet(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListOptionDTOSearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionListRequestStatusGet(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkflowApi.apiVversionListRequestStatusGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets all the items in a list.
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionModuleAllGet(version: string, pageNum?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModuleSearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionModuleAllGet(version, pageNum, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkflowApi.apiVversionModuleAllGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets WorkFlowStates by Module Id.
         * @param {number} moduleId 
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionModuleWorkflowStatesModuleIdGet(moduleId: number, version: string, pageNum?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowStateDTOSearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionModuleWorkflowStatesModuleIdGet(moduleId, version, pageNum, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkflowApi.apiVversionModuleWorkflowStatesModuleIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets all the items in a list.
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowAllGet(version: string, pageNum?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowDetailsSearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionWorkflowAllGet(version, pageNum, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkflowApi.apiVversionWorkflowAllGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates an existing Workflow
         * @param {string} version 
         * @param {DeleteCommand} [deleteCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowDelete(version: string, deleteCommand?: DeleteCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionWorkflowDelete(version, deleteCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkflowApi.apiVversionWorkflowDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets a detailed workflow .
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowIdGet(id: number, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionWorkflowIdGet(id, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkflowApi.apiVversionWorkflowIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets the inital state for a worfklow
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowIdInitialGet(id: number, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowStateDTOSearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionWorkflowIdInitialGet(id, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkflowApi.apiVversionWorkflowIdInitialGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets all the workflows for a given module Id.
         * @param {string} version 
         * @param {number} [idModule] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowIdModuleGet(version: string, idModule?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionWorkflowIdModuleGet(version, idModule, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkflowApi.apiVversionWorkflowIdModuleGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets a detailed workflow .
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowIdScopedGet(id: number, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionWorkflowIdScopedGet(id, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkflowApi.apiVversionWorkflowIdScopedGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets the possible states given the current state
         * @param {number} idState 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowIdStateIdStateGet(idState: number, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowStateDTOSearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionWorkflowIdStateIdStateGet(idState, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkflowApi.apiVversionWorkflowIdStateIdStateGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates an existing Workflow
         * @param {number} idModule 
         * @param {number} idEntity 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowLogsIdModuleIdEntityGet(idModule: number, idEntity: number, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogEntrySearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionWorkflowLogsIdModuleIdEntityGet(idModule, idEntity, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkflowApi.apiVversionWorkflowLogsIdModuleIdEntityGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets a detailed workflow .
         * @param {number} moduleId 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowModuleIdActiveScopedGet(moduleId: number, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionWorkflowModuleIdActiveScopedGet(moduleId, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkflowApi.apiVversionWorkflowModuleIdActiveScopedGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates an existing Workflow
         * @param {string} version 
         * @param {CreateCommand} [createCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowPost(version: string, createCommand?: CreateCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionWorkflowPost(version, createCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkflowApi.apiVversionWorkflowPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates an existing Workflow
         * @param {string} version 
         * @param {UpdateCommand} [updateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowPut(version: string, updateCommand?: UpdateCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionWorkflowPut(version, updateCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkflowApi.apiVversionWorkflowPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Search workflow
         * @param {string} version 
         * @param {string} [term] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowSearchGet(version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowDetailsSearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionWorkflowSearchGet(version, term, pageNum, pageSize, sort, order, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkflowApi.apiVversionWorkflowSearchGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets the states for a module by module name.
         * @param {string} moduleName 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowStatesByModuleNameModuleNameGet(moduleName: string, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowStateDTOSearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionWorkflowStatesByModuleNameModuleNameGet(moduleName, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkflowApi.apiVversionWorkflowStatesByModuleNameModuleNameGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets the possible transitions given the current state
         * @param {number} idState 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowTransitionsIdStateGet(idState: number, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowStateDTOSearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionWorkflowTransitionsIdStateGet(idState, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkflowApi.apiVversionWorkflowTransitionsIdStateGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates an existing Workflow
         * @param {string} version 
         * @param {UpdateStateCommand} [updateStateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionWorkflowUpdateStatePost(version: string, updateStateCommand?: UpdateStateCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionWorkflowUpdateStatePost(version, updateStateCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkflowApi.apiVversionWorkflowUpdateStatePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WorkflowApi - factory interface
 * @export
 */
export const WorkflowApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkflowApiFp(configuration)
    return {
        /**
         * 
         * @summary requestStatus ListOptionDto
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionListRequestStatusGet(version: string, options?: RawAxiosRequestConfig): AxiosPromise<ListOptionDTOSearchResponseResponse> {
            return localVarFp.apiVversionListRequestStatusGet(version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all the items in a list.
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionModuleAllGet(version: string, pageNum?: number, pageSize?: number, options?: RawAxiosRequestConfig): AxiosPromise<ModuleSearchResponseResponse> {
            return localVarFp.apiVversionModuleAllGet(version, pageNum, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets WorkFlowStates by Module Id.
         * @param {number} moduleId 
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionModuleWorkflowStatesModuleIdGet(moduleId: number, version: string, pageNum?: number, pageSize?: number, options?: RawAxiosRequestConfig): AxiosPromise<WorkflowStateDTOSearchResponseResponse> {
            return localVarFp.apiVversionModuleWorkflowStatesModuleIdGet(moduleId, version, pageNum, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all the items in a list.
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowAllGet(version: string, pageNum?: number, pageSize?: number, options?: RawAxiosRequestConfig): AxiosPromise<WorkflowDetailsSearchResponseResponse> {
            return localVarFp.apiVversionWorkflowAllGet(version, pageNum, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an existing Workflow
         * @param {string} version 
         * @param {DeleteCommand} [deleteCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowDelete(version: string, deleteCommand?: DeleteCommand, options?: RawAxiosRequestConfig): AxiosPromise<BooleanResponse> {
            return localVarFp.apiVversionWorkflowDelete(version, deleteCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a detailed workflow .
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowIdGet(id: number, version: string, options?: RawAxiosRequestConfig): AxiosPromise<WorkflowResponse> {
            return localVarFp.apiVversionWorkflowIdGet(id, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the inital state for a worfklow
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowIdInitialGet(id: number, version: string, options?: RawAxiosRequestConfig): AxiosPromise<WorkflowStateDTOSearchResponseResponse> {
            return localVarFp.apiVversionWorkflowIdInitialGet(id, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all the workflows for a given module Id.
         * @param {string} version 
         * @param {number} [idModule] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowIdModuleGet(version: string, idModule?: number, options?: RawAxiosRequestConfig): AxiosPromise<WorkflowDetailsResponse> {
            return localVarFp.apiVversionWorkflowIdModuleGet(version, idModule, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a detailed workflow .
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowIdScopedGet(id: number, version: string, options?: RawAxiosRequestConfig): AxiosPromise<WorkflowResponse> {
            return localVarFp.apiVversionWorkflowIdScopedGet(id, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the possible states given the current state
         * @param {number} idState 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowIdStateIdStateGet(idState: number, version: string, options?: RawAxiosRequestConfig): AxiosPromise<WorkflowStateDTOSearchResponseResponse> {
            return localVarFp.apiVversionWorkflowIdStateIdStateGet(idState, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an existing Workflow
         * @param {number} idModule 
         * @param {number} idEntity 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowLogsIdModuleIdEntityGet(idModule: number, idEntity: number, version: string, options?: RawAxiosRequestConfig): AxiosPromise<LogEntrySearchResponseResponse> {
            return localVarFp.apiVversionWorkflowLogsIdModuleIdEntityGet(idModule, idEntity, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a detailed workflow .
         * @param {number} moduleId 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowModuleIdActiveScopedGet(moduleId: number, version: string, options?: RawAxiosRequestConfig): AxiosPromise<WorkflowDetailsResponse> {
            return localVarFp.apiVversionWorkflowModuleIdActiveScopedGet(moduleId, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an existing Workflow
         * @param {string} version 
         * @param {CreateCommand} [createCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowPost(version: string, createCommand?: CreateCommand, options?: RawAxiosRequestConfig): AxiosPromise<WorkflowResponse> {
            return localVarFp.apiVversionWorkflowPost(version, createCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an existing Workflow
         * @param {string} version 
         * @param {UpdateCommand} [updateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowPut(version: string, updateCommand?: UpdateCommand, options?: RawAxiosRequestConfig): AxiosPromise<WorkflowDetailsResponse> {
            return localVarFp.apiVversionWorkflowPut(version, updateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search workflow
         * @param {string} version 
         * @param {string} [term] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowSearchGet(version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, options?: RawAxiosRequestConfig): AxiosPromise<WorkflowDetailsSearchResponseResponse> {
            return localVarFp.apiVversionWorkflowSearchGet(version, term, pageNum, pageSize, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the states for a module by module name.
         * @param {string} moduleName 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowStatesByModuleNameModuleNameGet(moduleName: string, version: string, options?: RawAxiosRequestConfig): AxiosPromise<WorkflowStateDTOSearchResponseResponse> {
            return localVarFp.apiVversionWorkflowStatesByModuleNameModuleNameGet(moduleName, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the possible transitions given the current state
         * @param {number} idState 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowTransitionsIdStateGet(idState: number, version: string, options?: RawAxiosRequestConfig): AxiosPromise<WorkflowStateDTOSearchResponseResponse> {
            return localVarFp.apiVversionWorkflowTransitionsIdStateGet(idState, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an existing Workflow
         * @param {string} version 
         * @param {UpdateStateCommand} [updateStateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionWorkflowUpdateStatePost(version: string, updateStateCommand?: UpdateStateCommand, options?: RawAxiosRequestConfig): AxiosPromise<BooleanResponse> {
            return localVarFp.apiVversionWorkflowUpdateStatePost(version, updateStateCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkflowApi - object-oriented interface
 * @export
 * @class WorkflowApi
 * @extends {BaseAPI}
 */
export class WorkflowApi extends BaseAPI {
    /**
     * 
     * @summary requestStatus ListOptionDto
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public apiVversionListRequestStatusGet(version: string, options?: RawAxiosRequestConfig) {
        return WorkflowApiFp(this.configuration).apiVversionListRequestStatusGet(version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all the items in a list.
     * @param {string} version 
     * @param {number} [pageNum] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public apiVversionModuleAllGet(version: string, pageNum?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return WorkflowApiFp(this.configuration).apiVversionModuleAllGet(version, pageNum, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets WorkFlowStates by Module Id.
     * @param {number} moduleId 
     * @param {string} version 
     * @param {number} [pageNum] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public apiVversionModuleWorkflowStatesModuleIdGet(moduleId: number, version: string, pageNum?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return WorkflowApiFp(this.configuration).apiVversionModuleWorkflowStatesModuleIdGet(moduleId, version, pageNum, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all the items in a list.
     * @param {string} version 
     * @param {number} [pageNum] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public apiVversionWorkflowAllGet(version: string, pageNum?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return WorkflowApiFp(this.configuration).apiVversionWorkflowAllGet(version, pageNum, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates an existing Workflow
     * @param {string} version 
     * @param {DeleteCommand} [deleteCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public apiVversionWorkflowDelete(version: string, deleteCommand?: DeleteCommand, options?: RawAxiosRequestConfig) {
        return WorkflowApiFp(this.configuration).apiVversionWorkflowDelete(version, deleteCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a detailed workflow .
     * @param {number} id 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public apiVversionWorkflowIdGet(id: number, version: string, options?: RawAxiosRequestConfig) {
        return WorkflowApiFp(this.configuration).apiVversionWorkflowIdGet(id, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the inital state for a worfklow
     * @param {number} id 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public apiVversionWorkflowIdInitialGet(id: number, version: string, options?: RawAxiosRequestConfig) {
        return WorkflowApiFp(this.configuration).apiVversionWorkflowIdInitialGet(id, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all the workflows for a given module Id.
     * @param {string} version 
     * @param {number} [idModule] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public apiVversionWorkflowIdModuleGet(version: string, idModule?: number, options?: RawAxiosRequestConfig) {
        return WorkflowApiFp(this.configuration).apiVversionWorkflowIdModuleGet(version, idModule, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a detailed workflow .
     * @param {number} id 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public apiVversionWorkflowIdScopedGet(id: number, version: string, options?: RawAxiosRequestConfig) {
        return WorkflowApiFp(this.configuration).apiVversionWorkflowIdScopedGet(id, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the possible states given the current state
     * @param {number} idState 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public apiVversionWorkflowIdStateIdStateGet(idState: number, version: string, options?: RawAxiosRequestConfig) {
        return WorkflowApiFp(this.configuration).apiVversionWorkflowIdStateIdStateGet(idState, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates an existing Workflow
     * @param {number} idModule 
     * @param {number} idEntity 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public apiVversionWorkflowLogsIdModuleIdEntityGet(idModule: number, idEntity: number, version: string, options?: RawAxiosRequestConfig) {
        return WorkflowApiFp(this.configuration).apiVversionWorkflowLogsIdModuleIdEntityGet(idModule, idEntity, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a detailed workflow .
     * @param {number} moduleId 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public apiVversionWorkflowModuleIdActiveScopedGet(moduleId: number, version: string, options?: RawAxiosRequestConfig) {
        return WorkflowApiFp(this.configuration).apiVversionWorkflowModuleIdActiveScopedGet(moduleId, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates an existing Workflow
     * @param {string} version 
     * @param {CreateCommand} [createCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public apiVversionWorkflowPost(version: string, createCommand?: CreateCommand, options?: RawAxiosRequestConfig) {
        return WorkflowApiFp(this.configuration).apiVversionWorkflowPost(version, createCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates an existing Workflow
     * @param {string} version 
     * @param {UpdateCommand} [updateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public apiVversionWorkflowPut(version: string, updateCommand?: UpdateCommand, options?: RawAxiosRequestConfig) {
        return WorkflowApiFp(this.configuration).apiVversionWorkflowPut(version, updateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search workflow
     * @param {string} version 
     * @param {string} [term] 
     * @param {number} [pageNum] 
     * @param {number} [pageSize] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public apiVversionWorkflowSearchGet(version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, options?: RawAxiosRequestConfig) {
        return WorkflowApiFp(this.configuration).apiVversionWorkflowSearchGet(version, term, pageNum, pageSize, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the states for a module by module name.
     * @param {string} moduleName 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public apiVversionWorkflowStatesByModuleNameModuleNameGet(moduleName: string, version: string, options?: RawAxiosRequestConfig) {
        return WorkflowApiFp(this.configuration).apiVversionWorkflowStatesByModuleNameModuleNameGet(moduleName, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the possible transitions given the current state
     * @param {number} idState 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public apiVversionWorkflowTransitionsIdStateGet(idState: number, version: string, options?: RawAxiosRequestConfig) {
        return WorkflowApiFp(this.configuration).apiVversionWorkflowTransitionsIdStateGet(idState, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates an existing Workflow
     * @param {string} version 
     * @param {UpdateStateCommand} [updateStateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public apiVversionWorkflowUpdateStatePost(version: string, updateStateCommand?: UpdateStateCommand, options?: RawAxiosRequestConfig) {
        return WorkflowApiFp(this.configuration).apiVversionWorkflowUpdateStatePost(version, updateStateCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


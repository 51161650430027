import { Typography, Box } from "@mui/material"
import DevErrorScreen from "components/common/DevErrorScreen"
import React from "react"
import { Component } from "react"
import { Toast, ToastBody, ToastHeader } from "reactstrap"

interface ErrorBoundaryState {
  hasError: boolean
  error: Error
  showMessage: boolean
}

interface ErrorBoundaryProps {
  fallback?: React.ReactNode
  children?: React.ReactNode
}

export default class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: null,
      showMessage: true
    }
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
      error,
    }
  }

  closeMessage = () => {
    this.setState({ showMessage: false })
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true })

    const splashScreen = document.querySelector(".splash-screen")
    if (!splashScreen) return
    splashScreen.classList.add("fadeOut")
    setTimeout(() => {
      splashScreen.remove()
    }, 1000)
  }

  render() {
    if (this.state.hasError) {
      if (import.meta.env.DEV) {
        return <DevErrorScreen error={this.state.error} />
      }

      if(!this.props.fallback){
        return this.state.showMessage&&<Box className="toast-container top-right" style={{ zIndex: 9999 }}>
          <Toast className={'toast-error top-right'} fade={false}>
            <ToastHeader toggle={() => { this.closeMessage() }}>
                Unhandled Error
            </ToastHeader>
            <ToastBody>
                <Typography variant="h6">An unknown error has occurred.</Typography>
                <Typography variant="h6" className="fw-bold">Please contact the administrator.</Typography>
            </ToastBody>
          </Toast>
        </Box>
      }

      return this.props.fallback
    }

    return this.props.children
  }
}
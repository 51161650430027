/* tslint:disable */
/* eslint-disable */
/**
 * Intranet - Reports API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { StringResponse } from '../models';
// @ts-ignore
import type { TimeSheetDetailedReportListResponse } from '../models';
/**
 * ReportsApi - axios parameter creator
 * @export
 */
export const ReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets the list of all Jobs
         * @param {string} version 
         * @param {Date} [dateFrom] 
         * @param {Date} [dateTo] 
         * @param {string} [projects] 
         * @param {string} [clients] 
         * @param {string} [employees] 
         * @param {string} [jobs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionBillingDetailedReportAllGet: async (version: string, dateFrom?: Date, dateTo?: Date, projects?: string, clients?: string, employees?: string, jobs?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionBillingDetailedReportAllGet', 'version', version)
            const localVarPath = `/api/v{version}/BillingDetailedReport/all`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString() :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString() :
                    dateTo;
            }

            if (projects !== undefined) {
                localVarQueryParameter['projects'] = projects;
            }

            if (clients !== undefined) {
                localVarQueryParameter['clients'] = clients;
            }

            if (employees !== undefined) {
                localVarQueryParameter['employees'] = employees;
            }

            if (jobs !== undefined) {
                localVarQueryParameter['jobs'] = jobs;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the list of all Jobs
         * @param {string} version 
         * @param {Date} [dateFrom] 
         * @param {Date} [dateTo] 
         * @param {string} [projects] 
         * @param {number} [clientId] 
         * @param {string} [employees] 
         * @param {string} [jobs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionBillingDetailedReportClientGet: async (version: string, dateFrom?: Date, dateTo?: Date, projects?: string, clientId?: number, employees?: string, jobs?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionBillingDetailedReportClientGet', 'version', version)
            const localVarPath = `/api/v{version}/BillingDetailedReport/client`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString() :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString() :
                    dateTo;
            }

            if (projects !== undefined) {
                localVarQueryParameter['projects'] = projects;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (employees !== undefined) {
                localVarQueryParameter['employees'] = employees;
            }

            if (jobs !== undefined) {
                localVarQueryParameter['jobs'] = jobs;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the list of all Jobs
         * @param {string} version 
         * @param {Date} [dateFrom] 
         * @param {Date} [dateTo] 
         * @param {string} [projects] 
         * @param {string} [clients] 
         * @param {string} [employees] 
         * @param {string} [jobs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTimeSheetDetailedReportAllGet: async (version: string, dateFrom?: Date, dateTo?: Date, projects?: string, clients?: string, employees?: string, jobs?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionTimeSheetDetailedReportAllGet', 'version', version)
            const localVarPath = `/api/v{version}/TimeSheetDetailedReport/all`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString() :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString() :
                    dateTo;
            }

            if (projects !== undefined) {
                localVarQueryParameter['projects'] = projects;
            }

            if (clients !== undefined) {
                localVarQueryParameter['clients'] = clients;
            }

            if (employees !== undefined) {
                localVarQueryParameter['employees'] = employees;
            }

            if (jobs !== undefined) {
                localVarQueryParameter['jobs'] = jobs;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the list of all Jobs
         * @param {string} version 
         * @param {Date} [dateFrom] 
         * @param {Date} [dateTo] 
         * @param {string} [projects] 
         * @param {number} [clientId] 
         * @param {string} [employees] 
         * @param {string} [jobs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTimeSheetDetailedReportClientGet: async (version: string, dateFrom?: Date, dateTo?: Date, projects?: string, clientId?: number, employees?: string, jobs?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionTimeSheetDetailedReportClientGet', 'version', version)
            const localVarPath = `/api/v{version}/TimeSheetDetailedReport/client`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString() :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString() :
                    dateTo;
            }

            if (projects !== undefined) {
                localVarQueryParameter['projects'] = projects;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (employees !== undefined) {
                localVarQueryParameter['employees'] = employees;
            }

            if (jobs !== undefined) {
                localVarQueryParameter['jobs'] = jobs;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsApi - functional programming interface
 * @export
 */
export const ReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets the list of all Jobs
         * @param {string} version 
         * @param {Date} [dateFrom] 
         * @param {Date} [dateTo] 
         * @param {string} [projects] 
         * @param {string} [clients] 
         * @param {string} [employees] 
         * @param {string} [jobs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionBillingDetailedReportAllGet(version: string, dateFrom?: Date, dateTo?: Date, projects?: string, clients?: string, employees?: string, jobs?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeSheetDetailedReportListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionBillingDetailedReportAllGet(version, dateFrom, dateTo, projects, clients, employees, jobs, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.apiVversionBillingDetailedReportAllGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets the list of all Jobs
         * @param {string} version 
         * @param {Date} [dateFrom] 
         * @param {Date} [dateTo] 
         * @param {string} [projects] 
         * @param {number} [clientId] 
         * @param {string} [employees] 
         * @param {string} [jobs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionBillingDetailedReportClientGet(version: string, dateFrom?: Date, dateTo?: Date, projects?: string, clientId?: number, employees?: string, jobs?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeSheetDetailedReportListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionBillingDetailedReportClientGet(version, dateFrom, dateTo, projects, clientId, employees, jobs, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.apiVversionBillingDetailedReportClientGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets the list of all Jobs
         * @param {string} version 
         * @param {Date} [dateFrom] 
         * @param {Date} [dateTo] 
         * @param {string} [projects] 
         * @param {string} [clients] 
         * @param {string} [employees] 
         * @param {string} [jobs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTimeSheetDetailedReportAllGet(version: string, dateFrom?: Date, dateTo?: Date, projects?: string, clients?: string, employees?: string, jobs?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeSheetDetailedReportListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionTimeSheetDetailedReportAllGet(version, dateFrom, dateTo, projects, clients, employees, jobs, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.apiVversionTimeSheetDetailedReportAllGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets the list of all Jobs
         * @param {string} version 
         * @param {Date} [dateFrom] 
         * @param {Date} [dateTo] 
         * @param {string} [projects] 
         * @param {number} [clientId] 
         * @param {string} [employees] 
         * @param {string} [jobs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTimeSheetDetailedReportClientGet(version: string, dateFrom?: Date, dateTo?: Date, projects?: string, clientId?: number, employees?: string, jobs?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeSheetDetailedReportListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionTimeSheetDetailedReportClientGet(version, dateFrom, dateTo, projects, clientId, employees, jobs, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.apiVversionTimeSheetDetailedReportClientGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ReportsApi - factory interface
 * @export
 */
export const ReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets the list of all Jobs
         * @param {string} version 
         * @param {Date} [dateFrom] 
         * @param {Date} [dateTo] 
         * @param {string} [projects] 
         * @param {string} [clients] 
         * @param {string} [employees] 
         * @param {string} [jobs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionBillingDetailedReportAllGet(version: string, dateFrom?: Date, dateTo?: Date, projects?: string, clients?: string, employees?: string, jobs?: string, options?: RawAxiosRequestConfig): AxiosPromise<TimeSheetDetailedReportListResponse> {
            return localVarFp.apiVversionBillingDetailedReportAllGet(version, dateFrom, dateTo, projects, clients, employees, jobs, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the list of all Jobs
         * @param {string} version 
         * @param {Date} [dateFrom] 
         * @param {Date} [dateTo] 
         * @param {string} [projects] 
         * @param {number} [clientId] 
         * @param {string} [employees] 
         * @param {string} [jobs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionBillingDetailedReportClientGet(version: string, dateFrom?: Date, dateTo?: Date, projects?: string, clientId?: number, employees?: string, jobs?: string, options?: RawAxiosRequestConfig): AxiosPromise<TimeSheetDetailedReportListResponse> {
            return localVarFp.apiVversionBillingDetailedReportClientGet(version, dateFrom, dateTo, projects, clientId, employees, jobs, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the list of all Jobs
         * @param {string} version 
         * @param {Date} [dateFrom] 
         * @param {Date} [dateTo] 
         * @param {string} [projects] 
         * @param {string} [clients] 
         * @param {string} [employees] 
         * @param {string} [jobs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTimeSheetDetailedReportAllGet(version: string, dateFrom?: Date, dateTo?: Date, projects?: string, clients?: string, employees?: string, jobs?: string, options?: RawAxiosRequestConfig): AxiosPromise<TimeSheetDetailedReportListResponse> {
            return localVarFp.apiVversionTimeSheetDetailedReportAllGet(version, dateFrom, dateTo, projects, clients, employees, jobs, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the list of all Jobs
         * @param {string} version 
         * @param {Date} [dateFrom] 
         * @param {Date} [dateTo] 
         * @param {string} [projects] 
         * @param {number} [clientId] 
         * @param {string} [employees] 
         * @param {string} [jobs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTimeSheetDetailedReportClientGet(version: string, dateFrom?: Date, dateTo?: Date, projects?: string, clientId?: number, employees?: string, jobs?: string, options?: RawAxiosRequestConfig): AxiosPromise<TimeSheetDetailedReportListResponse> {
            return localVarFp.apiVversionTimeSheetDetailedReportClientGet(version, dateFrom, dateTo, projects, clientId, employees, jobs, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportsApi - object-oriented interface
 * @export
 * @class ReportsApi
 * @extends {BaseAPI}
 */
export class ReportsApi extends BaseAPI {
    /**
     * 
     * @summary Gets the list of all Jobs
     * @param {string} version 
     * @param {Date} [dateFrom] 
     * @param {Date} [dateTo] 
     * @param {string} [projects] 
     * @param {string} [clients] 
     * @param {string} [employees] 
     * @param {string} [jobs] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public apiVversionBillingDetailedReportAllGet(version: string, dateFrom?: Date, dateTo?: Date, projects?: string, clients?: string, employees?: string, jobs?: string, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).apiVversionBillingDetailedReportAllGet(version, dateFrom, dateTo, projects, clients, employees, jobs, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the list of all Jobs
     * @param {string} version 
     * @param {Date} [dateFrom] 
     * @param {Date} [dateTo] 
     * @param {string} [projects] 
     * @param {number} [clientId] 
     * @param {string} [employees] 
     * @param {string} [jobs] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public apiVversionBillingDetailedReportClientGet(version: string, dateFrom?: Date, dateTo?: Date, projects?: string, clientId?: number, employees?: string, jobs?: string, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).apiVversionBillingDetailedReportClientGet(version, dateFrom, dateTo, projects, clientId, employees, jobs, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the list of all Jobs
     * @param {string} version 
     * @param {Date} [dateFrom] 
     * @param {Date} [dateTo] 
     * @param {string} [projects] 
     * @param {string} [clients] 
     * @param {string} [employees] 
     * @param {string} [jobs] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public apiVversionTimeSheetDetailedReportAllGet(version: string, dateFrom?: Date, dateTo?: Date, projects?: string, clients?: string, employees?: string, jobs?: string, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).apiVversionTimeSheetDetailedReportAllGet(version, dateFrom, dateTo, projects, clients, employees, jobs, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the list of all Jobs
     * @param {string} version 
     * @param {Date} [dateFrom] 
     * @param {Date} [dateTo] 
     * @param {string} [projects] 
     * @param {number} [clientId] 
     * @param {string} [employees] 
     * @param {string} [jobs] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public apiVversionTimeSheetDetailedReportClientGet(version: string, dateFrom?: Date, dateTo?: Date, projects?: string, clientId?: number, employees?: string, jobs?: string, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).apiVversionTimeSheetDetailedReportClientGet(version, dateFrom, dateTo, projects, clientId, employees, jobs, options).then((request) => request(this.axios, this.basePath));
    }
}



import React, { useState } from 'react';
import NewInvoiceForm from './NewInvoiceForm';
import { Grid, Typography } from "@mui/material";
import Select from 'react-select';
import intl from 'components/i18n/ReactIntlWrapper';
import ErrorSummary from '../common/ErrorSummary';
import NewInvoiceDropZone from './NewInvoiceDropZone'
import { useNewInvoice } from './newInvoiceProvider';

const NewInvoiceMain = () => {

    const { isManualCreation, billFromPersonas, isBillFromPersonasLoading, setInput, input } = useNewInvoice();
    const [nameValue, setNameValue ] = useState();
    const [errors, setErrors] = useState<any>();
    const [disabled, setDisabled] = useState(false);


    const handleInputChange = function (e) {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        });
    };

    const handlePersonaChange = (e) => {
        if (e !== null) {
            setInput({
                ...input,
                idFrom: e.id,
                from: e.name
            });
            setNameValue(e.id);
        } else {
            setNameValue(null);
            setInput({
                ...input,
                idFrom: null,
                from: null
            });
        }
    };

    return (
        <div className='container'>
            <div className='card mt-4'>
                <div className="card-header">
                    <h2 className="title">{intl.get('newInvoice.header')}</h2>
                </div>
                <div className='card-body'>
                    <Grid container xs={12} md={12} lg={12} xl={12}>
                        <Grid item xs={12} md={12} lg={12} xl={12}>
                            <ErrorSummary errors={errors} showEverything={true} ></ErrorSummary>
                        </Grid>
                    </Grid>
                    <Grid container xs={12} md={12} lg={12} xl={12} className='justify-content-around'>
                        <Grid container item xs={12} md={12} lg={12} xl={12}>
                            <Grid item xs={6} md={6} lg={6} xl={6}>
                                <Typography className={`${errors?.idFrom ? 'text-danger' : ''}`} style={{marginTop: errors?.idFrom ? "-30px" : "0px"}}>
                                    {errors?.idFrom && (<span className='text-danger'>{errors?.idFrom}</span>)}
                                </Typography>
                                <Select
                                    id='title'
                                    name='title'
                                    getOptionLabel={e => e.name}
                                    getOptionValue={e => e.id}
                                    placeholder={intl.get('newInvoice.invoiceSelectProfile')}
                                    value={(billFromPersonas != null && nameValue != null) ? (billFromPersonas.find((req) => req.id === nameValue)) : ''}
                                    onChange={(e) => handlePersonaChange(e)}
                                    isLoading={isBillFromPersonasLoading}
                                    options={billFromPersonas}
                                    isClearable={true}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <hr/>
                    <Grid container xs={12} md={12} lg={12} xl={12}>
                        <Grid md={isManualCreation ? 0 : 7}>
                            <NewInvoiceDropZone errors={errors} setErrors={setErrors} files={input["attachments"]} />
                        </Grid>
                        <Grid md={isManualCreation ? 12 : 5}>
                            <NewInvoiceForm errors={errors} setErrors={setErrors} handleInputChange={handleInputChange} disabled={disabled} />
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    )
};

export default NewInvoiceMain;

import React from 'react'
import { Box, Typography } from '@mui/material'
import { MdArrowBack } from 'react-icons/md'
import intl from 'components/i18n/ReactIntlWrapper';
import '../../scss/common/NotFoundPage.scss'

type ErrorTemplateFoundPageProps = {
    statusCode?: number,
    title: string,
    message?: string
};

export default function ErrorTemplateFoundPage({
    statusCode,
    title,
    message
}: ErrorTemplateFoundPageProps) {
  return (<div className='container'>
    <div className='card mt-4 position-absolute top-50 start-50 translate-middle'>
        <div className='container card-body flex justify-content-center align-items-center text-center'>
            <Box className="not-found-page-container">
                {statusCode?
                    <Typography className='text-center' sx={{fontSize: '8rem', fontWeight: 'bold'}}>
                        {statusCode}
                    </Typography>
                : 
                    <Typography color={"error"} className='text-center' sx={{fontSize: '3rem', fontWeight: 'bold', whiteSpace: 'nowrap'}}>
                        Unknow Error
                    </Typography>
                }
            </Box>
            <h2 className='text-center'>{title}</h2>
            {message && <p className='my-4'>{message}</p>}
            <a href='/' className='text-center d-block mt-3 text-decoration-none'>
                <MdArrowBack /> {intl.get('errorPage.back')} 
            </a>
        </div>
    </div>
  </div>)
}

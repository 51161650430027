import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';
import { useApi } from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import { ButtonWithOptions, ButtonWithOptionsType } from "components/common/ButtonWithOptions";
import { HiArrowLeft, HiOutlineSave, HiPencilAlt } from 'react-icons/hi';
import RequiredField from '../RequiredField';
import intl from 'react-intl-universal';
import Spinner from '../Spinner';
import { handleAPIError } from '../../common/errorHandler';
import CorporateEntityForm from './CorporateEntityForm';
import AddressCompact from '../AddressCompact';
import { SCOPES, ROLES } from "../../common/permissions";
import { confirmAlert } from 'react-confirm-alert';
import { CreateCorporateEntityCommand } from 'axiosApi/models/create-corporate-entity-command';
import { UpdateCorporateEntityCommand } from 'axiosApi/models/update-corporate-entity-command';
import PersonaCompact from 'components/Persona/PersonaCompact';
import { ENTITY_TYPE } from 'common/constants';

const CorporateEntityDetail = () => {

    const DefaultCorporateEntityDetailInput = {
        id: null,
        name: '',
        country: {name: '', labelKey: ''},
        idCountry: null,
        active: false,
        corporateEntityAddresses: [],
        personas: []
    };

    const [input, setInput] = useState(DefaultCorporateEntityDetailInput);

    const api: Api = useApi();

    const navigate = useNavigate();

    const toast = useToastMessageQueue();

    const { id } = useParams();
    const idNumber = parseInt(id);
    
    const [loading, setLoading] = useState<boolean>(false);

    const [errors, setErrors] = useState({});

    const [disabled, setDisabled] = useState(false);

    const [disabledNew, setDisabledNew] = useState(false);

    const [personaExist, setPersonaExist] = useState<boolean>(false);

    const [countries, setCountries] = useState(null);

    const fetchCorporateEntityDetail = async (idNumber: number) => {
        setLoading(true);
        const response = await api.invoiceApi.apiVversionCorporateEntityIdGet(idNumber, "1", {}).then((response) => {
            if (response.data.data) {
                setInput({
                    ...input,
                    id: response.data?.data?.id,
                    name: response.data?.data?.name,
                    country: {name: response.data?.data?.country?.name, labelKey: response.data?.data?.country?.labelKey},
                    idCountry: response.data?.data?.idCountry,
                    active: response.data?.data?.active,
                    corporateEntityAddresses: response.data?.data?.corporateEntityAddresses,
                })
            };
            setPersonaExist(false);
            setLoading(false);
        }).catch((error) => {
            handleAPIError(error, toast, errors);
            setErrors({...errors});
            setLoading(false);
        });
    };

    const fetchCountries = async () => {
        const response = await api.addressApi.apiVversionAddressCountryAllGet("1", {}).then((response) => {
            if (response.data) {
                setCountries(response.data?.data);                
            };
        }).catch((error) => {
            handleAPIError(error, toast, errors);
            setErrors({...errors});
        });
    };

    useEffect(() => {
        if (idNumber) {
            fetchCorporateEntityDetail(idNumber);
            setDisabled(true);
        };
        setDisabledNew(true);
        fetchCountries();
    }, [idNumber]);

    const handleInputChange = function (e) {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        });
        setErrors(validate({
            ...input,
            [e.target.name]: e.target.value
        }));
    };

    const handleCountryChange = (e) => {
        const inputVal = {
            ...input,
            idCountry: e.id,
            country: {...e.country, name: intl.get(e.labelKey) ,labelKey: e.labelKey},
        };
        setInput({ ...inputVal });
        setErrors(validate({
            ...input,
            idCountry: e.id,
        }));
    };

    const validate = function (input) {
        setDisabledNew(false);
        let errors: any = {};
        if (!input.name) {
            errors.name = intl.get('validate.errors.isRequired');
        }
        if (!input.idCountry) {
            errors.idCountry = intl.get('validate.errors.isRequired');
        }
        return errors;
    };

    const handleBackClick = (e) => {
        navigate('/corporateEntities');
        setPersonaExist(false);
    };

    const handleSaveClick = () => {
        handleSubmit(true);
    };

    const handleSaveCloseClick = () => {
        handleSubmit(false);
    };

    const handleEditMode = (e) => {
        e.preventDefault();
        if (idNumber) {
            setPersonaExist(false);
            fetchCorporateEntityDetail(idNumber);
        }
        setErrors({});
        setDisabled(!disabled);
    };

    const handleStatusChange = (e) => {
        const inputVal = {
            ...input,
            active: e.currentTarget.checked 
        };
        setInput({ ...inputVal });
    };

    const handlePersonaChange = (event) => {
        setInput((prevInput) => ({
            ...prevInput,
            personas: event
        }));
    };

    const handleDelete = () => {
        confirmAlert({
            title: intl.get('delete.modal.title'),
            message: 'The corporate entity will be deleted. Are you sure you want to perform this action?',
            buttons: [
                {
                    label: intl.get('delete.modal.cancel.button'),
                    onClick: () => { }
                },
                {
                    label: intl.get('delete.modal.delete.button'),
                    onClick: async () => {
                        const response = await api.invoiceApi.apiVversionCorporateEntityIdDelete(idNumber, "1", {}).then((response) => {
                            toast.success({ body: 'Corporate entity deleted!'});
                            navigate('/corporateEntities');
                        }).catch((error) => {
                            handleAPIError(error, toast, errors);
                            setErrors({...errors});
                        });
                    },
                }
            ]
        });
    };

    const handleSubmit = async (saveAndEdit: boolean = false) => {
        setLoading(true);
        const errors = validate(input);
        setErrors(errors);
        if (Object.keys(errors).length === 0) {
            if (idNumber) {
                let addressIds = input.corporateEntityAddresses.map(item => item.idAddress);
                let personaIds = input.personas
                let cmd: UpdateCorporateEntityCommand = {
                    id: idNumber,
                    name: input.name,
                    idCountry: input.idCountry,
                    active: input.active,
                    addressIds: addressIds,
                    personaIds: personaIds
                };
                const response = await api.invoiceApi.apiVversionCorporateEntityPut("1", cmd, {}).then((response) => {
                    if (saveAndEdit) {
                        setDisabled(true);
                        setPersonaExist(false);
                    } else {
                        navigate('/corporateEntities');
                    }
                    toast.success({ body: 'Corporate entity has been updated!'});
                    setLoading(false);
                }).catch((error) => {
                    handleAPIError(error, toast, errors);
                    setErrors({...errors});
                });
            } else {
                let addressIds = input.corporateEntityAddresses.map(item => item.idAddress);
                let cmd: CreateCorporateEntityCommand = {
                    name: input.name,
                    idCountry: input.idCountry,
                    active: input.active,
                    addressIds: addressIds,
                };
                const response = await api.invoiceApi.apiVversionCorporateEntityPost("1", cmd, {}).then((response) => {
                    toast.success({ body: 'Corporate entity has been added!'});
                    setLoading(false);
                    if (saveAndEdit && "data" in response.data)
                        navigate(`/corporateEntities/detail/${response.data.data.id}`);
                    else
                        navigate('/corporateEntities');
                }).catch((error) => {
                    handleAPIError(error, toast, errors);
                    setErrors({...errors});
                });
            };
        };
        setLoading(false);
    };

    const realAddresses = input.corporateEntityAddresses.filter(item => item.address?.idAddressType === 1); 
    
    const legalAddresses = input.corporateEntityAddresses.filter(item => item.address?.idAddressType === 2);

    return (
        <div className="container">
            <div className='card mt-4'>
                <div className="card-header">
                    {
                        idNumber ? 
                        <>
                            <h2 className="title">Corporate Entity Detail</h2>
                        </> :
                        <h2 className="title">New Corporate Entity</h2>
                    }
                </div>
                {
                    loading ?
                    <Spinner /> :
                    <div className='card-body'>
                        <CorporateEntityForm
                            errors={errors}
                            input={input}
                            handleInputChange={handleInputChange}
                            disabled={disabled}
                            handleStatusChange={handleStatusChange}
                            countries={countries}
                            handleCountryChange={handleCountryChange}
                        />
                        <div className='card'>
                            <AddressCompact
                                corporateEntityAddresses={realAddresses} 
                                disabled={disabled} 
                                editScopes={[SCOPES['corporateentity.edit']]} 
                                deleteScopes={[SCOPES['corporateentity.edit']]}
                                realAddress={true}
                                allowOne={true}
                                input={input}
                                setInput={setInput}
                                setErrosCorporateEntity={setErrors}
                            />
                        </div>
                        <hr className="separator"></hr>
                        <div className='card'>
                            <AddressCompact
                                corporateEntityAddresses={legalAddresses}
                                disabled={disabled} 
                                editScopes={[SCOPES['corporateentity.edit']]} 
                                deleteScopes={[SCOPES['corporateentity.edit']]}
                                legalAddress={true}
                                allowOne={true}
                                input={input}
                                setInput={setInput}
                            />
                        </div>
                        <hr className="separator"></hr>
                        <div className='card'>
                            <PersonaCompact
                                setPersonaExist={setPersonaExist}
                                personaExist={personaExist}
                                entityId={idNumber}
                                idEntityType={ENTITY_TYPE.CORPORATE_ENTITY} 
                                disabled={disabled} 
                                editScopes= {[SCOPES['corporateentity.edit']]}
                                deleteScopes= {[SCOPES['corporateentity.edit']]}
                                maxRows={1}
                                personaTypesIds={[1]}
                                corporateEntity={true}
                                inputCorporateEntity={input}
                                setInputCorporateEntity={setInput}
                                showCorporateEntities={false}
                            />
                        </div>
                        <hr className="separator"></hr>
                        <RequiredField/>
                        <div className='d-flex justify-content-end my-3'>
                            {
                                !idNumber && !disabled ?
                                <>
                                    {
                                        loading === true ?
                                        <Spinner small={true} /> :
                                        <>
                                            <button type='button' className='btn btn-outline-dark me-2' id='return' onClick={handleBackClick}>
                                                <HiArrowLeft/>
                                            </button>
                                            <ButtonWithOptions options={[{ title: `${intl.get('save&close.button')}`, onClick: handleSaveCloseClick, disabled: Object.keys(errors).length > 0 || disabledNew, type: ButtonWithOptionsType.item }]} outline={true} disabled={Object.keys(errors).length > 0 || disabledNew} className="btn btn-primary" onClick={handleSaveClick} ><HiOutlineSave className='mb-1'/> {intl.get('save.button')}</ButtonWithOptions>
                                        </>
                                    }
                                </> : null
                            }
                            {
                                idNumber && disabled ?
                                <>
                                    <button type='button' className='btn btn-outline-dark me-2' id='return' onClick={handleBackClick}>
                                        <HiArrowLeft/>
                                    </button>
                                    <button type='button' className='btn btn-primary me-2' id='edit' onClick={handleEditMode}>   
                                        <HiPencilAlt className='mb-1' /> {intl.get('edit.button')}
                                    </button>
                                </> : null
                            }
                            {
                                idNumber && !disabled ?
                                <>
                                    <button type='button' className='btn btn-outline-dark me-2' id='edit' onClick={handleEditMode}>   
                                        {intl.get('cancel.button')}
                                    </button>
                                    <button type='button' className='btn btn-outline-danger me-2' id='delete' onClick={handleDelete}>   
                                        {intl.get('delete.button')}
                                    </button>
                                    <ButtonWithOptions options={[{ title: `${intl.get('save&close.button')}`, onClick: handleSaveCloseClick, disabled: Object.keys(errors).length > 0 || disabled, type: ButtonWithOptionsType.item }]} outline={true} disabled={Object.keys(errors).length > 0 || disabled} className="btn btn-primary" onClick={handleSaveClick} ><HiOutlineSave className='mb-1'/> {intl.get('save.button')}</ButtonWithOptions>
                                </> : null
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default CorporateEntityDetail;
import React, { useState, useEffect } from 'react';
import { useApi } from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import { CreateNonWorkingDayCommand } from '../../axiosApi/models/create-non-working-day-command';
import { UpdateNonWorkingDayCommand } from '../../axiosApi/models/update-non-working-day-command';
import { HiOutlineSave, HiOutlineTrash, HiOutlineXCircle } from 'react-icons/hi';
import RequiredField from '../RequiredField';
import intl from 'components/i18n/ReactIntlWrapper';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Spinner from '../Spinner';
import CalendarEventForm from './CalendarEventForm';
import { confirmAlert } from 'react-confirm-alert';
import { handleAPIError } from 'common/errorHandler';

const CalendarEvent = ({ closeModal, eventId, updateEvents }) => {

    const defaultCalendarEventInput = {
        idCountry: null,
        country: {name: ''},
        description: '',
        date: null
    };

    const [input, setInput] = useState(defaultCalendarEventInput);

    const api: Api = useApi();

    const [errors, setErrors] = useState({});

    const [countries, setCountries] = useState(null);

    const [loading, setLoading] = useState<boolean>(false);

    const [loadingSave, setLoadingSave] = useState<boolean>(false);

    const toast = useToastMessageQueue();

    const fetchCalendarEvent = async (eventId: number) => {
        setLoading(true);
        const response = await api.timeoffApi.apiVversionNonWorkingDayIdGet(eventId, "1", {}).then((response) => {
            if (response.data.data) {
                setInput({
                    idCountry: response?.data?.data.idCountry,
                    country: {name: response?.data?.data.country.labelKey},
                    description: response?.data?.data.description,
                    date: new Date(response?.data?.data.date) 
                });
            };
        }).catch((error) => {
            handleAPIError(error, toast, errors);
            setErrors({ ...errors });
        }).finally(() => {
            setLoading(false);
        });
    };

    const fetchCountries = async () => {
        setLoading(true);
        const response = await api.addressApi.apiVversionAddressCountryAllGet("1", {}).then((response) => {
            if (response.data) {
                setCountries(response.data.data);
            };
        }).catch((error) => {
            handleAPIError(error, toast, errors);
            setErrors({ ...errors });
        }).finally(() => {
            setLoading(false);
        });
    };

    useEffect(() => {
        if (eventId) {
            fetchCalendarEvent(eventId);
        };
        fetchCountries();
    }, [eventId]);

    const handleInputChange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        });
        setErrors({});
    };

    const handleCountryChange = (e) => {
        const inputVal = {
            ...input,
            idCountry: e.id,
            country: {name: e.name}
        };
        setInput({ ...inputVal });
        setErrors({});
    };

    const handleDate = (e) => {
        setInput({
            ...input,
            date: e
        });
        setErrors({});
    };

    const validate = (input) => {
        let errors: any = {};
        if (!input.idCountry) {
            errors.idCountry = intl.get('validate.errors.isRequired');
        }
        if (!input.description) {
            errors.description = intl.get('validate.errors.isRequired');
        }
        if (!input.date) {
            errors.date = intl.get('validate.errors.isRequired');
        }
        setErrors({ ...errors });
        return Object.keys(errors).length > 0 ? errors : {};
    };

    const handleCancel = () => {
        if (eventId) {
            fetchCalendarEvent(eventId);
        };
        setInput(defaultCalendarEventInput);
        setErrors({});
        closeModal();
    };

    const handleSave = async (e) => {
        e.preventDefault();
        const errors = validate(input);
        if (JSON.stringify(errors) === JSON.stringify({})) {
            setLoadingSave(true);
            if (eventId) {
                let cmd: UpdateNonWorkingDayCommand = {
                    id: eventId,
                    idCountry: input?.idCountry,
                    date: input?.date,
                    description: input?.description
                };
                const response = await api.timeoffApi.apiVversionNonWorkingDayPut("1", cmd, {}).then((response) => {
                    if (response.data) {
                        toast.success({ body: intl.get('calendarEvent.toast.success.handleSave')});
                        setInput(defaultCalendarEventInput);
                        closeModal();
                        updateEvents();
                    };
                }).catch((error) => {
                    handleAPIError(error, toast, errors);
                    setErrors({ ...errors });
                }).finally(() => {
                    setLoadingSave(false);
                });
            } else {
                let cmd: CreateNonWorkingDayCommand = {
                    idCountry: input?.idCountry,
                    date: input?.date,
                    description: input?.description
                }
                const response = await api.timeoffApi.apiVversionNonWorkingDayPost("1", cmd, {}).then((response) => {
                    if (response.data) {
                        toast.success({ body: intl.get('calendarEvent.toast.success.handleSave.add')});
                        setInput(defaultCalendarEventInput);
                        closeModal();
                        updateEvents();
                    }
                }).catch((error) => {
                    handleAPIError(error, toast, errors);
                    setErrors({ ...errors });
                }).finally(() => {
                    setLoadingSave(false);
                });
            }
        }
    };

    const handleDelete = (eventId: number) => {
        confirmAlert({
            title: intl.get('delete.modal.title'),
            message: intl.get('delete.modal.calendarEvent.message'),
            buttons: [
                {
                    label: intl.get('delete.modal.cancel.button'),
                    onClick: () => { }
                },
                {
                    label: intl.get('delete.modal.delete.button'),
                    onClick: async () => {
                        setLoadingSave(true);
                        const response = await api.timeoffApi.apiVversionNonWorkingDayIdDelete(eventId, "1", {}).then((response) => {
                            toast.success({ body: intl.get('calendarEvent.toast.success.handleDelete')});
                            setLoading(false);
                            closeModal();
                            updateEvents();
                        }).catch((error) => {
                            handleAPIError(error, toast, errors);
                            setErrors({ ...errors });
                        }).finally(() => {
                            setLoadingSave(false);
                        });
                    },
                }
            ]
        });
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ my: 3 }}>
                <h2 style={{ textAlign: 'start', marginBottom: '1rem' }}>
                    {eventId ? intl.get('calendarEvent.title.edit') : intl.get('calendarEvent.title.add')}
                </h2>
                <Grid container sx={{ textAlign: 'start', mb: 3 }}>
                    {
                        loading === true ?
                        <Spinner small={true} /> :
                        <CalendarEventForm 
                            errors={errors}
                            input={input}
                            handleCountryChange={handleCountryChange}
                            countries={countries}
                            selectedCountry={input.country.name}
                            handleInputChange={handleInputChange}
                            handleDate={handleDate}
                        />
                    }
                </Grid>
                <Grid container spacing={2}>
                    <Grid xs={12} md={4}>
                        <RequiredField/>
                    </Grid>
                    <Grid xs={12} md={8}>
                        <Stack direction="row" spacing={2} justifyContent="flex-end">
                            {
                                loadingSave === true ?
                                <Spinner small={true} /> :
                                <>
                                    <Button 
                                        variant="outlined"
                                        color="inherit"
                                        onClick={handleCancel}
                                        startIcon={<HiOutlineXCircle />}
                                    >
                                        {intl.get('cancel.button')}
                                    </Button>
                                    {
                                        eventId &&
                                        <Button 
                                            variant="outlined"
                                            color="error"
                                            onClick={() => handleDelete(eventId)}
                                            startIcon={<HiOutlineTrash />}
                                        >
                                            {intl.get('delete.button')}
                                        </Button>
                                    }
                                    <Button 
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSave}
                                        startIcon={<HiOutlineSave />}
                                        disabled={JSON.stringify(errors) !== JSON.stringify({}) || input.country.name === "" ? true : false}
                                    >
                                        {intl.get('save.button')}
                                    </Button>
                                </>
                            }
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default CalendarEvent;


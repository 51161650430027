// WorkflowContext.tsx
import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';
import axios from 'axios';
import { WorkflowDetails } from '../../axiosApi/models/workflow-details';
import Api from '../../axiosApi/api';
import { useApi } from '../../api/ApiProvider';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import intl from 'components/i18n/ReactIntlWrapper';
import { handleAPIError } from 'common/errorHandler';

interface WorkflowContextProps {
  workflow: WorkflowDetails | null;
  loading: boolean;
  errors: Error | null;
}



const WorkflowContext = createContext<WorkflowContextProps | undefined>(undefined);

interface WorkflowProviderProps {
  moduleId: number;
  children: ReactNode;
}

export const WorkflowProvider: React.FC<WorkflowProviderProps> = ({ moduleId, children }) => {
  const [workflow, setWorkflow] = useState<WorkflowDetails | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [errors, setErrors] = useState<Error | null>(null);

  const api: Api = useApi();
  const toast = useToastMessageQueue();
  
  useEffect(() => {
    const fetchWorkflow = async () => {
        setLoading(true);
            const response = await api.workflowApi.apiVversionWorkflowModuleIdActiveScopedGet(moduleId,"1" ,{}).then((response)=>{
              if (response.data?.data) {
                setWorkflow(response.data.data);
              } else {
                setWorkflow(null);
              }
            }).catch((error)=>{
              handleAPIError(error, toast, errors);    
              setErrors({ ...errors });            
          });
          setLoading(false);
        };

    if (moduleId) {
      fetchWorkflow();
    }
  }, [moduleId]);

  return (
    <WorkflowContext.Provider value={{ workflow, loading, errors }}>
      {children}
    </WorkflowContext.Provider>
  );
};

export const useWorkflow = (): WorkflowContextProps => {
  const context = useContext(WorkflowContext);
  if (context === undefined) {
    throw new Error('useWorkflow must be used within a WorkflowProvider');
  }
  return context;
};

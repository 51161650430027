import { LocaleContext } from 'components/i18n/LocaleProvider';
import { useContext } from 'react';

const useLocale = () => {
    const {
        locale,
        languagesAvailable,
        changeLocale,
        reloadLocales,
        loading,
        error
    } = useContext(LocaleContext);

    return {
        locale,
        languagesAvailable,
        changeLocale,
        reloadLocales,
        loading,
        error
    };
};

export default useLocale;
import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import React, { useEffect, useState, useCallback } from 'react'
import { BiPlus, BiSave, BiSearch, BiTrash, BiX } from 'react-icons/bi'
import intl from 'components/i18n/ReactIntlWrapper'
import { useApi } from 'api/ApiProvider'
import Api from 'axiosApi/api'
import Spinner from 'components/Spinner'
import Paginator from './Paginator'
import { LocaleDto } from 'axiosApi/models'
import { useDebounce } from '@uidotdev/usehooks'
import TranslationInput from './TranslationInput'
import NewRow from './NewRow'
import { confirmAlert } from 'react-confirm-alert';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider'
import useLocale from 'i18n/useLocale'
import { LanguageDefinition, LANGUAGES_DEFINITION_LIST, LOCALES_ISO_639_1 } from 'components/i18n/locales'

export default function TranslationsPage() {

    const { localeApi }: Api = useApi();
    const { languagesAvailable, reloadLocales } = useLocale();
    const [loading, setLoading] = useState<boolean>(false);

    const [locales, setLocales] = useState<LocaleDto[] | null>(null);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    
    const [searchInputValue, setSearchInputValue] = useState<string>('');
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [searchTerm, setSearchTerm] = useState<string>('');

    const [showNewRow, setShowNewRow] = useState<boolean>(false);
    const [openLanguageModal, setOpenLanguageModal] = useState<boolean>(false);

    const debouncedSearchTerm = useDebounce(searchInputValue, 1000);
    const toast = useToastMessageQueue();

    const [newLanguages, setNewLanguages] = useState<LOCALES_ISO_639_1[]>([]);
    const [newLanguage, setNewLanguage] = useState<LanguageDefinition | null>(null);

    const [inputRefs, setInputRefs] = useState<{[key: string]: React.RefObject<HTMLInputElement>}>({});
    const [dirtyRefs, setDirtyRefs] = useState<string[]>([]);
    
    const getInputref = (key: string, language: string) => {
        if(!inputRefs[`${key}-${language}`]){
            inputRefs[`${key}-${language}`] = React.createRef();
            setInputRefs(inputRefs);
        }
        return inputRefs[`${key}-${language}`];
    }

    const addDirtyRef = (key: string) => {
        setDirtyRefs([...dirtyRefs, key]);
    }

    const removeDirtyRef = (key: string) => {
        setDirtyRefs(dirtyRefs.filter((dirtyKey) => dirtyKey !== key));
    }

    const addNewLanguage = () => {
        if(!newLanguage){
            return;
        }
        setNewLanguages([...newLanguages, newLanguage?.code]);
        setNewLanguage(null);
        setOpenLanguageModal(false);
    }

    useEffect(() => {
        setNewLanguages(newLanguages.filter((newLanguage) => !languagesAvailable.includes(newLanguage)));
    }, [languagesAvailable]);

    const deleteLocale = (key: string) => {
        confirmAlert({
            title: 'Confirm',
            message: intl.get('delete.modal.locale.message'),
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleDelete(key)
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    }

    const handleDelete = (key: string) => {
        setLoading(true);
        localeApi.apiVversionLocaleKeyDelete(key, "1").then(() => {
            toast.success({ header: 'Success', body: 'Locale deleted' });
            reloadLocales();
            refreshPage();
        }).catch(() => {
            toast.error({ header: 'Error', body: 'Error deleting locale' });
            console.log('Error deleting locale');
        }).finally(() => {
            setLoading(false);
        });
    }

    const refreshPage = () => {
        setLoading(true);
        localeApi.apiVversionLocaleSearchGet("1", searchTerm, currentPage, pageSize).then((response) => {
            const { data } = response?.data;
            const { queryResult, totalRecords } = data;
            setLocales(queryResult as any || []);
            setTotalRecords(totalRecords || 0);
        }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        setLoading(true);
        localeApi.apiVversionLocaleSearchGet("1", searchTerm, currentPage, pageSize).then((response) => {
            const { data } = response?.data;
            const { queryResult, totalRecords } = data;
            setLocales(queryResult as any || []);
            setTotalRecords(totalRecords || 0);
        }).finally(() => {
            setLoading(false);
        });
    }, [searchTerm, currentPage, pageSize]);

    useEffect(() => {
        if(debouncedSearchTerm?.length === 0){
            setSearchTerm('');
        }else if(debouncedSearchTerm?.length > 2){
            setSearchTerm(debouncedSearchTerm);
        }
    }, [debouncedSearchTerm]);
    
    const clearSearch = () => {
        setSearchInputValue('');
        setSearchTerm('');
    }

    const bulkSave = () => {
        setLoading(true);
        const dirtyLocales = dirtyRefs.map((dirtyKey) => {
            const [key, language] = dirtyKey.split('-');
            return {
                key,
                language,
                value: inputRefs[dirtyKey].current?.value
            }
        });

        const localeDtosToSave: LocaleDto[] = dirtyLocales.map((dirtyLocale) => {
            const localeDto = locales?.find((locale) => locale.key === dirtyLocale.key);
            if(!localeDto){
                return {
                    key: dirtyLocale.key,
                    localeTranslationDtos: [{
                        language: dirtyLocale.language,
                        value: dirtyLocale.value
                    }]
                }
            }else{
                const foundTranslation = localeDto.localeTranslationDtos.find((t) => t.language === dirtyLocale.language);
                if(foundTranslation){
                    localeDto.localeTranslationDtos = localeDto.localeTranslationDtos.map((t) => {
                        if(t.language === dirtyLocale.language){
                            return {
                                ...t,
                                value: dirtyLocale.value
                            }
                        }
                        return t;
                    });
                }else{
                    localeDto.localeTranslationDtos.push({
                        language: dirtyLocale.language,
                        value: dirtyLocale.value
                    });
                }
            }
            return localeDto;
        });

        localeApi.apiVversionLocaleBulkPut("1", {
            locales: localeDtosToSave
        }).then(() => {
            toast.success({ body: intl.get('locale.toast.success.save') });
            refreshPage();
        }).catch(() => {
            toast.error({ body: intl.get('locale.toast.error.save') });
        }).finally(() => {
            setLoading(false);
        });
    }

    const hasPendingChanges = useCallback(() => {
        return dirtyRefs.length > 0;
    }, [dirtyRefs]);

    const checkUnsavedChanges = (callback: () => void) => {
        if(!hasPendingChanges()){
            callback();
            return;
        }

        confirmAlert({
            title: 'Unsaved Changes',
            message: intl.get("confirm.modal.locale.message"),
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        callback();
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        // User chose not to proceed, do nothing
                    }
                }
            ]
        });
    }

    return (<>
        <Stack className="container" marginTop={4} gap={2}>
            <Box>
                <Typography color="primary" variant='h2'>{intl.get('locale.header')}</Typography>
            </Box>
            <Dialog
                open={openLanguageModal}
                onClose={() => setOpenLanguageModal(false)}
            >
                <DialogTitle className='py-2 px-3 d-flex justify-content-between align-items-center'>
                    <Typography variant='h6'>{intl.get('locale.addLanguage')}</Typography>
                    <IconButton className='p-0' onClick={() => setOpenLanguageModal(false)}>
                        <BiX/>
                    </IconButton>
                </DialogTitle>
                <DialogContent className='pt-2 my-2'>
                    <Autocomplete
                        options={LANGUAGES_DEFINITION_LIST}
                        getOptionLabel={(option) => `${option.name} (${option.code})`}
                        getOptionDisabled={(option) => languagesAvailable.includes(option.code)}
                        value={newLanguage}
                        onChange={(e, value) => {
                            setNewLanguage(value);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label={intl.get('locale.selectLanguage')}
                            />
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' color='primary' onClick={() => setOpenLanguageModal(false)}>{intl.get('cancel.button')}</Button>
                    <Button variant='contained' color='primary' disabled={!newLanguage} onClick={() => {addNewLanguage()}}>{intl.get('accept.button')}</Button>
                </DialogActions>
            </Dialog>
            <Box display='flex' justifyContent='space-between'>
                    <TextField
                        InputProps={{
                            endAdornment: <Box display={'flex'} gap={1}>
                                {searchInputValue&&<IconButton sx={{p:0}} onClick={() => clearSearch()}><BiX/></IconButton>}
                                <IconButton sx={{p:0}}><BiSearch size={20}/></IconButton>
                            </Box>
                        }}
                        value={searchInputValue}
                        onChange={(e) => setSearchInputValue(e.target.value)}
                        onBlur={() => setSearchInputValue(searchTerm)}
                        />
                    <Box display='flex' gap={1} >
                        <Button startIcon={<BiSave/>} onClick={() => bulkSave()} disabled={dirtyRefs.length === 0}>{intl.get('locale.saveAll')}</Button>
                        <Button startIcon={<BiPlus/>} onClick={() => setOpenLanguageModal(true)}>{intl.get('locale.addLanguage')}</Button>
                        <Button startIcon={<BiPlus/>} onClick={() => setShowNewRow(true)}>{intl.get('locale.addLabelKey')}</Button>
                    </Box>
            </Box>

            {loading &&
                <Spinner/>
            }
            {locales !== null && 
                <Box display="flex" flexDirection="column">
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{intl.get('locale.labelKey')}</TableCell>
                                    {languagesAvailable.concat(newLanguages).map((language) => (
                                        <TableCell key={`${language}-header`} className='text-uppercase'>{language}</TableCell>
                                    ))}
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {showNewRow&&
                                    <NewRow languages={languagesAvailable} onClose={() => setShowNewRow(false)}/>
                                }
                                {locales?.length === 0 &&
                                    <TableRow>
                                        <TableCell colSpan={languagesAvailable.length + 2} className='text-center'>
                                            <Typography>{intl.get('locale.noData')}</Typography>
                                        </TableCell>
                                    </TableRow>
                                }
                                {locales.map((locale) => (
                                    <TableRow key={`${locale.key}-label`}>
                                        <TableCell>{locale.key}</TableCell>
                                            {languagesAvailable.map((language) => (
                                                <TableCell key={`${locale.key}-${language}`}>
                                                    <TranslationInput
                                                        inputRef={getInputref(locale.key, language)}
                                                        onDirtyChange={(dirty) => {
                                                            if(dirty){
                                                                addDirtyRef(`${locale.key}-${language}`);
                                                            }else{
                                                                removeDirtyRef(`${locale.key}-${language}`);
                                                            }
                                                        }}
                                                        key={locale.key}
                                                        localeDto={locale}
                                                        language={language}
                                                        onSaveComplete={refreshPage}
                                                    />
                                                </TableCell>
                                            ))}
                                            {newLanguages.map((language) => (
                                                <TableCell key={`${locale.key}-${language}`}>
                                                    <TranslationInput
                                                        inputRef={getInputref(locale.key, language)}
                                                        onDirtyChange={(dirty) => {
                                                            if(dirty){
                                                                addDirtyRef(`${locale.key}-${language}`);
                                                            }else{
                                                                removeDirtyRef(`${locale.key}-${language}`);
                                                            }
                                                        }}
                                                        key={locale.key}
                                                        localeDto={locale}
                                                        language={language}
                                                        onSaveComplete={() =>{
                                                            reloadLocales();
                                                            refreshPage();
                                                        }}
                                                    />
                                                </TableCell>
                                            ))}
                                            <TableCell>
                                                <Box display='flex' gap={1} className='w-100' justifyContent={"center"}>
                                                    <IconButton color='primary' onClick={() => deleteLocale(locale.key)}>
                                                            <BiTrash/>
                                                    </IconButton>
                                                </Box>
                                            </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Paginator
                        totalRecords={totalRecords}
                        currentPage={currentPage}
                        onPageChange={(page) => checkUnsavedChanges(() => setCurrentPage(page))}
                        onPageSizeChange={(pageSize) => checkUnsavedChanges(() => setPageSize(pageSize))}
                    />
                </Box>
            }
        </Stack>
        
    </>)
}

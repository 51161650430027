import React, { useContext } from "react";
import { HiOutlineLogout } from "react-icons/hi";
import AuthProvider, { AuthContext } from './authentication/context/authContext'
import intl from 'components/i18n/ReactIntlWrapper';

/**
 * Renders a button which, when selected, will open a popup for login
 */
export const SignOutButton = () => {

    const authContext = useContext(AuthContext);

    const handleLogout = async () => {
        authContext.signOut();
    }

    return (
        <>
            <a className="user-options" href="#" onClick={handleLogout}>
                <HiOutlineLogout className="mb-1 me-1"></HiOutlineLogout>
                <span>{intl.get('singOutButton')}</span>
            </a>
        </>
    )
}
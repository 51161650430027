import React, { useState, useEffect } from "react";
import { useApi } from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import { formatDate } from "common/utils";
import intl from 'components/i18n/ReactIntlWrapper';
import Spinner from '../Spinner';
import { HiOutlineDocument } from 'react-icons/hi';
import CurrentAssignationsRow from "./CurrentAssignationsRow";
import CurrentAssignationsPagination from "./CurrentAssignationsPagination";
import ItemsSelect from "components/ItemsSelect";
import { handleAPIError } from "common/errorHandler";

const CurrentAssignations = ({ idEmployee }) => {

    const [state, setState] = useState({
        idEmployee: idEmployee,
        currentPage: 1
    });

    const api: Api = useApi();

    const toast = useToastMessageQueue();

    const [assignationsByEmployee, setAssignationsByEmployee] = useState({
        totalRecords: null,
		queryResult: []
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState<any>({});
    const [inactiveAssignations, setInactiveAssignations] = useState(false);

    const [pageCount, setpageCount] = useState<number>(0);

    const [limit, setLimit] = useState<number>(() => {
		return parseInt(localStorage.getItem('itemsPerPageCurrentAssignations'), 10) || 10;
	});

    useEffect(() => {
		setLimit(parseInt(localStorage.getItem('itemsPerPageCurrentAssignations'), 10) || 10);
	}, []);

    const fetchCurrentAssignationsByEmployee = async (idEmployee: number) => {
        setLoading(true);
        const response = await api.projectApi.apiVversionEmployeeProjectGetByEmployeeIdIdGet(idEmployee, "1", {}).then((response)=>{
                    setAssignationsByEmployee({
                        totalRecords: response.data.data.totalRecords,
                        queryResult: response.data.data.employeeProjects
                    });
                }).catch((error) => {
                    handleAPIError(error, toast, errors);    
                    setErrors({ ...errors });            
                });
        setLoading(false);
    };

    useEffect(() => {
        if (idEmployee) {
            fetchCurrentAssignationsByEmployee(idEmployee);
        };
    }, [idEmployee]);

    const handleInactiveAssignations = () => {
        setInactiveAssignations(!inactiveAssignations);
        setState({ ...state, currentPage: 1 });
    };

    const handlePageClick = pageNum => setState({ ...state, currentPage: pageNum });

	const nextPage = () => setState({ ...state, currentPage: state.currentPage + 1 });

	const prevPage = () => setState({ ...state, currentPage: state.currentPage - 1 });

	const initialPage = () => setState({ ...state, currentPage: 1 });

	const finalPage = () => setState({ ...state, currentPage: pageCount });

    const currentDate = new Date();
    const currentDateFormatted = currentDate.toISOString();
    const assignationsByEmployeeActive = assignationsByEmployee.queryResult?.filter((assignation) => assignation.dueDate >= currentDateFormatted);
    const assignationsByEmployeeActivePaginated = assignationsByEmployeeActive.slice((state.currentPage - 1) * limit, state.currentPage * limit);

    const assignationsByEmployeePaginated = assignationsByEmployee.queryResult?.slice((state.currentPage - 1) * limit, state.currentPage * limit);

    return (
        <>
            <div className="card-header">
                <h4 className="title" id="project"><HiOutlineDocument className='mb-1'/> {intl.get('currentAssignations.header')}</h4>
            </div>
            {
                loading === true ?
                <Spinner small={true} /> :
                <div className='card-body'>
                    <div className="container-overflow">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">{intl.get('currentAssignations.table.thead.project')}</th>
                                    <th scope="col" style={{ textAlign: 'center' }}>{intl.get('currentAssignations.table.thead.hourlyRate')}</th>
                                    <th scope="col" style={{ textAlign: 'center' }}>{intl.get('currentAssignations.table.thead.monthlyRate')}</th>
                                    <th scope="col">{intl.get('currentAssignations.table.thead.allocation')}</th>
                                    <th scope="col">{intl.get('currentAssignations.table.thead.role')}</th>
                                    <th scope="col" style={{ textAlign: 'center' }}>{intl.get('currentAssignations.table.thead.startDate')} - {intl.get('currentAssignations.table.thead.dueDate')} </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    inactiveAssignations ?
                                    assignationsByEmployeePaginated.map((assignation, i) => (
                                        <CurrentAssignationsRow
                                            key={i}
                                            projectName={assignation.projectName}
                                            hourlyRate={assignation.hourlyRate}
                                            monthlyRate={assignation.monthlyRate}
                                            roleName={assignation.roleName}
                                            startDate={assignation.startDate ? formatDate(new Date(assignation.startDate)) : ''}
                                            dueDate={assignation.dueDate ? formatDate(new Date(assignation.dueDate)) : ''}
                                            allocation={assignation.allocation}
                                            allocationType={assignation.allocationType}
                                        />
                                    )) :
                                    assignationsByEmployeeActivePaginated.map((assignation, i) => (
                                        <CurrentAssignationsRow
                                            key={i}
                                            projectName={assignation.projectName}
                                            hourlyRate={assignation.hourlyRate}
                                            monthlyRate={assignation.monthlyRate}
                                            roleName={assignation.roleName}
                                            startDate={assignation.startDate ? formatDate(new Date(assignation.startDate)) : ''}
                                            dueDate={assignation.dueDate ? formatDate(new Date(assignation.dueDate)) : ''}
                                            allocation={assignation.allocation}
                                            allocationType={assignation.allocationType}
                                        />
                                    ))
                                }
                            </tbody>
                        </table>
                        <div className="form-check ms-1">
                            <input className="form-check-input" type="checkbox" value="" onClick={handleInactiveAssignations}/>
                            <label className="form-check-label">{intl.get('currentAssignations.checkbox.inactive')}</label>
                        </div>
                        <div className="pagination row w-100">
                            <div className="col-10 col-xl-11 d-flex justify-content-center">
                                <CurrentAssignationsPagination 
                                    handlePageClick={handlePageClick} 
                                    limit={limit} 
                                    assignationsByEmployee={assignationsByEmployee} 
                                    nextPage={nextPage} 
                                    prevPage={prevPage} 
                                    state={state} 
                                    pageCount={pageCount} 
                                    setpageCount={setpageCount} 
                                    initialPage={initialPage} 
                                    finalPage={finalPage} 
                                    assignationsByEmployeeActive={assignationsByEmployeeActive} 
                                    inactiveAssignations={inactiveAssignations}
                                />
                            </div>
                            <div className="col-2 col-xl-1 d-flex justify-content-end">
                                <ItemsSelect
                                    input={state}
                                    setInput={setState}
                                    limit={limit} 
                                    setLimit={setLimit} 
                                    storageKey="itemsPerPageCurrentAssignations"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default CurrentAssignations;
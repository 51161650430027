import React, {Dispatch, FC, SetStateAction, useState} from 'react';
import '../../scss/AddressRow.scss';
import {useApi} from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import { PermissionsGate}  from '../PermissionsGate';
import { HiOutlinePencilAlt, HiOutlineTrash } from 'react-icons/hi';
import intl from 'components/i18n/ReactIntlWrapper';
import { handleAPIError } from '../../common/errorHandler';
import ModalPersona from './ModalPersona';
import { Chip } from '@mui/material';
import { CorporateEntity } from 'axiosApi/models/corporate-entity';
import { confirmAlert } from 'react-confirm-alert';
import { PersonaDTO } from 'axiosApi/models';

type PersonaRowProps = {
    disabled: boolean;
    errors:{};
    setErrors: Function;
    persona: PersonaDTO;
    corporateEntities: CorporateEntity[];
    editScopes : string[];
    deleteScopes: string[];
    inputProvider: any;
    entityId : number
    idEntityType : number
    personaExist: boolean;
    setPersonaExist: Dispatch<SetStateAction<boolean>>;
    setInputProvider: (e : any) => void;
    setPersonaTypeExist: Function;
    inputCorporateEntity?: any;
    setInputCorporateEntity?: (e : any) => void;
    showCorporateEntities?: boolean;
}

const PersonaRow: FC<PersonaRowProps> = (props) =>{

    const {editScopes, disabled, deleteScopes, persona, corporateEntities, personaExist, 
    setPersonaExist, inputProvider = null, setInputProvider = null, entityId, idEntityType, errors, setErrors, 
    setPersonaTypeExist, inputCorporateEntity = null, setInputCorporateEntity = null, showCorporateEntities = true} = props;

    const api: Api = useApi();

    const toast = useToastMessageQueue();

    const [isPersonaRowDetailOpen, setIsPersonaRowDetailOpen] = useState(false);

    const closePersonaRowDetail = () => setIsPersonaRowDetailOpen(false);

    const handlePersonaRowDetailOpen = () => {
        setIsPersonaRowDetailOpen(true);
    };

    const handleDelete = () => {
        confirmAlert({
            title: intl.get('delete.modal.title'),
            message: intl.get('delete.modal.personaRow.message'),
            buttons: [
                {
                    label: intl.get('delete.modal.cancel.button'),
                    onClick: () => { }
                },
                {
                    label: intl.get('delete.modal.delete.button'),
                    onClick: async () => {
                        const response = await api.invoiceApi.apiVversionPersonaIdDelete(persona.id, "1", {}).then((response) => {
                            setPersonaExist(false)
                            setPersonaTypeExist(0);
                            toast.success({ body: intl.get('personaRow.toast.success.handledelete')});
                        }).catch((error) => {
                            handleAPIError(error, toast, errors);
                            setErrors({...errors});
                        });
                    },
                }
            ]
        });
    };        

    return (
        <>
            {
                (personaExist && persona) && 
                <tr>
                    <td>{persona.name}</td>
                    <td>{intl.get(persona?.personaType?.labelKey)}</td>
                    <td>{persona.taxID}</td>
                    {showCorporateEntities && (
                        <td className='text-start'>
                            {persona?.corporateEntities?.length > 0 &&
                            persona?.corporateEntities?.map((corporateEntity,index) => 
                                <Chip
                                    key={index}
                                    label={corporateEntity.name}
                                    variant="outlined"
                                    size="small"
                                    className="text-white bg-secondary me-1 border-white fw-semibold"
                                />)
                            }
                        </td> 
                    )}
                    <td className='text-center'>
                    <PermissionsGate viewScopes={editScopes} editScopes={editScopes} viewRoles={[]} editRoles={[]} >
                        <a className={ disabled === true ? 'td-icon disabled mx-1' : 'td-icon mx-1'} onClick={handlePersonaRowDetailOpen} title={intl.get('personaRow.editButton.title')}>
                            <HiOutlinePencilAlt/>
                        </a>
                    </PermissionsGate>
                    <ModalPersona
                        isOpen={isPersonaRowDetailOpen} 
                        closeModal={closePersonaRowDetail} 
                        persona={persona} 
                        entityId = {entityId}
                        idEntityType = {idEntityType}
                        corporateEntities={corporateEntities}
                        inputProvider={inputProvider} 
                        setInputProvider={setInputProvider}
                        setPersonaExist={setPersonaExist}
                        personaExists={personaExist}
                        personaTypeExist={null}
                        inputCorporateEntity={inputCorporateEntity}
                        setInputCorporateEntity={setInputCorporateEntity}
                        showCorporateEntities={showCorporateEntities}
                    />    
                        <PermissionsGate viewScopes={deleteScopes} editScopes={deleteScopes} viewRoles={[]} editRoles={[]} > 
                            <a className={ disabled === true ? 'td-icon disabled mx-1' : 'td-icon mx-1'} onClick={handleDelete} title={intl.get('personaRow.deleteButton.title')}>
                                <HiOutlineTrash/>
                            </a>
                        </PermissionsGate>
                    </td>
                </tr> 
            }
        </>
    )   
}

export default PersonaRow;
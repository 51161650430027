import React, { useEffect, useState } from 'react';
import intl from 'components/i18n/ReactIntlWrapper';
import RequestStatusSelect from './RequestStatusSelect';
import RequestSelect from './RequestSelect';
import TimeOffSearchBar from './TimeOffSearchBar';
import '../../scss/Time Off/TimeOffNavbar.scss';
import { FaSearch } from "react-icons/fa";
import { Link } from 'react-router';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { HiOutlinePlus } from 'react-icons/hi';
import { PermissionsGate}  from '../PermissionsGate';
import { SCOPES } from 'common/permissions';
import { ENTITY_TYPE } from 'common/constants';
import { useWorkflow } from 'components/Workflow/WorkflowContext';

const TimeOffNavbar = ({ requestType, onSearch, HRView }) => {

    const [queryString, setQueryString] = useState<string>('');
    const [idStatus, setIdStatus] = useState(null);
    const [idRequest, setIdRequest] = useState(null);
    const [workflowLabelKeys, setWorkflowLabelKeys] = useState(null);
    const { workflow } = useWorkflow();

    useEffect(() => {
        setWorkflowLabelKeys(
            workflow?.states?.map(state => ({ label : state.labelKey, id : state.id }))
        )
    },[workflow])

    const handleSearchButton = () => {
        onSearch(queryString, idStatus, idRequest);
    };

    const handleStatusChange = (e) => {        
        if (e !== null) {
            setIdStatus(e.id);
        } else {
            setIdStatus(null);
        }
    };

    const handleRequestChange = (e) => {
        if (e !== null) {
            setIdRequest(e.id);
        } else {
            setIdRequest(null);
        }
    };

    return (
        <nav className='navbar container-fluid'>
            <div className='col-11 filters gap-3'>
                {
                    HRView ?
                    <PermissionsGate viewScopes={[SCOPES['timeoff.rrhh']]} editScopes={[SCOPES['timeoff.rrhh']]} entityType={ENTITY_TYPE.EMPLOYEE} viewRoles={[]} editRoles={[]} >
                        <TimeOffSearchBar queryString={queryString} setQueryString={setQueryString}/>
                    </PermissionsGate> 
                    :
                    null
                }
                <RequestStatusSelect requestStatus={workflowLabelKeys} selectedStatus={idStatus} handleStatusChange={handleStatusChange} isClearable={true} />
                <RequestSelect requestType={requestType} selectedRequest={idRequest} handleRequestChange={handleRequestChange} />
                <button type="button" className="btn btn-outline-primary" onClick={handleSearchButton} ><FaSearch className="mb-1"></FaSearch>&nbsp;{intl.get('jiraIssueSearch.search.button')}</button>
            </div>
            <div className='col-1 newRole'>
                <PermissionsGate viewScopes={[SCOPES['pto.add']]} editScopes={[SCOPES['pto.add']]} entityType={ENTITY_TYPE.EMPLOYEE} viewRoles={[]} editRoles={[]} >
                    {
                        HRView ?
                        <Link to= '/timeOff/requestHR'><button className='btn btn-primary p-1 plusButton' type='button' id='newRequest' data-tooltip-id='tooltipButton'><HiOutlinePlus className='plusIcon'/></button></Link>
                        :
                        <Link to= '/timeOff/request'><button className='btn btn-primary p-1 plusButton' type='button' id='newRequest' data-tooltip-id='tooltipButton'><HiOutlinePlus className='plusIcon'/></button></Link>
                    }    
                    <ReactTooltip 
                        id='tooltipButton'
                        place='left'
                        content={intl.get('timeOffNavbar.newRequest.button')}
                    />
                </PermissionsGate>
            </div>
        </nav>
    )
}

export default TimeOffNavbar;

import React, { useContext } from 'react';
import intl from 'components/i18n/ReactIntlWrapper';
import { getUserEntity } from 'common/UserEntityProvider';
import { Link } from "react-router";
import { HiUserCircle } from 'react-icons/hi';
import { AuthContext } from './authentication/context/authContext';
import { UserEntityDetails } from 'axiosApi/models';

const SwitchProfile = ({ openProfileMenu }) => {
    const { switchUserEntity, users } = useContext(AuthContext);
    const userDetails = getUserEntity();

    const handleUserChange = (option: UserEntityDetails) => {
        switchUserEntity(option.id);
    };
    
    return( 
        <> 
            <div className="user-options" style={{paddingLeft: "0.375rem"}}>
                <HiUserCircle className="me-1 mb-1"></HiUserCircle>
                <span>{
                    userDetails?.entityName
                } </span>
            </div>
                {users.length != 0 && 
                    <div className={open ? "dropdown-menu dark language-menu" : "dropdown-menu language-menu"} aria-labelledby="languageMenu" style={openProfileMenu?{"display":"block"}:{"display":"none"}} >
                        <h6>{intl.get('profileContent.userAccounts')}</h6>
                        {users.filter((option) => option.id !== userDetails?.id).map((option) => (
                            <Link key={`${option.id}-option`} className="dropdown-item" to="/#" id={option.entityName} onClick={() => handleUserChange(option)} >{option.entityName}</Link>
                        )) }
                    </div>
                }
        </>
    )
};

export default SwitchProfile;
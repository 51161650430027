import React from 'react';
import ReactDOM from 'react-dom/client';
import './scss/index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './scss/global/Global.scss';
import { StrictMode } from 'react';
import ErrorBoundary from './ErrorBoundary';
import ErrorTemplateFoundPage from './components/common/ErrorTemplatePage';

// Initialize the app
ReactDOM.createRoot(document.getElementById("root")).render(
  <StrictMode>
    <ErrorBoundary fallback={<ErrorTemplateFoundPage title="Something went wrong" message="Please try again later" />}>
      <App />
    </ErrorBoundary>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from 'react';
import { useNavigate } from 'react-router';
import intl from 'components/i18n/ReactIntlWrapper';
import { HiEye } from 'react-icons/hi';

const CorporateEntitiesRow = ({ className, id, name, countryLabelKey }) => {
    const navigate = useNavigate();
    
    const handleCorporateEntitiesDetail = () => {
        navigate(`/corporateEntities/detail/${id}`);
    };

    return (
        <tr className={className} style={{cursor: "default"}} >
            <td>{name}</td>
            <td>{intl.get(countryLabelKey)}</td>
            <td className='text-center'>
                <a className='td-icon mx-1' title="Detail" style={{cursor: "pointer"}}>
                    <HiEye onClick={() => handleCorporateEntitiesDetail()}/>
                </a>
            </td>
        </tr>
    )
}

export default CorporateEntitiesRow;

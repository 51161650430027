import React, { useState, useEffect } from "react";

const PdfViewer = ({ file }) => {
  const [pdfUrl, setPdfUrl] = useState(null);

  useEffect(() => {
    if (!file) return;

    const url = URL.createObjectURL(file);
    setPdfUrl(url);

    return () => URL.revokeObjectURL(url);
  }, [file]);

  return (
    <div style={{ width: "100%", height: "800px", border: "1px solid #ccc" }}>
      {pdfUrl ? (
        <iframe
          src={pdfUrl}
          title="PDF Viewer"
          width="100%"
          height="100%"
          style={{ border: "none" }}
        />
      ) : (
        <p>No PDF selected</p>
      )}
    </div>
  );
};

export default PdfViewer;

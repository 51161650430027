import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import CountrySelect from './CountrySelect';
import StateSelect from './StateSelect';
import AddressTypeSelect from './AddressTypeSelect';
import '../scss/AddressForm.scss';
import intl from 'components/i18n/ReactIntlWrapper';
import ErrorSummary from 'components/common/ErrorSummary';

const AddressForm = ({ errors, input, handleInputChange, selectedCountry, selectedState, countries, states, handleCountryChange, handleStateChange, addressTypes, selectedAddressType, handleAddressTypeChange, realAddress = null, legalAddress = null }) => {
    
    // Helper function to create label with error handling
    const renderLabel = (labelText, errorKey) => (
        <Typography 
            component="label" 
            sx={{ 
                display: 'block', 
                mb: 1, 
                color: errors[errorKey] ? 'error.main' : 'text.primary'
            }}
        >
            {labelText} {errors[errorKey] && (
                <Typography component="span" color="error">
                    {errors[errorKey]}
                </Typography>
            )}
        </Typography>
    );

    return (
        <Box component="form" sx={{ width: '100%' }}>
            <Box sx={{ mb: 2 }}>
                <Grid container>
                    <Grid xs={12}>
                        <ErrorSummary errors={errors} showEverything={true} />
                    </Grid>
                </Grid>
            </Box>
            
            <Box sx={{ mb: 2 }}>
                <Grid container spacing={2}>
                    <Grid xs={12} md={6}>
                        {renderLabel(intl.get('addressForm.address1'), 'address1')}
                        <TextField 
                            fullWidth
                            id="Address1"
                            name="address1"
                            placeholder={intl.get('addressForm.placeholder.address1')}
                            value={input.address1}
                            onChange={handleInputChange}
                            error={!!errors.address1}
                            size="small"
                        />
                    </Grid>
                    <Grid xs={12} md={6}>
                        {renderLabel(intl.get('addressForm.address2'), '')}
                        <TextField 
                            fullWidth
                            id="Address2"
                            name="address2"
                            placeholder={intl.get('addressForm.placeholder.address2')}
                            value={input.address2}
                            onChange={handleInputChange}
                            size="small"
                        />
                    </Grid>
                </Grid>
            </Box>
            
            <Box sx={{ mb: 2 }}>
                <Grid container spacing={2}>
                    <Grid xs={12} md={6}>
                        {renderLabel(intl.get('addressForm.address3'), '')}
                        <TextField 
                            fullWidth
                            id="Address3"
                            name="address3"
                            placeholder={intl.get('addressForm.placeholder.address3')}
                            value={input.address3}
                            onChange={handleInputChange}
                            size="small"
                        />
                    </Grid>
                    <Grid xs={12} md={6}>
                        {renderLabel(intl.get('addressForm.addressType'), 'idAddressType')}
                        <FormControl fullWidth>
                            <AddressTypeSelect
                                handleAddressTypeChange={handleAddressTypeChange}
                                selectedAddressType={selectedAddressType}
                                addressTypes={addressTypes}
                                realAddress={realAddress}
                            legalAddress={legalAddress}
                        />
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
            
            <Box sx={{ mb: 2 }}>
                <Grid container spacing={2}>
                    <Grid xs={12} md={6}>
                        {renderLabel(intl.get('addressForm.country'), 'idCountry')}
                        <FormControl fullWidth>
                            <CountrySelect
                                handleCountryChange={handleCountryChange}
                                selectedCountry={selectedCountry}
                                countries={countries}
                                inputCountry={input.country.name}
                            />
                        </FormControl>
                    </Grid>
                    <Grid xs={12} md={6}>
                        {renderLabel(intl.get('addressForm.stateProvince'), 'idStateProvince')}
                        <FormControl fullWidth>
                            <StateSelect
                                handleStateChange={handleStateChange}
                                selectedState={selectedState}
                                states={states}
                                inputState={input.stateProvince.name}
                                inputCountry={input.country.name}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
            
            <Box sx={{ mb: 2 }}>
                <Grid container spacing={2}>
                    <Grid xs={12} md={6}>
                        {renderLabel(intl.get('addressForm.city'), 'city')}
                        <TextField 
                            fullWidth
                            id="addressCity"
                            name="city"
                            value={input.city}
                            onChange={handleInputChange}
                            placeholder={intl.get('addressForm.placeholder.city')}
                            error={!!errors.city}
                            size="small"
                        />
                    </Grid>
                    <Grid xs={12} md={6}>
                        {renderLabel(intl.get('addressForm.zipCode'), 'zipCode')}
                        <TextField 
                            fullWidth
                            id="addressZipCode"
                            name="zipCode"
                            value={input.zipCode || ''}
                            onChange={handleInputChange}
                            placeholder={intl.get('addressForm.placeholder.zipCode')}
                            error={!!errors.zipCode}
                            inputProps={{ maxLength: 10 }}
                            size="small"
                        />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default AddressForm;
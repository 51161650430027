import React from 'react';
import { HiOutlineXCircle } from 'react-icons/hi';
import { Box, TextField } from '@mui/material';


const InvoicesAmountRange = ({ amounts, setAmounts, gap }) => {

    const renderAmountTextField = (value) => {
        return  <TextField
                    size="small"
                    placeholder={`${value} Amount`}
                    aria-label={value}
                    value={amounts[value]}
                    onChange={(e) => setAmounts({ ...amounts, [value]: e.target.value })}
                    type="number"
                    InputProps={{
                        inputMode: 'numeric',
                        endAdornment: amounts[value] && (
                                <HiOutlineXCircle
                                    className="iconButton pointer"
                                    onClick={() => setAmounts({ ...amounts, [value]: '' })}
                                />
                            ),
                        }}
                    />
    }

      return (
        <Box display={"flex"} gap={gap}>
            {renderAmountTextField('min')}
            {renderAmountTextField('max')}
        </Box>
      );
    };  
  
export default InvoicesAmountRange;
import React, { FC } from 'react';
import Select from 'react-select';
import { Label } from 'reactstrap';
import intl from 'components/i18n/ReactIntlWrapper';

export interface AllocationType {//TODO CC: this reeplace the old model from the API (//import { AllocationType } from '../../axiosApi/models';)
  name?: string | null;
  id?: number;
}

type AllocationTypeSelectProps = {
  handleAllocationTypeChange: any,
  selectedAllocationType: any,
  allocationTypes: any,
  errors: any,
  disabled: any
};

const AllocationTypeSelect: FC<AllocationTypeSelectProps> = ({ handleAllocationTypeChange, allocationTypes, selectedAllocationType, errors, disabled }) => {

  return (
    <>
      <Label check for="idAllocationType" className={`form-label mt-2 ${errors.allocationType ? 'text-danger' : ''}`}>
        {intl.get('employeeProjectForm.allocationType')} {errors.allocationType && (<span className='text-danger'>{errors.allocationType}</span>)}
      </Label>
      <Select
        id='idAllocationType'
        name='idAllocationType'
        getOptionLabel={e => e.name}
        getOptionValue={e => e.id}
        placeholder={intl.get('employeeProjectForm.placeholder.allocation')}
        value={disabled ? '1' : (allocationTypes != null && selectedAllocationType != null) ? (allocationTypes.find((allocationType: AllocationType) => allocationType.id === selectedAllocationType)) : '1'}
        onChange={handleAllocationTypeChange}
        isLoading={allocationTypes == null}
        options={allocationTypes}
        isDisabled={disabled}
      />
    </>
  );
}

export default AllocationTypeSelect;
import React, { useMemo, useState, useEffect } from 'react';
import { Grid, TextField, InputAdornment, MenuItem, Button, Typography, Menu } from '@mui/material';
import intl from 'components/i18n/ReactIntlWrapper';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import { useNewInvoice } from './newInvoiceProvider';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const NewInvoiceSummary = ({errors, setErrors}) => {
    const [taxesList, setTaxesList] = useState([]);
    const [finalResult, setFinalResult] = useState(0);
    const [dropdownOpen, setDropDownOpen] = useState(false);
    const [dropdownDiscountOpen, setDropDownDiscountOpen] = useState(false);
    const [dropdownShippingOpen, setDropDownShippingOpen] = useState(false);
    const [dropdownOtherChargesOpen, setDropDownOtherChargesOpen] = useState(false);
    const { taxesValues, input, setInput } = useNewInvoice();
    const toast = useToastMessageQueue();
    const [sds, setSds] = useState({
        discount: 0,
        shipping: 0,
        otherCharges: 0
    })
    const [sdsType, setSdsType] = useState({
        discountType: 1,
        shippingType: 1,
        otherChargesType: 1
    })

    const [discountAnchorEl, setDiscountAnchorEl] = useState<null | HTMLElement>(null);
    const [shippingAnchorEl, setShippingAnchorEl] = useState<null | HTMLElement>(null);
    const [otherChargesAnchorEl, setOtherChargesAnchorEl] = useState<null | HTMLElement>(null);
    const [taxesAnchorEl, setTaxesAnchorEl] = useState<null | HTMLElement>(null);

    const discountOpen = Boolean(discountAnchorEl);
    const shippingOpen = Boolean(shippingAnchorEl);
    const otherChargesOpen = Boolean(otherChargesAnchorEl);
    const taxesOpen = Boolean(taxesAnchorEl);

    const handleDiscountClick = (event: React.MouseEvent<HTMLElement>) => {
        setDiscountAnchorEl(event.currentTarget)
    };
    const handleShippingClick = (event: React.MouseEvent<HTMLElement>) => {
        setShippingAnchorEl(event.currentTarget)
    };
    const handleOtherChargesClick = (event: React.MouseEvent<HTMLElement>) => {
        setOtherChargesAnchorEl(event.currentTarget)
    };
    const handleTaxesClick = (event: React.MouseEvent<HTMLElement>) => {
        setTaxesAnchorEl(event.currentTarget)
    };

    const handleDiscountClose = () => {
        setDiscountAnchorEl(null)
    };
    const handleShippingClose = () => {
        setShippingAnchorEl(null)
    };
    const handleOtherChargesClose = () => {
        setOtherChargesAnchorEl(null)
    };
    const handleTaxesClose = () => {
        setTaxesAnchorEl(null)
    };

    const selectTypes: any = [{
        id: 1,
        label: "$"
    },
    {
        id: 2,
        label: "% "
    }
]

    const subTotal = useMemo(() => {
        let total = 0;
        input?.invoiceItems?.forEach(item => {
            total += Number(item.amount)
            if (item.taxesSum) {
                total += Number(item?.taxesSum)
            }
        });
        return total;
    }, [input?.invoiceItems])

    const filteredTaxes = taxesValues?.filter(tax => tax.appliesTo === 1);

    const subTotalWithoutTaxes = useMemo(() => {
        let total = 0;
        input?.invoiceItems?.forEach(item => {
            total += Number(item.amount)
        });
        return total;
    }, [input?.invoiceItems])

    const toggle = () => {
        setDropDownOpen(!dropdownOpen);
    }

    const toggleDiscount = () => {
        setDropDownDiscountOpen(!dropdownDiscountOpen);
    }
    const toggleShipping = () => {
        setDropDownShippingOpen(!dropdownShippingOpen);
    }
    const toggleOtherCharges = () => {
        setDropDownOtherChargesOpen(!dropdownOtherChargesOpen);
    }

    const handleNewTax = function(tax){
        if (taxesList?.some((t) => t.idTaxRate === tax.id)){
            return;
        }
        setTaxesList([
            ...taxesList,
            {
                idTaxRate: tax.id,
                taxAmount: tax.rate * subTotalWithoutTaxes / 100,
                taxRate: tax.rate,
                description: tax.name,
                invoiceItem: {}
            }
        ]);
        setInput({
            ...input,
            invoiceTaxes: [
                ...input.invoiceTaxes,
                {
                    idTaxRate: tax.id,
                    taxAmount: tax.rate * subTotalWithoutTaxes / 100,
                    taxRate: tax.rate,
                    description: tax.name,
                    invoiceItem: {}
                }
            ]
        })
    }

    const handleDeleteTax = function(idTaxRate){
        const newItems = taxesList.filter(tax => tax.idTaxRate !== idTaxRate)
        setTaxesList([...newItems])
        setInput({
            ...input,
            invoiceTaxes: [
                ...newItems
            ]
        })
    }

    const validate = function (name, value, type) {
        if (value > 100 && type === 2) {
            setErrors({
                ...errors,
                [name] : `${intl.get('newInvoice.errorLowerThan100')}`
            })
        } else if (value > subTotal && type === 1) {
            setErrors({
                ...errors,
                [name] : `${intl.get('newInvoice.errorLowerThanSubtotal')}`
            })
        } else {
            if (errors?.[name]) {
                const { [name]: omit, ...rest } = errors;
                setErrors(rest);
            }
        }
    };

    const getUsedTaxIds = () => {
        if (!input?.invoiceItems) return new Set();
        return new Set(
            input.invoiceItems.flatMap(item =>
                (item.invoiceItemTaxes || []).map(tax => tax.id)
            )
        );
    };

    useEffect(() => {
        for (const [key, value] of Object.entries(sds)) {
            const type = sdsType[key + "Type"];
            if (key === "discount"){
                validate(key, -value, type)
            } else {
                validate(key, value, type)
            }
        }
    }, [sds, sdsType, subTotal])

    const handleSdsChange = function(e){
        let subTotalWithChange = 0;
        if (e.target.name === "discount"){
            subTotalWithChange = - Number(e.target.value)
        } else {
            subTotalWithChange = Number(e.target.value)
        }
        setSds({
            ...sds,
            [e.target.name]: Number(subTotalWithChange)
        })
    }

    const handleSdsTypeChange = function(typeId, name){
        const typeName = name + "Type";
        setSdsType({...sdsType, [typeName]: typeId})
    }

    useEffect(() => {
        let addedValues = 0;
        if (sdsType.discountType === 1){
            addedValues += sds.discount;
            const hasTaxes = input?.invoiceItems?.some(item => item.invoiceItemTaxes && item.invoiceItemTaxes.length > 0);
            if (hasTaxes) {
                setSdsType({...sdsType, "discountType": 2});
                toast.warning({header: `${intl.get('delete.modal.title')}`, body: `${intl.get('newInvoice.errorCantUse$')}`})
            }
        } else {
            addedValues += (subTotalWithoutTaxes * sds.discount / 100);
        }
        if (sdsType.otherChargesType === 1){
            addedValues += sds.otherCharges;
        } else {
            addedValues += (subTotalWithoutTaxes * sds.otherCharges / 100);
        }
        if (sdsType.shippingType === 1){
            addedValues += sds.shipping;
        } else {
            addedValues += (subTotalWithoutTaxes * sds.shipping / 100);
        }
        if (!taxesList.length){
            setFinalResult(subTotal + addedValues)
        } else {
            let taxSum = 0;
            taxesList.forEach(tax => {
                taxSum += tax.taxRate * subTotalWithoutTaxes / 100
            });
            setFinalResult(subTotal + taxSum + addedValues)
            addedValues += taxSum
        }
    }, [subTotal, taxesList, sds, sdsType, input?.invoiceItems])

    const calculateTaxSummary = () => {
        if (!input?.invoiceItems || input?.invoiceItems?.length === 0) return [];
        // Group items by tax rate
        const taxGroups = input.invoiceItems.reduce((groups, item) => {
            // Skip if no taxes
            if (!item.invoiceItemTaxes || item.invoiceItemTaxes.length === 0) return groups;

            // Calculate base amount for this item
            const itemAmount = (item.quantity || 0) * (item.rate || 0);

            // Process each tax for the item
            item.invoiceItemTaxes.forEach(tax => {
                const taxKey = `${tax.id}-${tax.rate}-${tax.name}`; // Unique key for each tax
                if (!groups[taxKey]) {
                    groups[taxKey] = {
                        id: tax.id,
                        rate: tax.rate,
                        name: tax.name,
                        totalTax: 0,
                        baseAmount: 0
                    };
                }
                groups[taxKey].baseAmount += itemAmount;
                groups[taxKey].totalTax += itemAmount * (tax.rate / 100);
            });
            return groups;
        }, {});

        return Object.values(taxGroups);
    };

    return (
        <div className='container' style={{zIndex: -1}}>
            <div className='card-body'>
                <Grid style={{ display: "flex", alignItems: "center"}}>
                    <Grid md={6} className='justify-content-start'>
                        {intl.get('newInvoice.subtotal')}
                    </Grid>
                    <Grid md={6} style={{justifyContent: "end", display: "flex"}}>
                        {subTotalWithoutTaxes}
                    </Grid>
                </Grid>
                <Grid container xs={12} md={12} lg={12} xl={12} style={{marginTop: "15px", display: "flex", alignItems: "center"}}>
                    <Grid item xs={4} md={4} lg={4} xl={4}>
                        {intl.get('newInvoice.discount')}
                    </Grid>
                    <Grid item xs={4} md={4} lg={4} xl={4}>
                        <TextField
                            fullWidth
                            type="number"
                            name="discount"
                            placeholder="0"
                            onChange={handleSdsChange}
                            size='small'
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end" style={{marginRight: "-15px"}}>
                                        <Button size="small" style={{color: "black"}} endIcon={<ExpandMoreIcon />} onClick={handleDiscountClick}>
                                            {selectTypes.find(type => type.id === sdsType[`discountType`])?.label}
                                        </Button>
                                        <Menu
                                            elevation={0}
                                            anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                            }}
                                            anchorEl={discountAnchorEl}
                                            open={discountOpen}
                                            onClose={handleDiscountClose}
                                        >
                                            {selectTypes?.map((type) => <MenuItem key={type.id} onClick={() => handleSdsTypeChange(type.id, "discount")} disabled={false}>{`${type.label}`}</MenuItem>)}
                                        </Menu>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item xs={4} md={4} lg={4} xl={4} style={{justifyContent: "end", display: "flex"}}>
                        {sdsType.discountType === 1 ? sds.discount : Number(sds.discount * subTotalWithoutTaxes / 100).toFixed(2)}
                    </Grid>
                    {errors?.discount &&
                    <Grid container xs={12} md={12} lg={12} xl={12} style={{justifyContent: "center", marginTop: "5px", marginBottom: "-5px",}}>
                        <Typography className='text-danger'>{errors.discount}</Typography>
                    </Grid>}
                </Grid>
                <Grid container xs={12} md={12} lg={12} xl={12} style={{marginTop: "15px", display: "flex", alignItems: "center"}}>
                    <Grid item xs={4} md={4} lg={4} xl={4}>
                        {intl.get('newInvoice.shipping')}
                    </Grid>
                    <Grid item xs={4} md={4} lg={4} xl={4}>
                        <TextField
                            fullWidth
                            type="number"
                            name="shipping"
                            placeholder="0"
                            onChange={handleSdsChange}
                            size='small'
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end" style={{marginRight: "-15px"}}>
                                        <Button size="small" style={{color: "black"}} endIcon={<ExpandMoreIcon />} onClick={handleShippingClick}>
                                            {selectTypes.find(type => type.id === sdsType[`shippingType`])?.label}
                                        </Button>
                                        <Menu
                                            elevation={0}
                                            anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                            }}
                                            anchorEl={shippingAnchorEl}
                                            open={shippingOpen}
                                            onClose={handleShippingClose}
                                        >
                                            {selectTypes?.map((type) => <MenuItem key={type.id} onClick={() => handleSdsTypeChange(type.id, "shipping")} disabled={false}>{`${type.label}`}</MenuItem>)}
                                        </Menu>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item xs={4} md={4} lg={4} xl={4} style={{justifyContent: "end", display: "flex"}}>
                        {sdsType.shippingType === 1 ? sds.shipping : Number(sds.shipping * subTotalWithoutTaxes / 100).toFixed(2)}
                    </Grid>
                    {errors?.shipping &&
                    <Grid container xs={12} md={12} lg={12} xl={12} style={{justifyContent: "center", marginTop: "5px", marginBottom: "-5px",}}>
                        <Typography className='text-danger'>{errors.shipping}</Typography>
                    </Grid>}
                </Grid>

                <Grid container xs={12} md={12} lg={12} xl={12} style={{marginTop: "15px", display: "flex", alignItems: "center"}}>
                    <Grid item xs={4} md={4} lg={4} xl={4}>
                        {intl.get('newInvoice.other.charges')}
                    </Grid>
                    <Grid item xs={4} md={4} lg={4} xl={4}>
                        <TextField
                            fullWidth
                            type="number"
                            name="otherCharges"
                            placeholder="0"
                            onChange={handleSdsChange}
                            size='small'
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end" style={{marginRight: "-15px"}}>
                                        <Button size="small" style={{color: "black"}} endIcon={<ExpandMoreIcon />} onClick={handleOtherChargesClick}>
                                            {selectTypes.find(type => type.id === sdsType[`otherChargesType`])?.label}
                                        </Button>
                                        <Menu
                                            elevation={0}
                                            anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                            }}
                                            anchorEl={otherChargesAnchorEl}
                                            open={otherChargesOpen}
                                            onClose={handleOtherChargesClose}
                                        >
                                            {selectTypes?.map((type) => <MenuItem key={type.id} onClick={() => handleSdsTypeChange(type.id, "otherCharges")} disabled={false}>{`${type.label}`}</MenuItem>)}
                                        </Menu>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item xs={4} md={4} lg={4} xl={4} style={{justifyContent: "end", display: "flex"}}>
                        {sdsType.otherChargesType === 1 ? sds.otherCharges : Number(sds.otherCharges * subTotalWithoutTaxes / 100).toFixed(2)}
                    </Grid>
                    {errors?.otherCharges &&
                    <Grid container xs={12} md={12} lg={12} xl={12} style={{justifyContent: "center", marginTop: "5px", marginBottom: "-5px",}}>
                        <Typography className='text-danger'>{errors.otherCharges}</Typography>
                    </Grid>}
                </Grid>
                <hr/>
                <div className="mt-3">
                    {calculateTaxSummary()?.map((taxGroup: any) => (
                        <Grid key={`${taxGroup.id}-${taxGroup.rate}`} style={{ display: taxGroup.name ? "flex" : "none", alignItems: "center", marginBottom: "5px"}}>
                            <Grid md={6} className='justify-content-start'>
                                {`${taxGroup.name} [${taxGroup.rate}%]`}
                            </Grid>
                            <Grid md={3} style={{display: "flex", justifyContent: "middle"}}>
                                {Number(taxGroup.baseAmount).toFixed(2)}
                            </Grid>
                            <Grid md={3} style={{display: "flex", justifyContent: "flex-end"}}>
                                {Number(taxGroup.totalTax).toFixed(2)}
                            </Grid>
                        </Grid>
                    ))}
                </div>
                {taxesList?.map(tax => {
                    return <Grid container md={12} key={tax.idTaxRate} style={{ display: "flex", alignItems: "center"}}>
                                <Grid container md={6} className='justify-content-start'>
                                    <Grid md={7}>
                                        <a>{tax.description} [{tax.taxRate}%]</a>
                                    </Grid>
                                    <Grid md={5} style={{justifyContent: "flex-start"}}>
                                        <div>
                                            <svg onClick={() => handleDeleteTax(tax.idTaxRate)} style={{cursor: "pointer"}} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M18.36 19.7807L12 13.4107L5.63997 19.7807L4.21997 18.3607L10.59 12.0007L4.21997 5.6407L5.63997 4.2207L12 10.5907L18.36 4.2307L19.77 5.6407L13.41 12.0007L19.77 18.3607L18.36 19.7807Z" fill="#FF0000"/>
                                            </svg>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid md={6} style={{justifyContent: "end", display: "flex"}}>
                                    {Number(tax.taxRate * subTotalWithoutTaxes / 100).toFixed(2)}
                                </Grid>
                            </Grid>
                })}
                <div className='d-flex justify-content-end my-1'>
                    <Typography style={{color: "black", marginTop: "10px", cursor: "pointer", textDecoration: "underline"}} onClick={handleTaxesClick}>
                        +{intl.get('newInvoice.add.taxes')}
                    </Typography>
                    <Menu
                        elevation={0}
                        anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                        }}
                        transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                        }}
                        anchorEl={taxesAnchorEl}
                        open={taxesOpen}
                        onClose={handleTaxesClose}
                    >
                        {filteredTaxes?.filter(tax => !getUsedTaxIds().has(tax.id))
                                .map((tax) => <MenuItem key={tax.id} onClick={() => handleNewTax(tax)} disabled={false}>{`${tax.name} [${tax.rate}%]`}</MenuItem>)
                            }
                    </Menu>
                </div>
                <Grid style={{marginTop: "15px", display: "flex", background: "#E7F4FF", padding: "5px", borderRadius : "5px"}}>
                    <Grid md={6} className='justify-content-start'>
                        <b>{intl.get('newInvoice.total')}</b>
                    </Grid>
                    <Grid md={6} style={{justifyContent: "end", display: "flex"}}>
                        <b>{Number(finalResult).toFixed(2)}</b>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default NewInvoiceSummary;

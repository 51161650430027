import React, { FC } from 'react';
import Select from 'react-select';
import intl from 'components/i18n/ReactIntlWrapper';

type PersonaTypeSelectProps = {
  handlePersonaTypeChange: any,
  selectedPersonaType: number,
  PersonaTypes: any,
  personaTypeExist: number;
  personaTypesIds?: number[];
};

const PersonaTypeSelect: FC<PersonaTypeSelectProps> = (props) => {
  
  const { PersonaTypes, selectedPersonaType, handlePersonaTypeChange, personaTypeExist, personaTypesIds } = props

  const filteredOptions = personaTypeExist !== 0 ?
    PersonaTypes.filter(personaType => personaType.id !== personaTypeExist) :
    personaTypesIds.length !== 0 ?
    PersonaTypes.filter(personaType => personaTypesIds?.includes(personaType.id)) :
    PersonaTypes;

  return (
    <Select
      id="personaTypes"
      name="personaTypes"
      getOptionLabel={e =>  intl.get(e.label)}
      getOptionValue={e => e.id}
      placeholder={intl.get('personaForm.placeholder.personaType')}
      onChange={handlePersonaTypeChange}
      isLoading={PersonaTypes == null}
      options={filteredOptions}
      value={
            (PersonaTypes != null && selectedPersonaType != null) 
            && 
            (PersonaTypes.find((personaTypes) => personaTypes.id === selectedPersonaType)) 
            }
      isDisabled={selectedPersonaType != null && personaTypeExist != 0}
    />
  );
}

export default PersonaTypeSelect;
import React, { useState } from 'react';
import { Row, Col, Label } from 'reactstrap';
import RequestSelect from './RequestSelect';
import DateRangePickerDialog from 'components/DateRangePickerDialog';
import intl from 'components/i18n/ReactIntlWrapper';
import '../../scss/Time Off/TimeOffRequest.scss';
import StyledDropzone from "./TimeOffFileUploader";
import WorkflowActionsBar from 'components/Workflow/WorkflowActionsBar';
import EmployeeSelect from 'components/Project/EmployeeSelect';
import { PermissionsGate } from '../PermissionsGate';
import { SCOPES } from 'common/permissions';
import { ENTITY_TYPE } from 'common/constants';
import { IEditingModeSetings } from 'components/Workflow/WorkflowActionsBar';
import { isEditableByOwner } from '../hasPermission';
import Chip from '@mui/material/Chip';
import { HiOutlineDownload} from 'react-icons/hi';
import ErrorSummary from 'components/common/ErrorSummary';
import { MdOutlineCancel } from 'react-icons/md';
import { Typography, useTheme } from '@mui/material';


const TimeOffRequestForm = ({ requestType, input, setInput, errors, loading, handleRequestChange, handleDelete,handleDownload, handleDaySelect, handleSave, employees, handleEmployeeChange, HRView, setComments=null }) => {
    const [wfEditSettings, setWfEditSettings] = useState<IEditingModeSetings>();
    const theme = useTheme();
    const handleEditingMode = (params: IEditingModeSetings) => {
        setWfEditSettings(params);
    }     
    const getStateColor = (): any => {
        return input.workflowStateName === "Pending" || input.workflowStateName === "Pendiente" ? "warning" : input.workflowStateName === "Approved" || input.workflowStateName === "Aprobado" ? "success" : input.workflowStateName === "Observed" || input.workflowStateName === "Observado" ? "default" : input.workflowStateName === "Open" || input.workflowStateName === "Abierto" ? "primary" : input.workflowStateName === "Reopen" || input.workflowStateName === "Reabrir" ? "secondary" : "error";
    }
    return (
        <div className='container'>
            <Row className='justify-content-around'>
                <Col>
                    <ErrorSummary errors={errors} showEverything={true} ></ErrorSummary>
                </Col>
            </Row>
            <Row className='justify-content-around'>

                
                <Col md={input.id > 0 ? 6 : 12}>
                    <div>
                        <Label for="TimeOffRequestType" className={`${errors.idTimeOffRequestType ? 'text-danger' : ''}`}>
                            {intl.get('timeOff.type')}
                        </Label>
                        <RequestSelect requestType={requestType} selectedRequest={input.idTimeOffRequestType} handleRequestChange={handleRequestChange} disabled={!(wfEditSettings?.edit || isEditableByOwner(ENTITY_TYPE.EMPLOYEE, input.idEmployee, wfEditSettings))} />
                    </div>
                </Col>
                {input.id > 0 &&
                    <Col md={6}>
                        <div className='justify-content-end'>
                            <Label for="status" className='d-block'>{intl.get('timeOffApprove.status.label')}</Label>
                            <Chip 
                                variant='outlined'
                                className={`border px-4 py-2`}
                                label={<Typography className='statusLabel'>{intl.get(input.workFlowLabelKey)} </Typography>}
                                sx={{backgroundColor: input.workflowStateColor ? input.workflowStateColor : '#fff', color: theme.palette.getContrastText( input.workflowStateColor ? input.workflowStateColor : '#fff')}}
                                />
                        </div>
                    </Col>
                }
            </Row>
            <Row>
                <Col md={12}>
                    <div className='mt-20'>
                        <Label for="TimeOffRequestType" className={`${errors.range ? 'text-danger' : ''}`}>
                            {intl.get('timeOff.time.range')}
                        </Label>
                        <DateRangePickerDialog
                            disabled={!(wfEditSettings?.edit || isEditableByOwner(ENTITY_TYPE.EMPLOYEE, input.idEmployee, wfEditSettings))}
                            inputName="dateRange"
                            className='timesheet-datepicker'
                            selected={input.range}
                            setRange={handleDaySelect}
                            required
                        />
                    </div>
                </Col>
            </Row>
            {
                HRView ?
                    <PermissionsGate viewScopes={[SCOPES['timeoff.rrhh']]} editScopes={[SCOPES['timeoff.rrhh']]} entityType={ENTITY_TYPE.EMPLOYEE} viewRoles={[]} editRoles={[]} >
                        <Row>
                            <Col md={12}>
                                <div className='mt-20'>
                                    <Label check for="idEmployee" className="form-label">
                                        {intl.get('timeOffRequestForm.employeeName')}
                                    </Label>
                                    <EmployeeSelect
                                        handleEmployeeChange={handleEmployeeChange}
                                        employees={employees}
                                        selectedEmployee={input.idEmployee}
                                        disabled={!(wfEditSettings?.edit || isEditableByOwner(ENTITY_TYPE.EMPLOYEE, input.idEmployee, wfEditSettings))}
                                        newUser={false}
                                        id={null}
                                        securityRoles={null}
                                        edit={false}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </PermissionsGate>
                    :
                    null
            }
            <div className='mt-20'>
                <StyledDropzone
                    files={input.attachments}
                    setInput={setInput}
                    disabled={!(wfEditSettings?.edit || isEditableByOwner(ENTITY_TYPE.EMPLOYEE, input.idEmployee, wfEditSettings))}
                />
            </div>
            <div className='mt-20'>
                <Label for="attachedFiles" className='fw-bold d-block'>{intl.get('timeOffApprove.attachedFiles.label')}</Label>
                {
                    input.attachments?.map((file, i) => (
                        <>
                        {                            
                        <div key={i} className="d-flex gap-2 align-items-center">
                            <span className="mr-2 text-primary">{file.path ? file.path : file}</span>
                            <a 
                                type="button" 
                                href={file.path} 
                                download={file.path} 
                                className="d-inline-block"
                                >
                                <HiOutlineDownload size={18}  
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleDownload(file);
                                }}   className="mt-2 mb-1" />
                            </a>
                            <MdOutlineCancel 
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleDelete(i, file);
                                }}  
                                size={18} 
                                color="red" 
                                style={{ cursor: 'pointer' }}
                                className="mt-2 mb-1 pe-auto"
                            />
                        </div>                
                        } 
                        </>
                    ))
                }
            </div>
            <div className='mt-20'>
                <Row className='justify-content-between'>
                    <Col md={6}>
                        <Label for="MandatoryFields">
                            {intl.get('timeOff.mandatory.field')}
                        </Label>
                    </Col>
                    <Col md={6}>

                        <WorkflowActionsBar loading={loading} viewMode='buttons' handleEditingMode={handleEditingMode} onActionClick={handleSave} id={input.id} stateId={input.idWorkflowState} disabled={JSON.stringify(errors) !== JSON.stringify({}) || input.idTimeOffRequestType === null  ? true : false} />

                    </Col>
                </Row>
            </div>

        </div>
    )
}

export default TimeOffRequestForm;

import React, { useState, FC, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import '../../scss/Workflow/WorkflowActionsBar.scss';
import { useApi } from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import { useToastMessageQueue } from '../ToastMessages/ToastMessageProvider';
import intl from 'components/i18n/ReactIntlWrapper';
import { Button, Spinner } from 'reactstrap';
import { IconPickerItem } from 'react-icons-picker';
import { useWorkflow } from './WorkflowContext';
import WorkflowCommentsModal from './WorkflowCommentsModal';
import {handleAPIError} from '../../common/errorHandler';
import { UpdateStateCommand } from '../../axiosApi/models/update-state-command';
import { Box } from '@mui/material';
export type IEditingModeSetings = {
  edit: boolean,
  view: boolean,
  delete: boolean, 
  editByOwner: boolean,
  viewByOwner: boolean,
  deleteByOwner: boolean
};

export type handleEditingMode = (IEditingModeSetings) => void;
export type WorkflowActionClickParams = (stateId: number, comments?: string) => void;

type WorkflowActionsBarProps = {
  viewMode: string,
  onActionClick: WorkflowActionClickParams,
  loading: boolean,
  id: number,
  stateId: number,
  handleEditingMode?: handleEditingMode,
  errors?: Object,
  idTimeOffRequestType?: number, 
  disabled?: boolean, 
};

const WorkflowActionsBar: FC<WorkflowActionsBarProps> = ({ viewMode, onActionClick, loading:parentLoading, id, stateId, handleEditingMode, disabled}) => {

  const { workflow, loading,  errors:error } = useWorkflow();
  const api: Api = useApi();
  const toast = useToastMessageQueue();
  const navigate = useNavigate();
  const [entityId, setEntityId] = useState<number | null>(id);

  const [errors, setErrors] = useState<number | null>(null);

  const [isWorkflowCommentsOpen, setIsWorkflowCommentsOpen] = useState(false);
  const [pendingActionId, setPendingActionId] = useState<number | null>(null);
  
  const closeWorkflowComments = () => {setIsWorkflowCommentsOpen(false)};
  const handleClick = (id: number, showComments:boolean) => {
    setIsWorkflowCommentsOpen(showComments);
    if(!showComments)
      UpdateState(id);
    else
      setPendingActionId(id);
  };
    
  const onWorkflowCommentsModalSubmitted = (comments: string) => {
    if(!pendingActionId){
      toast.error(intl.get('error'));
      return;
    }

    UpdateState(pendingActionId, comments);      
  }

  useEffect(() => {
    setEntityId(id);
  }, [id]);

  const UpdateState = (id:number, comments:string =null)=>
  {
    //If there is no entityId, it means it is a new entity, so we can call the action directly that will handle the 
    //creation of the entity thru the corresponding API in the parent component
    if(entityId == null)
    {
      onActionClick(id);
      return;
    }
    //otherwise just call the API to update the state

    var command: UpdateStateCommand = {
      comments : comments, 
      workflowStateId: id , 
      entityId: entityId,
      moduleId: workflow.idModule,
    };
    
    api.workflowApi.apiVversionWorkflowUpdateStatePost("1", command).then((response)=>{
      onActionClick(id);
    }).catch((error)=>{
      handleAPIError(error, toast, errors);
      setErrors({ ...error });
    });
  }
  //console.log('stateID', stateId);
  const handleBackClick = (e) => {
    navigate(-1);
  };

  //when the workflow has loaded, call the parent component to enable or disable the edit, view and delete buttons
  useEffect(() => {
    if(handleEditingMode)
    {
        if(workflow)
        {
            if(stateId == null)
            {
              if(id == null)
                handleEditingMode({edit: true, view : true, delete : true, editByOwner : true, viewByOwner : true, deleteByOwner : true});
              else
                handleEditingMode({edit: false, view : false, delete : false, editByOwner : false, viewByOwner : false, deleteByOwner : false});

              return;
            }
            var state =  workflow?.states.find(x => x.id == stateId);
            handleEditingMode({edit: state?.editable, view : state?.viewable, delete : state?.deletable, editByOwner : state?.editableByOwner, viewByOwner : state?.viewableByOwner, deleteByOwner : state?.deletableByOwner});
        }
    } 
  }, [workflow, stateId, id]);

  return (
    <Box className="workflow-actions-bar" justifyContent="flex-end">
      {loading && <div className="flex-justify-center"><Spinner color="primary" /></div>}
      {!loading && <>
      <WorkflowCommentsModal
                isOpen={isWorkflowCommentsOpen}
                onCloseModal={closeWorkflowComments}
                onSubmit={onWorkflowCommentsModalSubmitted}
              />
        {viewMode === 'icons' ? (
        workflow?.transitions?.filter(x => (!!stateId?x.workflowStateFrom?.id === stateId: x.workflowStateFrom == null)).sort((a, b) => a.displayOrder - b.displayOrder).map((action, index) => (
          <React.Fragment key={index}>
            <button
              style={{ backgroundColor: action.displayColor ? action.displayColor : '#005BAF' }}
              onClick={() => handleClick(action.idWorkflowStateTo, action.allowComments)} // boolean to be replaced with showComments
              className="icon-button"
              title={action.labelKey ? intl.get(action.labelKey) : action.actionName}
            >
              <IconPickerItem value={action.icon} size={24} color="#ffffff" />
            </button>
          </React.Fragment>
        ))
       ) : (
        <>
          <Button outline={true} type="button" className="btn btn-outline-dark" onClick={handleBackClick}>
            {intl.get('workflow.action.cancel')}
          </Button>
          {workflow?.transitions.filter(x =>(!!stateId?x.workflowStateFrom?.id === stateId: x.workflowStateFrom == null)).sort((a, b) => a.displayOrder - b.displayOrder).map((action, index) => (
            
            <React.Fragment key={index}>
            <Button
              key={index}
              outline={true}
              type="button"
              className="btn-custom"
              style={{ '--button-color': action.displayColor ? action.displayColor : '#005BAF' } as React.CSSProperties}
              onClick={() => handleClick(action.idWorkflowStateTo, action.allowComments)} // boolean to be replaced with showComments
              disabled={disabled}
            >  
              {action.labelKey ? intl.get(action.labelKey) : action.actionName}
            </Button>
            
            </React.Fragment>
          ))}
        </>
      )}</>}
    </Box>
  );
};

export default WorkflowActionsBar;

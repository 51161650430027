import React from 'react';
import UserRow from './UserRow';
import { BiSortZA, BiSortAZ } from 'react-icons/bi';
import intl from 'components/i18n/ReactIntlWrapper';

const UserList = ({ searchResult, input, setInput }) => {

    const handleSortClick = (sort) => {
        setInput({ ...input, orderValue: (input.sortValue == sort ? (input.orderValue == 'desc' ? 'asc' : 'desc') : 'asc'), sortValue: sort, currentPage: 1 });
    }

    return (
        <div className="table-responsive my-2">
            <table className='table table-hover'>
                <thead>
                    <tr>
                        <th role='button' onClick={() => { handleSortClick('Email') }} scope="col">{intl.get('userList.table.thead.email')} {input.sortValue == 'Email' ? (input.orderValue == 'desc' ? <BiSortZA></BiSortZA> : <BiSortAZ></BiSortAZ>) : ''}</th>
                        <th scope="col" className='text-center'>{intl.get('roleList.table.thead.actions')}</th>
                    </tr>
                </thead>
                {
                    searchResult?.users?.length < 1 && input.queryTerm !== null ?
                    <tbody className='aling-center mb-0'>
                        <tr>
                            <td colSpan={6} className='mb-0' style={{cursor: "default"}}>{intl.get('UserList.notFound')}</td>
                        </tr>
                    </tbody> :
                    <tbody>
                        {
                            searchResult.users && searchResult.users.map((c,i) => (
                                <UserRow
                                    key={c.id}
                                    id={c.id}
                                    email={c.email}
                                    className={i%2>0?'':'odd'}
                                />
                            ))
                        }
                    </tbody>
                }
            </table>
        </div>
    )
}

export default UserList;

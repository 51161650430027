import React from 'react';
import EmployeeSelect from './EmployeeSelect';
import RoleSelect from './RoleSelect';
import DatePickerDialog from 'components/DatePickerDialog';
import '../../scss/Project/EmployeeProjectForm.scss';
import { Button } from "reactstrap";
import { HiOutlineSave } from 'react-icons/hi';
import RequiredField from '../RequiredField';
import { uniformizeDate } from 'common/utils';
import intl from 'components/i18n/ReactIntlWrapper';
import AllocationTypeSelect from './AllocationTypeSelect';
import { Label } from 'reactstrap';
import ErrorSummary from '../../components/common/ErrorSummary';
function EmployeeProjectForm({ handleEmployeeChange, employees, selectedEmployee, handleInputChange, handleRoleChange, selectedRole, roles, handleStartDate , handleDueDate, handleAddEmployeeProject, isEditMode, handleSaveEmployeeProject, handleCancelEdit, input, errors, disabled, handleAllocationTypeChange, selectedAllocationType, allocationTypes, setHiddenRole, setInput}) {


    const handleTimeSheetCheck = (e) => {
        setInput({ ...input, isTimesheetViewer: e.target.checked });
    };

    const handleEmptyRate = (e) => {
        if(!e.target.value){
            e.target.value = 0
        }
        handleInputChange(e)
    }


    return (
        <form>
            <div className="row m-0 justify-content-between">
                <div className="col-md-12">
                    <ErrorSummary errors={errors} showEverything={false} mode="warning"></ErrorSummary>
                </div>
            </div>
            <div className="row m-0 justify-content-between">
                <div className="col-md-4">
                <Label check for="idEmployee" className={`form-label mt-2 ${errors.employee ? 'text-danger' : ''}`}>
                    {intl.get('employeeProjectForm.employee')} {errors.employee && (<span className='text-danger'>{errors.employee}</span>)}
                </Label>
                    <EmployeeSelect
                        handleEmployeeChange={handleEmployeeChange}
                        employees={employees}
                        selectedEmployee={selectedEmployee}
                        disabled={disabled}
                        newUser={false}
                        id={null}
                        securityRoles={null}
                        edit={false}
                        setHiddenRole={setHiddenRole}
                    />
                </div>
                <div className="col-md-4">
                    <label htmlFor="hourlyRate" className={`form-label mt-2 ${errors.hourlyRate ? 'text-danger' : ''}`}>
                        {intl.get('employeeProjectForm.hourlyRate')} {errors.hourlyRate && (<span className='text-danger'>{errors.hourlyRate}</span>)}
                    </label>
                    <input
                        type="number"
                        step="0.01"
                        min="0"
                        className="form-control"
                        id="hourlyRate"
                        name="hourlyRate"
                        placeholder={intl.get('employeeProjectForm.placeholder.hourlyRate')}
                        onChange={handleEmptyRate}
                        value={disabled ? "0" : input.hourlyRate}
                        disabled={disabled}
                    ></input>
                </div>
                <div className="col-md-4">
                    <label htmlFor="monthlyRate" className={`form-label mt-2 ${errors.monthlyRate ? 'text-danger' : ''}`}>
                        {intl.get('employeeProjectForm.monthlyRate')} {errors.monthlyRate && (<span className='text-danger'>{errors.monthlyRate}</span>)}
                    </label>
                    <input
                        type="number"
                        step="0.01"
                        min="0"
                        className="form-control"
                        id="monthlyRate"
                        name="monthlyRate"
                        placeholder={intl.get('employeeProjectForm.placeholder.monthlyRate')}
                        onChange={handleEmptyRate}
                        value={disabled ? "0" : input.monthlyRate}
                        disabled={disabled}
                    ></input>
                </div>
            </div>
            <div className="row m-0 justify-content-between">
                <div className="col-md-4">
                    <label htmlFor="startDate" className={`form-label mt-2 ${errors.dueDate ? 'text-danger' : ''}`}>
                        {intl.get('employeeProjectForm.startDate')} {errors.startDate && (<span className='text-danger'>{errors.startDate}</span>)}
                    </label>
                    <DatePickerDialog
                        // disabled={false}
                        disabled={disabled}
                        inputName="startDate"
                        className={'timesheet-datepicker'}//+ (//errors.date ? ' timesheet-danger' : '')}
                        selected={disabled ? '' : (  input.startDate ? uniformizeDate(input.startDate) : '')}
                        setSelected={handleStartDate}
                        required
                    />
                </div>
                <div className="col-md-4">
                    <label htmlFor="dueDate" className={`form-label mt-2 ${errors.startDate ? 'text-danger' : ''}`}>
                        {intl.get('employeeProjectForm.dueDate')} {errors.dueDate && (<span className='text-danger'>{errors.dueDate}</span>)}
                    </label>
                    <DatePickerDialog
                        // disabled={false}
                        disabled={disabled}
                        inputName="dueDate"
                        className={'timesheet-datepicker'}//+ (//errors.date ? ' timesheet-danger' : '')}
                        selected={disabled ? '' : (  input.dueDate ? uniformizeDate(input.dueDate) : '')}
                        setSelected={handleDueDate}
                        required
                    />
                </div>
                <div className="col-md-4">
                        <Label check for="idRole" className={`form-label mt-2 ${errors.idRole ? 'text-danger' : ''}`}>
                            {intl.get('employeeProjectForm.role')} {errors.idRole && (<span className='text-danger'>{errors.idRole}</span>)}
                        </Label>
                        <RoleSelect
                            handleRoleChange={handleRoleChange}
                            selectedRole={selectedRole}
                            roles={roles}
                            disabled={disabled}
                        />
                </div>
                
            </div>
            <div className="row m-0 justify-content" id="employee">
                <div className="col-md-4 col-sm-5 cols-xs-6">
                    <label htmlFor="allocation" className={`form-label mt-2 ${errors.allocation ? 'text-danger' : ''}`}>
                        {intl.get('employeeProjectForm.allocation')} {errors.allocation && (<span className='text-danger'>{errors.allocation}</span>)}
                    </label>
                    <input
                        type="number"
                        step="1"
                        min={0}
                        className="form-control"
                        id="allocation"
                        name="allocation"
                        placeholder={intl.get('employeeProjectForm.placeholder.allocation')}
                        onChange={handleInputChange}
                        value={disabled ? 0 : input.allocation}
                        disabled={disabled}
                    ></input>
                </div>
                <div className="col-md-8 col-sm-10 cols-xs-9">
                    <AllocationTypeSelect
                        handleAllocationTypeChange={handleAllocationTypeChange}
                        selectedAllocationType={selectedAllocationType}
                        allocationTypes={allocationTypes}
                        errors={errors}
                        disabled={disabled}
                    />
                </div>
                <div className="col-md-2 col-sm-4 cols-xs-3 justify-content-between" style={{marginTop: "12px"}}>
                    <label htmlFor="isTimesheetViewer" className="form-check-label" style={{marginRight: "10px"}}>
                        Timesheet Viewer
                    </label>
                    <input 
                        id="isTimesheetViewer"
                        name="isTimesheetViewer"
                        className="form-check-input"
                        type="checkbox"
                        checked={input.isTimesheetViewer}
                        onChange={handleTimeSheetCheck}
                        disabled={disabled}
                    />
                </div>
                <div className="col-md-2 m-0 justify-content-between form-button">
                    {
                        !isEditMode() &&
                        <div className="d-flex justify-content-start align-items-end h-100" style={{marginTop: "4px"}}>
                            <Button outline type="submit" className={disabled ? "btn btn-primary my-2 my-md-0 flex-fill flex-md-grow-0 disabled" : "btn btn-primary my-2 my-md-0 flex-fill flex-md-grow-0"} onClick={handleAddEmployeeProject} disabled={JSON.stringify(errors) === JSON.stringify({}) ? false : true} >{intl.get('projectDetail.add.button')}</Button>
                        </div>
                    }
                    {
                        isEditMode() &&
                        <div className="d-flex justify-content-center justify-content-md-start align-items-end h-100" style={{marginTop: "4px"}}>
                            <Button outline className="btn btn-outline-dark flex-fill flex-md-grow-0 me-2 me-md-2 my-2 my-md-0 col-sm-6 col-md-12 me-sm-1" onClick={handleCancelEdit}>{intl.get('projectDetail.cancel.button')}</Button>
                            <Button outline type="submit" className="btn btn-primary flex-fill flex-md-grow-0 my-2 my-md-0 col-sm-6 col-md-12 ms-sm-1 ms-md-0" onClick={handleSaveEmployeeProject} disabled={JSON.stringify(errors) === JSON.stringify({}) ? false : true} ><HiOutlineSave className='mb-1'/> {intl.get('projectDetail.save.button')}</Button>
                        </div>
                    }
                </div>
            </div>
            <div className='row mt-2'>
                <RequiredField/>
            </div>
        </form>
    );
}

export default EmployeeProjectForm;
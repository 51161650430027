import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';
import { useApi } from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import {PermissionsGate } from '../../components/PermissionsGate';
import { SCOPES } from "../../common/permissions"; 
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import intl from 'components/i18n/ReactIntlWrapper';
import { Form, Row, Col, Label, Input } from 'reactstrap';
import { Employee, Role, Client, Provider, CorporateEntity, UpdateUserCommand, UserDetail } from 'axiosApi/models';
import { UpdateUserSecurityRolesCommand } from 'axiosApi/models/update-user-security-roles-command';
import { HiOutlinePlusCircle, HiArrowLeft } from 'react-icons/hi';
import UserAsEmployeeEdit from './UserAsEmployeeEdit';
import UserAsClientEdit from './UserAsClientEdit';
import UserAsProviderEdit from './UserAsProviderEdit';
import UserAsCorporateEntityEdit from './UserAsCorporateEntityEdit';
import "../../scss/User/UserDetail.scss";
import FormUserAsEmployee from './FormUserAsEmployee';
import Spinner from '../Spinner';
import { confirmAlert } from 'react-confirm-alert';
import FormUserAsClient from '../User/FormUserAsClient';
import FormUserAsProvider from '../User/FormUserAsProvider';
import FormUserAsCorporateEntity from '../User/FormUserAsCorporateEntity'
import { handleAPIError } from 'common/errorHandler';
import ErrorSummary from 'components/common/ErrorSummary';
import { ENTITY_TYPE } from 'common/constants';

const UserDetail = () => {

    const DefaultUserInput = {
        active: null,
        email: '',
        idStatus: null,
        userStatus: '',
        userDetails: []
    };

    const api: Api = useApi();

    const toast = useToastMessageQueue();

    const [input, setInput] = useState(DefaultUserInput);

    const [employees, setEmployees] = useState<Employee[]>([]);

    const [roles, setRoles] = useState<Role[]>([]);

    const [clients, setClients] = useState(null);

    const [providers, setProviders] = useState<Provider[]>([]);

    const [corporateEntities, setCorporateEntities] = useState<CorporateEntity[]>([]);

    const [loading, setLoading] = useState<boolean>(false);

    const [disabled, setDisabled] = useState<boolean>(false);

    const [isFormUserAsEmployeeOpen, setIsFormUserAsEmployeeOpen] = useState(false);

    const [editedUserAsEmployee, setEditedUserAsEmployee] = useState({
        id: 0,
        entityType: ENTITY_TYPE.EMPLOYEE,
        entityId: 0,
        entityName: '',
        securityRoles: [],
        idFe: null,
        isDefault: false
    });

    const [isFormUserAsClientOpen, setIsFormUserAsClientOpen] = useState(false);
    
    const [editedUserAsClient, setEditedUserAsClient] = useState({
        id: 0,
        entityType: ENTITY_TYPE.CLIENT,
        entityName: '',
        entityId: 0,
        securityRoles: [],
        idFe: null,
        isDefault: false
    });
    
    const [isFormUserAsProviderOpen, setIsFormUserAsProviderOpen] = useState(false);
    
    const [editedUserAsProvider, setEditedUserAsProvider] = useState({
        id: 0,
        entityType: ENTITY_TYPE.PROVIDER,
        entityName: '',
        entityId: 0,
        securityRoles: [],
        idFe: null,
        isDefault: false
    });
    
    const [isFormUserAsCorporateEntityOpen, setIsFormUserAsCorporateEntityOpen] = useState(false);
    const [editedUserAsCorporateEntity, setEditedUserAsCorporateEntity] = useState({
        id: 0,
        entityType: 4,
        entityId: 0,
        entityName: '',
        securityRoles: [],
        idFe: null,
        isDefault: false
    });

    const [edit, setEdit] = useState(false);
    const [errors, setErrors] = useState(null);

    const [previousDefaultId, setPreviousDefaultId] = useState(null);

    const navigate = useNavigate();

    const generateUniqueId = () => crypto.randomUUID();

    const { id } = useParams();
    const idNumber = parseInt(id);

    const findAndUpdateDefaultProfile = () => {
        const defaultProfile = input.userDetails.find(profile => profile.isDefault === true);
        if (defaultProfile) {
            setPreviousDefaultId(defaultProfile.idFe);
        }
    };

    const handleDefaultChange = (idFe, isDefault = true) => {
        if(isDefault){
            findAndUpdateDefaultProfile();
        };
        setInput((prevInput) => ({
            ...prevInput,
            userDetails: prevInput.userDetails.map((profile) => ({
                ...profile,
                isDefault: isDefault ? profile.idFe === idFe : profile.isDefault && profile.idFe !== idFe
            })),
        }));
    };

    const fetchEmployees = async () => {
        setLoading(true);
        const response = await api.employeeApi.apiVversionEmployeeAllGet("1", 1, 100, {}).then((response) => {
            if (response.data.data) {
                setEmployees(response.data?.data?.queryResult);
            };
            console.log("Success");
            setLoading(false);
        }).catch((error) => {
            handleAPIError(error, toast, errors);    
                setErrors({ ...errors }); 
                setLoading(false);
        });
    };

    const fetchRoles = async () => {
        setLoading(true);
        const response = await api.employeeApi.apiVversionSecurityRoleAllGet("1", 1, 100, {}).then((response) => {
            if (response.data.data) {
                setRoles(response.data?.data?.queryResult);
            };
            console.log("Success");
            setLoading(false);
        }).catch((error) => {
            if (error.response) {
                console.log("Data :", error.response.data);
                console.log("Status :" + error.response.status);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log("Error", error.message);
            }
            toast.error({ header: intl.get('employeeProject.toast.error.fetchRoles'), body: error.message });
            setLoading(false);
        });
    };

    const fetchProviders = async () => {
        setLoading(true);
        const response = await api.employeeApi.apiVversionProviderAllGet("1", 1, 100, {}).then((response) => {
            if (response.data.data) {
                setProviders(response.data?.data?.queryResult);
            };
            setLoading(false);
        }).catch((error) => {
            handleAPIError(error, toast, errors);    
            setErrors({ ...errors }); 
            setLoading(false);
        });
    };

    const fetchCorporateEntities = async () => {
        setLoading(true);
        const response = await api.invoiceApi.apiVversionCorporateEntityAllGet("1", 1, 100, {}).then((response) => {
            if (response.data.data) {
                setCorporateEntities(response.data?.data?.queryResult);
            };
            setLoading(false);
        }).catch((error) => {
            handleAPIError(error, toast, errors);    
            setErrors({ ...errors }); 
            setLoading(false);
        });
    };

    const fetchUserDetail = async (idNumber: number) => {
        setLoading(true);
        const response = await api.employeeApi.apiVversionUserIdGet(idNumber, "1", {}).then((response) => {
            if (response?.data?.data) {
                const userDetailsIdFe = response.data?.data?.userDetails?.map(user => ({
                    ...user,
                    idFe: generateUniqueId(),
                }));
                setInput({
                    ...input,
                    active: response.data?.data?.active,
                    email: response.data?.data?.emailAddress,
                    userDetails: userDetailsIdFe
                });
                const filteredClients = response?.data?.data?.userDetails?.filter(detail => detail.entityType === ENTITY_TYPE.CLIENT) || [];
                const filteredEmployees = response?.data?.data?.userDetails?.filter(detail => detail.entityType === ENTITY_TYPE.EMPLOYEE) || [];
                const filteredProviders = response?.data?.data?.userDetails?.filter(detail => detail.entityType === ENTITY_TYPE.PROVIDER) || [];
                const filteredCoorporateEntities = response?.data?.data?.userDetails?.filter(detail => detail.entityType === ENTITY_TYPE.CORPORATE_ENTITY) || [];
                setClients(filteredClients);
                // setEmployees(filteredEmployees) commented until typeahead is applied
                // setProviders(filteredProviders) commented until typeahead is applied
                // setCorporateEntities(filteredCoorporateEntities) commented until typeahead is applied
                console.log("Success");
            };
            setLoading(false);
        }).catch((error) => {
            handleAPIError(error, toast, errors);    
            setErrors({ ...errors }); 
            setLoading(false);
        });
    };

    useEffect(() => {
        fetchEmployees();
        fetchRoles();
        fetchProviders();
        fetchCorporateEntities();
        fetchUserDetail(idNumber);
    }, [idNumber]);

    const handleEmployeeChange = (selectedOption, id) => {
        if(selectedOption == null){
            setDisabled(true);
            // setEditedUserAsEmployee({
            //     ...editedUserAsEmployee,
            //     entityId: null,
            //     entityName: '',
            //     idFe: null
            // }); Commented because this will be implemented when typeahead is applied
            return
        }
        setEditedUserAsEmployee({
            ...editedUserAsEmployee,
            entityId: selectedOption.id,
            entityName: selectedOption.entityName || selectedOption.name,
            idFe: id
        });
        setDisabled(false);
        setEdit(false);
    };

    const handleClientChange = (selectedOption, id) => {
        if(selectedOption == null){
            setDisabled(true);
            setEditedUserAsClient({
                ...editedUserAsClient,
                entityId: null,
                entityName: '',
                idFe: null
            });
            return
        }
        setEditedUserAsClient({
            ...editedUserAsClient,
            entityId: selectedOption.id,
            entityName: selectedOption.entityName || selectedOption.name,
            idFe: id
        });
        setDisabled(false);
        setEdit(false);
    };

    const handleProviderChange = (selectedOption, id) => {
        if(selectedOption == null){
            setDisabled(true);
            // //            setEditedUserAsClient({
            //     ...editedUserAsClient,
            //     entityId: null,
            //     entityName: '',
            //     idFe: null
            // }); Commented because this will be implemented when typeahead is applied
            return
        }
        setEditedUserAsProvider({
            ...editedUserAsProvider,
            entityId: selectedOption.id,
            entityName: selectedOption.entityName || selectedOption.name,
            idFe: id
        });
        setDisabled(false);
        setEdit(false);
    };

    const handleCorporateEntityChange = (selectedOption, id) => {
        if(selectedOption == null){
            setDisabled(true);
            // //            setEditedUserAsCorporateEntity({
            //     ...editedUserAsCorporateEntity,
            //     entityId: null,
            //     entityName: '',
            //     idFe: null
            // }); Commented because this will be implemented when typeahead is applied            
            return
        }
        setEditedUserAsCorporateEntity({
            ...editedUserAsCorporateEntity,
            entityId: selectedOption.id,
            entityName: selectedOption.entityName || selectedOption.name,
            idFe: id
        });
        setDisabled(false);
        setEdit(false);
    };

    const handleRolesUserAsEmployeeChange = (event, id) => {
        
        const selectedRolesIds = event.map(role => role.id);
        //setEditedUserAsEmployee(null); Commented because this will be implemented when typeahead is applied
        setEditedUserAsEmployee({
            ...editedUserAsEmployee,
            securityRoles: selectedRolesIds
        });
    };

    const handleRolesUserAsClientChange = (event, id) => {
        const selectedRolesIds = event.map(role => role.id);
        setEditedUserAsClient({
            ...editedUserAsClient,
            securityRoles: selectedRolesIds
        });
    };

    const handleRolesUserAsProviderChange = (event, id) => {
        const selectedRolesIds = event.map(role => role.id);
        setEditedUserAsProvider({
            ...editedUserAsProvider,
            securityRoles: selectedRolesIds
        });
    };

    const handleRolesUserAsCorporateEntityChange = (event, id) => {
        const selectedRolesIds = event.map(role => role.id);
        setEditedUserAsCorporateEntity({
            ...editedUserAsCorporateEntity,
            securityRoles: selectedRolesIds
        });
    };

    const handleSave = async (idFe, isDefault) => {
        confirmAlert({
            title: intl.get('delete.modal.title'),
            message: intl.get('confirm.modal.userDetail.message'),
            buttons: [
                {
                    label: intl.get('delete.modal.cancel.button'),
                    onClick: () => { 
                        setLoading(false);
                    }
                },
                {
                    label: intl.get('save.modal.save.button'),
                    onClick: async () => {
                        setLoading(true);
                        const userDetailEdited = input.userDetails.map(user => {
                            if (user.idFe === editedUserAsEmployee.idFe) {
                                return editedUserAsEmployee;
                            } else {
                                return user;
                            };
                        });
                        setInput({
                            ...input,
                            userDetails: userDetailEdited
                        });
                        if (editedUserAsEmployee.id) {
                            const userUpdated = input.userDetails.find(user => user.idFe === idFe);
                            const userDetailEditedSecurityRoles = userDetailEdited.find(user => user.idFe === idFe);
                            let cmd: UpdateUserSecurityRolesCommand = {
                                //TODO CC: commented out because it is not used in the API
                                //id: userUpdated.id ,
                                idUser: idNumber,
                                idEntity: userUpdated.entityId,
                                entityType: userUpdated.entityType,
                                securityRoles: userDetailEditedSecurityRoles.securityRoles,
                                isDefault: userUpdated.isDefault
                            };
                            const response = await api.employeeApi.apiVversionUserIdSecurityrolesPut(idNumber, "1", cmd, {}).then((response) => {
                                if (response.data.data) {
                                    const newUserDetails = response.data?.data?.userDetails?.map(user => ({
                                        ...user,
                                        idFe: generateUniqueId()
                                    }));
                                    setInput({
                                        ...input,
                                        userDetails: newUserDetails
                                    });
                                    toast.success({ body: intl.get('newUser.toast.success.handleSave')});
                                };
                                setIsFormUserAsEmployeeOpen(!isFormUserAsEmployeeOpen);
                                setLoading(false);
                            }).catch((error) => {
                                handleAPIError(error, toast, errors);    
                                setErrors({ ...errors }); 
                                setLoading(false);
                            });
                        } else {
                            let cmd: UpdateUserSecurityRolesCommand = {
                                //TODO CC: commented out because it is not used in the API
                                //id: null ,
                                idUser: idNumber,
                                idEntity: editedUserAsEmployee.entityId,
                                entityType: editedUserAsEmployee.entityType,
                                securityRoles: editedUserAsEmployee.securityRoles
                            };
                            const response = await api.employeeApi.apiVversionUserIdSecurityrolesPut(idNumber, "1", cmd, {}).then((response) => {
                                if (response.data.data) {
                                    const newUserDetails = response.data?.data?.userDetails?.map(user => ({
                                        ...user,
                                        idFe: generateUniqueId()
                                    }));
                                    setInput({
                                        ...input,
                                        userDetails: newUserDetails
                                    });
                                    toast.success({ body: intl.get('newUser.toast.success.handleSave.add')});
                                };
                                setIsFormUserAsEmployeeOpen(!isFormUserAsEmployeeOpen);
                                setLoading(false);
                            }).catch((error) => {
                                handleAPIError(error, toast, errors);    
                                setErrors({ ...errors }); 
                                setLoading(false);
                            });
                        }
                    },
                }
            ]
        });
    };

    const handleSaveClient = async (idFe) => {
        confirmAlert({
            title: intl.get('delete.modal.title'),
            message: intl.get('confirm.modal.userDetail.message'),
            buttons: [
                {
                    label: intl.get('delete.modal.cancel.button'),
                    onClick: () => { 
                        setLoading(false);
                    }
                },
                {
                    label: intl.get('save.modal.save.button'),
                    onClick: async () => {
                        setLoading(true);
                        const userDetailEdited = input.userDetails.map(user => {
                            if (user.idFe === editedUserAsClient.idFe) {
                                return editedUserAsClient;
                            } else {
                                return user;
                            };
                        });
                        setInput({
                            ...input,
                            userDetails: userDetailEdited
                        });
                        if (editedUserAsClient.id) {
                            const userUpdated = input.userDetails.find(user => user.idFe === idFe);
                            const userDetailEditedSecurityRoles = userDetailEdited.find(user => user.idFe === idFe);
                            let cmd: UpdateUserSecurityRolesCommand = {
                                //TODO CC: commented out because it is not used in the API
                                //id: userUpdated.id ,
                                idUser: idNumber,
                                idEntity: userUpdated.entityId,
                                entityType: userUpdated.entityType,
                                securityRoles: userDetailEditedSecurityRoles.securityRoles,
                                isDefault: userUpdated.isDefault
                            };
                            const response = await api.employeeApi.apiVversionUserIdSecurityrolesPut(idNumber, "1", cmd, {}).then((response) => {
                                if (response.data.data) {
                                    const newUserDetails = response.data?.data?.userDetails?.map(user => ({
                                        ...user,
                                        idFe: generateUniqueId()
                                    }));
                                    setInput({
                                        ...input,
                                        userDetails: newUserDetails
                                    });
                                    toast.success({ body: intl.get('newUser.toast.success.handleSave')});
                                };
                                setIsFormUserAsClientOpen(!isFormUserAsClientOpen);
                                setLoading(false);
                            }).catch((error) => {
                                handleAPIError(error, toast, errors);    
                                setErrors({ ...errors }); 
                                setLoading(false);
                            });
                        } else {
                            let cmd: UpdateUserSecurityRolesCommand = {
                                //TODO CC: commented out because it is not used in the API
                                //id: null ,
                                idUser: idNumber,
                                idEntity: editedUserAsClient.entityId,
                                entityType: editedUserAsClient.entityType,
                                securityRoles: editedUserAsClient.securityRoles
                            };
                            const response = await api.employeeApi.apiVversionUserIdSecurityrolesPut(idNumber, "1", cmd, {}).then((response) => {
                                if (response.data.data) {
                                    const newUserDetails = response.data?.data?.userDetails?.map(user => ({
                                        ...user,
                                        idFe: generateUniqueId()
                                    }));
                                    setInput({
                                        ...input,
                                        userDetails: newUserDetails
                                    });
                                    toast.success({ body: intl.get('newUser.toast.success.handleSave.add')});
                                };
                                setIsFormUserAsClientOpen(!isFormUserAsClientOpen);
                                setLoading(false);
                            }).catch((error) => {
                                handleAPIError(error, toast, errors);    
                                setErrors({ ...errors }); 
                                setLoading(false);
                            });
                        }
                    },
                }
            ]
        });
    };

    const handleSaveProvider = async (idFe) => {
        confirmAlert({
            title: intl.get('delete.modal.title'),
            message: intl.get('confirm.modal.userDetail.message'),
            buttons: [
                {
                    label: intl.get('delete.modal.cancel.button'),
                    onClick: () => { 
                        setLoading(false);
                    }
                },
                {
                    label: intl.get('save.modal.save.button'),
                    onClick: async () => {
                        setLoading(true);
                        const userDetailEdited = input.userDetails.map(user => {
                            if (user.idFe === editedUserAsProvider.idFe) {
                                return editedUserAsProvider;
                            } else {
                                return user;
                            };
                        });
                        setInput({
                            ...input,
                            userDetails: userDetailEdited
                        });
                        if (editedUserAsProvider.id) {
                            const userUpdated = input.userDetails.find(user => user.idFe === idFe);
                            const userDetailEditedSecurityRoles = userDetailEdited.find(user => user.idFe === idFe);
                            let cmd: UpdateUserSecurityRolesCommand = {
                                //TODO CC: commented out because it is not used in the API
                                //id: userUpdated.id ,
                                idUser: idNumber,
                                idEntity: userUpdated.entityId,
                                entityType: userUpdated.entityType,
                                securityRoles: userDetailEditedSecurityRoles.securityRoles,
                                isDefault: userUpdated.isDefault
                            };
                            const response = await api.employeeApi.apiVversionUserIdSecurityrolesPut(idNumber, "1", cmd, {}).then((response) => {
                                if (response.data.data) {
                                    const newUserDetails = response.data?.data?.userDetails?.map(user => ({
                                        ...user,
                                        idFe: generateUniqueId()
                                    }));
                                    setInput({
                                        ...input,
                                        userDetails: newUserDetails
                                    });
                                    toast.success({ body: intl.get('newUser.toast.success.handleSave')});
                                };
                                setIsFormUserAsProviderOpen(!isFormUserAsProviderOpen);
                                setLoading(false);
                            }).catch((error) => {
                                handleAPIError(error, toast, errors);    
                                setErrors({ ...errors }); 
                                setLoading(false);
                            });
                        } else {
                            let cmd: UpdateUserSecurityRolesCommand = {
                                //TODO CC: commented out because it is not used in the API
                                //id: null ,
                                idUser: idNumber,
                                idEntity: editedUserAsProvider.entityId,
                                entityType: editedUserAsProvider.entityType,
                                securityRoles: editedUserAsProvider.securityRoles
                            };
                            const response = await api.employeeApi.apiVversionUserIdSecurityrolesPut(idNumber, "1", cmd, {}).then((response) => {
                                if (response.data.data) {
                                    const newUserDetails = response.data?.data?.userDetails?.map(user => ({
                                        ...user,
                                        idFe: generateUniqueId()
                                    }));
                                    setInput({
                                        ...input,
                                        userDetails: newUserDetails
                                    });
                                    toast.success({ body: intl.get('newUser.toast.success.handleSave.add')});
                                };
                                setIsFormUserAsProviderOpen(!isFormUserAsProviderOpen);
                                setLoading(false);
                            }).catch((error) => {
                                handleAPIError(error, toast, errors);    
                                setErrors({ ...errors }); 
                                setLoading(false);
                            });
                        }
                    },
                }
            ]
        });
    };
    
    const handleSaveCorporateEntity = async (idFe) => {
        confirmAlert({
            title: intl.get('delete.modal.title'),
            message: intl.get('confirm.modal.userDetail.message'),
            buttons: [
                {
                    label: intl.get('delete.modal.cancel.button'),
                    onClick: () => { 
                        setLoading(false);
                    }
                },
                {
                    label: intl.get('save.modal.save.button'),
                    onClick: async () => {
                        setLoading(true);
                        const userDetailEdited = input.userDetails.map(user => {
                            if (user.idFe === editedUserAsCorporateEntity.idFe) {
                                return editedUserAsCorporateEntity;
                            } else {
                                return user;
                            };
                        });
                        setInput({
                            ...input,
                            userDetails: userDetailEdited
                        });
                        if (editedUserAsCorporateEntity.id) {
                            const userUpdated = input.userDetails.find(user => user.idFe === idFe);
                            const userDetailEditedSecurityRoles = userDetailEdited.find(user => user.idFe === idFe);
                            let cmd: UpdateUserSecurityRolesCommand = {
                                //TODO CC: commented out because it is not used in the API
                                //id: userUpdated.id ,
                                idUser: idNumber,
                                idEntity: userUpdated.entityId,
                                entityType: userUpdated.entityType,
                                securityRoles: userDetailEditedSecurityRoles.securityRoles,
                                isDefault: userUpdated.isDefault
                            };
                            const response = await api.employeeApi.apiVversionUserIdSecurityrolesPut(idNumber, "1", cmd, {}).then((response) => {
                                if (response.data.data) {
                                    const newUserDetails = response.data?.data?.userDetails?.map(user => ({
                                        ...user,
                                        idFe: generateUniqueId()
                                    }));
                                    setInput({
                                        ...input,
                                        userDetails: newUserDetails
                                    });
                                    toast.success({ body: intl.get('newUser.toast.success.handleSave')});
                                };
                                setIsFormUserAsCorporateEntityOpen(!isFormUserAsCorporateEntityOpen);
                                setLoading(false);
                            }).catch((error) => {
                                handleAPIError(error, toast, errors);    
                                setErrors({ ...errors }); 
                                setLoading(false);
                            });
                        } else {
                            let cmd: UpdateUserSecurityRolesCommand = {
                                //TODO CC: commented out because it is not used in the API
                                //id: null ,
                                idUser: idNumber,
                                idEntity: editedUserAsCorporateEntity.entityId,
                                entityType: editedUserAsCorporateEntity.entityType,
                                securityRoles: editedUserAsCorporateEntity.securityRoles
                            };
                            const response = await api.employeeApi.apiVversionUserIdSecurityrolesPut(idNumber, "1", cmd, {}).then((response) => {
                                if (response.data.data) {
                                    const newUserDetails = response.data?.data?.userDetails?.map(user => ({
                                        ...user,
                                        idFe: generateUniqueId()
                                    }));
                                    setInput({
                                        ...input,
                                        userDetails: newUserDetails
                                    });
                                    toast.success({ body: intl.get('newUser.toast.success.handleSave.add')});
                                };
                                setIsFormUserAsCorporateEntityOpen(!isFormUserAsCorporateEntityOpen);
                                setLoading(false);
                            }).catch((error) => {
                                handleAPIError(error, toast, errors);
                                setErrors({ ...errors }); 
                                setLoading(false);
                            });
                        }
                    },
                }
            ]
        });
    };

    const handleDelete = (idFe) => {
        const userDetailDelete = input.userDetails.find(user => user.idFe === idFe);
        confirmAlert({
            title: intl.get('delete.modal.title'),            
            message: intl.get('delete.modal.userDetail.message'),
            buttons: [
                {
                    label: intl.get('delete.modal.cancel.button'),
                    onClick: () => {
                        setLoading(false);
                    }
                },
                {
                    label: intl.get('delete.modal.delete.button'),
                    onClick: async () => {
                        setLoading(true);
                        const response = await api.employeeApi.apiVversionUserUserEntityIdUserEntityDelete(userDetailDelete.id, "1", {}).then((response) => {
                            if (response.data.data) {
                                const NewUserDetails = input.userDetails.filter(user => user.id !== userDetailDelete.id);
                                setInput({
                                    ...input,
                                    userDetails: NewUserDetails
                                });
                                toast.success({ body: intl.get('userRow.toast.success.handleDeleteUser')});
                            };
                            setLoading(false);
                        }).catch((error) => {
                            handleAPIError(error, toast, errors);    
                setErrors({ ...errors }); 
                setLoading(false);
                        });
                    },
                }
            ]
        });
    };    

    const handleStatus = async () => {
        setLoading(true);
        let cmd: UpdateUserCommand = {
            id: idNumber,
            active: !input.active,
            emailAddress: input.email,
            userDetails: input.userDetails,
        };
        const response = await api.employeeApi.apiVversionUserPut("1", cmd, {}).then((response) => {
            toast.success({ body: intl.get('roleRow.toast.success.handleStatus')});
            setInput({
                ...input,
                active: response?.data.data.active,
            })
        }).catch((error) => {
            handleAPIError(error, toast, errors);    
            setErrors({ ...errors }); 
            setLoading(false);
        }).finally(() => {
            setLoading(false);
        });
    };

    const handleBackClick = (e) => {
        navigate('/users');
    };

    const handleFormUserAsEmployeeOpen = () => {
        setIsFormUserAsEmployeeOpen(!isFormUserAsEmployeeOpen);
        if (!isFormUserAsEmployeeOpen) {
            setEdit(false);
            // setEmployess(null) commented until typeahead is applied
        };
    };

    const handleFormUserAsClientOpen = () => {
        setIsFormUserAsClientOpen(!isFormUserAsClientOpen);
        if (!isFormUserAsClientOpen) {
            setEdit(false);
            setClients(null)
        };
    };

    const handleFormUserAsProviderOpen = () => {
        setIsFormUserAsProviderOpen(!isFormUserAsProviderOpen);
        if (!isFormUserAsProviderOpen) {
            setEdit(false);
           // setProviders(null) commented until typeahead is applied
        };
    };

    const handleFormUserAsCorporateEntityOpen = () => {
        setIsFormUserAsCorporateEntityOpen(!isFormUserAsCorporateEntityOpen);
        if (!isFormUserAsCorporateEntityOpen) {
            setEdit(false);
            // setCorporateEntities(null) commented until typeahead is applied
        };
    };

    const handleEditUserAsEmployee = (idFe) => {
        setEditedUserAsEmployee({
            id: 0,
            entityType: 1,
            entityId: 0,
            entityName: '',
            securityRoles: [],
            idFe: null,
            isDefault: false
        });
        handleFormUserAsEmployeeOpen();
        if (idFe) {
            const editedEntity = input.userDetails.find(entity => entity.idFe === idFe);
            setEditedUserAsEmployee(editedEntity);
            setEdit(true);
        };
    };

    const handleEditUserAsClient = (idFe) => {
        setEditedUserAsClient({
            id: 0,
            entityType: 2,
            entityId: 0,
            entityName: '',
            securityRoles: [],
            idFe: null,
            isDefault: false
        });
        handleFormUserAsClientOpen();
        if (idFe) {
            const editedEntity = input.userDetails.find(entity => entity.idFe === idFe);
            setEditedUserAsClient(editedEntity);
            setEdit(true);
        };
    };

    const handleEditUserAsProvider = (idFe) => {
        setEditedUserAsProvider({
            id: 0,
            entityType: 3,
            entityId: 0,
            entityName: '',
            securityRoles: [],
            idFe: null,
            isDefault: false
        });
        handleFormUserAsProviderOpen();
        if (idFe) {
            const editedEntity = input.userDetails.find(entity => entity.idFe === idFe);
            setEditedUserAsProvider(editedEntity);
            setEdit(true);
        };
    };

    const handleEditUserAsCorporateEntity = (idFe) => {
        setEditedUserAsCorporateEntity({
            id: 0,
            entityType: 4,
            entityId: 0,
            entityName: '',
            securityRoles: [],
            idFe: null,
            isDefault: false
        });
        handleFormUserAsCorporateEntityOpen();
        if (idFe) {
            const editedEntity = input.userDetails.find(entity => entity.idFe === idFe);
            setEditedUserAsCorporateEntity(editedEntity);
            setEdit(true);
        };
    };

    return (
        <div className="container">
            <Row className='justify-content-center'>
                <div className='card mt-4 col-8'>
                    <div className="card-header">
                        <h2 className="title mb-0">{intl.get('userDetail.header')}</h2>
                    </div>
                    <div className='card-body'>
                         <ErrorSummary errors={errors} showEverything={false} mode="warning"></ErrorSummary>
                        <PermissionsGate viewScopes={[SCOPES['users.read']]} editScopes={[SCOPES['users.edit']]} viewRoles={[]} editRoles={[]} RenderError={()=>{return <span>{intl.get('permissionsGate')}</span>}}>
                            {
                                loading === true ?
                                <Spinner /> :
                                <Form>
                                    <Row className='mb-4'>
                                        <Col>
                                            <Label for="userEmail" className='margin-label'>{intl.get('userDetail.userEmail')}</Label>
                                            <Input
                                                id="userEmail"
                                                name="email"
                                                placeholder={intl.get('newUser.placeholder.email')}
                                                value={input?.email}
                                                disabled={true}
                                                className={"input-email"}
                                            />
                                        </Col>
                                        <Col>
                                            <Label for="status" className='margin-label'>{intl.get('statusSelect.label')}</Label>
                                            <div className="form-check form-switch switch">
                                                <label className="form-check-label margin-label" htmlFor="flexSwitchCheckDefault">{`${input.active ? intl.get('roleRow.active') : intl.get('roleRow.inactive')}`}</label>
                                                <input className="form-check-input input-switch" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={input.active} onChange={handleStatus} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='mb-3'>
                                        <span className='fw-bold mb-1'>{intl.get('newUser.assignment.title')}</span>
                                        <span>{intl.get('newUser.assignment.message')}</span>
                                    </Row>
                                    {
                                        isFormUserAsEmployeeOpen && editedUserAsEmployee?.entityId !== 0 || isFormUserAsEmployeeOpen && editedUserAsEmployee?.securityRoles.length > 0 ?
                                        <FormUserAsEmployee
                                            key={editedUserAsEmployee?.id}
                                            idFe={editedUserAsEmployee?.idFe}
                                            securityRoles={editedUserAsEmployee?.securityRoles}
                                            roles={roles}
                                            entityId={editedUserAsEmployee?.entityId}
                                            isDefault={editedUserAsEmployee?.isDefault}
                                            employees={employees}
                                            handleEmployeeChange={handleEmployeeChange}
                                            handleRoleChange={handleRolesUserAsEmployeeChange}
                                            handleFormUserAsEmployeeOpen={handleFormUserAsEmployeeOpen}
                                            handleSave={handleSave}
                                            edit={edit}
                                            onDefaultChange={(idFe, isDefault) => handleDefaultChange(idFe, isDefault)}
                                            previousDefaultId={previousDefaultId}
                                            disabled={disabled}
                                            setDisabled={setDisabled}
                                        /> :
                                        isFormUserAsEmployeeOpen && editedUserAsEmployee?.entityId === 0 || isFormUserAsEmployeeOpen && editedUserAsEmployee?.securityRoles.length === 0 ?
                                        <FormUserAsEmployee
                                            key={generateUniqueId()}
                                            idFe={generateUniqueId()}
                                            securityRoles={null}
                                            roles={roles}
                                            entityId={null}
                                            isDefault={false}
                                            employees={employees}
                                            handleEmployeeChange={handleEmployeeChange}
                                            handleRoleChange={handleRolesUserAsEmployeeChange}
                                            handleFormUserAsEmployeeOpen={handleFormUserAsEmployeeOpen}
                                            handleSave={handleSave}
                                            edit={edit}
                                            onDefaultChange={null}
                                            previousDefaultId={null}
                                            disabled={disabled}
                                            setDisabled={setDisabled}
                                        /> :
                                        null
                                    }
                                    <Row>
                                        <Label for="userAsEmployee">
                                            {intl.get('newUser.userAsEmployee')}
                                        </Label>
                                    </Row>
                                    {
                                        input.userDetails.filter(comp => comp.entityType === 1).map(comp => (
                                            <UserAsEmployeeEdit
                                                key={comp.idFe}
                                                idFe={comp.idFe}
                                                entityId={comp.entityId}
                                                securityRoles={comp.securityRoles}
                                                isDefault={comp.isDefault}
                                                employees={employees}
                                                roles={roles}
                                                isFormUserAsEmployeeOpen={isFormUserAsEmployeeOpen}
                                                isFormUserAsClientOpen={isFormUserAsClientOpen}
                                                isFormUserAsProviderOpen={isFormUserAsProviderOpen}
                                                isFormUserAsCorporateEntityOpen={isFormUserAsCorporateEntityOpen}
                                                handleEditUserAsEmployee={handleEditUserAsEmployee}
                                                handleDelete={handleDelete}
                                            />
                                        ))
                                    }
                                    <Row>
                                        <Col className='text-md-end addEmployeeSize'>
                                            <HiOutlinePlusCircle className={isFormUserAsEmployeeOpen || isFormUserAsClientOpen || isFormUserAsProviderOpen || isFormUserAsCorporateEntityOpen ? 'me-1 addEmployeeInactive' : 'me-1 addEmployeeActive'}/>
                                            <a type='button' className={isFormUserAsEmployeeOpen || isFormUserAsClientOpen || isFormUserAsProviderOpen || isFormUserAsCorporateEntityOpen ? "addUserEntityDisabled link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" : "link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"} onClick={() => handleEditUserAsEmployee(null)} >{intl.get('newUser.addEmployee')}</a>
                                        </Col>
                                    </Row>
                                    <hr className="separator mt-4"></hr>
                                    {
                                        isFormUserAsClientOpen && editedUserAsClient?.entityId !== 0 || isFormUserAsClientOpen && editedUserAsClient?.securityRoles.length > 0 ?
                                        <FormUserAsClient
                                            key={editedUserAsClient?.id}
                                            idFe={editedUserAsClient?.idFe}
                                            securityRoles={editedUserAsClient?.securityRoles}
                                            roles={roles}
                                            entityId={editedUserAsClient?.entityId}
                                            isDefault={editedUserAsClient?.isDefault}
                                            clients={editedUserAsClient}
                                            setClients={setClients}
                                            handleClientChange={handleClientChange}
                                            handleRoleChange={handleRolesUserAsClientChange}
                                            handleFormUserAsClientOpen={handleFormUserAsClientOpen}
                                            handleSave={handleSaveClient}
                                            edit={edit}
                                            onDefaultChange={(idFe, isDefault) => handleDefaultChange(idFe, isDefault)}
                                            previousDefaultId={previousDefaultId}
                                            disabled={disabled}
                                            setDisabled={setDisabled}
                                        /> :
                                        isFormUserAsClientOpen && editedUserAsClient?.entityId === 0 || isFormUserAsClientOpen && editedUserAsClient?.securityRoles.length === 0 ?
                                        <FormUserAsClient
                                            key={generateUniqueId()}
                                            idFe={generateUniqueId()}
                                            securityRoles={null}
                                            roles={roles}
                                            entityId={null}
                                            isDefault={false}
                                            clients={clients}                                            setClients={setClients}
                                            handleClientChange={handleClientChange}
                                            handleRoleChange={handleRolesUserAsClientChange}
                                            handleFormUserAsClientOpen={handleFormUserAsClientOpen}
                                            handleSave={handleSaveClient}
                                            edit={edit}
                                            onDefaultChange={null}
                                            previousDefaultId={null}
                                            disabled={disabled}
                                            setDisabled={setDisabled}
                                        /> :
                                        null
                                    }
                                    <Row>
                                        <Label for="userAsClient">
                                            {intl.get('newUser.userAsClient')}
                                        </Label>
                                    </Row>
                                    {
                                        input.userDetails.filter(comp => comp.entityType === ENTITY_TYPE.CLIENT).map(comp => {
                                            return(
                                                <UserAsClientEdit
                                                    key={comp.idFe}
                                                    idFe={comp.idFe}
                                                    entityId={comp.entityId}
                                                    securityRoles={comp.securityRoles}
                                                    isDefault={comp.isDefault}
                                                    clientName={comp.entityName}
                                                    roles={roles}
                                                    isFormUserAsEmployeeOpen={isFormUserAsEmployeeOpen}
                                                    isFormUserAsClientOpen={isFormUserAsClientOpen}
                                                    isFormUserAsProviderOpen={isFormUserAsProviderOpen}
                                                    isFormUserAsCorporateEntityOpen={isFormUserAsCorporateEntityOpen}
                                                    handleEditUserAsClient={handleEditUserAsClient}
                                                    handleDelete={handleDelete}
                                                />
                                            )
                                        } 
                                        )
                                    }
                                    <Row>
                                        <Col className='text-md-end addEmployeeSize'>
                                            <HiOutlinePlusCircle className={isFormUserAsClientOpen || isFormUserAsEmployeeOpen || isFormUserAsProviderOpen || isFormUserAsCorporateEntityOpen ? 'me-1 addEmployeeInactive' : 'me-1 addEmployeeActive'}/>
                                            <a type='button' className={isFormUserAsClientOpen || isFormUserAsEmployeeOpen || isFormUserAsProviderOpen || isFormUserAsCorporateEntityOpen ? "addUserEntityDisabled link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" : "link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"} onClick={() => handleEditUserAsClient(null)}>{intl.get('newUser.addClient')}</a>
                                        </Col>
                                    </Row>
                                    <hr className="separator mt-4"></hr>
                                    {
                                        isFormUserAsProviderOpen && editedUserAsProvider?.entityId !== 0 || isFormUserAsProviderOpen && editedUserAsProvider?.securityRoles.length > 0 ?
                                        <FormUserAsProvider
                                            key={editedUserAsProvider?.id}
                                            idFe={editedUserAsProvider?.idFe}
                                            securityRoles={editedUserAsProvider?.securityRoles}
                                            roles={roles}
                                            entityId={editedUserAsProvider?.entityId}
                                            isDefault={editedUserAsProvider.isDefault}
                                            providers={providers}
                                            handleProviderChange={handleProviderChange}
                                            handleRoleChange={handleRolesUserAsProviderChange}
                                            handleFormUserAsProviderOpen={handleFormUserAsProviderOpen}
                                            handleSave={handleSaveProvider}
                                            edit={edit}
                                            onDefaultChange={(idFe, isDefault) => handleDefaultChange(idFe, isDefault)}
                                            previousDefaultId={previousDefaultId}
                                            disabled={disabled}
                                            setDisabled={setDisabled}
                                        /> :
                                        isFormUserAsProviderOpen && editedUserAsProvider.entityId === 0 || isFormUserAsProviderOpen && editedUserAsProvider.securityRoles.length === 0 ?
                                        <FormUserAsProvider
                                            key={generateUniqueId()}
                                            idFe={generateUniqueId()}
                                            securityRoles={null}
                                            roles={roles}
                                            entityId={null}
                                            isDefault={false}
                                            providers={providers}
                                            handleProviderChange={handleProviderChange}
                                            handleRoleChange={handleRolesUserAsProviderChange}
                                            handleFormUserAsProviderOpen={handleFormUserAsProviderOpen}
                                            handleSave={handleSaveProvider}
                                            edit={edit}
                                            onDefaultChange={null}
                                            previousDefaultId={null}
                                            disabled={disabled}
                                            setDisabled={setDisabled}
                                        /> :
                                        null
                                    }
                                    <Row>
                                        <Label for="userAsProvider">
                                            {intl.get('newUser.userAsProvider')}
                                        </Label>
                                    </Row>
                                    {
                                        input.userDetails.filter(comp => comp.entityType === 3).map(comp => (
                                            <UserAsProviderEdit
                                                key={comp.idFe}
                                                idFe={comp.idFe}
                                                entityId={comp.entityId}
                                                securityRoles={comp.securityRoles}
                                                isDefault={comp.isDefault}
                                                providers={providers}
                                                roles={roles}
                                                isFormUserAsEmployeeOpen={isFormUserAsEmployeeOpen}
                                                isFormUserAsClientOpen={isFormUserAsClientOpen}
                                                isFormUserAsProviderOpen={isFormUserAsProviderOpen}
                                                isFormUserAsCorporateEntityOpen={isFormUserAsCorporateEntityOpen}
                                                handleEditUserAsProvider={handleEditUserAsProvider}
                                                handleDelete={handleDelete}
                                            />
                                        ))
                                    }
                                    <Row>
                                        <Col className='text-md-end addEmployeeSize'>
                                            <HiOutlinePlusCircle className={isFormUserAsProviderOpen || isFormUserAsEmployeeOpen || isFormUserAsClientOpen || isFormUserAsCorporateEntityOpen ? 'me-1 addEmployeeInactive' : 'me-1 addEmployeeActive'}/>
                                            <a type='button' className={isFormUserAsProviderOpen || isFormUserAsEmployeeOpen || isFormUserAsClientOpen || isFormUserAsCorporateEntityOpen ? "addUserEntityDisabled link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" : "link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"} onClick={() => handleEditUserAsProvider(null)}>{intl.get('newUser.addProvider')}</a>
                                        </Col>
                                    </Row>
                                    <hr className="separator mt-4"></hr>
                                    {
                                        isFormUserAsCorporateEntityOpen && editedUserAsCorporateEntity?.entityId !== 0 || isFormUserAsCorporateEntityOpen && editedUserAsCorporateEntity?.securityRoles.length > 0 ?
                                        <FormUserAsCorporateEntity
                                            key={editedUserAsCorporateEntity?.id}
                                            idFe={editedUserAsCorporateEntity?.idFe}
                                            securityRoles={editedUserAsCorporateEntity?.securityRoles}
                                            roles={roles}
                                            entityId={editedUserAsCorporateEntity?.entityId}
                                            isDefault={editedUserAsCorporateEntity.isDefault}
                                            corporateEntities={corporateEntities}
                                            handleCorporateEntityChange={handleCorporateEntityChange}
                                            handleRoleChange={handleRolesUserAsCorporateEntityChange}
                                            handleFormUserAsCorporateEntityOpen={handleFormUserAsCorporateEntityOpen}
                                            handleSave={handleSaveCorporateEntity}
                                            edit={edit}
                                            onDefaultChange={(idFe, isDefault) => handleDefaultChange(idFe, isDefault)}
                                            previousDefaultId={previousDefaultId}
                                            disabled={disabled}
                                            setDisabled={setDisabled}
                                        /> :
                                        isFormUserAsCorporateEntityOpen && editedUserAsCorporateEntity.entityId === 0 || isFormUserAsCorporateEntityOpen && editedUserAsCorporateEntity.securityRoles.length === 0 ?
                                        <FormUserAsCorporateEntity
                                            key={generateUniqueId()}
                                            idFe={generateUniqueId()}
                                            securityRoles={null}
                                            roles={roles}
                                            entityId={null}
                                            isDefault={false}
                                            corporateEntities={corporateEntities}
                                            handleCorporateEntityChange={handleCorporateEntityChange}
                                            handleRoleChange={handleRolesUserAsCorporateEntityChange}
                                            handleFormUserAsCorporateEntityOpen={handleFormUserAsCorporateEntityOpen}
                                            handleSave={handleSaveCorporateEntity}
                                            edit={edit}
                                            onDefaultChange={null}
                                            previousDefaultId={null}
                                            disabled={disabled}
                                            setDisabled={setDisabled}
                                        /> :
                                        null
                                    }
                                    <Row>
                                        <Label for="userAsCorporateEntity">
                                            {intl.get('newUser.userAsCorporateEntity')}
                                        </Label>
                                    </Row>
                                    {
                                        input.userDetails.filter(comp => comp.entityType === 4).map(comp => (
                                            <UserAsCorporateEntityEdit
                                                key={comp.idFe}
                                                idFe={comp.idFe}
                                                entityId={comp.entityId}
                                                securityRoles={comp.securityRoles}
                                                isDefault={comp.isDefault}
                                                corporateEntities={corporateEntities}
                                                roles={roles}
                                                isFormUserAsEmployeeOpen={isFormUserAsEmployeeOpen}
                                                isFormUserAsClientOpen={isFormUserAsClientOpen}
                                                isFormUserAsProviderOpen={isFormUserAsProviderOpen}
                                                isFormUserAsCorporateEntityOpen={isFormUserAsCorporateEntityOpen}
                                                handleEditUserAsCorporateEntity={handleEditUserAsCorporateEntity}
                                                handleDelete={handleDelete}
                                            />
                                        ))
                                    }
                                    <Row>
                                        <Col className='text-md-end addEmployeeSize'>
                                            <HiOutlinePlusCircle className={isFormUserAsProviderOpen || isFormUserAsEmployeeOpen || isFormUserAsClientOpen || isFormUserAsCorporateEntityOpen ? 'me-1 addEmployeeInactive' : 'me-1 addEmployeeActive'}/>
                                            <a type='button' className={isFormUserAsProviderOpen || isFormUserAsEmployeeOpen || isFormUserAsClientOpen || isFormUserAsCorporateEntityOpen ? "addUserEntityDisabled link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" : "link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"} onClick={() => handleEditUserAsCorporateEntity(null)}>{intl.get('newUser.addCorporateEntity')}</a>
                                        </Col>
                                    </Row>

                                    <div className='d-flex justify-content-end my-4'>
                                        <button type='button' className='btn btn-outline-dark me-2' id='return' onClick={handleBackClick}>
                                            <HiArrowLeft/>
                                        </button>
                                    </div>
                                </Form>
                            }
                        </PermissionsGate>
                    </div>
                </div>
            </Row>
		</div>
    )
}

export default UserDetail;
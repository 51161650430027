/* tslint:disable */
/* eslint-disable */
/**
 * Intranet - Projects API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { BooleanResponse } from '../models';
// @ts-ignore
import type { CreateEmployeeProjectCommand } from '../models';
// @ts-ignore
import type { CreateProjectCommand } from '../models';
// @ts-ignore
import type { EmployeeProjectByEmployeeIdResponseResponse } from '../models';
// @ts-ignore
import type { EmployeeProjectByIdResponseResponse } from '../models';
// @ts-ignore
import type { EmployeeProjectByProjectIdResponseResponse } from '../models';
// @ts-ignore
import type { EmployeeProjectResponse } from '../models';
// @ts-ignore
import type { ListOptionDTOSearchResponseResponse } from '../models';
// @ts-ignore
import type { ProjectByIdResponseResponse } from '../models';
// @ts-ignore
import type { ProjectListResponse } from '../models';
// @ts-ignore
import type { ProjectResponse } from '../models';
// @ts-ignore
import type { ProjectSearchResponseResponse } from '../models';
// @ts-ignore
import type { RoleSearchResponseResponse } from '../models';
// @ts-ignore
import type { StatusCodeResult } from '../models';
// @ts-ignore
import type { StringResponse } from '../models';
// @ts-ignore
import type { UpdateEmployeeProjectCommand } from '../models';
// @ts-ignore
import type { UpdateProjectCommand } from '../models';
/**
 * ProjectApi - axios parameter creator
 * @export
 */
export const ProjectApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets the list of EmployeeProjects by Project id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionEmployeeProjectGetByClientIdIdGet: async (id: number, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVversionEmployeeProjectGetByClientIdIdGet', 'id', id)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionEmployeeProjectGetByClientIdIdGet', 'version', version)
            const localVarPath = `/api/v{version}/EmployeeProject/getByClientId/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the list of EmployeeProjects by Employee ID.
         * @param {number} id The employee Id.
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionEmployeeProjectGetByEmployeeIdIdGet: async (id: number, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVversionEmployeeProjectGetByEmployeeIdIdGet', 'id', id)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionEmployeeProjectGetByEmployeeIdIdGet', 'version', version)
            const localVarPath = `/api/v{version}/EmployeeProject/getByEmployeeId/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the list of EmployeeProjects by Project id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionEmployeeProjectGetByProjectIdIdGet: async (id: number, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVversionEmployeeProjectGetByProjectIdIdGet', 'id', id)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionEmployeeProjectGetByProjectIdIdGet', 'version', version)
            const localVarPath = `/api/v{version}/EmployeeProject/getByProjectId/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a EmployeeProject by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionEmployeeProjectIdDelete: async (id: number, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVversionEmployeeProjectIdDelete', 'id', id)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionEmployeeProjectIdDelete', 'version', version)
            const localVarPath = `/api/v{version}/EmployeeProject/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a EmployeeProject by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionEmployeeProjectIdGet: async (id: number, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVversionEmployeeProjectIdGet', 'id', id)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionEmployeeProjectIdGet', 'version', version)
            const localVarPath = `/api/v{version}/EmployeeProject/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a New EmployeeProject.
         * @param {string} version 
         * @param {CreateEmployeeProjectCommand} [createEmployeeProjectCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionEmployeeProjectPost: async (version: string, createEmployeeProjectCommand?: CreateEmployeeProjectCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionEmployeeProjectPost', 'version', version)
            const localVarPath = `/api/v{version}/EmployeeProject`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEmployeeProjectCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an existing EmployeeProject
         * @param {string} version 
         * @param {UpdateEmployeeProjectCommand} [updateEmployeeProjectCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionEmployeeProjectPut: async (version: string, updateEmployeeProjectCommand?: UpdateEmployeeProjectCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionEmployeeProjectPut', 'version', version)
            const localVarPath = `/api/v{version}/EmployeeProject`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEmployeeProjectCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the list of projects according to visibility rules
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionListProjectsGet: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionListProjectsGet', 'version', version)
            const localVarPath = `/api/v{version}/List/projects`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the list of projects type-ahead according to visibility rules
         * @param {string} version 
         * @param {string} [term] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionListProjectsTypeAheadGet: async (version: string, term?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionListProjectsTypeAheadGet', 'version', version)
            const localVarPath = `/api/v{version}/List/projectsTypeAhead`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Role ListOptionDTO
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionListRolesGet: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionListRolesGet', 'version', version)
            const localVarPath = `/api/v{version}/List/roles`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the list of all Projects
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionProjectAllGet: async (version: string, pageNum?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionProjectAllGet', 'version', version)
            const localVarPath = `/api/v{version}/Project/all`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a Project by Employee Id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionProjectGetByClientIdIdGet: async (id: number, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVversionProjectGetByClientIdIdGet', 'id', id)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionProjectGetByClientIdIdGet', 'version', version)
            const localVarPath = `/api/v{version}/Project/getByClientId/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a Project by Employee Id
         * @param {number} id 
         * @param {string} version 
         * @param {Date} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionProjectGetByEmployeeIdIdGet: async (id: number, version: string, endDate?: Date, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVversionProjectGetByEmployeeIdIdGet', 'id', id)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionProjectGetByEmployeeIdIdGet', 'version', version)
            const localVarPath = `/api/v{version}/Project/getByEmployeeId/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a project by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionProjectIdDelete: async (id: number, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVversionProjectIdDelete', 'id', id)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionProjectIdDelete', 'version', version)
            const localVarPath = `/api/v{version}/Project/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a Project by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionProjectIdGet: async (id: number, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVversionProjectIdGet', 'id', id)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionProjectIdGet', 'version', version)
            const localVarPath = `/api/v{version}/Project/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a New Project.
         * @param {string} version 
         * @param {CreateProjectCommand} [createProjectCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionProjectPost: async (version: string, createProjectCommand?: CreateProjectCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionProjectPost', 'version', version)
            const localVarPath = `/api/v{version}/Project`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProjectCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an existing Project
         * @param {string} version 
         * @param {UpdateProjectCommand} [updateProjectCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionProjectPut: async (version: string, updateProjectCommand?: UpdateProjectCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionProjectPut', 'version', version)
            const localVarPath = `/api/v{version}/Project`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProjectCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary searchs a Project
         * @param {string} version 
         * @param {string} [term] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {Array<string>} [f] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionProjectSearchGet: async (version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionProjectSearchGet', 'version', version)
            const localVarPath = `/api/v{version}/Project/search`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (f) {
                localVarQueryParameter['f'] = f;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all projects by typeahead
         * @param {string} text 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionProjectTypeaheadTextGet: async (text: string, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'text' is not null or undefined
            assertParamExists('apiVversionProjectTypeaheadTextGet', 'text', text)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionProjectTypeaheadTextGet', 'version', version)
            const localVarPath = `/api/v{version}/Project/typeahead/{text}`
                .replace(`{${"text"}}`, encodeURIComponent(String(text)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the list of all Roles
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionRoleAllGet: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionRoleAllGet', 'version', version)
            const localVarPath = `/api/v{version}/Role/all`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectApi - functional programming interface
 * @export
 */
export const ProjectApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets the list of EmployeeProjects by Project id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionEmployeeProjectGetByClientIdIdGet(id: number, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeProjectByProjectIdResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionEmployeeProjectGetByClientIdIdGet(id, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.apiVversionEmployeeProjectGetByClientIdIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets the list of EmployeeProjects by Employee ID.
         * @param {number} id The employee Id.
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionEmployeeProjectGetByEmployeeIdIdGet(id: number, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeProjectByEmployeeIdResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionEmployeeProjectGetByEmployeeIdIdGet(id, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.apiVversionEmployeeProjectGetByEmployeeIdIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets the list of EmployeeProjects by Project id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionEmployeeProjectGetByProjectIdIdGet(id: number, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeProjectByProjectIdResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionEmployeeProjectGetByProjectIdIdGet(id, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.apiVversionEmployeeProjectGetByProjectIdIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes a EmployeeProject by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionEmployeeProjectIdDelete(id: number, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionEmployeeProjectIdDelete(id, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.apiVversionEmployeeProjectIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets a EmployeeProject by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionEmployeeProjectIdGet(id: number, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeProjectByIdResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionEmployeeProjectIdGet(id, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.apiVversionEmployeeProjectIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Creates a New EmployeeProject.
         * @param {string} version 
         * @param {CreateEmployeeProjectCommand} [createEmployeeProjectCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionEmployeeProjectPost(version: string, createEmployeeProjectCommand?: CreateEmployeeProjectCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeProjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionEmployeeProjectPost(version, createEmployeeProjectCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.apiVversionEmployeeProjectPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates an existing EmployeeProject
         * @param {string} version 
         * @param {UpdateEmployeeProjectCommand} [updateEmployeeProjectCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionEmployeeProjectPut(version: string, updateEmployeeProjectCommand?: UpdateEmployeeProjectCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeProjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionEmployeeProjectPut(version, updateEmployeeProjectCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.apiVversionEmployeeProjectPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets the list of projects according to visibility rules
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionListProjectsGet(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListOptionDTOSearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionListProjectsGet(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.apiVversionListProjectsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets the list of projects type-ahead according to visibility rules
         * @param {string} version 
         * @param {string} [term] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionListProjectsTypeAheadGet(version: string, term?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListOptionDTOSearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionListProjectsTypeAheadGet(version, term, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.apiVversionListProjectsTypeAheadGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Role ListOptionDTO
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionListRolesGet(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListOptionDTOSearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionListRolesGet(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.apiVversionListRolesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets the list of all Projects
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionProjectAllGet(version: string, pageNum?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectSearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionProjectAllGet(version, pageNum, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.apiVversionProjectAllGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets a Project by Employee Id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionProjectGetByClientIdIdGet(id: number, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectSearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionProjectGetByClientIdIdGet(id, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.apiVversionProjectGetByClientIdIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets a Project by Employee Id
         * @param {number} id 
         * @param {string} version 
         * @param {Date} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionProjectGetByEmployeeIdIdGet(id: number, version: string, endDate?: Date, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectSearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionProjectGetByEmployeeIdIdGet(id, version, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.apiVversionProjectGetByEmployeeIdIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes a project by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionProjectIdDelete(id: number, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionProjectIdDelete(id, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.apiVversionProjectIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets a Project by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionProjectIdGet(id: number, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectByIdResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionProjectIdGet(id, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.apiVversionProjectIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Creates a New Project.
         * @param {string} version 
         * @param {CreateProjectCommand} [createProjectCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionProjectPost(version: string, createProjectCommand?: CreateProjectCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionProjectPost(version, createProjectCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.apiVversionProjectPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates an existing Project
         * @param {string} version 
         * @param {UpdateProjectCommand} [updateProjectCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionProjectPut(version: string, updateProjectCommand?: UpdateProjectCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionProjectPut(version, updateProjectCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.apiVversionProjectPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary searchs a Project
         * @param {string} version 
         * @param {string} [term] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {Array<string>} [f] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionProjectSearchGet(version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectSearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionProjectSearchGet(version, term, pageNum, pageSize, sort, order, f, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.apiVversionProjectSearchGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets all projects by typeahead
         * @param {string} text 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionProjectTypeaheadTextGet(text: string, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionProjectTypeaheadTextGet(text, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.apiVversionProjectTypeaheadTextGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets the list of all Roles
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionRoleAllGet(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleSearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionRoleAllGet(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.apiVversionRoleAllGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProjectApi - factory interface
 * @export
 */
export const ProjectApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets the list of EmployeeProjects by Project id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionEmployeeProjectGetByClientIdIdGet(id: number, version: string, options?: RawAxiosRequestConfig): AxiosPromise<EmployeeProjectByProjectIdResponseResponse> {
            return localVarFp.apiVversionEmployeeProjectGetByClientIdIdGet(id, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the list of EmployeeProjects by Employee ID.
         * @param {number} id The employee Id.
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionEmployeeProjectGetByEmployeeIdIdGet(id: number, version: string, options?: RawAxiosRequestConfig): AxiosPromise<EmployeeProjectByEmployeeIdResponseResponse> {
            return localVarFp.apiVversionEmployeeProjectGetByEmployeeIdIdGet(id, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the list of EmployeeProjects by Project id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionEmployeeProjectGetByProjectIdIdGet(id: number, version: string, options?: RawAxiosRequestConfig): AxiosPromise<EmployeeProjectByProjectIdResponseResponse> {
            return localVarFp.apiVversionEmployeeProjectGetByProjectIdIdGet(id, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a EmployeeProject by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionEmployeeProjectIdDelete(id: number, version: string, options?: RawAxiosRequestConfig): AxiosPromise<BooleanResponse> {
            return localVarFp.apiVversionEmployeeProjectIdDelete(id, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a EmployeeProject by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionEmployeeProjectIdGet(id: number, version: string, options?: RawAxiosRequestConfig): AxiosPromise<EmployeeProjectByIdResponseResponse> {
            return localVarFp.apiVversionEmployeeProjectIdGet(id, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a New EmployeeProject.
         * @param {string} version 
         * @param {CreateEmployeeProjectCommand} [createEmployeeProjectCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionEmployeeProjectPost(version: string, createEmployeeProjectCommand?: CreateEmployeeProjectCommand, options?: RawAxiosRequestConfig): AxiosPromise<EmployeeProjectResponse> {
            return localVarFp.apiVversionEmployeeProjectPost(version, createEmployeeProjectCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an existing EmployeeProject
         * @param {string} version 
         * @param {UpdateEmployeeProjectCommand} [updateEmployeeProjectCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionEmployeeProjectPut(version: string, updateEmployeeProjectCommand?: UpdateEmployeeProjectCommand, options?: RawAxiosRequestConfig): AxiosPromise<EmployeeProjectResponse> {
            return localVarFp.apiVversionEmployeeProjectPut(version, updateEmployeeProjectCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the list of projects according to visibility rules
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionListProjectsGet(version: string, options?: RawAxiosRequestConfig): AxiosPromise<ListOptionDTOSearchResponseResponse> {
            return localVarFp.apiVversionListProjectsGet(version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the list of projects type-ahead according to visibility rules
         * @param {string} version 
         * @param {string} [term] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionListProjectsTypeAheadGet(version: string, term?: string, options?: RawAxiosRequestConfig): AxiosPromise<ListOptionDTOSearchResponseResponse> {
            return localVarFp.apiVversionListProjectsTypeAheadGet(version, term, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Role ListOptionDTO
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionListRolesGet(version: string, options?: RawAxiosRequestConfig): AxiosPromise<ListOptionDTOSearchResponseResponse> {
            return localVarFp.apiVversionListRolesGet(version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the list of all Projects
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionProjectAllGet(version: string, pageNum?: number, pageSize?: number, options?: RawAxiosRequestConfig): AxiosPromise<ProjectSearchResponseResponse> {
            return localVarFp.apiVversionProjectAllGet(version, pageNum, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a Project by Employee Id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionProjectGetByClientIdIdGet(id: number, version: string, options?: RawAxiosRequestConfig): AxiosPromise<ProjectSearchResponseResponse> {
            return localVarFp.apiVversionProjectGetByClientIdIdGet(id, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a Project by Employee Id
         * @param {number} id 
         * @param {string} version 
         * @param {Date} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionProjectGetByEmployeeIdIdGet(id: number, version: string, endDate?: Date, options?: RawAxiosRequestConfig): AxiosPromise<ProjectSearchResponseResponse> {
            return localVarFp.apiVversionProjectGetByEmployeeIdIdGet(id, version, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a project by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionProjectIdDelete(id: number, version: string, options?: RawAxiosRequestConfig): AxiosPromise<BooleanResponse> {
            return localVarFp.apiVversionProjectIdDelete(id, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a Project by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionProjectIdGet(id: number, version: string, options?: RawAxiosRequestConfig): AxiosPromise<ProjectByIdResponseResponse> {
            return localVarFp.apiVversionProjectIdGet(id, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a New Project.
         * @param {string} version 
         * @param {CreateProjectCommand} [createProjectCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionProjectPost(version: string, createProjectCommand?: CreateProjectCommand, options?: RawAxiosRequestConfig): AxiosPromise<ProjectResponse> {
            return localVarFp.apiVversionProjectPost(version, createProjectCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an existing Project
         * @param {string} version 
         * @param {UpdateProjectCommand} [updateProjectCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionProjectPut(version: string, updateProjectCommand?: UpdateProjectCommand, options?: RawAxiosRequestConfig): AxiosPromise<ProjectResponse> {
            return localVarFp.apiVversionProjectPut(version, updateProjectCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary searchs a Project
         * @param {string} version 
         * @param {string} [term] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {Array<string>} [f] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionProjectSearchGet(version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<ProjectSearchResponseResponse> {
            return localVarFp.apiVversionProjectSearchGet(version, term, pageNum, pageSize, sort, order, f, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all projects by typeahead
         * @param {string} text 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionProjectTypeaheadTextGet(text: string, version: string, options?: RawAxiosRequestConfig): AxiosPromise<ProjectListResponse> {
            return localVarFp.apiVversionProjectTypeaheadTextGet(text, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the list of all Roles
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionRoleAllGet(version: string, options?: RawAxiosRequestConfig): AxiosPromise<RoleSearchResponseResponse> {
            return localVarFp.apiVversionRoleAllGet(version, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectApi - object-oriented interface
 * @export
 * @class ProjectApi
 * @extends {BaseAPI}
 */
export class ProjectApi extends BaseAPI {
    /**
     * 
     * @summary Gets the list of EmployeeProjects by Project id
     * @param {number} id 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public apiVversionEmployeeProjectGetByClientIdIdGet(id: number, version: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).apiVversionEmployeeProjectGetByClientIdIdGet(id, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the list of EmployeeProjects by Employee ID.
     * @param {number} id The employee Id.
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public apiVversionEmployeeProjectGetByEmployeeIdIdGet(id: number, version: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).apiVversionEmployeeProjectGetByEmployeeIdIdGet(id, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the list of EmployeeProjects by Project id
     * @param {number} id 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public apiVversionEmployeeProjectGetByProjectIdIdGet(id: number, version: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).apiVversionEmployeeProjectGetByProjectIdIdGet(id, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a EmployeeProject by id
     * @param {number} id 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public apiVversionEmployeeProjectIdDelete(id: number, version: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).apiVversionEmployeeProjectIdDelete(id, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a EmployeeProject by id
     * @param {number} id 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public apiVversionEmployeeProjectIdGet(id: number, version: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).apiVversionEmployeeProjectIdGet(id, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a New EmployeeProject.
     * @param {string} version 
     * @param {CreateEmployeeProjectCommand} [createEmployeeProjectCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public apiVversionEmployeeProjectPost(version: string, createEmployeeProjectCommand?: CreateEmployeeProjectCommand, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).apiVversionEmployeeProjectPost(version, createEmployeeProjectCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates an existing EmployeeProject
     * @param {string} version 
     * @param {UpdateEmployeeProjectCommand} [updateEmployeeProjectCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public apiVversionEmployeeProjectPut(version: string, updateEmployeeProjectCommand?: UpdateEmployeeProjectCommand, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).apiVversionEmployeeProjectPut(version, updateEmployeeProjectCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the list of projects according to visibility rules
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public apiVversionListProjectsGet(version: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).apiVversionListProjectsGet(version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the list of projects type-ahead according to visibility rules
     * @param {string} version 
     * @param {string} [term] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public apiVversionListProjectsTypeAheadGet(version: string, term?: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).apiVversionListProjectsTypeAheadGet(version, term, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Role ListOptionDTO
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public apiVversionListRolesGet(version: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).apiVversionListRolesGet(version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the list of all Projects
     * @param {string} version 
     * @param {number} [pageNum] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public apiVversionProjectAllGet(version: string, pageNum?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).apiVversionProjectAllGet(version, pageNum, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a Project by Employee Id
     * @param {number} id 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public apiVversionProjectGetByClientIdIdGet(id: number, version: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).apiVversionProjectGetByClientIdIdGet(id, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a Project by Employee Id
     * @param {number} id 
     * @param {string} version 
     * @param {Date} [endDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public apiVversionProjectGetByEmployeeIdIdGet(id: number, version: string, endDate?: Date, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).apiVversionProjectGetByEmployeeIdIdGet(id, version, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a project by id
     * @param {number} id 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public apiVversionProjectIdDelete(id: number, version: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).apiVversionProjectIdDelete(id, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a Project by id
     * @param {number} id 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public apiVversionProjectIdGet(id: number, version: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).apiVversionProjectIdGet(id, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a New Project.
     * @param {string} version 
     * @param {CreateProjectCommand} [createProjectCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public apiVversionProjectPost(version: string, createProjectCommand?: CreateProjectCommand, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).apiVversionProjectPost(version, createProjectCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates an existing Project
     * @param {string} version 
     * @param {UpdateProjectCommand} [updateProjectCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public apiVversionProjectPut(version: string, updateProjectCommand?: UpdateProjectCommand, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).apiVversionProjectPut(version, updateProjectCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary searchs a Project
     * @param {string} version 
     * @param {string} [term] 
     * @param {number} [pageNum] 
     * @param {number} [pageSize] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {Array<string>} [f] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public apiVversionProjectSearchGet(version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).apiVversionProjectSearchGet(version, term, pageNum, pageSize, sort, order, f, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all projects by typeahead
     * @param {string} text 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public apiVversionProjectTypeaheadTextGet(text: string, version: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).apiVversionProjectTypeaheadTextGet(text, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the list of all Roles
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public apiVversionRoleAllGet(version: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).apiVversionRoleAllGet(version, options).then((request) => request(this.axios, this.basePath));
    }
}


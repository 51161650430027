import React, { useState } from 'react';
import { Form, Row, Col, Label, Input } from 'reactstrap';
import '../../scss/Roles/RoleForm.scss';
import intl from 'components/i18n/ReactIntlWrapper';
import StatusSelect from '../StatusSelect';
import ScopeMultiSelect from './ScopeMultiSelect';
import AssignedUsers from './AssignedUsers';

const RoleForm = ({ errors, input, handleInputChange, handleStatusChange, status, disabled, scopes, idNumber, users, handleScopeChange, selectedScopes }) => {

    const [searchedUser, setSearchedUser] = useState<string>('');

    return (
        idNumber ?
        <Form className='mb-3'>
            <Row className='mb-4' >
                <Col md={5}>
                    <div className='d-inline-flex col-12 flex-column'>
                        <Label for="roleName" className={`${errors.name ? 'text-danger' : ''}`}>
                            {intl.get('roleForm.name')} {errors.name && (<span className='text-danger'>{errors.name}</span>)}
                        </Label>
                        <Input
                            id="roleName"
                            name="name"
                            placeholder={intl.get('roleForm.placeholder.name')}
                            value={input?.name}
                            onChange={handleInputChange}
                            disabled={disabled}
                        />
                    </div>
                </Col>
                <Col md={5}>
                    <div className='d-inline-flex col-12 flex-column'>
                        <Label for="roleDescription">
                            {intl.get('roleForm.description')}
                        </Label>
                        <Input
                            id="roleDescription"
                            name="description"
                            placeholder={intl.get('roleForm.placeholder.description')}
                            value={input?.description}
                            onChange={handleInputChange}
                            disabled={disabled}
                        />
                    </div>
                </Col>
                <Col md={2}>
                    <div className='d-inline-flex col-12 flex-column'>
                        <Label for="roleStatus">
                            {intl.get('roleForm.status')}
                        </Label>
                        <StatusSelect
                            handleStatusChange={handleStatusChange}
                            selectedStatus={input.idActive}
                            status={status}
                            disabled={disabled}
                            defaultValue={false}
                            isClearable={false}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Row className='mb-4'>
                        <span className='fw-bold mb-1'>{intl.get('roleForm.assignment.title')}</span>
                        <span>{intl.get('roleForm.assignment.message')}</span>
                    </Row>
                    <ScopeMultiSelect
                        handleScopeChange={handleScopeChange}
                        selectedScopes={selectedScopes}
                        scopes={scopes}
                        disabled={disabled}
                    />
                </Col>
                <Col>
                    <Row className='mb-4'>
                        <span className='fw-bold mb-1'>{intl.get('roleForm.userAssignment.title')}</span>
                        <span>{intl.get('roleForm.userAssignment.message')}</span>
                    </Row>
                    <AssignedUsers users={users} idNumber={idNumber} searchedUser={searchedUser} setSearchedUser={setSearchedUser}/>
                </Col>
            </Row>
        </Form> :
        <Form className='mb-3'>
            <Row>
                <div className='d-inline-flex col-12 flex-column'>
                    <Label for="roleName" className={`${errors.name ? 'text-danger' : ''}`}>
                        {intl.get('roleForm.name')} {errors.name && (<span className='text-danger'>{errors.name}</span>)}
                    </Label>
                    <Input
                        id="roleName"
                        name="name"
                        placeholder={intl.get('roleForm.placeholder.name')}
                        value={input?.name}
                        onChange={handleInputChange}
                        disabled={disabled}
                    />
                </div>
            </Row>
            <Row>
                <div className='d-inline-flex col-12 flex-column my-3'>
                    <Label for="roleDescription">
                        {intl.get('roleForm.description')}
                    </Label>
                    <Input
                        id="roleDescription"
                        name="description"
                        placeholder={intl.get('roleForm.placeholder.description')}
                        value={input?.description}
                        onChange={handleInputChange}
                        disabled={disabled}
                    />
                </div>
            </Row>
            <Row className='mb-5'>
                <div className='d-inline-flex col-12 flex-column'>
                    <Label for="roleStatus">
                        {intl.get('roleForm.status')}
                    </Label>
                    <StatusSelect
                        handleStatusChange={handleStatusChange}
                        selectedStatus={input.idActive}
                        status={status}
                        disabled={disabled}
                        defaultValue={false}
                        isClearable={false}
                    />
                </div>
            </Row>
            <Row className='mb-3'>
                <span className='fw-bold mb-1'>{intl.get('roleForm.assignment.title')}</span>
                <span>{intl.get('roleForm.assignment.message')}</span>
            </Row>
            <ScopeMultiSelect
                handleScopeChange={handleScopeChange}
                selectedScopes={selectedScopes}
                scopes={scopes}
                disabled={disabled}
            />
        </Form>
    )
}

export default RoleForm;
import React from 'react';
import ProjectRow from './ProjectRow';
import { ProjectsInput, SearchResult } from './Projects';
import { BiSortZA, BiSortAZ } from 'react-icons/bi';
import intl from 'components/i18n/ReactIntlWrapper';

type ProjectsListProps = {
    searchResult: SearchResult,
    input: ProjectsInput,
    setInput: Function,
}

const ProjectsList = ({ searchResult, input, setInput }: ProjectsListProps) => {

    const handleSortClick = (sort) => {
        setInput({ ...input, orderValue: (input.sortValue == sort ? (input.orderValue == 'desc' ? 'asc' : 'desc') : 'asc'), sortValue: sort, currentPage: 1 });
    }

    return (
        <div className="table-responsive my-2">
            <table className='table table-hover'>
                <thead>
                    <tr>
                        <th role='button' onClick={() => { handleSortClick('Name') }} scope="col">{intl.get('projectsList.table.thead.name')} {input.sortValue == 'Name' ? (input.orderValue == 'desc' ? <BiSortZA></BiSortZA> : <BiSortAZ></BiSortAZ>) : ''}</th>
                        <th role='button' onClick={() => { handleSortClick('Client') }} scope="col">{intl.get('projectsList.table.thead.client')} {input.sortValue == 'Client' ? (input.orderValue == 'desc' ? <BiSortZA></BiSortZA> : <BiSortAZ></BiSortAZ>) : ''}</th>
                        <th scope="col" className='text-center'>{intl.get('roleList.table.thead.actions')}</th>
                    </tr>
                </thead>
                {
                    searchResult.projects.length === 0 && input.queryTerm !== null ?
                    <tbody className='aling-center mb-0'>
                        <tr>
                            <td colSpan={6} className=' mb-0' style={{cursor: "default"}}>{intl.get('projectsList.notFound')}</td>
                        </tr>
                    </tbody> :
                    <tbody>
                        {
                            searchResult.projects && searchResult.projects.map((p,i) => (
                                <ProjectRow
                                    key={p.id}
                                    id={p.id}
                                    name={p.name}
                                    client={p.client}
                                    className={i%2>0?'':'odd'}

                                />
                            ))
                        }
                    </tbody>
                }
            </table>
        </div>
    )
}

export default ProjectsList;

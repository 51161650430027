import { Box, Button } from '@mui/material'
import React from 'react'
import { FaSearch } from 'react-icons/fa'
import '../../scss/SearchButton.scss'
import intl from 'components/i18n/ReactIntlWrapper';

export default function SearchButton({clickEvent}) {
  return (
        <Button 
            fullWidth
            variant="outlined" 
            color="primary" 
            onClick={() => clickEvent()}
            className="search-button"
            >   
            <Box sx={{ display: 'flex', gap: 1 , alignItems:"center"}}>
                <FaSearch className='mb-1'/>
                {intl.get('jiraIssueSearch.search.button')}
            </Box>
        </Button> 
     )
}

import intl, { init } from 'react-intl-universal'

const ReactIntlWrapper = {
    init: (options: any) => {
        init(options);
    },
    get: (key: string, variables?: any) => {
        const translatedMessage = key ? intl.get(key, variables) : "";
        if(!key){
            console.error(`Translation key is empty`);
            return "Key is not defined";
        }
        
        //if(key && !translatedMessage) console.error(`Translation not found for key: ${key}`);
        return translatedMessage ? translatedMessage : `${key}`;
    },
    has: (key: string) => {
        const translatedMessage = key ? intl.get(key) : "";
        return !!translatedMessage;
    }
}

export default ReactIntlWrapper;
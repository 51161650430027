import React, { useEffect } from "react";
import { BrowserRouter } from "react-router";
import ApiProvider from './api/ApiProvider';
import { PageLayout } from './components/PageLayout';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import './scss/global/ConfirmAlert.scss';
import AuthProvider from './components/authentication/context/authContext'
import { ToastMessageProvider } from "./components/ToastMessages/ToastMessageProvider";
import { ToastMessageQueue } from "./components/ToastMessages/ToastMessageQueue";
import BerpRoutes from './components/common/routes/BerpRoutes';
import LocaleProvider from './components/i18n/LocaleProvider';
import theme from './theme';
import { ThemeProvider } from "@mui/material";
import LayoutProvider from "./components/Layout/LayoutProvider";
import ErrorBoundary from "./ErrorBoundary";

export default function App() {
  useEffect(() => {
    const splashScreen = document.querySelector('.splash-screen');
    if(!splashScreen) return;
    splashScreen.classList.add('fadeOut');
    setTimeout(() => {
      splashScreen.remove();
  }, 2000);

  }, []);

  return (
    <ThemeProvider theme={theme}>
    <BrowserRouter>
    <LayoutProvider>
    <ToastMessageProvider>
    <ApiProvider>
    <AuthProvider>
    <LocaleProvider>
    <PageLayout>
        <ToastMessageQueue autoDelete={true} autoDeleteTime={3000}></ToastMessageQueue>
        <ErrorBoundary>
          <BerpRoutes />
        </ErrorBoundary>
    </PageLayout>
    </LocaleProvider>
    </AuthProvider>
    </ApiProvider>
    </ToastMessageProvider> 
    </LayoutProvider>
    </BrowserRouter>
    </ThemeProvider>
  );
}
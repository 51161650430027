import { createTheme} from "@mui/material";


const DEFAULT_BORDER_RADIUS = 8;

const DIVIDER_COLOR_1 = "#bebebe";

const BACKGROUND_HEADER_LIGHT = "#f5f5f5";
const BACKGROUND_CONTENT_LIGHT = "#fafafa";

const DEFAULT_INPUT_FIELD_HEIGHT = "40px";
const DEFAULT_INPUT_LABEL_MARGINTOP = '-0.4rem';
const DEFAULt_TEXTFIELD_PLACEHOLDER_PADDING_CENTER = "0.4rem 0px";

const NONE = 'none'

export default createTheme({   
    palette: {
        divider: DIVIDER_COLOR_1,
        primary: {
            main: "#005baf",
        },
    },
    typography: {
        fontFamily: `system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif`,
        fontSize: 16,
        fontWeightRegular: 400,
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
    components: {
        MuiDialog: {
            styleOverrides: {
                paper: {
                    minWidth: 400
                }
            },
        },
        MuiSelect: {
            defaultProps: {
                variant: "outlined",
                size: "small",
            },
            styleOverrides:{
                root:{
                    height: DEFAULT_INPUT_FIELD_HEIGHT,  
                }
            }
        },
        MuiButton: {
            defaultProps: {
                variant: "contained",
                color: "primary",
                size: "small",
                disableElevation: true,
            },
            styleOverrides:{
                root:{
                    textTransform: NONE,
                    height: DEFAULT_INPUT_FIELD_HEIGHT,
                    boxShadow: NONE,
                }
            }
        },
        MuiTextField: {
            defaultProps: {
                variant: "outlined",
                size: "small",
            },
            styleOverrides: {
                root: {
                    maxHeight: 40,
                    height:40,
                    '.borders-only-on-hover&:not(:hover):not(:focus-within) fieldset': {
                        border: 'none',
                    },
                    '.hide-icons-on-hover&:not(:hover):not(:focus-within) svg': {
                        visibility: 'hidden',
                    },
                    '.hide-icons-on-hover& .MuiInputBase-root.MuiOutlinedInput-root': {
                        paddingRight: "0!important",
                    },
                    '& input': {
                        textOverflow: 'ellipsis',
                    },
                    '& .MuiInputBase-input::placeholder': {
                        textTransform: 'capitalize', 
                        padding: DEFAULt_TEXTFIELD_PLACEHOLDER_PADDING_CENTER,
                    },
                    "& .MuiInputLabel-root": {
                        padding: DEFAULt_TEXTFIELD_PLACEHOLDER_PADDING_CENTER,
                        color: "inherit", 
                    "&.Mui-focused": {
                        color: "inherit", 
                    }},
                },           
            },
        },

        MuiTypography: {
            defaultProps: {
                fontWeight:500
            },
            variants: [
                {
                    props: { variant: "h1" },
                    style: {
                        fontSize: "2.5rem",
                    },
                },
                {
                    props: { variant: "h2" },
                    style: {
                        fontSize: "2rem",
                    },
                },
                {
                    props: { variant: "h3" },
                    style: {
                        fontSize: "1.17rem",
                    },
                },
                {
                    props: { variant: "h4" },
                    style: {
                        fontSize: "1rem",
                    },
                },
                {
                    props: { variant: "h5" },
                    style: {
                        fontSize: "0.83rem",
                    },
                },
                {
                    props: { variant: "h6" },
                    style: {
                        fontSize: "0.67rem",
                    },
                },
            ]
        },
        MuiAutocomplete: {
            defaultProps: {
                size: "small",
            }
        },
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    ":not(.MuiPaper-root)": {
                        borderBottom: `1px solid ${DIVIDER_COLOR_1}`,
                    },
                    "& .table": {
                        marginBottom: 0,
                    }
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: 12,
                    borderBottom: `1px solid ${DIVIDER_COLOR_1}`,
                },
                head: {
                    fontWeight: "bold",
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    "&:last-child td": {
                        borderBottom: "none",
                    },
                },
            },
        },
        MuiInput: {
            defaultProps: {
                fullWidth: true,
                disableUnderline: true,
                sx : { border: 1, paddingLeft: "10px", borderRadius: "5px", height: "40px", borderColor: "#CCC" }
            }
        },
        MuiPaper: {
            defaultProps: {
                variant: "outlined",
            },
            styleOverrides: {
                root: {
                    borderRadius: DEFAULT_BORDER_RADIUS
                },
            },
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    overflow: "hidden",
                    '&:first-of-type': {
                        borderTopLeftRadius: DEFAULT_BORDER_RADIUS,
                        borderTopRightRadius: DEFAULT_BORDER_RADIUS
                    },
                    '&:last-of-type': {
                        borderBottomLeftRadius: DEFAULT_BORDER_RADIUS,
                        borderBottomRightRadius: DEFAULT_BORDER_RADIUS
                    },
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    backgroundColor: BACKGROUND_HEADER_LIGHT,
                },
            },
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    backgroundColor: BACKGROUND_CONTENT_LIGHT,
                },
            },
        },
        MuiSelect: {
            styleOverrides:{
                root:{
                    height: DEFAULT_INPUT_FIELD_HEIGHT,  
                }
            }
        },
        MuiInputBase: {
            defaultProps: {
                size: "small",
            },
        },
        MuiButton:{
            styleOverrides:{
                root:{
                    textTransform: NONE,
                    height: DEFAULT_INPUT_FIELD_HEIGHT,
                    boxShadow: NONE,
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
              root:{
                '& .MuiInputBase-input::placeholder': {
                  textTransform: 'capitalize', 
                },
              },
            },
        },
    },
});
import React from "react";
import JobRow from "./JobRow";
import { Job, JobType } from "axiosApi/models";
import intl from 'components/i18n/ReactIntlWrapper';

export type JobListProps = {
    jobsByProjectPaginated: Job[],
    jobTypes: JobType[],
    isJiraReady: boolean,
    handleJobEdit: Function,
    handleTaskJob: Function,
    handleJobDelete: Function,
    isJobEditMode: Function,
    disabled: boolean,
    input: any
}
const JobList = ({ jobsByProjectPaginated, jobTypes, isJiraReady, handleJobEdit, handleTaskJob, handleJobDelete, isJobEditMode, disabled, input }: JobListProps) => {
    return (
        <div className="container">
            <div className="container-overflow">
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col">{intl.get('jobList.table.thead.job')}</th>
                            <th style={{ textAlign: 'center' }} scope="col">{intl.get('jobList.table.thead.billable')}</th>
                            <th scope="col">{intl.get('jobList.table.thead.jobType')}</th>
                            <th scope="col" style={{ textAlign: 'center' }}>{intl.get('jobList.table.thead.actions')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            jobsByProjectPaginated && jobsByProjectPaginated.map((job,i) => (
                                <JobRow
                                    key={job.id}
                                    idJob={job.id}
                                    description={job.description}
                                    billable={job.billable}
                                    jobType={jobTypes ? jobTypes.find((jobType) => jobType.id == job.idJobType) : 'Loading..'}
                                    isJiraReady={isJiraReady}
                                    handleJobEdit={handleJobEdit}
                                    handleTaskJob={handleTaskJob}
                                    handleJobDelete={handleJobDelete}
                                    isJobEditMode={isJobEditMode}
                                    disabled={disabled}
                                    input={input}
                                    className={i%2>0?'':'odd'}
                                />
                            ))      
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default JobList;
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { IoMdArrowRoundBack } from "react-icons/io";

import {
    ReactFlow,
    ReactFlowProvider,
    Background,
    useNodesState,
    useEdgesState,
    MarkerType,
    Controls,
    useReactFlow,
    MiniMap,
    Position,
    Panel,
    Connection,
} from '@xyflow/react'
import '@xyflow/react/dist/style.css'
import { PiFlowArrowBold } from "react-icons/pi";
import FloatingEdge, { FloatingEdgeType } from './CustomLayout/FloatingEdge'
import FloatingConnectionLine from './FloatingFunction/FloatingConnectionLine'
import '../../scss/Workflow/WorkflowEditor.scss'
import { useNavigate, useParams } from 'react-router'
import Api from 'axiosApi/api'
import { CreateCommand, UpdateCommand, WorkflowDetails, WorkflowDetailsResponse, WorkflowStateDTO } from 'axiosApi/models'
import { useApi } from '../../api/ApiProvider'
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider'
import intl from 'components/i18n/ReactIntlWrapper'
import { Box, Button, Chip, Divider, FormControl, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Paper, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import EditableNode, { EditableNodeType } from './CustomLayout/EditableNode'
import EditableNodeDrawer from './CustomLayout/EditableNodeDrawer'
import FloatingEdgeDrawer from './CustomLayout/FloatingEdgeDrawer'
import { FaEdit, FaTrash } from 'react-icons/fa'
import { TbArrowMoveRight } from "react-icons/tb";
import { IconPickerItem } from 'react-icons-picker'
import { TiFlowSwitch } from "react-icons/ti";
import AddStateModal from './AddStateModal'
import { TbDragDrop } from "react-icons/tb";
import { WorkflowTransitionDTO } from 'axiosApi/models/workflow-transition-dto';
import { AxiosResponse } from 'axios';
import { confirmAlert } from "react-confirm-alert";
import { handleAPIError } from 'common/errorHandler';
import { PermissionsGate } from 'components/PermissionsGate';
import { SCOPES } from 'common/permissions';
import { useLayout } from 'components/Layout/LayoutProvider';

const initialNodes: EditableNodeType[] = []
const initialEdges: FloatingEdgeType[] = []

const edgeTypes = {
    floating: FloatingEdge as React.FC<any>,
}

const defaultViewport = { x: 10, y: 15, zoom: 3 }

const WorkflowEditor: React.FC = () => {
    const { id } = useParams()
    const api: Api = useApi()
    const toast = useToastMessageQueue()
    const [workflowData, setWorkflowData] = useState<UpdateCommand>(null);
    
    
    const [nodes, setNodes, onNodesChange] = useNodesState<EditableNodeType>(initialNodes)
    const [edges, setEdges, onEdgesChange] = useEdgesState<FloatingEdgeType>(initialEdges)

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [addTransitionMode, setAddTransitionMode] = useState<boolean>(false);
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState(null);
    const [hasChanges, setHasChanges] = useState(false);
    const { fitView } = useReactFlow();

    const navigate = useNavigate();

    const createWorkflow = (workflowData: CreateCommand) => {
        const dataReq = clearIdsOfNewStatesAndTransitions(workflowData);
        setLoading(true);
        return api.workflowApi.apiVversionWorkflowPost('1', dataReq, {}).then((response) => {
            if(response.status === 200){
                const { data } = response.data;
                const workflowData = loadWorkflowData(data);
                //handleLoadJson(data)
                handleLoadWorkflowData(workflowData);
                setHasChanges(false);
                toast.success({ body: intl.get('workflowEditor.messages.created') });
                navigate(`/workflow/${data.id}`,{
                    replace: true
                })
                return response;
            }else{
                throw new Error('Error saving workflow')
            }
        }).catch((error) => {
            handleAPIError(error, toast, errors);
            setErrors({ ...errors });
        }).finally(() => {
            setLoading(false)
        })
    }

    const saveWorkflow = (workflowData: UpdateCommand) => {
        const dataReq = clearIdsOfNewStatesAndTransitions(workflowData);
        setLoading(true);
        return api.workflowApi.apiVversionWorkflowPut('1', dataReq, {}).then((response) => {
            if(response.status === 200){
                const { data } = response.data;
                const workflowData = loadWorkflowData(data);
                
                handleLoadWorkflowData(workflowData);
                setHasChanges(false);
                toast.success({ body: intl.get('workflowEditor.messages.updated') });
                
                return response;
            }else{
                throw new Error('Error saving workflow')
            }
        }).catch((error) => {
            handleAPIError(error, toast, errors);
            setErrors({ ...errors });
        }).finally(() => {
            setLoading(false)
        })
    }

    const validateForm = () => {
        //validate workflow name
        if(!workflowData.name){
            toast.warning({ header: intl.get('error'), body: intl.get('workflow.name.required') });
            return false;
        }
        return true;
    }

    const saveAction = (actionData: any) => {
        if(!validateForm()){
            return;
        }
        if(actionData?.id){
            saveWorkflow(actionData);
        }else{
            createWorkflow(actionData);
        }
    }

    const saveWorkflowState = (workflowData: UpdateCommand) => {
        setWorkflowData(workflowData);
        setHasChanges(true);
        return new Promise((resolve, reject) => {
            resolve('ok');
        })
    }

    const fetchWorkflowDetail = async (idNumber: number) => {
        setLoading(true)
        api.workflowApi
            .apiVversionWorkflowIdGet(idNumber, '1', {})
            .then((response: AxiosResponse<WorkflowDetailsResponse, any>) => {
                if (response.data) {
                    const { data } = response.data;
                    const workflowData = loadWorkflowData(data);
                    
                    handleLoadWorkflowData(workflowData)
                }
            })
            .catch((error)=>{
                handleAPIError(error, toast, errors);    
                setErrors({ ...errors });            
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        if (id != 'new') {
            fetchWorkflowDetail(parseInt(id))
        }
        else {
            handleLoadWorkflowData(initializeWorkflow());
        }
    }, [id])

    const onConnect = useCallback(
        (params: Connection) =>
          setEdgeSelected(params),
        []
    )

    const initializeWorkflow = (): UpdateCommand => {
        const workflowData: UpdateCommand = {
            name: "",
            id: 0,
            idModule: 1,
            states: [],
            transitions: []
        }

        return workflowData;
    }

    const loadWorkflowData = (workflowDetail: WorkflowDetails): UpdateCommand => {
        const workflowData: UpdateCommand = {
            name: workflowDetail.name,
            id: workflowDetail.id,
            idModule: workflowDetail.idModule,
            states: workflowDetail.states.map((state) => ({
                id: state.id,
                isDeleted: state.isDeleted,
                name: state.name,
                idWorkflow: state.idWorkflow,
                complete: state.complete,
                extraAttributes: state.extraAttributes,
                labelKey: state.labelKey,
                action: state.action,
                reactFlowId: state.reactFlowId,
                viewScopes: state.viewScopes,
                editScopes: state.editScopes,
                deleteScopes: state.deleteScopes,
                initial: state.initial,
                color: state.displayColor,
                editable: state.editable,
                deletable: state.deletable,
                viewable: state.viewable,
                editableByOwner: state.editableByOwner,
                deletableByOwner: state.deletableByOwner,
                viewableByOwner: state.viewableByOwner,
                displayColor: state.displayColor,
            })),
            transitions: workflowDetail.transitions.map((transition) => ({
                id: transition.id,
                isDeleted: transition.isDeleted,
                idWorkflowStateFrom: transition.idWorkflowStateFrom,
                idWorkflowStateTo: transition.idWorkflowStateTo,
                actionName: transition.actionName,
                labelKey: transition.labelKey,
                displayOrder: transition.displayOrder,
                displayColor: transition.displayColor,
                icon: transition.icon,
                allowComments: transition.allowComments,
                extraAttributes: transition.extraAttributes,
                idScope: transition.idScope,
                reactFlowId: transition.id.toString(),
                stateFromReactFlowId: transition.idWorkflowStateFrom? transition.idWorkflowStateFrom.toString() : null,
                stateToReactFlowId: transition.idWorkflowStateTo? transition.idWorkflowStateTo.toString() : null,
            }))
        }

        return workflowData;
    }

    const handleLoadWorkflowData = (workflowData: UpdateCommand) => {
        setWorkflowData(workflowData);
        const center = { x: 0, y: 0 };
        const gap = {
            x: 300,
            y: 300,
        }

        const loadedNodes: EditableNodeType[] = workflowData.states.map((state) => {
            const degrees = state.id * (360 / (workflowData.states.length-1));
            const radians = degrees * (Math.PI / 180);
            const extraAttributes = JSON.parse(state.extraAttributes ?? '{}')
            const x = extraAttributes?.position?.x || gap.x * Math.cos(radians) + center.x;
            const y = extraAttributes?.position?.y || gap.y * Math.sin(radians) + center.y;

            return ({
                id: state.id.toString(),
                type: 'editableNode',
                sourcePosition: Position.Right,
                targetPosition: Position.Left,
                data: {
                    internalId: `${state.id}`,
                    name: state.name,
                    labelKey: state.labelKey,
                    editScopes: state.editScopes,
                    viewScopes: state.viewScopes,
                    deleteScopes: state.deleteScopes,
                    initial: state.initial,
                    final: state.complete,
                    transitionEditMode: false,
                    color: state.displayColor,
                    extraAttributes: {
                        ...extraAttributes,
                    },
                },
                position: {
                    x: x,
                    y: y,
                },
            })
        })

        const loadedEdges: FloatingEdgeType[] = workflowData.transitions.map((transition) => {
            const extraAttributes = JSON.parse(transition.extraAttributes ?? '{}')
            return {
                id: transition.id.toString(),
                source: transition.stateFromReactFlowId,
                target: transition.stateToReactFlowId,
                type: 'floating',
                markerEnd: {
                    type: MarkerType.ArrowClosed,
                    width: 30,
                    height: 30,
                    color: '#black',
                },
                data: {
                    actionName: transition.actionName,
                    labelKey: transition.labelKey,
                    internalId: transition.id.toString(),
                    scope: transition.idScope,
                    AllowComments: transition.allowComments,
                    Icon: transition.icon,
                    DisplayColor: transition.displayColor,
                    extraAttributes: {
                        ...extraAttributes,
                    }
                },
            }
        })

        //TODO

        pushVirtualInitialNode(loadedNodes, loadedEdges);
        pushVirtualFinalNode(loadedNodes, loadedEdges);

        setNodes(loadedNodes);
        setEdges(loadedEdges);
        fitView();
    }

    const nodeTypes = {
        editableNode: EditableNode as React.FC<any>,
    }

    const [nodeSelected, setNodeSelected] = useState<EditableNodeType>(null);
    const [edgeSelected, setEdgeSelected] = useState<FloatingEdgeType | Connection>(null);

    const [newStatesUuid, setNewStatesUuid] = useState<string[]>([]);
    const [newTransitionsUuid, setNewTransitionsUuid] = useState<string[]>([]);

    const isNewTransition = (id: string) => newTransitionsUuid.includes(id);
    const isNewState = (id: string) => newStatesUuid.includes(id);

    const clearIdsOfNewStatesAndTransitions = (data: any) => {
        const newData = { ...data };
        newData.states.forEach((state) => {
            if (isNewState(state.reactFlowId)) {
                state.id = 0;
            }
        })
        newData.transitions.forEach((transition) => {
            if (isNewTransition(transition.reactFlowId)) {
                transition.id = 0;
            }
        })

        return newData;

    }

    const findNode = useCallback((id: string) => nodes.find((node) => node.id === id), [nodes]);
    const pushVirtualInitialNode = (nodes: EditableNodeType[], edges: FloatingEdgeType[]) => {
        const initialNode = nodes.find((node) => node.data.initial);
        if (!initialNode) {
            return
        }
        const initialVirtualNode : EditableNodeType = {
            id: "initial-node",
            parentId: initialNode.id,
            type: 'editableNode',
            data: {
                internalId: "initial-node",
                name: 'Initial',
                labelKey: 'Initial',
                editScopes: "",
                viewScopes: "",
                deleteScopes: "",
                initial: true,
                final: false,
                transitionEditMode: false,
                color: null,
                extraAttributes: {
                    virtualInitialNode: true
                },
            },
            position: {
                x: 50,
                y: -100,
            },
            sourcePosition: Position.Right,
            targetPosition: Position.Left,
        }
        const initialVirtualEdge : FloatingEdgeType = {
            id: "initial-edge",
            source: "initial-node",
            target: initialNode.id,
            selectable: false,
            type: 'floating',
            data: {
                actionName: 'Initial',
                labelKey: 'Initial',
                Icon: null,
                AllowComments: false,
                DisplayColor: null,
                extraAttributes: {
                    virtualInitialEdge: true
                },
                scope: null,
            },
        }
        nodes.push(initialVirtualNode);
        edges.push(initialVirtualEdge);
    }
    const pushVirtualFinalNode = (nodes: EditableNodeType[], edges: FloatingEdgeType[]) => {
        const finalNode = nodes.find((node) => node.data.final);
        if (!finalNode) {
            return
        }
        const finalVirtualNode : EditableNodeType = {
            id: "final-node",
            parentId: finalNode.id,
            type: 'editableNode',
            data: {
                internalId: "final-node",
                name: 'Final',
                labelKey: 'Final',
                editScopes: "",
                viewScopes: "",
                deleteScopes: "",
                initial: false,
                final: true,
                transitionEditMode: false,
                color: null,
                extraAttributes: {
                    virtualFinalNode: true
                },
            },
            position: {
                x: 50,
                y: 100,
            },
            sourcePosition: Position.Right,
            targetPosition: Position.Left,
        }
        const finalVirtualEdge : FloatingEdgeType = {
            id: "final-edge",
            source: finalNode.id,
            target: "final-node",
            selectable: false,
            type: 'floating',
            data: {
                actionName: 'Final',
                labelKey: 'Final',
                Icon: null,
                AllowComments: false,
                DisplayColor: null,
                extraAttributes: {
                    virtualFinalEdge: true
                },
                scope: null,
            },
        }
        nodes.push(finalVirtualNode);
        edges.push(finalVirtualEdge);
    }

    const createRandomId = () : string =>  {
        let id : number = Math.floor(Math.random() * 1000000);
        //check if exist in node
        if(nodes.find((node) => node.id === id.toString())){
            return createRandomId();
        }
        //check if exist in edge
        if(edges.find((edge) => edge.id === id.toString())){
            return createRandomId();
        }
        return id.toString();
    }

    const addState = (stateName: string) => {
        if (stateName) {
            const newState: EditableNodeType = {
                id: createRandomId(),
                type: 'editableNode',
                data: {
                    internalId: null,
                    name: stateName,
                    labelKey: stateName,
                    editScopes: "",
                    viewScopes: "",
                    deleteScopes: "",
                    initial: false,
                    final: false,
                    extraAttributes: {},
                    transitionEditMode: false,
                    color: null,
                },
                position: { x: Math.random() * 400, y: Math.random() * 200 },
                sourcePosition: Position.Right,
                targetPosition: Position.Left,
            }
            setNewStatesUuid([...newStatesUuid, newState.id]);
            saveNode(newState);
        }
    }

    useEffect(() => {
      setNodes((nds) => nds.map((node) => ({ ...node, data: { ...node.data, transitionEditMode: addTransitionMode } })))
    }, [addTransitionMode])

    const updateEdgesOnWorkflowData = (edges: FloatingEdgeType[]) => {
        setWorkflowData((oldData) => ({
            ...oldData,
            transitions: edges.filter((edge) => !edge.data.extraAttributes?.virtualInitialEdge && !edge.data.extraAttributes?.virtualFinalEdge).map((edge) => {
                const oldTransition = oldData.transitions.find((transition) => transition.id === parseInt(edge.id))
                const transition: WorkflowTransitionDTO = {
                    id: oldTransition?parseInt(edge.id):0,
                    isDeleted: oldTransition?.isDeleted||false,
                    idWorkflowStateFrom: edge.source?parseInt(edge.source):null,
                    idWorkflowStateTo: edge.target?parseInt(edge.target):null,
                    actionName: edge.data.actionName,
                    labelKey: edge.data.labelKey,
                    displayOrder: oldTransition?.displayOrder || 0,
                    displayColor: edge.data.DisplayColor,
                    icon: edge.data.Icon,
                    allowComments: edge.data.AllowComments,
                    extraAttributes: JSON.stringify(edge.data.extraAttributes),
                    idScope: oldTransition?.idScope || 0,
                    reactFlowId: edge.id,
                    stateFromReactFlowId: edge.source,
                    stateToReactFlowId: edge.target,
                }
                return transition
            })
        }))
    }

    const saveNode = (node: EditableNodeType) => {
        setLoading(true);
        setNodeSelected(null)

        node.data.extraAttributes = {
            ...node.data.extraAttributes,
            position:{
                x: node.position.x,
                y: node.position.y,
            }
        }
        
        let isNew = false;
        
        const newNodes = nodes.map((oldNode) => (oldNode.id === node.id ? {
            ...oldNode,
            ...node
        } : oldNode));

        if (!newNodes.some((n) => n.id === node.id)) {
            isNew = true;
            newNodes.push(node);
        }

        const workFlowDataToSave = {
            ...workflowData,
            states: newNodes.filter((node) => !node.data.extraAttributes?.virtualInitialNode && !node.data.extraAttributes?.virtualFinalNode).map((node) => {
                const oldNode = workflowData.states.find((state) => state.id === parseInt(node.id??'-1'))
                const state: WorkflowStateDTO = {
                    id: isNew?0:parseInt(node.id),
                    isDeleted: false,
                    name: node.data.name,
                    idWorkflow: workflowData.id,
                    complete: node.data.final?true:false,
                    extraAttributes: JSON.stringify(node.data.extraAttributes),
                    labelKey: node.data.labelKey,
                    action: node.data.name,
                    reactFlowId: node.id,
                    viewScopes: node.data.viewScopes,
                    editScopes: node.data.editScopes,
                    deleteScopes: node.data.deleteScopes,
                    initial: node.data.initial?true:false,
                    editable: oldNode?.editable || false,
                    deletable: oldNode?.deletable || false,
                    viewable: oldNode?.viewable || false,
                    editableByOwner: oldNode?.editableByOwner || false,
                    deletableByOwner: oldNode?.deletableByOwner || false,
                    viewableByOwner: oldNode?.viewableByOwner || false,
                    displayColor: node.data.color
                }
                return state
            }),
            transitions: edges.filter((edge) => !edge.data.extraAttributes?.virtualInitialEdge && !edge.data.extraAttributes?.virtualFinalEdge).map((edge) => {
                const isNew = isNewTransition(edge.id);
                const oldTransition = workflowData.transitions.find((transition) => transition.id === parseInt(edge.id))
                const transition: WorkflowTransitionDTO = {
                    id: isNew?0:parseInt(edge.id),
                    isDeleted: oldTransition?.isDeleted || false,
                    idWorkflowStateFrom: edge.source?parseInt(edge.source):null,
                    idWorkflowStateTo: edge.target?parseInt(edge.target):null,
                    actionName: edge.data.actionName,
                    labelKey: edge.data.labelKey,
                    displayOrder: oldTransition?.displayOrder || 0,
                    displayColor: edge.data.DisplayColor,
                    icon: edge.data.Icon,
                    allowComments: edge.data.AllowComments,
                    extraAttributes: null,
                    idScope: oldTransition?.idScope || 0,
                    reactFlowId: edge.id,
                    stateFromReactFlowId: edge.source,
                    stateToReactFlowId: edge.target,
                }
                return transition
            })
        };

        saveWorkflowState(workFlowDataToSave).then((response) => {
            //updateNodesOnWorkflowData(newNodes);
            setNodes(newNodes)
        }).finally(() => {
            setLoading(false)
        })
    }

    const removeNode = (id: string) => {
        saveWorkflowState({
            ...workflowData,
            states: workflowData.states.filter((state) => state.id !== parseInt(id)),
            transitions: workflowData.transitions.filter((transition) => transition.idWorkflowStateFrom !== parseInt(id) && transition.idWorkflowStateTo !== parseInt(id))
        }).then((response) => {
            setNodes((oldNodes) => oldNodes.filter((node) => node.id !== id));
            setEdges((oldEdges) => oldEdges.filter((edge) => edge.source !== id && edge.target !== id));
            setNodeSelected(null);
        })
        setNewStatesUuid(newStatesUuid.filter((uuid) => uuid !== id));
    }

    const removeEdge = (id: string) => {
        saveWorkflowState({
            ...workflowData,
            transitions: workflowData.transitions.filter((transition) => transition.id !== parseInt(id))
        }).then((response) => {
            setEdges((oldEdges) => oldEdges.filter((edge) => edge.id !== id));
            setEdgeSelected(null);
        })
        setNewTransitionsUuid(newTransitionsUuid.filter((uuid) => uuid !== id));
    }

    const saveEdge = (edge: FloatingEdgeType) => {
        setLoading(true);
        setEdgeSelected(null);
        setAddTransitionMode(false);

        const newEdges = edges.map((oldEdge) => (oldEdge.id === edge.id ? {
            ...oldEdge,
            ...edge
        } : oldEdge));


        if(!edge?.id){
            const newUuid = createRandomId();
            newEdges.push({
                ...edge,
                id: newUuid
            });
            setNewTransitionsUuid([...newTransitionsUuid, newUuid]);
        }

        const workFlowDataToSave = {
            ...workflowData,
            states: nodes.filter((node) => !node.data.extraAttributes?.virtualInitialNode && !node.data.extraAttributes?.virtualFinalNode).map((node) => {
                const oldNode = workflowData.states.find((state) => state.id === parseInt(node.id))
                const state: WorkflowStateDTO = {
                    id: oldNode?parseInt(node.id):0,
                    isDeleted: false,
                    name: node.data.name,
                    idWorkflow: workflowData.id,
                    complete: node.data.final?true:false,
                    extraAttributes: JSON.stringify(node.data.extraAttributes),
                    labelKey: node.data.labelKey,
                    action: node.data.name,
                    reactFlowId: node.id,
                    viewScopes: node.data.viewScopes,
                    editScopes: node.data.editScopes,
                    deleteScopes: node.data.deleteScopes,
                    initial: node.data.initial?true:false,
                    editable: oldNode?.editable || false,
                    deletable: oldNode?.deletable || false,
                    viewable: oldNode?.viewable || false,
                    editableByOwner: oldNode?.editableByOwner || false,
                    deletableByOwner: oldNode?.deletableByOwner || false,
                    viewableByOwner: oldNode?.viewableByOwner || false,
                    displayColor: node.data.color
                }
                return state
            }),
            transitions: newEdges.filter((edge) => !edge.data.extraAttributes?.virtualInitialEdge && !edge.data.extraAttributes?.virtualFinalEdge).map((edge) => {
                const oldTransition = workflowData.transitions.find((transition) => transition.id === parseInt(edge.id))
                const transition: WorkflowTransitionDTO = {
                    id: oldTransition?parseInt(edge.id):0,
                    isDeleted: oldTransition?.isDeleted||false,
                    idWorkflowStateFrom: parseInt(edge.source),
                    idWorkflowStateTo: parseInt(edge.target),
                    actionName: edge.data.actionName,
                    labelKey: edge.data.labelKey,
                    displayOrder: oldTransition?.displayOrder,
                    displayColor: edge.data.DisplayColor,
                    icon: edge.data.Icon,
                    allowComments: edge.data.AllowComments,
                    extraAttributes: null,
                    idScope: edge.data?.scope||null,
                    reactFlowId: edge.id,
                    stateFromReactFlowId: edge.source,
                    stateToReactFlowId: edge.target,
                }
                return transition
            })
        };

        saveWorkflowState(workFlowDataToSave).then((response) => {
            updateEdgesOnWorkflowData(newEdges);
            setEdgeSelected(null)
            setEdges(newEdges)
        }).finally(() => {
            setLoading(false)
        })
    }

    const removeNodeConfirm = (id: string) => {
        confirmAlert({
            title: intl.get('delete.modal.title'),
            message: intl.get('confirm.modal.workflowDetail.message'),
            buttons: [
                {
                    label: intl.get('delete.modal.delete.button'),
                    onClick: () => {
                        removeNode(id)
                        return true;
                    }
                },
                {
                    label: intl.get('delete.modal.cancel.button'),
                    onClick: () => {
                        return false;
                    }
                }
            ]
        });
    }

    const removeEdgeConfirm = (id: string) => {
        confirmAlert({
            title: intl.get('delete.modal.title'),
            message: intl.get('confirm.modal.workflowDetail.message'),
            buttons: [
                {
                    label: intl.get('delete.modal.delete.button'),
                    onClick: () => {
                        removeEdge(id)
                    }
                },
                {
                    label: intl.get('delete.modal.cancel.button'),
                    onClick: () => {}
                }
            ]
        });

    }

    const layoutContext = useLayout();
    useEffect(() => {
        layoutContext.setLoading(loading);
    }, [loading])

    return (
        <div className='container'>
            <EditableNodeDrawer selectedNode={nodeSelected} onClose={() => setNodeSelected(null)} saveNode={saveNode} deleteNode={(id) => removeNodeConfirm(id)} />
            <FloatingEdgeDrawer selectedEdge={edgeSelected} onClose={() => setEdgeSelected(null)} saveEdge={saveEdge} deleteEdge={(id) => removeEdgeConfirm(id)} />
            <Stack gap={2} marginY={2}>
                {id ?
                    <Typography variant='h4' className="title mb-0">{intl.get('workflowDetail.header')}</Typography>
                    :
                    <Typography variant='h4' className="title mb-0">{intl.get('newWorkflow.header')}</Typography>
                }
                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                    <Box display={"flex"} gap={2}>
                        <AddStateModal
                            open={isModalOpen}
                            setOpen={setIsModalOpen}
                            onAddState={addState}
                        />
                        <PermissionsGate  viewScopes={[SCOPES['workflows.edit']]} editScopes={[SCOPES['workflows.edit']]} editRoles={[]} viewRoles={[]}>
                            <Button size='medium' disabled={!hasChanges}  variant='contained' color='primary' className='newAddress' onClick={() => saveAction(workflowData)}>
                                <span className="px-2">{intl.get('save.button')}</span>
                            </Button>
                        </PermissionsGate>
                    </Box>
                    <Button size='medium' variant='contained' color='primary' className='newAddress' onClick={() => navigate(`/workflows`)}>
                        <Box display={"flex"} gap={2}>
                            <IoMdArrowRoundBack size={24} />
                            <span className="px-2">{intl.get('workflowEditor.button.backToWorkflows')}</span>
                        </Box>
                    </Button>
                </Box>
                <Box>
                    <FormControl size='small' fullWidth>
                        <TextField
                            size='small'
                            id="workflow-name"
                            label={intl.get('workflow.name')}
                            InputLabelProps={{
                                shrink: workflowData?.name ? true : false,
                            }}
                            value={workflowData?.name || ''}
                            onChange={(e) => {
                                setWorkflowData((oldData) => ({ ...oldData, name: e.target.value }))
                                setHasChanges(true);
                            }} />
                    </FormControl>
                </Box>
            </Stack>
            <div style={{ height: '70vh', width: '100%', overflow: 'hidden' }} className="floatingedges">
                <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={(nodes) => {onNodesChange(nodes)}}
                    onEdgesChange={(edges) => {onEdgesChange(edges)}}
                    onConnect={onConnect}
                    fitView
                    connectionLineComponent={FloatingConnectionLine}
                    nodeTypes={nodeTypes}
                    edgeTypes={edgeTypes}
                    onNodeClick={(event, node) => {
                        setNodeSelected(node)
                    }}
                    onEdgeClick={(event: React.MouseEvent, edge: FloatingEdgeType) => {
                        setEdgeSelected(edge)
                    }}
                    defaultViewport={defaultViewport}
                    onNodeDragStop={(event, node) => {
                        saveNode(node)
                    }}
                >
                    <Panel>
                        <Paper sx={{width: '120px'}} variant='outlined'>
                            <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} gap={1} p={1}>
                                <ToggleButtonGroup
                                    color="primary"
                                    value={addTransitionMode}
                                    exclusive
                                    onChange={(e, newValue) => setAddTransitionMode(newValue?true:false)}
                                    aria-label="text alignment"
                                >
                                    <ToggleButton value={false}>
                                        <TbDragDrop size={18}/>
                                    </ToggleButton>
                                    <ToggleButton value={true}>
                                        <PiFlowArrowBold size={18}/>
                                    </ToggleButton>
                                </ToggleButtonGroup>
                                <Typography sx={{fontSize: '.8rem', fontWeight: 'bold'}}>
                                  {addTransitionMode?intl.get('workflowDetail.button.editTransitions'):intl.get('workflowDetail.button.dragAndDrop')}
                                </Typography>
                            </Box>
                        </Paper>
                    </Panel>
                    <Controls />
                    <Background />
                    <MiniMap nodeColor={() => 'gray'} nodeStrokeWidth={3} zoomable pannable maskStrokeColor='black' />
                </ReactFlow>
            </div>
            <Box>
                <Box className="d-flex align-items-center gap-2 mt-4">
                    <TiFlowSwitch size={32} />
                    <Typography variant='h4'>Workflow Transitions</Typography>
                </Box>
                <List>
                    {edges.filter((edge) => !edge.data.extraAttributes?.virtualInitialEdge && !edge.data.extraAttributes?.virtualFinalEdge).map((edge) => {
                        const nodeSource = findNode(edge.source);
                        const nodeTarget = findNode(edge.target);

                        return(<Fragment key={`${edge.id}-transition-item`}>
                            <ListItem className='border-bottom border-gray-200 m-0'>
                                <ListItemText
                                    primary={
                                        <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
                                            {edge?.data?.Icon&&
                                                <IconPickerItem
                                                    size={28}
                                                    value={edge.data.Icon}
                                                    color={edge?.data?.DisplayColor?edge.data.DisplayColor:'black'}
                                                />
                                            }
                                            <Stack style={{width:"250px"}}>
                                                <Typography p={0} className='text-sm' style={{textOverflow: "ellipsis"}}>{`#${edge?.id} - ${edge.data?.labelKey&&intl.get(edge.data?.labelKey)|| edge?.data?.actionName}`}</Typography>
                                                <Typography p={0} className='text-sm' style={{textOverflow: "ellipsis"}}>Name:{edge?.data?.actionName}</Typography>
                                                <Typography p={0} className='text-sm' style={{textOverflow: "ellipsis", whiteSpace: "nowrap"}}>Label Key:{edge?.data?.labelKey}</Typography>
                                            </Stack>
                                            <Divider orientation="vertical" flexItem style={{backgroundColor: 'black'}} />
                                            <>
                                                {nodeSource ? <Chip size='small' label={`#${nodeSource?.id} - ${nodeSource.data?.name}`} className='border border-gray' /> :
                                                <Chip size='small' variant='outlined' label='No source' className='border border-grey  w-68' />}
                                                <TbArrowMoveRight size={24} />
                                                {nodeTarget ? <Chip size='small' label={`#${nodeTarget?.id} - ${nodeTarget.data?.name}`} className='border border-gray' /> :
                                                <Chip size='small' variant='outlined' label='No target' className='border border-grey' />}
                                            </>
                                        </Box>
                                    }
                                />
                                <ListItemSecondaryAction>
                                    <IconButton onClick={() => setEdgeSelected(edge)}>
                                        <FaEdit />
                                    </IconButton>
                                    <IconButton onClick={() => removeEdgeConfirm(edge.id)}>
                                        <FaTrash />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider className='w-100 border-solid border-gray-200' />
                        </Fragment>)
                    })}
                </List>
            </Box>           
        </div>
    )
}

const FlowWithProvider: React.FC = (props) => {
    return (
        <ReactFlowProvider>
            <WorkflowEditor {...props} />
        </ReactFlowProvider>
    )
}

export default FlowWithProvider

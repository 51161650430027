import React, { FC } from 'react';
import Select from 'react-select';
import { Employee } from '../../axiosApi/models';
import intl from 'components/i18n/ReactIntlWrapper';
import { sortCollection } from '../../common/utils';
import { SORT_TYPES } from '../../common/constants';

type EmployeeSelectProps = {
  handleEmployeeChange: any,
  selectedEmployee: any,
  employees: any,
  disabled: any,
  newUser: boolean,
  id: any,
  securityRoles: any,
  edit:boolean,
  setHiddenRole?: any,
};

const EmployeeSelect: FC<EmployeeSelectProps> = ({ handleEmployeeChange, employees, selectedEmployee, disabled, newUser, id, edit, setHiddenRole = false }) => {

  const customStyles = {
    control: base => ({
      ...base,
      height: 56,
      minHeight: 56
    }),
    menu: (base) => ({
      ...base,
      maxHeight: '18.7rem',
    })
  };

  const handleChange = (selectedOption: any) => {
    handleEmployeeChange(selectedOption, id);
  };

  const handleMenuOpen = () => {
    setHiddenRole(true);
  };

  const handleMenuClose = () => {
    setHiddenRole(false);
  };

  return (
    <Select
      id='idEmployee'
      name='idEmployee'
      getOptionLabel={e => e.entityName ? `${e.entityName}` : `${e.name} ${e.lastName}`}
      getOptionValue={e => e.entityId ? e.entityId : e.id}
      placeholder={intl.get('employeeProjectForm.placeholder.employee')}
      value={edit ? ((employees != null && selectedEmployee != null) ? (employees?.find((employee: Employee | any) => employee.entityId ? employee.entityId === selectedEmployee : employee.id === selectedEmployee)) : '') :  ((employees != null && selectedEmployee != null) ? (employees?.find((employee: Employee | any ) =>employee.entityId ? employee.entityId === selectedEmployee : employee.id === selectedEmployee)) : '')}
      onChange={handleChange}
      isLoading={employees == null}
      options={employees}
      isDisabled={disabled}
      styles={newUser ? customStyles : undefined}
      onMenuOpen={setHiddenRole! ? handleMenuOpen : null}
      onMenuClose={setHiddenRole! ? handleMenuClose : null}
    />
  );
}

export default EmployeeSelect;
import { Autocomplete, Box, Button, Dialog, DialogContent, DialogTitle, Icon, IconButton, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { BiEdit, BiPlus } from 'react-icons/bi';
import { FaSave } from 'react-icons/fa';
import { HiTranslate } from 'react-icons/hi';
import { MdClose } from 'react-icons/md';
import intl from 'components/i18n/ReactIntlWrapper';
import { useApi } from '../api/ApiProvider';
import Api from 'axiosApi/api';
import { useDebounce } from '@uidotdev/usehooks';
import { LocaleDto } from 'axiosApi/models';
import { useToastMessageQueue } from './ToastMessages/ToastMessageProvider';
import Spinner from './Spinner';
import useLocale from 'i18n/useLocale';

type LabelKeySelectorProps = {
    ref?: React.RefObject<HTMLDivElement>;
    labelKey?: string;
    setLabelKey?: (labelKey: string) => void;
    value?: string;
    onChange?: (labelKey: string) => void;
    label?: string;
    size?: 'small' | 'medium';
    className?: string;
    inputClassName?: string;
    disabled?: boolean;
}


// Function to fetch locales from the API
type LocaleItem = {
    [key: string]: string;
};

export default function LabelKeySelector({
    ref,
    label,
    labelKey,
    setLabelKey,
    size = 'small',
    className: autocompleteClassName,
    inputClassName,
    disabled,
}: LabelKeySelectorProps) {
    const [open, setOpen] = useState(false);
    const [mode, setMode] = useState<'create' | 'update'>('create');
    const [localeKeys, setLocaleKeys] = useState<string[]>([]);
    const api: Api = useApi();

    const [loading, setLoading] = useState<boolean>(false);
    const [autoCompleteLoading, setAutoCompleteLoading] = useState<boolean>(false);
    const [value, setValue] = useState<string | null>(labelKey || null);
    const [modalValue, setModalValue] = useState<string | null>(null);
    const [searchTerm, setSearchTerm] = useState("");
    const debouncedSearchTerm = useDebounce(searchTerm, 1000);
    const toast = useToastMessageQueue();
    const { reloadLocales, languagesAvailable } = useLocale();

    const langueagesRefs = languagesAvailable.map((language) => React.createRef<HTMLInputElement>());

    useEffect(() => {
        if(debouncedSearchTerm?.length < 3) {
            setLocaleKeys([]);
            return;
        }else{
            setAutoCompleteLoading(true);
            api.localeApi.apiVversionLocaleSearchKeyGet("1", debouncedSearchTerm).then((response) => {
                const { data } = response?.data;
                setLocaleKeys(data.queryResult);
            }).finally(() => {
                setAutoCompleteLoading(false);
            });
        }
    }, [debouncedSearchTerm]);


    const [defaultTranslations, setDefaultTranslations] = useState<{[x: string]: string;}[]>();

    useEffect(() => {
        if(open) {
            loadLocaleTranslationsByKey(value);
        }
    }, [open]);

    const loadLocaleTranslationsByKey = (key: string) => {
        return api.localeApi.apiVversionLocaleKeyGet(key, "1");
    }

    useEffect(() => {
        labelKey && labelKey != value && setValue(labelKey);
    }, [labelKey]);

    useEffect(() => {
        (setLabelKey && value) && setLabelKey(value);
    }, [value]);

    const openNewModel = () => {
        setMode('create');
        setModalValue(value || '');
        setLabelKey('');
        setOpen(true);
    }

    const openEditModel = (key: string) => {
        setMode('update');
        setLoading(true);
        loadLocaleTranslationsByKey(key).then((response) => {
            const { data } = response?.data;
            if(data) {
                setLabelKey(data.key);
                setModalValue(data.key);
                //setValue(data.key);
                setDefaultTranslations(data.localeTranslationDtos.map((locale) => ({
                    [locale.language]: locale.value,
                })));
                setOpen(true);
            }else{
                throw new Error();
            }
        }).catch((error) => {
            toast.error({ body: "ERROR!" });
        }).finally(() => {
            setLoading(false);
        });
    }

    function onRequestClose(event: {}, reason: 'backdropClick' | 'escapeKeyDown'): void {
        if (reason && reason == "backdropClick" && "escapeKeyDown") 
            return;
        setOpen(false);
    }

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        
        const key = e.currentTarget.elements["key"].value;
        const localeReq : LocaleDto = {
            key: key,
            localeTranslationDtos: languagesAvailable.map((language) => ({
                language: language,
                value: e.currentTarget.elements[language].value,
            })),
        };

        //KEY VALIDATION TOAST
        if(!key) {
            toast.warning({ body: intl.get('locale.toast.error.key') });
            return;
        }

        //ALMOST ONE TRANSLATION VALIDATION TOAST
        if(localeReq.localeTranslationDtos.filter((locale) => locale.value).length == 0) {
            toast.warning({ body: intl.get('locale.toast.error.translation') });
            return;
        }

        saveFunction(localeReq);
    }

    const clearForm = () => {
        setLabelKey('');
        setValue(null);
    }

    const saveFunction = (localeDto: LocaleDto) => {
        setLoading(true);

        const savePromise = mode === 'update' ? api.localeApi.apiVversionLocalePut("1", {
            locale: localeDto,
        }) :
        api.localeApi.apiVversionLocalePost("1", {
            locale: localeDto,
        });

        savePromise.then((response) => {
            const { data } = response?.data;
            if(data) {
                reloadLocales();
                toast.success({ body: intl.get('locale.toast.success.save') });
                setOpen(false);
                clearForm();
                console.log("localeDto.key", localeDto.key);
                setLabelKey(localeDto.key);
                setValue(localeDto.key);
            }else{
                toast.error({ body: intl.get('locale.toast.error.save') });
                throw new Error();
            }
        }).catch((error) => {
            toast.error({ body: intl.get('locale.toast.error.save') });
        }).finally(() => {
            setLoading(false);
        });
    }

    return (<>
        {loading && <Spinner small={false} />}
        <Dialog
                open={open}
                onClose={onRequestClose}
            >
            <DialogTitle className='py-2 px-3 d-flex justify-content-between align-items-center'>
                <Typography className='px-2'>{mode === 'update' ? intl.get('locale.editLabelKey') : intl.get('locale.addNewLabelKey')}</Typography>
                <IconButton onClick={() => setOpen(false)}>
                    <MdClose />
                </IconButton>
            </DialogTitle>
            <DialogContent className='pt-2 my-2'>
                <form noValidate autoComplete="off" onSubmit={onSubmit}>
                    <TextField
                        id="key"
                        name="key"
                        inputRef={ref}
                        label={"Key"}
                        size="small"
                        type="text"
                        inputProps={{ maxLength: 100 }}
                        value={modalValue}
                        //onChange={(e) => setLabelKey(e.target.value)}
                        onChange={(e) => setModalValue(e.target.value)}
                        disabled={mode === 'update'}
                        sx={{
                            minWidth: '350px',
                        }}
                    />
                    <Stack gap={2} sx={{
                        marginTop: '1rem',
                        padding: '1rem',
                        borderRadius: '5px',
                        border: '1px solid #e0e0e0',
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                    }}>
                        <Typography>
                            <HiTranslate size={32} />
                            Translations
                        </Typography>
                        {languagesAvailable.map((language) => (
                            <TextField
                                key={language}
                                label={language}
                                name={language}
                                defaultValue={defaultTranslations?.find((locale) => locale[language])?.[language]}
                                inputRef={langueagesRefs[languagesAvailable.indexOf(language)]}
                                variant='outlined'
                                size='small'
                                sx={{ width: '100%' }}
                            />
                        ))}
                    </Stack>
                    <Box display={"flex"} gap={2} flexDirection={'row'} justifyContent={'flex-end'} mt={2}>
                        <Button type='submit' startIcon={<FaSave/>} variant="contained" color="primary" sx={{width: 'fit-content', alignSelf: 'end'}}>
                            {intl.get('save.modal.save.button')}
                        </Button>
                    </Box>
                </form>
            </DialogContent>
        </Dialog>
        <Box className='d-flex gap-2'>
            <Autocomplete
                value={value}
                inputValue={searchTerm}
                onFocus={() => setSearchTerm(value)}
                onInputChange={(event, newInputValue) => {
                    setSearchTerm(newInputValue);
                }}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
                ref={ref}
                options={localeKeys}
                loading={autoCompleteLoading}
                openOnFocus={false}
                getOptionLabel={(option: string) => `${intl.has(option)?intl.get(option):'Translation not found'} (${option})`}
                filterOptions={(options, params) => {
                    return options;
                }}
                className={`${autocompleteClassName}`}
                noOptionsText={value?.length <= 3 ? intl.get('labelKeySelector.noOptionsText') : intl.get('labelKeySelector.almostThreeOptionsText')}
                style={{ width: 300 }}
                renderInput={(params) => <TextField {...params} className={`${inputClassName}`} size={size} label={label || intl.get('labelKeySelector.label')} variant='outlined' />}
                disabled={disabled}
        />
            <Button
                variant='contained'
                color='primary'
                onClick={() => intl.has(value) ? openEditModel(value) : openNewModel()} 
            >
                {intl.has(value) ?
                    <BiEdit />
                :
                    <BiPlus />
                }
            </Button>
        </Box>
    </>)
}

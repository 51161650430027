import React, { FC } from 'react';
import Select from 'react-select';
import 'scss/Project/ClientSelect.scss';
import intl from 'components/i18n/ReactIntlWrapper';
import { sortCollection } from '../../common/utils';
import { SORT_TYPES } from '../../common/constants';
import { Provider } from 'axiosApi/models';

type ProviderSelectProps = {
  handleProviderChange: any,
  selectedProvider: any,
  providers: any,
  disabled: any,
  newUser: boolean,
  id: any,
  securityRoles: any,
  edit: boolean,
  setHiddenRole: any,
};

const ProviderSelect: FC<ProviderSelectProps> = ({ handleProviderChange, providers, selectedProvider, disabled, newUser, id, securityRoles, edit, setHiddenRole }) => {

  const customStyles = {
    control: base => ({
      ...base,
      height: 56,
      minHeight: 56
    }),
    menu: (base) => ({
      ...base,
      maxHeight: '18.7rem',
    })
  };

  const handleChange = (selectedOption: any) => {
    handleProviderChange(selectedOption, id);
  };

  const handleMenuOpen = () => {
    setHiddenRole(true);
  };

  const handleMenuClose = () => {
    setHiddenRole(false);
  };

  return (
    <Select
      id='idProvider'
      name='idProvider'
      getOptionLabel={e => e.entityName ? `${e.entityName}` : e.name}
      getOptionValue={e => e.entityId ? e.entityId : e.id}
      placeholder={intl.get('providerSelect.placeholder.provider')}
      value={edit ? ((providers != null && selectedProvider != null) ? (providers?.find((provider: Provider | any) => provider.entityId ? provider.entityId === selectedProvider : provider.id === selectedProvider)) : '') : disabled ? '' : ((providers != null && selectedProvider != null) ? (providers?.find((provider: Provider | any) => provider.entityId ? provider.entityId === selectedProvider : provider.id === selectedProvider)) : '')}
      onChange={handleChange}
      isLoading={providers == null}
      options={providers}
      isDisabled={disabled}
      styles={newUser ? customStyles : undefined}
      onMenuOpen={handleMenuOpen}
      onMenuClose={handleMenuClose}
    />
  );
}

export default ProviderSelect;
import { ClientsApi } from './apis/clients-api';
import { AddressApi } from './apis/address-api';
import { JobsApi } from './apis/jobs-api';
import { ContactinfoApi } from './apis/contactinfo-api';
import { EmployeeApi } from './apis/employee-api';
import { ProjectApi } from './apis/project-api';
import { TimesheetApi } from './apis/timesheet-api';
import { ReportsApi } from './apis/reports-api';
import { TimeoffApi } from './apis/timeoff-api';
import { WorkflowApi } from './apis/workflow-api';
import { InvoiceApi } from './apis/invoice-api';
import { LocaleApi } from './apis/locale-api';
import { getApiKey } from 'components/authentication/context/authContext';
import { Configuration } from './configuration';
export type Settings = {
    URL: string,
    CLIENT_ID: string,
    LOGIN_DOMAIN: string,
    REDIRECT_URL: string,
    RESPONSE_TYPE: string,
    URL_ADDRESS: string,
    URL_CLIENTS: string,
    URL_CONTACTINFO: string,
    URL_EMPLOYEE: string,
    URL_INVOICE: string,
    URL_JOBS: string,
    URL_LOCALE: string,
    URL_PROJECT: string,
    URL_REPORTS: string,
    URL_TIMEOFF: string,
    URL_TIMESHEET: string,
    URL_WORKFLOW: string,
    USER_POOL_ID: string,
}
export default class Api{
    public clientsApi: ClientsApi;
    public addressApi: AddressApi;
    public jobsApi: JobsApi;
    public contactinfoApi: ContactinfoApi;
    public employeeApi: EmployeeApi;
    public projectApi: ProjectApi;
    public timesheetApi: TimesheetApi;
    public reportsApi: ReportsApi;
    public timeoffApi: TimeoffApi;
    public workflowApi: WorkflowApi;
    public invoiceApi: InvoiceApi;
    public localeApi: LocaleApi;
    constructor(settings: Settings)
    {
        const defaultConfig: Configuration = new Configuration({apiKey: getApiKey});
        delete defaultConfig.baseOptions.headers['User-Agent'];
        this.clientsApi = new ClientsApi(defaultConfig, settings.URL_CLIENTS);
        this.addressApi = new AddressApi(defaultConfig, settings.URL_ADDRESS);
        this.jobsApi = new JobsApi(defaultConfig, settings.URL_JOBS);
        this.contactinfoApi = new ContactinfoApi(defaultConfig, settings.URL_CONTACTINFO);
        this.employeeApi = new EmployeeApi(defaultConfig, settings.URL_EMPLOYEE);
        this.projectApi = new ProjectApi(defaultConfig, settings.URL_PROJECT);
        this.timesheetApi = new TimesheetApi(defaultConfig, settings.URL_TIMESHEET);
        this.reportsApi = new ReportsApi(defaultConfig, settings.URL_REPORTS);
        this.timeoffApi = new TimeoffApi(defaultConfig, settings.URL_TIMEOFF);
        this.workflowApi = new WorkflowApi(defaultConfig, settings.URL_WORKFLOW);
        this.invoiceApi = new InvoiceApi(defaultConfig, settings.URL_INVOICE);
        this.localeApi = new LocaleApi(defaultConfig, settings.URL_LOCALE);
    }
}
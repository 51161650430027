import React, { useState, useEffect } from 'react';
import ProjectCompactRow from './ProjectCompactRow';
import Spinner from './Spinner';
import { HiOutlineDocument } from 'react-icons/hi';
import intl from 'components/i18n/ReactIntlWrapper';
import {useApi} from '../api/ApiProvider';
import Api from '../axiosApi/api';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import ProjectCompactPagination from './ProjectCompactPagination';
import { sortCollection } from '../common/utils';
import { SORT_TYPES } from '../common/constants';
import ItemsSelect from './ItemsSelect';
import { handleAPIError } from '../common/errorHandler';

const ProjectCompact = ({ idNumber, input, setInput }) => {

    const [loading, setLoading] = useState<boolean>(false);

    const [projectsByClient, setProjectsByClient] = useState([]);

    const [limit, setLimit] = useState<number>(10);

    const [errors, setErrors] = useState({});

    const api: Api = useApi();

    const toast = useToastMessageQueue();

    const fetchProjectsByClient = async (idNumber: number) => {
        setLoading(true);
        const response = await api.projectApi.apiVversionProjectGetByClientIdIdGet(idNumber, "1", {}).then((response) => {
            if (response.data) {
                setProjectsByClient(response.data?.data?.queryResult);
                setLoading(false);
            };
        }).catch((error) => {
            handleAPIError(error, toast, errors);
            setErrors({...errors});
            setLoading(false);
        });
    };

    useEffect(() => {
        if (idNumber) {
            fetchProjectsByClient(idNumber);
        };
    }, [idNumber]);

    const [pageCount, setpageCount] = useState<number>(0);

    const handlePageClick = pageNum => setInput({ ...input, currentPage: pageNum });

	const nextPage = () => setInput({ ...input, currentPage: input.currentPage + 1 });

	const prevPage = () => setInput({ ...input, currentPage: input.currentPage - 1 });

	const initialPage = () => setInput({ ...input, currentPage: 1 });

	const finalPage = () => setInput({ ...input, currentPage: pageCount });

    const projectsByClientSortedByName = sortCollection(projectsByClient, "name", SORT_TYPES.ASC);

    const projectsByClientPaginated = projectsByClientSortedByName?.slice((input.currentPage - 1) * limit, input.currentPage * limit);

    return (
        <>
            <div className="card-header">
                <h4 className="title" id="project"><HiOutlineDocument className='mb-1'/> {intl.get('projectCompact.header')}</h4>
            </div>
            {
                loading === true ?
                <Spinner small={true} /> :
                <div className="card-body">
                    <div className="table-responsive container-overflow">
                        <table className='table table-hover'>
                            <thead>
                                <tr>
                                    <th scope="col" >{intl.get('projectCompact.table.thead.projectName')}</th>
                                    <th scope="col" >{intl.get('projectCompact.table.thead.client')}</th>
                                    <th scope="col" >{intl.get('projectCompact.table.thead.budget')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    projectsByClientPaginated?.map((p,i) => (
                                        <ProjectCompactRow
                                            key={p.id}
                                            id={p.id}
                                            name={p.name}
                                            client={p.client}
                                            budget={p.budget}
                                            className={i%2>0?'':'odd'}
                                        />
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="pagination row w-100">
						<div className="col-10 col-xl-11 d-flex justify-content-center">
                        <ProjectCompactPagination handlePageClick={handlePageClick} limit={limit} projectsByClient={projectsByClient} nextPage={nextPage} prevPage={prevPage} input={input} pageCount={pageCount} setpageCount={setpageCount} initialPage={initialPage} finalPage={finalPage} idClient={idNumber}/>

						</div>
						<div className="col-2 col-xl-1 d-flex justify-content-end">
								<ItemsSelect
									input={input}
									setInput={setInput}
									limit={limit} 
									setLimit={setLimit} 
									storageKey="itemsPerPageClients"
								/>
						</div>
					</div>
                </div>
            }
        </>
    )

}

export default ProjectCompact;
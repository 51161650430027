import React from "react";
import { Autocomplete, Chip, Skeleton, TextField } from "@mui/material"
import { Scope } from "axiosApi/models/scope";
import { ScopeDTO } from "axiosApi/models";

type ChipSelectorProps = {
    label: string;
    value: any;
    onChange: any;
    scopes: ScopeDTO[];
    multiple?: boolean;
    loading?: boolean;
}

const ChipSelector: React.FC<ChipSelectorProps> = ({ label, onChange, value, scopes, multiple = true, loading }) => {
    return (<>
        {loading ?
            <Skeleton variant="rectangular" width={"100%"} height={40} animation="wave" className="rounded" />
        :
            <Autocomplete
                multiple={multiple}
                options={scopes}
                size="small"
                value={value}
                isOptionEqualToValue={(option, value) =>{
                    if(value instanceof Array) return option?.id?.toString() === value[0]?.id?.toString()
                    else return option?.id?.toString() === value?.id?.toString()
                }}
                onChange={(event, newValue) => {
                    if (!!onChange) onChange(newValue)
                }}
                getOptionLabel={(scope) => { 
                    if(scope instanceof Array) return scopes.find(x=>x.id == scope[0]?.id)?.scope?.toString()|| ''
                    else return scopes.find(x=>x.id == scope?.id)?.scope?.toString()|| ''}}
                renderGroup={(params) => [
                    <div key={params.key}>
                        <strong>{params.group}</strong>
                    </div>
                ]}
                renderTags={(value, getTagProps) =>{
                    if(multiple){
                        return value?.map((option: ScopeDTO, index: number) =>
                            <Chip
                                key={`${option.scope}-scope`}
                                label={option.scope}
                                color="primary"
                                variant="outlined"
                                {...getTagProps({ index })}
                            />
                        )
                    }else{
                        return(
                            <Chip
                                key={0}
                                label={ value[0]?.scope }
                                color="primary"
                                variant="outlined"
                                {...getTagProps({ index: 0 })}
                            />
                        )
                    }
                }}
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" label={label} placeholder="Select scopes" />
                )}
            />
        }
    </>)
}

export default ChipSelector;
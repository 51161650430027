import React, { useState, useEffect, FC } from 'react';
import EmployeeNavbar from './EmployeeNavbar';
import EmployeePagination from './EmployeePagination';
import EmployeeList from './EmployeeList';
import Filters from '../../fixture/filtersEmployee';
import Spinner from '../Spinner';
import 'scss/Employees.scss';
import { useApi } from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import { Employee, EmployeeSearchResponse } from '../../axiosApi/models';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import intl from 'components/i18n/ReactIntlWrapper';
import ItemsSelect from '../ItemsSelect';
import { handleAPIError } from 'common/errorHandler';
import ErrorSummary from 'components/common/ErrorSummary';

export type StateInput = {
    queryTerm?: string | null,
    sortValue?: string | null,
    orderValue?: string | null,
    filterValue?: null,
    currentPage?: number,
    clicked?: boolean,
};

export type SearchResult ={
    totalRecords?: number | null,
    employees?: Employee[]
};

const Employees = () => {

    const [state, setState] = useState<StateInput | null>({
        queryTerm: "",
        sortValue: "Name",
        orderValue: "asc",
        filterValue: null,
        currentPage: 1,
        clicked: false,
    });

    const api: Api = useApi();

    const [searchResult, setSearchResult] = useState<SearchResult | null>({
        totalRecords: null,
        employees: [],
    });
    const [errors, setErrors] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(false);

    const [pageCount, setpageCount] = useState<number>(0);

    const [limit, setLimit] = useState<number>(() => {
		return parseInt(localStorage.getItem('itemsPerPageEmployee'), 10) || 10;
	});

    useEffect(() => {
		setLimit(parseInt(localStorage.getItem('itemsPerPageEmployee'), 10) || 10);
	}, []);

    const sortOptions = [
        { id: 1, order: "asc", sort: "Name", description: "Name Ascending" },
        { id: 2, order: "desc", sort: "Name", description: "Name Descending" },
        { id: 3, order: "asc", sort: "Lastname", description: "Last Name Ascending" },
        { id: 4, order: "desc", sort: "Lastname", description: "Last Name Descending" }
    ];

    const mapFilter = new Map();

    for (let i = 0; i < Filters.filters.length; i++) {
        const parentRef = React.createRef();
        const mapChildren = new Map();
        for (let j = 0; j < Filters.filters[i].filtersItems.length; j++) {
            const childrenRef = React.createRef();
            mapChildren.set(Filters.filters[i].filtersItems[j].value, childrenRef);
        }
        let valor = { parentRef: parentRef, childrenRef: mapChildren };
        mapFilter.set(Filters.filters[i].value, valor);
    };

    const toast = useToastMessageQueue();

    const fetchEmployees = async (term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>) => {
        setLoading(true);
        const response = await api.employeeApi.apiVversionEmployeeSearchGet("1", state.queryTerm, state.currentPage, limit, state.sortValue, state.orderValue, f, {}).then((response) => {
            if(response.data.data === null) {
                setSearchResult({
                    totalRecords: null,
                    employees: null
                });
            } else {
                setSearchResult({
                    totalRecords: response.data?.data?.totalRecords,
                    employees: response.data?.data?.queryResult
                });
            };
            setLoading(false);
        }).catch((error) => {
            handleAPIError(error, toast, errors);    
            setErrors({ ...errors }); 
            setLoading(false);       
		});
        
    };

    useEffect(() => {
        fetchEmployees();
    }, [state, limit]);

    const onSearch = (queryString) => {
        setState({ ...state, queryTerm: queryString, currentPage: 1 });
    };

    const handlePageClick = pageNum => setState({ ...state, currentPage: pageNum });

    const nextPage = () => setState({ ...state, currentPage: state.currentPage + 1 });

    const prevPage = () => setState({ ...state, currentPage: state.currentPage - 1 });

    const initialPage = () => setState({ ...state, currentPage: 1 });

    const finalPage = () => setState({ ...state, currentPage: pageCount });

    const handleSort = (e) => {
        const inputVal = {
            ...state,
            orderValue: e.order,
            sortValue: e.sort,
			currentPage: 1
        };
        setState({ ...inputVal });
    };

    const handleFilter = async (e) => {
        let checked = e.target.checked;
        let value = e.target.value;
        let parentValue = e.target.getAttribute("parent");
        let newFilterValue = null;
        if (parentValue === null) {
            let childrenMap = mapFilter.get(value).childrenRef;
            let values = Array.from<any>(childrenMap.values());
            if (state.filterValue === null) {
                newFilterValue = { filters: [] };
            } else {
                newFilterValue = state.filterValue;
            }
            if (checked) {
                let index = newFilterValue.filters.findIndex(filter => filter.name === value);
                let keys = Array.from(childrenMap.keys());
                if (index >= 0) {
                    let filtersUnselected = [];
                    for (let i = 0; i < keys.length; i++) {
                        let filterKey = newFilterValue.filters[index].selected.includes(keys[i]);
                        if (!filterKey) {
                            filtersUnselected.push(keys[i]);
                        }
                    }
                    for (let i = 0; i < filtersUnselected.length; i++) {
                        childrenMap.get(filtersUnselected[i]).current.checked = true;
                        newFilterValue.filters[index].selected.push(filtersUnselected[i]);
                    }
                } else {
                    keys.forEach(key => {
                        childrenMap.get(key).current.checked = true;
                    })
                    newFilterValue.filters.push({ name: value, selected: keys });
                }
            } else {
                newFilterValue = state.filterValue;
                values.forEach(element => {
                    element.current.checked = false;
                });
                let indexFilter = newFilterValue.filters.findIndex(filter => filter.name === value);
                newFilterValue.filters[indexFilter].selected = [];
            }
            setState({ ...state, filterValue: newFilterValue, currentPage: 1 });
        } else {
            if (state.filterValue === null) {
                newFilterValue = { filters: [] };
            } else {
                newFilterValue = state.filterValue;
            }
            if (checked) {
                let index = newFilterValue.filters.findIndex(filter => filter.name === parentValue);
                if (index >= 0) {
                    newFilterValue.filters[index].selected.push(value);
                    let filterItem = Filters.filters.find(filter => filter.value === parentValue);
                    if (newFilterValue.filters[index].selected.length === filterItem.filtersItems.length) {
                        let parentRefValue = mapFilter.get(parentValue).parentRef;
                        parentRefValue.current.checked = true;
                    }
                } else {
                    newFilterValue.filters.push({ name: parentValue, selected: [value] });
                }
            } else {
                let parentRefValue = mapFilter.get(parentValue).parentRef;
                parentRefValue.current.checked = false;
                newFilterValue = state.filterValue;
                let indexParent = newFilterValue.filters.findIndex(filter => filter.name === parentValue);
                let indexValue = newFilterValue.filters[indexParent].selected.findIndex(filter => filter === value);
                let selected = newFilterValue.filters[indexParent].selected;
                selected.splice(indexValue, 1);
                newFilterValue.filters[indexParent].selected = selected;
            }
            setState({ ...state, filterValue: newFilterValue, currentPage: 1 });
        }
    };

    const handleClickButtonFilter = () => {
        setState({ ...state, clicked: !state.clicked, currentPage: 1 });
    };

    return (
        <div className="container">
            <div className='card mt-4'>
                <div className="card-header">
                    <h2 className="title">{intl.get('employee.header')}</h2>
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <div className='' id={`${state.clicked ? 'column-2' : ''}`} >
                            <ErrorSummary errors={errors} showEverything={false} mode="warning"></ErrorSummary>
                            <EmployeeNavbar onSearch={onSearch}/>
                            {
                                loading === true ?
                                <Spinner /> :
                                <>
                                    <EmployeeList searchResult={searchResult} state={state} setState={setState} />
                                    <div className="pagination row w-100">
                                        <div className="col-10 col-xl-11 d-flex justify-content-center">
                                            <EmployeePagination 
                                                handlePageClick={handlePageClick} 
                                                limit={limit} 
                                                searchResult={searchResult} 
                                                nextPage={nextPage} 
                                                prevPage={prevPage} 
                                                state={state} 
                                                pageCount={pageCount} 
                                                setpageCount={setpageCount} 
                                                initialPage={initialPage} 
                                                finalPage={finalPage}
                                            />
                                        </div>
                                        <div className="col-2 col-xl-1 d-flex justify-content-end">
                                            <ItemsSelect
                                                input={state}
                                                setInput={setState}
                                                limit={limit} 
                                                setLimit={setLimit} 
                                                storageKey="itemsPerPageEmployee"
                                            />
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Employees;
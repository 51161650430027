import React, { useState, useEffect } from 'react'
import { CognitoAccessToken, CognitoIdToken, CognitoRefreshToken, CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import intl from 'components/i18n/ReactIntlWrapper';
import Api from 'axiosApi/api';
import { useApi, useApiData } from 'api/ApiProvider';
import { useLocation, useNavigate, useSearchParams } from 'react-router';
import Spinner from 'components/Spinner';
import { UserEntityDetails } from 'axiosApi/models';

const COGNITO_USER_SESSION = 'CognitoUserSession';
const USER_ENTITY = 'UserEntity';
const USERS = 'Users';

const getApiKey = (e:string) => new Promise<string>((resolve, reject) => {
  const cognitoUserSessionData = loadSession(COGNITO_USER_SESSION) as any;
  const cognitoUserSession = cognitoUserSessionData ? new CognitoUserSession({
    IdToken: new CognitoIdToken({ IdToken: cognitoUserSessionData.idToken.jwtToken }),
    AccessToken: new CognitoAccessToken({ AccessToken: cognitoUserSessionData.accessToken.jwtToken }),
    RefreshToken: new CognitoRefreshToken({ RefreshToken: cognitoUserSessionData.refreshToken.token })
  }) : null;
  const userEntityId = loadSession(USER_ENTITY) as UserEntityDetails;
  switch (e)
  {
      case "Authorization":
          resolve(cognitoUserSession?.getAccessToken()?.getJwtToken()?`Bearer ${cognitoUserSession.getAccessToken().getJwtToken()}`: '');
          break;
      case "Userentity":
          resolve(userEntityId?.id?.toString() || '');
          break;
      default:
          reject('Invalid key');
  }
});

const saveSession = (key: string, object: any) => {
  const objectStorage = JSON.stringify(object);
  const objectStorageB64 = btoa(objectStorage);
  sessionStorage.setItem(key, objectStorageB64);
}

const loadSession = (key: string) => {
  const objectStorageB64 = sessionStorage.getItem(key);
  if(!objectStorageB64){
    return null;
  }
  const objectStorage = atob(objectStorageB64);
  return JSON.parse(objectStorage);
}

export enum AuthStatus {
  Loading,
  SignedIn,
  SignedOut,
}
export interface IAuth {
  cognitoUserSession?: CognitoUserSession | null
  userEntityDetails?: UserEntityDetails | null
  users?: UserEntityDetails[]
  switchUserEntity?: (id: number) => void
  sessionInfo?: { username?: string; email?: string; sub?: string; accessToken?: string; refreshToken?: string }
  authStatus?: AuthStatus
  signInWithEmail?: any
  signUpWithEmail?: any
  signOut?: any
  getSession?: any
  sendCode?: any
  forgotPassword?: any
  changePassword?: any
  getAttributes?: any
  setAttribute?: any
}

const defaultState: IAuth = {
  sessionInfo: {},
  authStatus: AuthStatus.SignedOut,
  signOut() {
    console.log('Not loaded yet')
  },
}

type Props = {
  children?: React.ReactNode
}

export const AuthContext = React.createContext(defaultState)

const AuthProvider = ({ children }: Props) => {
  const [verifyCode, setVerifyCode] = useState<string|null>(null);
  const [authStatus, setAuthStatus] = useState(AuthStatus.Loading)
  const [cognitoUserSession, setCognitoUserSession] = useState<CognitoUserSession | null>(null)
  const [userEntityDetails, setUserEntityDetails] = useState<UserEntityDetails | null>(null)
  const [users, setUsers] = useState<UserEntityDetails[]>([]);
  const [sessionInfo, setSessionInfo] = useState({})
  const toast = useToastMessageQueue();

  const { employeeApi } : Api = useApi();
  const { redirectUrl } = useApiData();

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    //check if the url contains the code parameter
    if(searchParams.has("code") && searchParams.get("code") !== verifyCode){
      setVerifyCode(searchParams.get("code"));
    }
  }, [searchParams, navigate, location])

  useEffect(() => {
    if(verifyCode){
      setAuthStatus(AuthStatus.Loading);
      setVerifyCode(null);
      authenticate(verifyCode).then((valid) => {
        if(valid){
          setAuthStatus(AuthStatus.SignedIn);
        }else{
          throw new Error("Failed to authenticate");
        }
      }).catch((err) => {
        console.error(err);
        toast.error({ header: 'Error', body: intl.get('login.userinfo.failed')});
        signOut();
      }).finally(() => {
        navigate('/');
      });
    }
  }, [verifyCode])

  const RETRY_LIMIT = 3;
  useEffect(() => {
    async function getSessionFromStorage() {
      const cognitoUserSessionData = loadSession(COGNITO_USER_SESSION) as any;
      const cognitoUserSession = cognitoUserSessionData ? new CognitoUserSession({
        IdToken: new CognitoIdToken({ IdToken: cognitoUserSessionData.idToken.jwtToken }),
        AccessToken: new CognitoAccessToken({ AccessToken: cognitoUserSessionData.accessToken.jwtToken }),
        RefreshToken: new CognitoRefreshToken({ RefreshToken: cognitoUserSessionData.refreshToken.token })
      }) : null;
      
      if(cognitoUserSession!==null && cognitoUserSession.isValid()){
        const userEntityDetails = loadSession(USER_ENTITY) as UserEntityDetails;
        if(userEntityDetails === null){
          return false;
        }
        const users = loadSession(USERS) as UserEntityDetails[];
        setCognitoUserSession(cognitoUserSession);
        setUserEntityDetails(userEntityDetails);
        setUsers(users);
        return true;
      }
      return false;
    }
    setAuthStatus(AuthStatus.Loading);
    getSessionFromStorage().then((res) => {
      setAuthStatus(res ? AuthStatus.SignedIn : AuthStatus.SignedOut);
    }).catch((err) => {
      toast.error({ header: 'Error', body:  intl.get('login.userinfo.failed')});
      signOut();
    });
  }, [])

  function signOut() {
    if(authStatus === AuthStatus.SignedOut)
    {
      console.debug("Already signed out");
      return;
    }
    console.debug("Signing out");
    setAuthStatus(AuthStatus.SignedOut);
    setCognitoUserSession(null);
    setUserEntityDetails(null);
    setUsers([]);
    sessionStorage.clear();
  }

  const authenticate = async (authCode: string) : Promise<boolean> => {
    return new Promise(async (resolve, reject) => {
      try{
        const cognitoUserSession = await verifyAuthCode(authCode);
        setCognitoUserSession(cognitoUserSession);
        saveSession(COGNITO_USER_SESSION, cognitoUserSession);

        const username = cognitoUserSession.getIdToken().payload.email;
        const usersEntityDetails = await fetchUserEntityDetails(username);
        setUserEntityDetails(usersEntityDetails[0]);
        saveSession(USER_ENTITY, usersEntityDetails[0]);

        setUsers(usersEntityDetails);
        saveSession(USERS, usersEntityDetails);
        
        resolve(true);
      }catch(err){
        console.error(err);
        reject(false);
        sessionStorage.clear();
      }
    });
  }

  const verifyAuthCode = async (authCode: string): Promise<CognitoUserSession> => {
    return new Promise(async (resolve, reject) => {
      let retries = 0;
      let cognitoUserSession: CognitoUserSession | null = null;
      do {
        try{
          const resp = await employeeApi.apiVversionLoginPost("1", authCode, redirectUrl);
          if(retries > 0){
            console.debug(`Retrying to get tokens. Retry: ${retries}`);
          }
          if(resp.status !== 200){
            throw new Error(`Failed to get tokens. Status: ${resp.status}. Retry: ${retries}`);
          }
          const tokens = resp.data as any;
          cognitoUserSession= new CognitoUserSession({
            IdToken: new CognitoIdToken({ IdToken: tokens.id_token }),
            AccessToken: new CognitoAccessToken({ AccessToken: tokens.access_token }),
            RefreshToken: new CognitoRefreshToken({ RefreshToken: tokens.refresh_token })
          });
          if(!cognitoUserSession.isValid()){
            throw new Error(`Invalid tokens. Retry: ${retries}. exp: ${cognitoUserSession?.getIdToken()?.getExpiration()}`);
          }
          resolve(cognitoUserSession);
        }catch(err){
          console.error(err);
          retries++;
        }
      }while(!cognitoUserSession && retries < RETRY_LIMIT);
      reject("Failed to get tokens");
    });
  }
  
  const fetchUserEntityDetails = async (username: string): Promise<UserEntityDetails[]> => {
    return new Promise(async (resolve, reject) => {
      let retries = 0;
      let users: UserEntityDetails[] = [];
      do {
        try{
          const resp = await employeeApi.apiVversionLoginEntitiesGet("1", username);
          if(retries > 0){
            console.debug(`Retrying to get user entity. Retry: ${retries}`);
          }
          if(resp.status !== 200){
            throw new Error(`Failed to get user entity. Status: ${resp.status}. Retry: ${retries}`);
          }
          users = resp.data.data;
          if(!users || users.length === 0){
            throw new Error(`Invalid user entity. Retry: ${retries}`);
          }
        }catch(err){
          console.error(err);
          retries++;
        }
      } while (!users && retries < RETRY_LIMIT);
      if(users){
        resolve(users);
      }else{
        reject("Failed to get user entity");
      }
    });
  }

  if(authStatus === AuthStatus.Loading || searchParams.has("code")){
    return <Spinner/>
  }

  const switchUserEntity = (id) => {
    const userEntity = users.find((user) => user.id === id);
    if(userEntity){
      setUserEntityDetails(userEntity);
      saveSession(USER_ENTITY, userEntity);
    } else {
      toast.error({ header: 'Error', body: intl.get('switchProfile.toast.error.fetchSwitchProfile')});
    }
  }

  return <AuthContext.Provider key={`user-entity-${userEntityDetails?.id}`}
  value={{
    cognitoUserSession,
    userEntityDetails,
    users,
    switchUserEntity,
    authStatus,
    sessionInfo,
    signOut,
  }}>
    {children}
  </AuthContext.Provider>
}

export default AuthProvider

export {
  getApiKey
}
import React, { useState, useEffect } from 'react';
import intl from 'components/i18n/ReactIntlWrapper';
import { Row, Col, Input } from 'reactstrap';
import { HiOutlineTrash, HiOutlinePencilAlt } from 'react-icons/hi';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import '../../scss/User/UserAsEmployeeEdit.scss';
import Spinner from '../Spinner';

const UserAsProviderEdit = ({ providers, roles, securityRoles, isDefault, idFe, entityId, isFormUserAsProviderOpen, isFormUserAsEmployeeOpen, isFormUserAsClientOpen, isFormUserAsCorporateEntityOpen, handleEditUserAsProvider, handleDelete }) => {

    const [loading, setLoading] = useState<boolean>(true);

    const selectedProvider = providers?.find(provider => provider.id === entityId);

    const assignedSecurityRoles = roles?.filter(role => securityRoles?.includes(role.id));

    useEffect(() => {
        if (selectedProvider && assignedSecurityRoles) {
            setLoading(false);
        }
    }, [selectedProvider, assignedSecurityRoles]);
    
    return (
        <Row className='mb-4'>
            {
                loading ?
                <Spinner small={true}/> :
                <>
                    <Col md={5} className={"col-input-assignment"} >
                        <Input
                            id="providerName"
                            name="name"
                            placeholder={intl.get('formUserAsClient.placeholder.name')}
                            value={selectedProvider?.name}
                            disabled={true}
                            className={"input-assignment"}
                        />
                    </Col>
                    <Col md={7} className='mt-2 mt-md-0'>
                        <Row>
                            <Col md={8} className='mb-1 mb-md-0'>
                                <Stack direction="row" spacing={1}>
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, maxHeight: '5rem', overflowY: 'auto', marginTop: '6px' }}>
                                            {
                                                assignedSecurityRoles.length > 0 ? (
                                                    assignedSecurityRoles?.map((role, index) => (
                                                        <Chip key={role.id} label={role.name} color="primary" variant="outlined" size="small"/>
                                                    ))
                                                ) : (
                                                    <Chip label={intl.get('userAsEmployeeEdit.noRolesAssigned')} color="default" variant="outlined" size="small"/>
                                                )
                                            }
                                    </Box>
                                </Stack>
                            </Col>
                            <Col md={2} className='d-flex align-items-end justify-content-center'>
                                <div className="form-check form-switch switch">
                                    <input className="form-check-input input-switch" type="checkbox" role="switch" id="switchCheckIsDefault" checked={isDefault} title='Default' disabled={true}/>
                                </div>
                            </Col>
                            <Col md={2}>
                                <Row>
                                    <Col md={6}>
                                        <a title={intl.get('userAsEmployeeEdit.editButton.title')} className={isFormUserAsProviderOpen || isFormUserAsEmployeeOpen || isFormUserAsClientOpen || isFormUserAsCorporateEntityOpen ? 'td-icon links-disabled' : 'td-icon'} role="button" onClick={() => handleEditUserAsProvider(idFe)}>
                                            <HiOutlinePencilAlt className='iconTrashEdit'/>
                                        </a>
                                    </Col>
                                    <Col md={6}>
                                        <a title={intl.get('userAsEmployeeEdit.deleteButton.title')} className={isFormUserAsProviderOpen || isFormUserAsEmployeeOpen || isFormUserAsClientOpen || isFormUserAsCorporateEntityOpen ? 'td-icon links-disabled' : 'td-icon'} role="button" onClick={() => handleDelete(idFe)}>
                                            <HiOutlineTrash className='iconTrashEdit'/>
                                        </a>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </>
            }
        </Row>
    )
}

export default UserAsProviderEdit;

import React, { FC } from 'react';
import Select from 'react-select';
import intl from 'components/i18n/ReactIntlWrapper';

type RequestSelectProps = {
    requestType: any,
    selectedRequest: any,
    handleRequestChange: any
    disabled?:boolean|false
};

const RequestSelect: FC<RequestSelectProps> = ({ requestType, selectedRequest, handleRequestChange, disabled }) => {

  const customStyles = {
    dropdownIndicator: (base: any) => ({
      ...base,
      cursor: 'pointer',
    }),
    menuPortal: (base: any) => ({
      ...base,
      zIndex: 9999, 
    }),
  };


  return (
    <>
      <Select
        styles={customStyles}
        id='request'
        name='request'
        getOptionLabel={e => intl.get(e.label)}
        getOptionValue={e => e.id}
        placeholder={intl.get('requestSelect.placeholder')}
        value={(requestType != null && selectedRequest != null) ? (requestType.find((req) => req.id === selectedRequest)) : ''}
        onChange={handleRequestChange}
        isLoading={requestType == null}
        options={requestType}
        isClearable={true}
        isDisabled={disabled}
        menuPortalTarget={document.body}
      />
    </>
  );
}

export default RequestSelect;

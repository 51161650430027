import React, { FC, useEffect, useState } from 'react';
import TimesheetAutocomplete from './TimesheetAutocomplete';
import TimesheetDayDuration from './TimesheetDayDuration'
import { addDays, format, set } from 'date-fns';
import { GetTimesheetsByIdEmployeeAndDateRangeGroupedByDateResponse, Project, Task, Timesheet } from 'axiosApi/models';
import { TimesheetEditProps, TimesheetInput, convertTimesheetToTimesheetInput, getClientProjectName, handleTimesheetSave } from './timesheetUtils';
import Api from 'axiosApi/api';
import { formatDate, formatDateForAPI, toHoursAndMinutes } from 'common/utils';
import { FaTrashAlt } from "react-icons/fa";
import { ToastMessageProviderProps, useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import { confirmAlert } from 'react-confirm-alert';
import intl from 'components/i18n/ReactIntlWrapper';
import { handleAPIError } from 'common/errorHandler';

type TimesheetRowProps = {
    api: Api,
    prefixKey: string,
    firstDay: Date,
    projects: Project[] | undefined,
    durationClick: (timesheetEditProps: TimesheetEditProps) => void,
    workingWeekDays: number,
    timesheetRow: GetTimesheetsByIdEmployeeAndDateRangeGroupedByDateResponse | undefined,
    preselectedTask: Task | null,
    idEmployee: number,
    handleTimesheetSave: Function,
    deleteRow: Function,
    isTaskInTimesheetRows: Function,
    loading: boolean,
    dateChanged: boolean,
    setDateChanged: Function
    deleteDisabled: boolean
}


const TimesheetRow: FC<TimesheetRowProps> = ({ api, prefixKey, firstDay, projects, durationClick, workingWeekDays, timesheetRow, preselectedTask, idEmployee, handleTimesheetSave, deleteRow, isTaskInTimesheetRows, loading, dateChanged, setDateChanged, deleteDisabled }) => {
    const [input, setInput] = useState<TimesheetInput>({
        idEmployee: idEmployee,
        idProject: timesheetRow ? timesheetRow.idProject : null,
        task: preselectedTask,
        description: '',
        date: new Date(),
        duration: '',
        startTime: null,
        taskIsModified: false
    });
    const [errors, setErrors] = useState({});
    const [tasks, setTasks] = useState<Task[]>();
    const toast = useToastMessageQueue();
    const [idProject, setIdProject] = useState(null);
    const [task, setTask] = useState(null);

    const [loadingTasks, setLoadingTasks] = useState(false);

    const fetchTaskByIdProject = async (api: Api, idProject: number, setTasksByProject: Function, input: TimesheetInput, toast: ToastMessageProviderProps, errors: any, setErrors: Function) => {

        if (input.idEmployee && idProject) {
            setLoadingTasks(true);
            await api.jobsApi.apiVversionTaskGetByEmployeeAndProjectIdEmployeeIdProjectGet(input.idEmployee, idProject, "1", {})
                .then((response) => {
                    if (response.data.data) {
                        setTasksByProject(response.data.data.queryResult);
                    }
                })
                .catch((error) => {
                    handleAPIError(error, toast, errors);
                    setErrors({ ...errors });
                }).finally(() => {
                    setLoadingTasks(false);
                });
        } else if (!idProject) {
            setTasksByProject([]);
        }
    };

    useEffect(() => {
        if (input.task != null && isTaskInTimesheetRows(input.task?.id) && preselectedTask == null) {
            setInput({ ...input, idProject: null, task: null });
        }
        if (input.task != preselectedTask && preselectedTask != null && input.task != null) {
            const idProject = timesheetRow ? timesheetRow.idProject : null;
            setInput({ ...input, idProject: timesheetRow ? timesheetRow.idProject : null, task: preselectedTask });
            setIdProject(timesheetRow ? timesheetRow.idProject : null);
            setTask(preselectedTask);
            if (idProject != null && input.task == null)
                fetchTaskByIdProject(api, idProject, setTasks, input, toast, errors, setErrors);
        }
        if (input.task == preselectedTask && preselectedTask != null && input.task != null && input.idProject == null) {
            const idProject = timesheetRow ? timesheetRow.idProject : null;
            setInput({ ...input, idProject: timesheetRow ? timesheetRow.idProject : null, task: preselectedTask });
            setIdProject(timesheetRow ? timesheetRow.idProject : null);
            setTask(preselectedTask);
            if (idProject != null)
                fetchTaskByIdProject(api, idProject, setTasks, input, toast, errors, setErrors);
        }

        // if ((timesheetRow && Object.keys(timesheetRow?.timesheetsGroupedByDate).length == 0 || timesheetRow == null) && input.idProject && !tasks) {
        //     fetchTaskByIdProject(api, input.idProject, setTasks, input, toast);
        // }
    }, [preselectedTask, idProject, task, timesheetRow]);

    useEffect(() => {
        if (!dateChanged) {
            (idProject !== null && task !== null) && (input.task == null || input.idProject == null) ? setInput({ ...input, idProject: idProject, task: task }) : null;
        }
        else {
            setIdProject(null);
            setTask(null);
            setDateChanged(false);
        }
    }, [input, idProject, task, dateChanged]);



    useEffect(() => {
        setInput({
            idEmployee: idEmployee,
            idProject: timesheetRow ? timesheetRow.idProject : null,
            task: preselectedTask,
            description: '',
            date: new Date(),
            duration: '',
            startTime: null,
            taskIsModified: false
        })

    }, [firstDay]);





    const handleDurationChange = (duration: String) => {
        setInput({
            ...input,
            duration: duration
        });
    }

    const handleDurationSave = (timesheet: Timesheet) => {
        handleTimesheetSave(api, { id: timesheet.id, idTask: timesheet.idTask ?? input.task, idProject: input.idProject, idEmployee: input.idEmployee, date: timesheet.date, duration: timesheet.duration, description: timesheet.description ?? '' }, () => { }, prefixKey);
    }

    const handleProjectChange = (idProject: number) => {
        setIdProject(idProject);
        if (idProject != input.idProject && timesheetRow) {

            setInput({
                ...input,
                idProject: idProject,
                task: (idProject ? input.task : null),
                taskIsModified: idProject != input.idProject

            });

            fetchTaskByIdProject(api, idProject, setTasks, input, toast, errors, setErrors);
            if (idProject == null && timesheetRow)
                Object.keys(timesheetRow.timesheetsGroupedByDate).forEach((key) => { timesheetRow.timesheetsGroupedByDate[key].idTask = null })

        } else {
            setInput({
                ...input,
                idProject: idProject,
                task: (idProject ? input.task : null),
                taskIsModified: idProject != input.idProject

            });
            fetchTaskByIdProject(api, idProject, setTasks, input, toast, errors, setErrors);
            if (idProject == null && timesheetRow)
                Object.keys(timesheetRow.timesheetsGroupedByDate).forEach((key) => { timesheetRow.timesheetsGroupedByDate[key].idTask = null })

        }
        //TODO: Si idProject esta vacio y hay timelogs cargados en el row mostrar ! de atencion acá y en Task.
    }
    const handleTaskChange = (_task: Task) => {
        if (_task != null && isTaskInTimesheetRows(_task.id)) {
            toast.error({ body: intl.get('timesheetRow.toast.error.handleTaskChange') });
            return;
        }
        setTask(_task);
        setInput({
            ...input,
            task: _task
        });

    }

    

    const totalDurationByTask = (): string => {
        return toHoursAndMinutes(timesheetRow ? Object.keys(timesheetRow.timesheetsGroupedByDate).map((date) => { return timesheetRow.timesheetsGroupedByDate[date].duration ? timesheetRow.timesheetsGroupedByDate[date].duration : 0 }).reduce((subtotal, current) => subtotal + current, 0) : 0);
    }

    const getTaskNameFromRow = (timesheetRow: GetTimesheetsByIdEmployeeAndDateRangeGroupedByDateResponse): string => {
        if (timesheetRow && timesheetRow.timesheetsGroupedByDate) {
            var task: Task = timesheetRow.timesheetsGroupedByDate[Object.keys(timesheetRow.timesheetsGroupedByDate)[0]].task;
            return task ? task.job.description + ' - ' + task.description : '';
        }
        return '';
    }
    
    return (
        <div className="d-flex py-2 pr-2 border-bottom" key={prefixKey}>
            <div className="timesheet-first-column-width row flex-grow-1">
                <div className="col-6 pr-2">
                    {(timesheetRow && Object.keys(timesheetRow?.timesheetsGroupedByDate).length == 0 || timesheetRow == null) &&
                        <TimesheetAutocomplete loading={loading} type="project" className="timesheet-autocomplete" key={'tpi_' + prefixKey} prefixKey={'tpi_' + prefixKey} options={projects} hasProjectValue={input.idProject ? true : false} value={input.idProject ? input.idProject : idProject} setValue={handleProjectChange} placeholder={intl.get('timesheetRow.autocomplete.placeholder.project')} name="idProject" disabled={(timesheetRow != null && Object.keys(timesheetRow.timesheetsGroupedByDate).length > 0) || loading}></TimesheetAutocomplete>
                    }
                    {timesheetRow && Object.keys(timesheetRow?.timesheetsGroupedByDate).length > 0 &&
                        <input type="text" className="form-control" value={getClientProjectName(projects, input.idProject)} disabled></input>
                    }
                </div>
                <div className="col-6 pl-0">
                    {(timesheetRow && Object.keys(timesheetRow?.timesheetsGroupedByDate).length == 0 || timesheetRow == null) &&
                        <>
                            <TimesheetAutocomplete loading={loadingTasks} type="task" className="timesheet-autocomplete" key={'tti_' + prefixKey} prefixKey={'tti_' + prefixKey} options={tasks} hasProjectValue={input.idProject ? true : false} value={input.task ? input.task?.id : task?.id} setValue={handleTaskChange} placeholder={intl.get('timesheetRow.autocomplete.placeholder.task')} name="idTask" disabled={(timesheetRow != null && Object.keys(timesheetRow.timesheetsGroupedByDate).length > 0) || tasks == undefined || ( tasks && tasks.length == 0) || idProject == null} ></TimesheetAutocomplete>
                            {Object.keys(errors).includes('idTask') && (<p className='timesheet-danger'>{errors['idTask']}</p>)}
                        </>
                    }
                    {timesheetRow && Object.keys(timesheetRow?.timesheetsGroupedByDate).length > 0 &&
                        <input type="text" className="form-control" value={getTaskNameFromRow(timesheetRow)} disabled></input>
                    }
                </div>
            </div>
            {[...Array(workingWeekDays)].map((x, i) => {
                const day: Date = addDays(firstDay, i);
                const formatedDay: string = formatDateForAPI(day);
                const timesheet: Timesheet | undefined = (timesheetRow && timesheetRow.timesheetsGroupedByDate && Object.keys(timesheetRow.timesheetsGroupedByDate).includes(formatedDay)) ? timesheetRow.timesheetsGroupedByDate[formatedDay] : undefined;

                const idTimesheet = timesheet ? timesheet.id : null;

                return <div className='timesheet-column-width' key={'tsd_' + prefixKey + i}>
                    <TimesheetDayDuration
                        disabled={((idTimesheet == null && (input.idProject == null || input.task == null)) || (loading || loadingTasks)) ? true : false}
                        duration={idTimesheet ? toHoursAndMinutes(timesheet.duration) : ''}
                        id={idTimesheet}
                        idTimesheet={idTimesheet}
                        onClick={() => { durationClick({ date: day, idTimesheet: idTimesheet, idProject: input.idProject, idRow: prefixKey, task: input.task, duration: timesheet ? toHoursAndMinutes(timesheet.duration) : '', description: timesheet ? timesheet.description : '' }) }}
                        handleDurationChange={handleDurationChange}
                        handleDurationSave={handleDurationSave}
                        timesheet={timesheet}
                        date={formatedDay}
                        description={timesheet ? timesheet.description : ''}

                    ></TimesheetDayDuration> </div>
            })}

            <div className="timesheet-delete-column-width d-flex align-items-center justify-content-center">
                <span className="h6 m-2 text-dark-light font-weight-500 align-self-center">
                    <FaTrashAlt tabIndex={1} style={{cursor: deleteDisabled ? 'not-allowed' : 'pointer'}} role="button" color={deleteDisabled ? "lightgrey" : "black"} onClick={() => {
                        if(deleteDisabled) return;
                        deleteRow(prefixKey.includes('new') ? prefixKey : input.task?.id);
                    }} />
                </span>
            </div>
            <div className="timesheet-totals-column-width d-flex align-items-center justify-content-end">
                <span className="h6 m-0 text-dark-light font-weight-500 align-self-center">{totalDurationByTask()}</span>
            </div>
        </div>
    );
}

export default TimesheetRow;
import React from 'react';
import intl from 'components/i18n/ReactIntlWrapper';
import { Row, Col } from 'reactstrap';
import { HiOutlineTrash } from 'react-icons/hi';
import UserSelectRole from './UserSelectRole';
import { confirmAlert } from 'react-confirm-alert';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import { Client } from 'axiosApi/models';
import Api from 'axiosApi/api';
import { useApi } from 'api/ApiProvider';
import TypeAhead from 'components/common/TypeAhead';

const UserAsClient = (props) => {

    const { handleClientChange,setClients ,clients, input, setInput, handleRoleChange, roles, securityRoles, idFe, hiddenRole, setHiddenRole } = props;

    const toast = useToastMessageQueue();

    const selectedClient = input.userDetails.filter(client => client.idFe === idFe);

    const { clientsApi }:Api = useApi();

    const deleteUserAsClient = () => {
        confirmAlert({
            title: intl.get('delete.modal.title'),
            message: intl.get('delete.modal.userAsClient.message'),
            buttons: [
                {
                    label: intl.get('delete.modal.cancel.button'),
                    onClick: () => { }
                },
                {
                    label: intl.get('delete.modal.delete.button'),
                    onClick: () => {
                        const updatedUserDetails = input.userDetails.filter(user => user.idFe !== idFe);
                        setInput((prevInput) => ({
                            ...prevInput,
                            userDetails: updatedUserDetails
                        }));
                        toast.success({ body: intl.get('userAsClient.toast.success.handleDelete')});
                    },
                }
            ]
        });
    };

    return (
        <Row className='mb-2'>
            <Col md={6}>
            <TypeAhead
                    apiPromise={(searchTerm: string) => clientsApi?.apiVversionClientTypeaheadTextGet(searchTerm, "1")}
                    getOptionLabel={(option: Client | any) => option?.entityName || option?.name}
                    value={clients?.find(client => client?.id === selectedClient?.entityId)}
                    label= {intl.get("reportsFilterComponent.clients")}
                    getOptionDisabled={(option) => option.entityId ?  option.entityId === clients?.entityId : option?.id === clients?.id}
                    initialText={intl.get('projectForm.placeholder.typeAhead.client')}
                    onChange={(event, newValue) => {
                        setClients(clients?.lenght > 0 ? [...clients,newValue] : [newValue]);
                        handleClientChange(newValue, idFe)
                    }}
                />
            </Col>
            <Col md={6} className='mt-2 mt-md-0'>
                <Row>
                    <Col md={11} className='mb-1 mb-md-0'>
                        <UserSelectRole
                            handleRoleChange={handleRoleChange}
                            selectedRoles={securityRoles}
                            roles={roles}
                            disabled={false}
                            id={idFe}
                            hiddenRole={hiddenRole}
                        />
                    </Col>
                    <Col md={1}>
                        <a title={intl.get('employeeRow.deleteButton.title')} className='td-icon' role="button" onClick={deleteUserAsClient}>
                            <HiOutlineTrash className='iconTrash'/>
                        </a>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default UserAsClient;

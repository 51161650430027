import React, { useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  AutocompleteFreeSoloValueMapping,
  AutocompleteProps,
  Chip,
  TextField,
} from "@mui/material";
import { useDebounce } from "@uidotdev/usehooks";
import intl from "components/i18n/ReactIntlWrapper";

type AutocompleteCustomProps<
  Value,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> = {
  apiPromise: (searchTerm: string) => Promise<any>;
  label?: string;
  inputClassName?: string;
  getOptionLabel: (
    option: Value | AutocompleteFreeSoloValueMapping<FreeSolo>
  ) => string;
  getOptionDisabled?: (option: Value) => boolean;
  value?: Value;
  onChange?: (event: React.ChangeEvent<{}>, value: Value | null) => void;
  disabled?: boolean;
  initialText?: string;
  charLength?: number;
  multiple?: boolean;
  disableClearable?: boolean;
  autocompleteProps?: AutocompleteProps<
    Value,
    Multiple,
    DisableClearable,
    FreeSolo
  >;
  isOptionEqualToValue?: (option: any, value: any) => boolean;
};

export default function TypeAhead<
  Value,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(props: AutocompleteCustomProps<Value, Multiple, DisableClearable, FreeSolo>) {
  const {
    apiPromise,
    label,
    inputClassName,
    getOptionLabel,
    getOptionDisabled,
    onChange,
    disabled,
    value,
    initialText,
    autocompleteProps,
    charLength,
    multiple,
    disableClearable,
    isOptionEqualToValue = (selectedOption, option) => selectedOption.id === option.id ,
  } = props;

  const DEBOUNCE_TIME = 1000;
  const MAX_LENGTH = charLength ?? 3;
  
  const [internalValue, setInternalValue] = useState<any | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, DEBOUNCE_TIME);
  const [options, setOptions] = useState<any[]>([]);
  const [noOptions, setNoOptions] =  useState<boolean>(false);
  const [autoCompleteLoading, setAutoCompleteLoading] =  useState<boolean>(false);
  const [limitTags, setLimitTags] = useState(1);
  const autocompleteRef = useRef<HTMLDivElement | null>(null);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (debouncedSearchTerm?.length < MAX_LENGTH) {
      setOptions([]);
      return;
    } else {
      setAutoCompleteLoading(true);
      apiPromise(searchTerm)
        .then((response) => {
          const { data } = response?.data;
          setOptions(data);
          setNoOptions(data?.length > 0 ? false : true);
        })
        .finally(() => {
          setAutoCompleteLoading(false);
        });
    }
  }, [debouncedSearchTerm]);

    useEffect(() => {
      const calculateLimitTags = () => {
        if (autocompleteRef.current) {
          const parent = autocompleteRef.current.querySelector(".MuiInputBase-root");
          const parentStyles = window.getComputedStyle(parent);
          const parentWidth = parent.clientWidth - parseFloat(parentStyles.paddingLeft) - parseFloat(parentStyles.paddingRight);
  
          // Width of the input. Initial approach to hide it when unfocused instead but this breaks Autocomplete.
          const input = autocompleteRef.current.querySelector( ".MuiInputBase-input");
          const inputStyles = window.getComputedStyle(input);
          const inputWidth = parseFloat(inputStyles.paddingLeft) + parseFloat(inputStyles.paddingRight) + parseFloat(inputStyles.minWidth);
  
          // Width of the tag (the "+1" for hidden items). Can't get the size if it doesn't exist, so we use 2x the font size of the input as a heuristic.
          const tagWidth = parseFloat(inputStyles.fontSize) * 2;
  
          let totalChipWidth = inputWidth + tagWidth;
          let chipsThatFit = 0;
  
          const chips = autocompleteRef.current.querySelectorAll(".MuiChip-root");
          chips.forEach((chip) => {
            const chipWidth =
              chip.clientWidth + parseInt(window.getComputedStyle(chip).marginRight) + parseInt(window.getComputedStyle(chip).marginLeft);
            if (totalChipWidth + chipWidth <= parentWidth) {
              totalChipWidth += chipWidth;
              chipsThatFit += 1;
            }
          });
          setLimitTags(chipsThatFit);
        }
      };
      calculateLimitTags();
      window.addEventListener("resize", calculateLimitTags);
      return () => {
        window.removeEventListener("resize", calculateLimitTags);
      };
    }, [value, isFocused]);

  useEffect(() => {
    if (value) {
      setInternalValue(value);
    }
  }, [value]);

  const getNoOptionsText = (searchTerm: string, options: any[], intialText: string) => {
    if (searchTerm?.length === 0){
      return intialText;
    }
    if (searchTerm?.length > 0 && searchTerm?.length < MAX_LENGTH) {
      return intl.get('placeholder.enterAtLeastXChars', { count: MAX_LENGTH });
    }
    if (searchTerm?.length >= MAX_LENGTH && noOptions && options?.length === 0) {
      return intl.get('placeholder.noOptions');
    }
    return intl.get('spinner') 
   };

  return (
    multiple ?  
    <Autocomplete
      ref={autocompleteRef}
      disableClearable={disableClearable ?? false}
      disablePortal
      open={isFocused}
      onOpen={() => setIsFocused(true)}
      isOptionEqualToValue={isOptionEqualToValue}
      onClose={(event, reason) => {
        if (reason === "blur") {
          setIsFocused(false);
        }
      }}
      multiple={multiple?? false}
      renderTags={(value, getTagProps) => value.map((option, index) => ( <Chip key={option.id} label={option.name} color="primary" variant="outlined" {...getTagProps({ index })} />)) }
      limitTags={limitTags}
      inputValue={searchTerm || ""}
      onInputChange={(event, newInputValue) => {
        setSearchTerm(newInputValue || '');
      }}
      options={options}
      loading={autoCompleteLoading}
      loadingText={intl.get('spinner')}
      value={internalValue || ""}
      onFocus={() => {
        const optionLabel = internalValue ? getOptionLabel(internalValue) : null;
        setSearchTerm(optionLabel || "");
      }}
      onChange={(event, newValue) => {
        setInternalValue(newValue);
        onChange(event, newValue as Value | null);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          className={`${inputClassName}`}
          size={autocompleteProps?.size || "small"}
          label={label}
          variant="outlined"
        />
      )}
      getOptionLabel={(option: Value) => {
        return getOptionLabel(option) || ''
      }}
      getOptionDisabled={getOptionDisabled}
      disabled={disabled}
      noOptionsText = {getNoOptionsText(searchTerm, options, initialText)}
      {...autocompleteProps}
    />
    :
    <Autocomplete
      inputValue={searchTerm || ""}
        onInputChange={(event, newInputValue) => {
          setSearchTerm(newInputValue || '');
        }}
        options={options}
        loading={autoCompleteLoading}
        loadingText={intl.get('spinner')}
        value={internalValue || ""}
        onFocus={() => {
          const optionLabel = internalValue ? getOptionLabel(internalValue) : null;
          setSearchTerm(optionLabel || "");
        }}
        onChange={(event, newValue) => {
          setInternalValue(newValue);
          onChange(event, newValue as Value | null);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            className={`${inputClassName}`}
            size={autocompleteProps?.size || "small"}
            label={label}
            variant="outlined"
          />
        )}
        getOptionLabel={(option: Value) => {
          return getOptionLabel(option) || ''
        }}
        getOptionDisabled={getOptionDisabled}
        disabled={disabled}
        noOptionsText = {getNoOptionsText(searchTerm, options, initialText)}
        {...autocompleteProps}
    />
  );
}

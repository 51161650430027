import React, { useContext, createContext, useEffect, useState } from 'react'
import { useDebounce, useLocalStorage } from "@uidotdev/usehooks";
import I18nUtils from 'i18n/I18n-utils';
import Api from 'axiosApi/api';
import { useApi } from 'api/ApiProvider';
import { changeCurrentLocale, init } from 'react-intl-universal';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { es } from 'date-fns/locale/es';
import { enUS } from 'date-fns/locale/en-US';
import Spinner from 'components/Spinner';
import { LOCALES_ISO_639_1 } from './locales';

const DateFnsLocales: { [key in LOCALES_ISO_639_1]?: any } = {
    en: enUS,
    es: es
}

const LOCALE_STORAGE_KEY = 'locale';
export const LocaleContext = createContext({
    locale: 'en' as LOCALES_ISO_639_1,
    languagesAvailable: [] as LOCALES_ISO_639_1[],
    changeLocale: (locale: LOCALES_ISO_639_1) => {},
    reloadLocales: () => {},
    loading: true,
    error: false
});

export default function LocaleProvider({ children }) {
    const [localeLoading, setLocaleLoading] = useState(true);
    const [intlLoading, setIntlLoading] = useState(true);

    const [error, setError] = useState(false);
    
    const [locale, setLocale] = useLocalStorage<LOCALES_ISO_639_1>(LOCALE_STORAGE_KEY, I18nUtils.getPreferredLanguage());
    const [locales, setLocales] = useState<Record<string, any> | null>(null);
    const { localeApi } : Api = useApi();

    const [languagesAvailable, setLanguagesAvailable] = useState<LOCALES_ISO_639_1[]>([]);

    const changeLocale = (newLocale: LOCALES_ISO_639_1) => {
        setLocale(newLocale);
        changeCurrentLocale(newLocale);
    }

    const reloadLocales = async () => {
        setLocaleLoading(true);
        localeApi.apiVversionLocaleGet("1").then((response) => {
            if (response.data.errors) {
                throw new Error('Failed to fetch locales');
            }
            const { data: { data } } = response;
            const locales = {};
    
            // Parse the data into the required format for react-intl-universal
            for (const [locale, translations] of Object.entries(data)) {
                locales[locale] = translations;
            }
            
            setLocales(locales);
        }).finally(() => {
            setLocaleLoading(false);
        });
    }

    useEffect(() => {
        if (!localeApi) {
            return;
        }
        reloadLocales();
    }, [localeApi]);

    useEffect(() => {
        if (localeLoading) {
            return;
        }
        let error = false;
        setIntlLoading(true);
        //check if locales are loaded
        if (!locales || Object.keys(locales).length === 0) {
            return;
        }

        //check if locales are loaded are valid
        if (!locales[locale]) {
            console.error(`Locale ${locale} is not available`);
            error = true;
        }

        //check if all locales in the list are valid using LOCALES_ISO_639_1 type
        const localeKeys = Object.keys(locales);
        for (const key of localeKeys) {
            const parsedKey = key as LOCALES_ISO_639_1;
            if(parsedKey === null){
                console.error(`Locale ${key} is not available`);
                error = true;
            }
        }

        setLanguagesAvailable(localeKeys as LOCALES_ISO_639_1[]);

        if (!error) {
            init({
                currentLocale: locale,
                locales
            }).finally(() => {
                setIntlLoading(false);
            });
        }else{
            setError(true);
            setIntlLoading(false);
        }

    }, [locales, localeLoading]);

    const debouncedLoading = useDebounce(localeLoading || intlLoading || locales == null, 200);
    return (
        <LocaleContext.Provider key={`lang-${locale}`} value={{
            locale,
            languagesAvailable,
            changeLocale,
            reloadLocales,
            loading: localeLoading || intlLoading,
            error
        }}>
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={{
                    ...DateFnsLocales[locale],
                    options: {
                        weekStartsOn: 1,
                    },
                }}
            >
                {debouncedLoading && <Spinner />} 
                {locales!==null && children}
            </LocalizationProvider>
        </LocaleContext.Provider>
    );
}
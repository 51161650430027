import { Box, IconButton, TableCell, TableRow, TextField } from "@mui/material"
import { useApi } from 'api/ApiProvider'
import Api from 'axiosApi/api'
import Spinner from "components/Spinner"
import { useToastMessageQueue } from "components/ToastMessages/ToastMessageProvider"
import useLocale from "i18n/useLocale"
import React, { useRef, useState } from "react"
import { BiSave, BiX } from "react-icons/bi"

type NewRowProps = {
  onClose: () => void
  languages: string[]
}

export default function NewRow({ onClose, languages }: NewRowProps) {
  const { localeApi }: Api = useApi();
  const keyRef = useRef<HTMLInputElement>(null);
  const componentsRef = useRef<{ [key: string]: HTMLInputElement | null }>({});
  const [errors, setErrors] = useState<string[]>([]);
  const toast = useToastMessageQueue();
  const [loading, setLoading] = useState(false);
  const { reloadLocales } = useLocale();

  const validate = () => {
    const validatingErrors = [];
    
    if (!keyRef.current?.value) {
      validatingErrors.push('key');
    }

    const hasAtLeastOneTranslation = languages.some((language) => 
      componentsRef.current[language]?.value?.trim()
    );

    if (!hasAtLeastOneTranslation) {
      validatingErrors.push('translation');
    }

    if (validatingErrors.length > 0) {
      setErrors(validatingErrors);
    } else {
      setErrors([]);
    }

    return !!keyRef.current?.value && hasAtLeastOneTranslation;
  }

  const clearErrors = () => {
    setErrors([]);
  }

  const saveNewKey = () => {
    if (!validate()) {
      toast.warning({ header: 'Warning', body: 'Label key and at least one translation is required' });
      return;
    }
    
    setLoading(true);
    const key = keyRef.current?.value || "";
    const translations = languages.map((language) => ({
      language,
      value: componentsRef.current[language]?.value || ""
    }));

    localeApi.apiVversionLocalePost("1", {
      locale: {
        key,
        localeTranslationDtos: translations
      }
    }).then(() => {
      reloadLocales();
      onClose();
    }).finally(() => {
      setLoading(false);
    });
  }

  return (<>
      {loading && <Spinner />}
      <TableRow>
        <TableCell>
          <TextField 
            inputRef={keyRef} 
            fullWidth 
            onFocus={clearErrors} 
            error={errors.includes('key')} 
            />
        </TableCell>
        {languages.map((language) => (
          <TableCell key={`new-key-${language}`}>
            <TextField 
              inputRef={(el) => (componentsRef.current[language] = el)} // Asignamos correctamente el ref
              fullWidth 
              onFocus={clearErrors} 
              error={errors.includes('translation')} 
              />
          </TableCell>
        ))}
        <TableCell align="center">
          <Box display="flex" gap={1} className="w-100" justifyContent={"center"}>
            <IconButton color="primary" onClick={saveNewKey} disabled={loading}>
              <BiSave />
            </IconButton>
            <IconButton color="primary" onClick={onClose}>
              <BiX />
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
}

import React, {useMemo, useEffect} from 'react';
import { Grid, Input, TextField } from "@mui/material";
import intl from 'components/i18n/ReactIntlWrapper';
import TaxDropDown from '../TaxDropDown';
import { useNewInvoice } from './newInvoiceProvider';

const NewInvoiceItemTableRow = (props) => {
    const { taxesValues, input, setInput } = useNewInvoice();
    const taxesList = useMemo(() => {
        return props?.invoiceItemTaxes
    }, [props?.invoiceItemTaxes])

    useEffect(() => {
        let taxSum = 0;
        props?.invoiceItemTaxes.forEach(tax => {
            taxSum += Number(tax.rate) * Number(props?.amount) / 100;
        });
        setInput({
            ...input,
            invoiceItems: input?.invoiceItems?.map((item) =>
                item.id === props.id
                    ? { ...item, taxesSum: taxSum} : item
            ),
        });
    }, [props?.invoiceItemTaxes, props?.amount]);

    const handleTaxChange = function (e, itemId, taxListId) {
        if (taxesList.some(tax => tax["id"] === e["id"])){
            return ;
        }
        const updatedTax = {
            id: e.id,
            rate: e.rate,
            name: e.name,
        };
        setInput({
            ...input,
            invoiceItems: input?.invoiceItems?.map((item) =>
                item.id === itemId
                    ? { ...item, invoiceItemTaxes: item?.invoiceItemTaxes?.map((tax) =>
                        tax.taxListId === taxListId
                        ? {...tax, ...updatedTax } : tax
                     )} : item
            ),
        });
    };

    const handleDeleteTax = function (itemId, taxListId) {
        const newItem = input?.invoiceItems?.find(item => item.id === Number(itemId))
        const newItemTax = newItem?.invoiceItemTaxes?.filter(item => item.taxListId !== taxListId)
        setInput({
            ...input,
            invoiceItems: input?.invoiceItems?.map((item) =>
                item.id === itemId
                    ? { ...item, invoiceItemTaxes: newItemTax } : item
            ),
        });
    };

    const handleNewTax = function(itemId){
        const filteredTaxes = taxesValues?.filter(tax => tax.appliesTo === 2);
        if (taxesList?.length >= filteredTaxes?.length){
            return;
        }
        const item = input?.invoiceItems?.find(item => item.id === Number(itemId))
        const itemTaxLastId = item?.invoiceItemTaxes[item?.invoiceItemTaxes.length - 1]?.["id"];
        const mappedTaxIds = taxesList?.map(tax => tax.id)
        const defaultTax = filteredTaxes?.find(tax => !mappedTaxIds?.includes(tax["id"]))
        const newItem = {
            taxListId: ( itemTaxLastId + 1 )|| 1,
            id: taxesList?.length === 0 ? "" : defaultTax.id,
            name: taxesList?.length === 0 ? "" :  defaultTax.name,
            rate: taxesList?.length === 0 ? "" :  defaultTax.rate,
        }
        setInput({
            ...input,
            invoiceItems: input?.invoiceItems?.map((item) =>
                item.id === itemId
                    ? { ...item, invoiceItemTaxes: [...item?.invoiceItemTaxes, newItem] }
                    : item
            ),
        });
    };
    return (
        <>
            {
                props.id ?
                <tr className={props.className}>
                    <td>
                        <TextField
                            id="description"
                            size='small'
                            name={`description[${props.id}]`}
                            placeholder={intl.get('newInvoice.desctiption.placeholder')}
                            value={props?.description}
                            onChange={(e) => props.handleInputItemTableChange(e)}
                            disabled={false}
                            fullWidth
                            multiline
                        />
                    </td>
                    <td>
                        <Input
                            id="quantity"
                            type="number"
                            name={`quantity[${props.id}]`}
                            placeholder={intl.get('newInvoice.qty.placeholder')}
                            value={props?.quantity}
                            onChange={(e) => props.handleInputItemTableChange(e)}
                            disabled={false}
                        />
                        {props.invoiceItemTaxes?.map((tax, key) => {
                            return <div key={key} style={{marginTop: "20px", width: "210%"}}>
                                <Grid container xs={12} md={12} lg={12} xl={12}>
                                    <Grid item xs={11} md={11} lg={11} xl={11}>
                                        <TaxDropDown handleTaxChange={(e) => handleTaxChange(e, props.id, tax.taxListId)} taxId={tax.id} disabled={false} isClearable={false}/>
                                    </Grid>
                                    <Grid container xs={1} md={1} lg={1} xl={1} className='align-items-center'>
                                        <Grid item xs={12} md={12} lg={12} xl={12} onClick={() => handleDeleteTax(props.id, tax.taxListId)} style={{paddingLeft: "5px"}}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M18.36 19.7807L12 13.4107L5.63997 19.7807L4.21997 18.3607L10.59 12.0007L4.21997 5.6407L5.63997 4.2207L12 10.5907L18.36 4.2307L19.77 5.6407L13.41 12.0007L19.77 18.3607L18.36 19.7807Z" fill="#FF0000"/>
                                            </svg>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        })}
                    </td>
                    <td>
                        <Input
                            id="rate"
                            type="number"
                            name={`rate[${props.id}]`}
                            placeholder={intl.get('newInvoice.rate.placeholder')}
                            value={props?.rate}
                            onChange={(e) => props.handleInputItemTableChange(e)}
                            disabled={false}
                        />
                    </td>
                    <td>
                        <Input
                            id="amount"
                            type="number"
                            name={`amount[${props.id}]`}
                            placeholder={intl.get('newInvoice.amount.placeholder')}
                            value={(props?.rate * props?.quantity).toFixed(2)}
                            onChange={(e) => props.handleInputItemTableChange(e)}
                            disabled={true}
                        />
                        <a style={{textDecoration: "underline", cursor: "pointer"}} onClick={() => handleNewTax(props.id)}>
                            +{intl.get('newInvoice.add.taxes')}
                        </a>
                    </td>
                    <td>
                    </td>
                    <td>
                        <div onClick={() => props.handleDelete(props.id)}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.36 19.7807L12 13.4107L5.63997 19.7807L4.21997 18.3607L10.59 12.0007L4.21997 5.6407L5.63997 4.2207L12 10.5907L18.36 4.2307L19.77 5.6407L13.41 12.0007L19.77 18.3607L18.36 19.7807Z" fill="#FF0000"/>
                            </svg>
                        </div>
                    </td>
                </tr>
                    :
                null
            }
        </>
    )
}

export default NewInvoiceItemTableRow;

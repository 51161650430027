import React, { useRef, useState } from 'react';
import { format, getWeek, startOfWeek, addDays, previousMonday, nextMonday } from 'date-fns';
import { usePopper } from 'react-popper';
import '../scss/DayPicker.scss';
import { HiArrowLeft, HiArrowRight } from "react-icons/hi";
import { BsCalendarDate } from "react-icons/bs";
import { Box, Button, ButtonGroup, Popover } from '@mui/material';
import WeekPicker from './WeekPicker';
import intl from 'components/i18n/ReactIntlWrapper';

interface WeekPickerDialogProps {
    selectedDate: Date
    handleDateChange: Function
    handleInputChange: any
    workingWeekDays: number
}
const WeekPickerDialog: React.FC<WeekPickerDialogProps> = ({ selectedDate, handleDateChange, handleInputChange, workingWeekDays }: WeekPickerDialogProps) => {
    const getInputValueText = (date: Date) => {
        let week = getWeek(date, { weekStartsOn: 1 });
        let firstDayOfWeek = startOfWeek(date, { weekStartsOn: 1 });
        let lastDayOfWeek = addDays(firstDayOfWeek, workingWeekDays - 1);
        let firstDateYear = firstDayOfWeek.getFullYear() == lastDayOfWeek.getFullYear() ? '' : " " + firstDayOfWeek.getFullYear();
        let weekMsg = (week == getWeek(new Date(), { weekStartsOn: 1 })) ? intl.get('timesheetComponent.weekPicker.thisWeekLabel') : (intl.get('timesheetComponent.weekPicker.weekLabel') + week + ", ");
        return weekMsg + format(firstDayOfWeek, 'MMM dd') + firstDateYear + " - " + format(lastDayOfWeek, 'MMM dd yyyy');
    }

    const [inputValue, setInputValue] = useState<string>(getInputValueText(selectedDate));
    const [isPopperOpen, setIsPopperOpen] = useState(false);

    const inputButtonRef = useRef<HTMLButtonElement>(null);
    const popperRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
        null
    );

    const popper = usePopper(popperRef.current, popperElement, {
        placement: 'bottom-start'
    });

    const closePopper = () => {
        setIsPopperOpen(false);
        buttonRef?.current?.focus();
    };


    const handleInputClick = () => {
        setIsPopperOpen(true);
    };

    const handleDaySelect = (date: Date | undefined) => {

        if (date) {
            let firstDayOfWeek = startOfWeek(date, { weekStartsOn: 1 });
            handleDateChange(firstDayOfWeek);
            setInputValue(getInputValueText(date));
            closePopper();
        } else {
            setInputValue('');
        }
    };
    const prevWeek = () => {
        let preMonday = previousMonday(selectedDate);
        handleDateChange(preMonday);
        setInputValue(getInputValueText(preMonday));
    };
    const nextWeek = () => {
        let preMonday = nextMonday(selectedDate);
        handleDateChange(preMonday);
        setInputValue(getInputValueText(preMonday));
    };

    //setInputValue(getInputValueText(selectedDate));
    return (<>
        <Box>
            <ButtonGroup variant="outlined" aria-label="Basic button group" color='inherit'>
                <Button
                    ref={buttonRef}
                    type="button"
                    aria-label="Prev Week"
                    onClick={prevWeek}
                >
                    <HiArrowLeft/>
                </Button>
                <Button ref={inputButtonRef} onClick={handleInputClick}>
                    {inputValue}
                </Button>
                <Button aria-describedby={"calendar-popover"} onClick={handleInputClick}><BsCalendarDate/></Button>
                <Popover
                    id={"calendar-popover"}
                    open={isPopperOpen}
                    anchorEl={inputButtonRef.current}
                    onClose={closePopper}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    classes={{ paper: 'px-2' }}
                >
                    <WeekPicker
                        value={selectedDate}
                        onChange={handleDaySelect}
                    />
                </Popover>
                <Button
                    ref={buttonRef}
                    type="button"
                    aria-label="Next Week"
                    onClick={nextWeek}
                >
                    <HiArrowRight/>
                </Button>
            </ButtonGroup>
        </Box>
        {/*
        <div className='weekpicker'>
            <div ref={popperRef}>
                <button
                    ref={buttonRef}
                    type="button"
                    className="button-reset weekpicker-button weekpicker-button-first"
                    aria-label="Prev Week"
                    onClick={prevWeek}
                >
                    <HiArrowLeft></HiArrowLeft>
                </button>
                <DatePicker
                    value={selectedDate}
                    onChange={handleDaySelect}
                    open={isPopperOpen}
                    autoFocus={false}
                    onOpen={() => setIsPopperOpen(true)}
                    onClose={() => setIsPopperOpen(false)}
                    slotProps={{
                        textField: {
                            size: 'small',
                            onClick: () => {
                                setIsPopperOpen(true);
                            },
                            className: 'input-reset col-3 weekpicker-input',
                            sx: {
                                userSelect: 'none',
                            },
                            InputProps: {
                                readOnly: true,
                                value: inputValue,
                                endAdornment: undefined,
                                sx: {
                                    borderRadius: 0,
                                    padding: 0,
                                    userSelect: 'none',
                                }
                            }
                        }
                    }}
                />
                    <input
                        type="text"
                        value={inputValue}
                        readOnly={true}
                        onClick={handleInputClick}
                        onChange={handleInputChange}
                        className="input-reset col-3 weekpicker-input"
                    />
                    <button
                        ref={buttonRef}
                        type="button"
                        className="button-reset weekpicker-button  weekpicker-button-calendar"
                        aria-label="Pick a date"
                        onClick={handleInputClick}
                    >
                        <BsCalendarDate></BsCalendarDate>
                    </button>
                <button
                    ref={buttonRef}
                    type="button"
                    className="button-reset weekpicker-button  weekpicker-button-last"
                    aria-label="Next Week"
                    onClick={nextWeek}
                >
                    <HiArrowRight></HiArrowRight>
                </button>
            </div>
            {isPopperOpen && (
                <FocusTrap
                    active
                    focusTrapOptions={{
                        initialFocus: false,
                        allowOutsideClick: true,
                        clickOutsideDeactivates: true,
                        onDeactivate: closePopper,
                        fallbackFocus: buttonRef.current?.id

                    }}
                >
                    <div
                        tabIndex={-1}
                        style={popper.styles.popper}
                        className="dialog-sheet"
                        {...popper.attributes.popper}
                        ref={setPopperElement}
                        role="dialog"
                    >
                        <DayPicker locale={es}
                            initialFocus={isPopperOpen}
                            mode="single"
                            defaultMonth={selectedDate}
                            selected={selectedDate}
                            onSelect={handleDaySelect}
                        />
                    </div>
                </FocusTrap>
            )}
        </div>
        */}
    </>);
}
export default WeekPickerDialog;
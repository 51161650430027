import React, { useState, useEffect } from 'react';
import intl from 'components/i18n/ReactIntlWrapper';
import { Row, Col } from 'reactstrap';
import { HiOutlineCheckCircle, HiOutlineXCircle } from 'react-icons/hi';
import UserSelectRole from './UserSelectRole';
import '../../scss/User/FormUserAsEmployee.scss';
import TypeAhead from 'components/common/TypeAhead';
import { Client } from 'axiosApi/models';
import Api from 'axiosApi/api';
import { useApi } from 'api/ApiProvider';

const FormUserAsClient = (props) => {
    const { disabled, setDisabled,idFe, securityRoles, roles, entityId, clients, setClients, handleClientChange, handleRoleChange, handleFormUserAsClientOpen, handleSave, edit, isDefault, onDefaultChange, previousDefaultId }= props;

    const [hiddenRole, setHiddenRole] = useState(false);

    const [isDefaultState, setIsDefaultState] = useState(isDefault);

    const { clientsApi }:Api = useApi();

    useEffect(() => {
        validate(entityId, securityRoles);
    }, [entityId, securityRoles]);

    useEffect(() => {
        setIsDefaultState(isDefault); 
    }, [isDefault]);

    const validate = (entityId, securityRoles) => {
        if (!entityId || securityRoles?.length === 0) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    };

    const handleSwitchChange = (event) => {
        const newValue = event.target.checked;
        if (newValue) {
            setIsDefaultState(true);
            onDefaultChange(idFe, true); 
        } else {
            setIsDefaultState(false);
            if (previousDefaultId && previousDefaultId !== idFe) {
                onDefaultChange(previousDefaultId, true); 
            }
        }
    };

    return (
        <Row className='mb-3'>
            <Col md={5}>
                <TypeAhead
                    apiPromise={(searchTerm: string) => clientsApi?.apiVversionClientTypeaheadTextGet(searchTerm, "1")}
                    getOptionLabel={(option: Client | any) => option.entityName || option.name}
                    value={clients}
                    label= {intl.get("reportsFilterComponent.clients")}
                    disabled={entityId && edit ? true : false}
                    getOptionDisabled={(option) => option?.entityId ?  option?.entityId === clients?.entityId : option?.id === clients?.id}
                    initialText={intl.get('projectForm.placeholder.typeAhead.client')}
                    onChange={(event, newValue) => {
                        setClients(newValue);
                        handleClientChange(newValue)
                    }}
                />
            </Col>
            <Col md={7} className='mt-2 mt-md-0'>
                <Row>
                    <Col md={8} className='mb-1 mb-md-0'>
                        <UserSelectRole
                            handleRoleChange={handleRoleChange}
                            selectedRoles={securityRoles}
                            roles={roles}
                            disabled={false}
                            id={idFe}
                            hiddenRole={hiddenRole}
                        />
                    </Col>
                    <Col md={2} className='d-flex align-items-top default'>
                        <div className="form-check form-switch switch">
                            <input 
                                className="form-check-input input-switch" 
                                type="checkbox" 
                                role="switch" 
                                id="switchCheckIsDefault" 
                                checked={isDefaultState} 
                                title='Default' 
                                disabled={!edit}
                                onChange={edit ? handleSwitchChange : null}
                            />
                        </div>
                    </Col>
                    <Col md={2}>
                        <Row>
                            <Col md={6}>
                                <a title={intl.get('userAsEmployeeEdit.cancelButton.title')} className='td-icon' role="button" onClick={handleFormUserAsClientOpen}>
                                    <HiOutlineXCircle className={entityId ? 'iconCancelEdit' : 'iconCancel'}/>
                                </a>
                            </Col>
                            <Col md={6}>
                                <a title={intl.get('userAsEmployeeEdit.confirmButton.title')} className='td-icon' role="button" onClick={() => disabled ? null : handleSave(idFe, isDefault)}>
                                    <HiOutlineCheckCircle className={entityId && disabled ? 'iconSaveEdit saveDisabled' : entityId && !disabled ? 'iconSaveEdit' : !entityId && disabled ? 'iconSave saveDisabled' : 'iconSave'}/>
                                </a>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
};

export default FormUserAsClient;
import React, { FC, useEffect } from 'react';
import Select from 'react-select';
import intl from 'components/i18n/ReactIntlWrapper';

type AddressType = {
  id: number;
  label: string;
};

type AddressTypeSelectProps = {
  handleAddressTypeChange: (selectedOption: AddressType | null) => void,
  selectedAddressType: any,
  addressTypes: any,
  realAddress: boolean,
  legalAddress: boolean
};

const AddressTypeSelect: FC<AddressTypeSelectProps> = ({ addressTypes, selectedAddressType, handleAddressTypeChange, realAddress = null, legalAddress = null }) => {
  
  const fixedValue =
    realAddress && addressTypes
      ? addressTypes.find((addressType) => addressType.id === 1)
      : legalAddress && addressTypes
      ? addressTypes.find((addressType) => addressType.id === 2)
      : null;

  const value =
    fixedValue || (addressTypes && selectedAddressType != null
      ? addressTypes.find((addressType) => addressType.id === selectedAddressType)
      : null);

  useEffect(() => {
    if (fixedValue && fixedValue.id !== selectedAddressType) {
      handleAddressTypeChange(fixedValue);
    }
  }, [fixedValue, selectedAddressType, handleAddressTypeChange]);
  
  return (
    <Select
      id="addressType"
      name="addressType"
      getOptionLabel={realAddress || legalAddress ? e => e.label : e => intl.get(e.label)}
      getOptionValue={e => e.id}
      placeholder={intl.get('addressForm.placeholder.addressType')}
      value={value}
      onChange={(selectedOption) =>
        handleAddressTypeChange(selectedOption as AddressType)
      }
      isLoading={addressTypes == null}
      options={addressTypes}
      isDisabled={realAddress || legalAddress}
    />
  );
}

export default AddressTypeSelect;
import React, { useEffect } from 'react';
import intl from 'components/i18n/ReactIntlWrapper';
import ReactLoading from 'react-loading';
import '../scss/Spinner.scss';
import { Backdrop } from '@mui/material';
import { useLayout } from './Layout/LayoutProvider';

interface TestProps {
    small?: boolean;
}

const Spinner = ({small = false}:TestProps) => {
        const { setLoading } = useLayout();
        useEffect(() => {
            if(!small){
                setLoading(true);
                return () => {
                    setLoading(false);
                }
            }
        }, []);

        if(small){
            return(
            <div className='spinner'>
            <div className="loading-bar" >
            <ReactLoading type={"bars"} color={"#005BAF"} height={100} width={100} />
            </div>
            </div>)
        }
       

    
}

export default Spinner;

import React, { useState } from 'react';
import intl from 'components/i18n/ReactIntlWrapper';
import { HiOutlineXCircle } from 'react-icons/hi';

const TimeOffSearchBar = ({ queryString, setQueryString }) => {

    return (
        <div className="d-flex">
            <div className="d-flex col-3 input-group">
                <input 
                    type="text" 
                    className="form-control" 
                    placeholder={intl.get('timeOffSearchBar.placeholder')} 
                    aria-label="Search"
                    value={queryString}
                    onChange={e => setQueryString(e.target.value)}
                />
                {
                    queryString ?
                    <button 
                        className="btn btn-outline-secondary close p-0" 
                        type="button"
                        onClick={(e) => {
                            setQueryString('');
                        }}
                    >
                        <HiOutlineXCircle className='mx-1 iconButton'/>
                    </button> :
                    null
                }
            </div>
        </div>
    )
}

export default TimeOffSearchBar;
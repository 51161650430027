import React, { useState } from 'react';
import { Link } from 'react-router';
import '../../scss/Roles/RoleNavbar.scss';
import { HiOutlinePlus } from 'react-icons/hi';
import { Tooltip as ReactTooltip } from "react-tooltip";
import intl from 'components/i18n/ReactIntlWrapper';
import StatusSelect from '../StatusSelect';
import ScopeSelect from './ScopeSelect';
import EntitySelect from './EntitySelect';
import { FaSearch } from "react-icons/fa";
import RoleSearchBar from './RoleSearchBar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import SearchButton from 'components/common/SearchButton';
import PlusButton from 'components/common/PlusButton';

const RoleNavbar = ({ entities, status, scopes, onSearch, input }) => {
    
    const [queryString, setQueryString] = useState<string>('');
    const [idScope, setIdScope] = useState<string>(null);
    const [userEntityId, setUserEntityId] = useState<string>(null);
    const [entityId, setEntityId] = useState<string>(null);
    const [entityTypeId, setEntityTypeId] = useState<string>(null);
    const [idStatus, setIdStatus] = useState<string>(null);

    const handleSearchButton = () => {
        onSearch(queryString, idScope, entityId, entityTypeId, idStatus);
    };

    const handleScopeChange = (e, v) => {
        if (v !== null) {
            setIdScope(v.id);
        } else {
            setIdScope(null);
        }
    };

    const handleEntityChange = (e, v) => {
        if (v !== null) {
            setEntityId(v.id);
            setEntityTypeId(v.typeId);
        } else {
            setEntityId(null);
            setEntityTypeId(null);
        }
    };

    const handleStatusChange = (e, v) => {
        if (v !== null) {
            setIdStatus(v.active);
        } else {
            setIdStatus(null);
        }
    };

    return (<>
        <Grid container spacing={1}>
                <Grid md item>
                <RoleSearchBar queryString={queryString} setQueryString={setQueryString}/>
                </Grid>
                <Grid md={2} item>
                <ScopeSelect scopes={scopes} handleScopeChange={handleScopeChange} selectedScope={idScope}/>
                </Grid>
                <Grid md={2} item>
                <EntitySelect entities={entities} handleEntityChange={handleEntityChange} selectedEntity={entityId} selectedEntityType={entityTypeId} disabled={false} input={input} idNumber={null}/>
                </Grid>
                <Grid md={2} item>
                <StatusSelect status={status} handleStatusChange={handleStatusChange} selectedStatus={idStatus} disabled={false} defaultValue={false} isClearable={true}/>
                </Grid>
                <Grid md={2} item>
                    <SearchButton clickEvent={handleSearchButton} />
                </Grid>
                <Grid item
                    xs="auto" // Works like width:fit-content.
                >
                    <PlusButton link='/role/new' tooltip="role.new"/>
                </Grid>
        </Grid>
    </>)
}

export default RoleNavbar;
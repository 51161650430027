// Note: This file is used to provide utility functions for i18n
import { LOCALES_ISO_639_1 } from "components/i18n/locales";

const DEFAULT_LANGUAGE = 'en';
/**
 * Converts a RFC5646 language code to an ISO 639-1 language code
 * @param rfc5646 The RFC5646 language code (e.g. en-US)
 * @returns The ISO 639-1 language code (e.g. en)
 */
const rfc5646ToIso6391 = (rfc5646) => {
    return rfc5646.split('-')[0];
}

/**
 * Gets the preferred language of the user
 * @returns The preferred language of the user
 * @default 'en'
 */
const getPreferredLanguage = ():LOCALES_ISO_639_1 => {
    let language = navigator.language || navigator.languages[0] || DEFAULT_LANGUAGE;
    language = rfc5646ToIso6391(language) as LOCALES_ISO_639_1;
    return (language || DEFAULT_LANGUAGE) as LOCALES_ISO_639_1;
}

export default {
    rfc5646ToIso6391,
    getPreferredLanguage
}
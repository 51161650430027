import React, { useMemo, useState } from "react";
import "../../scss/Time Off/FileUploader.scss";
import intl from 'components/i18n/ReactIntlWrapper';
import { useDropzone } from "react-dropzone";
import { Col, Row } from "reactstrap";
import { HiDocument } from "react-icons/hi";

const StyledDropzone = ({ files ,setInput,disabled = false }) => {
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
  useDropzone({
    accept: { "image/png": [], "image/jpeg": [], "application/pdf": [] },
    onDrop: (file) => {
      if (
        file?.[0]?.type === "image/jpeg" ||
        file?.[0]?.type === "image/png" ||
        file?.[0]?.type === "application/pdf"
      ) {
        if( !files.some((attachment) => attachment.name ? attachment.name == file[0].name :  attachment == file[0].name) ){
          setInput((prevInput) => ({
            ...prevInput,
            attachments: prevInput.attachments ? [...prevInput.attachments, file[0]] : [file[0]]
          }));
        }
      }
    },
    noClick: true,
    disabled: disabled
  });

  const className = useMemo(() => {
    let classNames = "base-style";
    if (isFocused) classNames += " focused-style";
    if (isDragAccept) classNames += " accept-style";
    if (isDragReject) classNames += " reject-style";
    return classNames;
  }, [isFocused, isDragAccept, isDragReject]);

  return (
    <div>
      <div {...getRootProps({ className })}>
        <input {...getInputProps()} />
          <Row className="justify-content-around">
              <div>
                <HiDocument className="iconFileTimeOff" />
              </div>
              <b>{intl.get("timeOff.attached.files")}</b>
              <div className="dropZoneText">
                <p>
                  {intl.get("timeOff.file.type")}
                  <br></br>
                  {intl.get("timeOff.file.size")}
                </p>
              </div>
          </Row>
      </div>
    </div>
  );
};

export default StyledDropzone;

import React from "react"
import { ENTITY_TYPE } from 'common/constants';
import { Outlet, Route, RouteProps, Routes } from "react-router"
import ErrorTemplateFoundPage from "./common/ErrorTemplatePage"
import intl from 'components/i18n/ReactIntlWrapper'
import { hasPermission } from "./hasPermission";
import { getUserEntity } from "common/UserEntityProvider";
import ROUTES, { RoutePermissionDefinition } from "Routes";

export default function SecurityWrapperRoutes() {
    const entityType = getUserEntity()?.entityType;
    
    return (
        <Routes>
            {Object.keys(ROUTES).map((key) => {
                const entityTypeSection = parseInt(key);

                return ROUTES.map((route) => {
                        const routeProps = route as RoutePermissionDefinition;
                        const permissionsProps = route as RoutePermissionDefinition;
                        const { scopes } = permissionsProps;
                        const scopeGranted = scopes?hasPermission({ scopes }):true;
                        const entityTypeGranted = entityTypeSection === entityType || entityTypeSection === ENTITY_TYPE.ANY;
                        const viewGranted = scopeGranted && entityTypeGranted;
                        
                        return <Route key={`${entityTypeSection}-${routeProps.path}`} element={viewGranted ? <Outlet /> : <ErrorTemplateFoundPage statusCode={401} title={intl.get('errorPage.unauthorized')} />}>
                            <Route {...routeProps}/>
                        </Route>
                    });
            })}
            <Route path="*" element={<ErrorTemplateFoundPage statusCode={404} title={intl.get('notFoundPage.title')} />} />
        </Routes>
    );
}
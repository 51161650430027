import React, {useContext, useEffect} from "react";
import { useNavigate } from 'react-router';
import  { AuthContext, AuthStatus } from './authentication/context/authContext'
import SignInButton from "./SignInButton";
import Spinner from './Spinner';

export const PageLogin = () => {
    const { authStatus } = useContext(AuthContext)
    const isAuthenticated = authStatus === AuthStatus.SignedIn;
    const navigate = useNavigate();

    useEffect(() => {
        if(isAuthenticated){
            navigate('/');
        }
    }, [authStatus])

    return (
        <div>
            {authStatus === AuthStatus.Loading ? (
                <Spinner/>
            ) : (
                <div className="signInBox">
                    <img src="/biglogo.png" width={300}/>
                    <div className="mb-3">Borderless Enterprise Resource Planning</div>
                    <div>
                        <SignInButton/>
                    </div>
                </div>
            )}
        </div>
    );
}

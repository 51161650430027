import React, { useState, useEffect } from 'react';
import { Grid, Typography, Input } from "@mui/material";
import { useNavigate } from 'react-router';
import Spinner from '../Spinner';
import '../../scss/ClientForm.scss';
import Select from 'react-select';
import intl from 'components/i18n/ReactIntlWrapper';
import NewInvoiceItemTable from './NewInvoiceItemTable'
import NewInvoiceSummary from './NewInvoiceSummary'
import Chip from "@mui/material/Chip";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useNewInvoice } from './newInvoiceProvider';
import { useApi } from 'api/ApiProvider';
import Api from "axiosApi/api";
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import WorkflowActionsBar from '../Workflow/WorkflowActionsBar';
import { useWorkflow } from 'components/Workflow/WorkflowContext';
import { handleAPIError } from '../../common/errorHandler';

const NewInvoiceForm = ({ errors, setErrors, handleInputChange, disabled }) => {
    const { currencies, invoiceTypes, billToPersonas, isBillToPersonasLoading, input, setInput, isManualCreation, isBillingAddressFromLoading, isBillingAddressToLoading } = useNewInvoice();
    const [currencyValue, setCurrencyValue] =  useState();
    const [invoiceTypeValue, setInvoiceTypeValue] = useState();
    const [billToValue, setBillToValue] = useState();
    const [statusLabel, setStatusLabel] = useState<String>();
    const [loading, setLoading] = useState<boolean>(false);
    const [dateValue, setDateValue] = React.useState<any>(null);
    const [workflowLabelKeys, setWorkflowLabelKeys] = useState(null);
    const toast = useToastMessageQueue();
    const api: Api = useApi();
    const navigate = useNavigate();
    const { workflow } = useWorkflow();

    useEffect(() => {
        setWorkflowLabelKeys(
            workflow?.states?.map(state => ({ label : state.labelKey, id : state.id }))
        )
    },[workflow])

    useEffect(() => {
        const currentStatus = workflowLabelKeys?.filter((labelKey) => labelKey.id === input.idWorkflowState)
        if (!currentStatus?.[0]){
            setStatusLabel("invoicing.manualReview")
        } else {
            setStatusLabel(currentStatus?.label)
        }
    },[workflowLabelKeys])

    const handleDateChange = (date) => {
        setDateValue(date);
        setInput({
            ...input,
            date: date
        });
    };

    const handleCurrencyChange = (e) => {
        if (e !== null) {
            setCurrencyValue(e.id);
            if (errors?.idCurrency) {
                const { idCurrency: omit, ...rest } = errors;
                setErrors(rest);
            }
        } else {
            setCurrencyValue(null);
        }
        setInput({
            ...input,
            idCurrency: e.id
        });
    };

    const handleInvoiceTypeChange = (e) => {
        if (e !== null) {
            setInvoiceTypeValue(e.id);
        } else {
            setInvoiceTypeValue(null);
        }
        setInput({
            ...input,
            idInvoiceType: e.id
        });
    };

    const handleBillToChange = (e) => {
        if (e !== null) {
            setBillToValue(e.id);
            setInput({
                ...input,
                idTo: e.id,
                to: e.name
            })
        } else {
            setBillToValue(null);
            setInput({
                ...input,
                idTo: null,
                to: null
            });
        }
    };

    const handleSave = async (id, comments) => {
        setLoading(true);
        console.log(input)
        input.comments = comments;
        try {
            // Validate required fields
            const errors = {};
            if (!input.number) errors['number'] = `${intl.get('newInvoice.numberRequired')}`;
            if (!input.idCurrency) errors['idCurrency'] = `${intl.get('newInvoice.currencyRequired')}`;
            if (!input.idInvoiceType) errors['idInvoiceType'] = `${intl.get('newInvoice.isRequired')}`;
            if (!input.date) errors['date'] = `${intl.get('newInvoice.dateRequired')}`;
            if (!input.paymentTerms) errors['paymentTerms'] = `${intl.get('newInvoice.areRequired')}`;
            if (!input.addressFrom) errors['addressFrom'] = `${intl.get('newInvoice.isRequired')}`;
            if (!input.addressTo) errors['addressTo'] = `${intl.get('newInvoice.isRequired')}`;
            if (!input.idFrom) errors['idFrom'] = `${intl.get('newInvoice.profileRequired')}`;
            if (!input.idTo) errors['idTo'] = `${intl.get('newInvoice.isRequired')}`;
            if (!input.invoiceItems || input.invoiceItems.length === 0) errors['invoiceItems'] = `${intl.get('newInvoice.itemsRequired')}`;
            if (!input.attachments && !isManualCreation) errors['attachments'] = `${intl.get('newInvoice.isRequired')}`;

            if (Object.keys(errors).length > 0) {
                setErrors(errors);
                setLoading(false);
                return;
            }

            if (input.invoiceItems) {
                input.invoiceItems = input.invoiceItems.map(item => {
                    if (item.invoiceItemTaxes && item.invoiceItemTaxes.length > 0) {
                        const itemAmount = (Number(item.quantity) || 0) * (Number(item.rate) || 0);
                        item.invoiceItemTaxes = item.invoiceItemTaxes.map(tax => ({
                            idTaxRate: tax.id,
                            taxAmount: itemAmount * (Number(tax.rate) / 100),
                            taxRate: Number(tax.rate),
                            invoiceItemId: 0,
                            invoiceItem: {}
                        }));
                    }
                    return item;
                });
            }

            input.idWorkflowState = id,
            input.number = input.number.toString();
            input.idInvoiceType = Number(input.idInvoiceType)

            if (input.attachments) {
                const reader = new FileReader();
                reader.readAsDataURL(input.attachments[0]);
                await new Promise<void>((resolve) => {
                    reader.onload = () => {
                        input.fileURI = reader.result;
                        resolve();
                    };
                });
            } else {
                input.fileURI = null;
            }

            if (input.date) {
                const date = new Date(input.date);
                input.date = date.toISOString().split('T')[0];
            }
            if (input.invoice) {
                const reader = new FileReader();
                reader.readAsDataURL(input.invoice);
                await new Promise(() => {
                    reader.onload = () => {
                        input.fileURI = reader.result;
                        delete input.invoice;
                    };
                });
            }
            const response = await api.invoiceApi.apiVversionInvoicePost("1", input);
            if (response.data?.data) {
                toast.success({ body: intl.get('newInvoice.toast.success.handleSaveAsDraft') });
                navigate("/myInvoices");
            }
        } catch (error) {
            handleAPIError(error, toast, errors);
            setErrors({ ...errors });
        }
        setLoading(false);
    };

    return (
        <>
            <Grid container xs={12} md={12} lg={12} xl={12} className='justify-content-around' style={{marginTop: (errors?.number || errors?.idCurrency) ? "20px" : "0px"}}>
                <Grid container xs={6} md={6} lg={6} xl={6}>
                    <Grid item xs={12} md={12} lg={12} xl={12} container>
                        <Grid item xs={5} md={5} lg={5} xl={5}>
                            {
                                loading === true ?
                                <Spinner /> :
                                <h2 className="title">{intl.get('newInvoice.header')}</h2>
                            }
                        </Grid>
                        <Grid item xs={12} md={7} lg={7} xl={7} style={{marginTop: errors?.number ? "-30px" : "0px"}}>
                            {errors?.number && (
                                <Typography className='text-danger'>
                                    {errors?.number}
                                </Typography>
                            )}
                            <Input
                                id="number"
                                name="number"
                                placeholder={intl.get('newInvoice.invoiceNumber')}
                                value={input?.number}
                                onChange={(e) => handleInputChange(e)}
                                disabled={disabled}
                                type='number'
                                fullWidth
                                />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container xs={6} md={6} lg={6} xl={6}>
                    <Grid container item xs={12} md={12} lg={12} xl={12} className='justify-content-start'>
                        <Grid item xs={12} md={7} lg={7} xl={7} style={{ display: "flex", justifyContent: "flex-end", marginTop: "2px", paddingRight: "10px"}}>
                            <Chip
                                key="statusChip"
                                label={intl.get(`${statusLabel}`)}
                                color="primary"
                                variant="filled"
                                style={{
                                    fontWeight: "bold"
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={5} lg={5} xl={5} style={{marginTop: errors?.idCurrency ? "-30px" : "0px"}}>
                            {errors?.idCurrency && (
                                <Typography className='text-danger'>
                                    {errors?.idCurrency}
                                </Typography>
                            )}
                            <Select
                                id='currency'
                                name='currency'
                                getOptionLabel={e => e.code}
                                getOptionValue={e => e.id}
                                placeholder={intl.get('newInvoice.invoiceCurrency')}
                                value={(currencyValue != null && currencies != null) ? (currencies?.find((req) => req.id === currencyValue)) : ''}
                                onChange={(e) => handleCurrencyChange(e)}
                                isLoading={false}
                                options={currencies}
                                isClearable={false}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container xs={12} md={12} lg={12} xl={12} className='justify-content-around'>
                <Grid container item xs={6} md={6} lg={6} xl={6}>
                    <Grid container item xs={12} md={12} lg={12} xl={12}>
                        <Typography className={`${errors?.addressFrom ? 'text-danger' : ''}`}>
                            {intl.get('newInvoice.invoiceAddressFrom')} {errors?.addressFrom && (<span className='text-danger'>{errors?.addressFrom}</span>)}
                        </Typography>
                        <Input
                            id="addressFrom"
                            name="addressFrom"
                            placeholder={intl.get('newInvoice.invoiceAddressFrom')}
                            value={input?.addressFrom}
                            onChange={(e) => handleInputChange(e)}
                            disabled={isBillingAddressFromLoading}
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={6} md={6} lg={6} xl={6}>
                    <Grid container item xs={12} md={12} lg={12} xl={12}  className='justify-content-end' style={{marginTop: "30px", alignContent: "center", display: "flex"}}>
                            <Typography className={`${errors?.date ? 'text-danger' : ''}`} style={{marginTop: "10px"}}>
                                {!errors?.date && intl.get('newInvoice.date')} {errors?.date && (<span className='text-danger'>{errors?.date}</span>)}
                            </Typography>
                            <div style={{marginTop: "-6px", marginLeft: "10px"}}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker
                                        slotProps={{
                                            openPickerIcon: { fontSize: 'small' },
                                            textField: {
                                                size: "small",
                                            }
                                        }}
                                        className="Invoice.DatePicker"
                                        value={dateValue}
                                        onChange={(e) => handleDateChange(e)}/>
                                </DemoContainer>
                            </LocalizationProvider>
                            </div>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container xs={12} md={12} lg={12} xl={12} className='justify-content-around' style={{marginTop: "10px"}}>
                <Grid container xs={6} md={6} lg={6} xl={6}>
                    <Typography className={`${errors?.idTo ? 'text-danger' : ''}`} style={{marginTop: "5px"}}>
                        {intl.get('newInvoice.to')} {errors?.idTo && (<span className='text-danger'>{errors?.idTo}</span>)}
                    </Typography>
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                        <Select
                            id='billTo'
                            name='billTo'
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e.id}
                            placeholder={intl.get('newInvoice.to')}
                            value={(billToValue != null && billToPersonas != null) ? (billToPersonas?.find((req) => req.id === billToValue)) : ''}
                            onChange={(e) => handleBillToChange(e)}
                            isLoading={isBillToPersonasLoading}
                            options={billToPersonas}
                            isClearable={true}
                        />
                    </Grid>
                </Grid>
                <Grid container xs={6} md={6} lg={6} xl={6}>
                </Grid>
            </Grid>
            <Grid container item xs={12} md={12} lg={12} xl={12} className='justify-content-around' style={{marginTop: "10px"}}>
                <Grid container item xs={6} md={6} lg={6} xl={6}>
                    <Typography className={`${errors?.addressTo ? 'text-danger' : ''}`}>
                        {intl.get('newInvoice.invoiceAddressTo')} {errors?.addressTo && (<span className='text-danger'>{errors?.addressTo}</span>)}
                    </Typography>
                    <Input
                        id="addressTo"
                        name="addressTo"
                        placeholder={intl.get('newInvoice.invoiceAddressTo')}
                        value={input?.addressTo}
                        onChange={(e) => handleInputChange(e)}
                        disabled={isBillingAddressToLoading}
                    />
                </Grid>
                <Grid container xs={6} md={6} lg={6} xl={6}>
                </Grid>
            </Grid>
            <hr/>
            <Grid container xs={12} md={12} lg={12} xl={12}>
                <Grid container xs={6} md={6} lg={6} xl={6}  className='justify-content-start'>
                    <Typography className={`${errors?.idInvoiceType ? 'text-danger' : ''}`} style={{marginTop: "5px"}}>
                        {intl.get('newInvoice.invoiceType')} {errors?.idInvoiceType && (<span className='text-danger'>{errors?.idInvoiceType}</span>)}
                    </Typography>
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                        <Select
                        id='invoiceType'
                        name='invoiceType'
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e.id}
                        placeholder={intl.get('newInvoice.invoiceType')}
                        value={(invoiceTypeValue != null && invoiceTypes != null) ? (invoiceTypes?.find((req) => req.id === invoiceTypeValue)) : ''}
                        onChange={(e) => handleInvoiceTypeChange(e)}
                        isLoading={false}
                        options={invoiceTypes}
                        isClearable={false}
                        />
                    </Grid>
                </Grid>
                <Grid container xs={6} md={6} lg={6} xl={6}  className='justify-content-end'>
                    <Grid container xs={10} md={10} lg={10} xl={10} className='justify-content-start'>
                        <Typography className={`${errors?.paymentTerms ? 'text-danger' : ''}`} style={{marginTop: "5px"}}>
                            {intl.get('newInvoice.paymentTerms')} {errors?.paymentTerms && (<span className='text-danger'>{errors?.paymentTerms}</span>)}
                        </Typography>
                    </Grid>
                    <Grid container xs={10} md={10} lg={10} xl={10} className='justify-content-end'>
                        <Input
                        id="paymentTerms"
                        name="paymentTerms"
                        placeholder={intl.get('newInvoice.paymentTerms')}
                        value={input?.paymentTerms}
                        onChange={(e) => handleInputChange(e)}
                        disabled={disabled}
                    />
                    </Grid>
                </Grid>
            </Grid>
            {errors?.invoiceItems && (
                <Grid container xs={12} md={12} lg={12} xl={12} className='justify-content-start' style={{marginTop: "10px", marginBottom: "-10px"}}>
                    <Typography className='text-danger'>
                        {errors?.invoiceItems}
                    </Typography>
                </Grid>
            )}
            <Grid xs={12} md={12} lg={12} xl={12} style={{marginTop: "10px"}}>
                <NewInvoiceItemTable setErrors={setErrors}/>
            </Grid>
            <Grid container xs={12} md={12} lg={12} xl={12} style={{marginTop: "10px"}}>
                <Grid xs={4} md={4} lg={4} xl={4}>
                </Grid>
                <Grid xs={8} md={8} lg={8} xl={8}>
                    <NewInvoiceSummary errors={errors} setErrors={setErrors} />
                </Grid>
            </Grid>
            <Grid className='justify-content-end'>
                <Grid className="d-flex justify-content-end">
                    <WorkflowActionsBar
                        viewMode=''
                        onActionClick={handleSave}
                        id={input.id}
                        stateId={input.idWorkflowState}
                        loading={loading}
                        disabled={disabled}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default NewInvoiceForm;

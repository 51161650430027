import React, { Dispatch, FC, SetStateAction } from "react";
import Modal, { ModalSizes } from "../Modal";
import PersonaFC from "./Persona";
import { Persona } from "axiosApi/models/persona";
import { CorporateEntity } from "axiosApi/models/corporate-entity";
import { PersonaDTO } from "axiosApi/models";

type ModalPersonaProps = {
    isOpen: boolean;
    closeModal: () => void;
    persona?: PersonaDTO | null;
    inputProvider?: any;
    setInputProvider?: (value: any) => void;
    setPersonaExist: Dispatch<SetStateAction<boolean>>;
    entityId : number;
    idEntityType : number;
    corporateEntities : CorporateEntity[];
    personaExists: boolean;
    personaTypeExist: number;
    personaTypesIds?: number[];
    inputCorporateEntity?: any;
    setInputCorporateEntity?: (value: any) => void;
    showCorporateEntities?: boolean;
  }
  

 const ModalPersona : FC<ModalPersonaProps> = (props) => {
    
    const {persona = null, corporateEntities ,isOpen, closeModal,  inputProvider, 
    setInputProvider, personaExists, setPersonaExist, entityId, idEntityType, personaTypeExist = 0, personaTypesIds = [], 
    inputCorporateEntity = null, setInputCorporateEntity = null, showCorporateEntities = true } = props        
    
    return (
         <Modal isOpen={isOpen} closeModal={closeModal} size={ModalSizes.small} height="auto">
             <PersonaFC 
                closeModal={closeModal} 
                persona={persona} 
                entityId = {entityId}
                idEntityType = {idEntityType}
                inputProvider={inputProvider}
                corporateEntities={corporateEntities}       
                setInputProvider={setInputProvider} 
                setPersonaExist={setPersonaExist}
                personaExists={personaExists}
                personaTypeExist={personaTypeExist}
                personaTypesIds={personaTypesIds}
                inputCorporateEntity={inputCorporateEntity}
                setInputCorporateEntity={setInputCorporateEntity}
                showCorporateEntities={showCorporateEntities}
              />
         </Modal>
   );
 };

 export default ModalPersona;
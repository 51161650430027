import React, { useState, useEffect, FC } from 'react';
import ContactForm from './ContactForm';
import '../scss/ContactInfo.scss';
import { useApi } from '../api/ApiProvider';
import Api from '../axiosApi/api';
import { CreateContactInfoCommand, UpdateContactInfoCommand } from 'axiosApi/models';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import contactType from '../fixture/contactType';
import { HiOutlineSave } from 'react-icons/hi';
import RequiredField from './RequiredField';
import intl from 'components/i18n/ReactIntlWrapper';
import { Row, Col } from 'reactstrap';
import Spinner from './Spinner';
import FlowWithProvider from './Workflow/WorkflowEditor';
type WorkflowAdminProps = {

  
};

// const ContactInfo: FC<ContactInfoProps> = ({ contactInfoId, contactInfos, setContactInfos, contactInfosId, setContactInfosId, closeModal, loading, setLoading }) => {
const WorkflowAdmin: FC<WorkflowAdminProps> = () => {
  return (
	<FlowWithProvider></FlowWithProvider>
  );
}

export default WorkflowAdmin;
import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Popper from '@mui/material/Popper';
import Chip from '@mui/material/Chip';
import intl from 'components/i18n/ReactIntlWrapper';
import '../../scss/User/UserSelectRole.scss';

const UserSelectRole = ({ handleRoleChange, selectedRoles, roles, disabled, id, hiddenRole }) => {
  
  const popperOptions = {
    modifiers: [
      {
        name: 'flip',
        enabled: true,
        options: {
          altBoundary: true,
          fallbackPlacements: ['bottom'],
        },
      },
      {
        name: 'preventOverflow',
        enabled: true,
        options: {
          altAxis: true,
          tether: false,
        },
      },
    ],
  };

  const handleChange = (event, newValue) => {
    handleRoleChange(newValue, id);
  };

  return (
    <Autocomplete
      disabled={disabled}
      multiple
      id="roles"
      options={roles ? roles : []}
      value={selectedRoles?.length === 0 || selectedRoles === null || selectedRoles == undefined ? [] : roles.filter(role => selectedRoles.includes(role.id))}
      onChange={handleChange}
      getOptionLabel={(role) => role?.name}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={intl.get('userSelectRole.label')}
          className={hiddenRole ? "hidden" : ""}
        />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            key={index}
            label={option.name}
            color="primary"  
            variant="outlined" 
            {...getTagProps({ index })}
          />
        ))
      }
      PopperComponent={(props) => (
        <Popper {...props} placement="bottom-start" {...popperOptions} />
      )}
    />
  );
}

export default UserSelectRole;

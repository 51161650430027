import React, { useState, useEffect } from 'react';
import intl from 'components/i18n/ReactIntlWrapper';
import { Row, Col, Input } from 'reactstrap';
import { HiOutlineTrash, HiOutlinePencilAlt } from 'react-icons/hi';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import '../../scss/User/UserAsEmployeeEdit.scss';
import Spinner from '../Spinner';

const UserAsEmployeeEdit = ({
    employees,
    roles,
    idFe,
    entityId,
    securityRoles,
    isDefault,
    isFormUserAsEmployeeOpen,
    isFormUserAsClientOpen,
    isFormUserAsProviderOpen,
    handleEditUserAsEmployee,
    isFormUserAsCorporateEntityOpen,
    handleDelete,
}) => {
    
    const [loading, setLoading] = useState(true);
    const selectedEmployee = employees?.find(employee => employee.id === entityId);
    const assignedSecurityRoles = roles?.filter(role => securityRoles?.includes(role.id));

    useEffect(() => {
        if (selectedEmployee && assignedSecurityRoles) {
            setLoading(false);
        }
    }, [selectedEmployee, assignedSecurityRoles]);

    return (
        <Row className='mb-4'>
            {loading ? (
                <Spinner small={true} />
            ) : (
                <>
                    <Col md={5} className="col-input-assignment">
                        <Input
                            id="userName"
                            name="name"
                            placeholder={intl.get('formUserAsEmployee.placeholder.name')}
                            value={`${selectedEmployee?.name} ${selectedEmployee?.lastName}`}
                            disabled={true} 
                            className="input-assignment"
                        />
                    </Col>
                    <Col md={7} className='mt-2 mt-md-0'>
                        <Row>
                            <Col md={8} className='mb-1 mb-md-0'>
                                <Stack direction="row" spacing={1}>
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, maxHeight: '5rem', overflowY: 'auto', marginTop: '6px' }}>
                                        {assignedSecurityRoles.length > 0 ? (
                                            assignedSecurityRoles.map((role) => (
                                                <Chip key={role.id} label={role.name} color="primary" variant="outlined" size="small" />
                                            ))
                                        ) : (
                                            <Chip label={intl.get('userAsEmployeeEdit.noRolesAssigned')} color="default" variant="outlined" size="small" />
                                        )}
                                    </Box>
                                </Stack>
                            </Col>
                            <Col md={2} className='d-flex align-items-center default-edit'>
                                <div className="form-check form-switch switch">
                                    <input
                                        className="form-check-input input-switch"
                                        type="checkbox"
                                        role="switch"
                                        id="switchCheckIsDefault"
                                        checked={isDefault}
                                        disabled={true}
                                    />
                                </div>
                            </Col>
                            <Col md={2}>
                                <Row>
                                    <Col md={6}>
                                        <a
                                            title={intl.get('userAsEmployeeEdit.editButton.title')}
                                            className={isFormUserAsEmployeeOpen || isFormUserAsClientOpen || isFormUserAsProviderOpen || isFormUserAsCorporateEntityOpen ? 'td-icon links-disabled' : 'td-icon'}
                                            role="button"
                                            onClick={() => handleEditUserAsEmployee(idFe)}
                                        >
                                            <HiOutlinePencilAlt className='iconTrashEdit' />
                                        </a>
                                    </Col>
                                    <Col md={6}>
                                        <a
                                            title={intl.get('userAsEmployeeEdit.deleteButton.title')}
                                            className={isFormUserAsEmployeeOpen || isFormUserAsClientOpen || isFormUserAsProviderOpen || isFormUserAsCorporateEntityOpen ? 'td-icon links-disabled' : 'td-icon'}
                                            role="button"
                                            onClick={() => handleDelete(idFe)}
                                        >
                                            <HiOutlineTrash className='iconTrashEdit' />
                                        </a>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </>
            )}
        </Row>
    );
};

export default UserAsEmployeeEdit;

import React from 'react';
import { Form, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import '../../scss/EmployeeForm.scss';
import intl from 'components/i18n/ReactIntlWrapper';

const EmployeeForm = ({ errors, input, handleInputChange, disabled }) => {
    return (
        <Form className='my-3'>
            <Row form ="true" className='justify-content-around'>
                <Col md={4}>
                    <div className='d-inline-flex col-12 flex-column'>
                        <Label for="employeeName" className={`${errors.name ? 'text-danger' : ''}`}>
                            {intl.get('employeeForm.name')} {errors.name && (<span className='text-danger'>{errors.name}</span>)}
                        </Label>
                        <Input
                            id="employeeName"
                            name="name"
                            placeholder={intl.get('employeeForm.placeholder.name')}
                            value={input?.name}
                            onChange={handleInputChange}
                            disabled={disabled}
                        />
                    </div>
                </Col>
                <Col md={4}>
                    <div className='d-inline-flex col-12 flex-column'>
                        <Label for="employeeLastName" className={`${errors.lastName ? 'text-danger' : ''}`}>
                            {intl.get('employeeForm.tradeName')} {errors.lastName && (<span className='text-danger'>{errors.lastName}</span>)}
                        </Label>
                        <Input
                            id="employeeLastName"
                            name="lastName"
                            placeholder={intl.get('employeeForm.placeholder.tradeName')}
                            value={input?.lastName}
                            onChange={handleInputChange}
                            disabled={disabled}
                        />
                    </div>
                </Col>
                <Col md={4}>
                    <div className='d-inline-flex col-12 flex-column'>
                        <Label for="employeeEmail" className={`${errors.emailAddress ? 'text-danger' : ''}`}>
                            {intl.get('employeeForm.email')} {errors.emailAddress && (<span className='text-danger'>{errors.emailAddress}</span>)}
                        </Label>
                        <Input
                            id="employeeEmail"
                            name="emailAddress"
                            placeholder={intl.get('employeeForm.placeholder.email')}
                            value={input?.emailAddress}
                            onChange={handleInputChange}
                            disabled={disabled}
                        />
                    </div>
                </Col>
            </Row>
        </Form>
    )
}

export default EmployeeForm;
import React, { useState, useEffect } from 'react';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import 'scss/Reports/ReportsComponent.scss';
import './ReportsFilterComponent';
import { useApi } from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import { ReportsFilterComponent, ReportsFilterComponentProps } from './ReportsFilterComponent';
import { TimeSheetDetailedReport, UserEntityDetails } from '../../axiosApi/models';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { HiChevronUp, HiChevronDown, HiOutlineClock } from 'react-icons/hi'
import { SCOPES } from '../../common/permissions';
import { PermissionsGate } from '../PermissionsGate';
import { styled } from '@mui/material/styles';
import intl from 'components/i18n/ReactIntlWrapper';
import { getUserEntity } from 'common/UserEntityProvider';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Colors,
    ChartOptions
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { handleAPIError } from '../../common/errorHandler';

interface Task {
    idTask: number;
    taskDescription: string;
    time: number;
    idEmployee: number;
    employeeName: string;
    date: Date
}

interface Job {
    idJob: number;
    jobDescription: string;
    tasks: Task[];
    billableHours: number;
    totalHours: number;
    nonBillableHours: number;
}

interface Client {
    idClient: number;
    clientName: string;
    projects: Project[];
    totalHours: number;
    billableHours: number;
    nonBillableHours: number;
}

interface Project {
    idProject: number;
    projectName: string;
    jobs: Job[];
    totalHours: number;
    billableHours: number;
    nonBillableHours: number;
}

interface DateEntry {
    date: string;
    billableHours: number;
    nonBillableHours: number;

}

interface Employee {
    idEmployee: number;
    employeeName: string;
    totalHours: number;
    billableHours: number;
    nonBillableHours: number;
    monthlyRate: number;
    hourlyRate: number;
    billingAmount: number;
}



interface TimesheetData {
    data: TimeSheetDetailedReport[];
}
export const options = {
    plugins: {
        title: {
            display: false,
            text: 'Billable vs Non Billable',
        },
    },
    responsive: true,
    scales: {
        x: {
            stacked: true,

            grid: {
                lineWidth: 0
            }
        },
        y: {
            stacked: true,
        },

    }
};

export const ClientTimesheetReportComponent = () => {

    const api: Api = useApi();
    const toast = useToastMessageQueue();
    const [results, setResults] = React.useState<TimeSheetDetailedReport[]>([]);
    const [clients, setClients] = React.useState<Client[]>([]);
    const [chartData, setChartData] = React.useState<any>(null);
    const [clientChartData, setClientChartData] = React.useState<any>(null);
    const [employeeChartData, setEmployeeChartData] = React.useState<any>(null);
    const [errors, setErrors] = useState({});
    const userEntity : UserEntityDetails = getUserEntity();
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend,
        Colors
    );

    const handleFilterChange = (filterData) => {
        fetchData(filterData);
    }

    useEffect(() => {
        // Actualiza el título del documento usando la API del navegador
        const clientJobTaskTree = createClientProjectJobTaskTree(results);
        setClients(clientJobTaskTree);
    }, [results]);


    const fetchData = async (filterData: ReportsFilterComponentProps) => {
        const response = await api.reportsApi.apiVversionTimeSheetDetailedReportClientGet("1", filterData.from, filterData.to, filterData.projects, userEntity.entityId , filterData.employees, filterData.jobTypes, {}).then((response) => {
            if (response.data.data) {
                setResults(response.data?.data);
            };
        }).catch((error) => {
            handleAPIError(error, toast, errors);
            setErrors({...errors});
        });
    };

    function createClientProjectJobTaskTree(timesheetData: TimeSheetDetailedReport[]): Client[] {
        const clients: Client[] = [];
        const dates: DateEntry[] = [];
        const employees: Employee[] = [];

        for (const entry of timesheetData) {
            let client = clients.find((c) => c.idClient === entry.idClient);

            if (!client) {
                client = {
                    idClient: entry.idClient,
                    clientName: entry.clientName,
                    projects: [],
                    billableHours: entry.billable ? entry.time : 0,
                    nonBillableHours: entry.billable ? 0 : entry.time,
                    totalHours: entry.time
                };
                clients.push(client);
            }
            else {
                client.billableHours += entry.billable ? entry.time : 0;
                client.nonBillableHours += entry.billable ? 0 : entry.time;
                client.totalHours += entry.time;
            }


            let project = client.projects.find((p) => p.idProject === entry.idProject);

            if (!project) {
                project = {
                    idProject: entry.idProject,
                    projectName: entry.projectName,
                    jobs: [],
                    billableHours: entry.billable ? entry.time : 0,
                    nonBillableHours: entry.billable ? 0 : entry.time,
                    totalHours: entry.time

                };
                client.projects.push(project);
            }
            else {
                project.billableHours += entry.billable ? entry.time : 0;
                project.nonBillableHours += entry.billable ? 0 : entry.time;
                project.totalHours += entry.time;
            }

            let job = project.jobs.find((j) => j.idJob === entry.idJob);

            if (!job) {
                job = {
                    idJob: entry.idJob,
                    jobDescription: entry.jobDescription,
                    tasks: [],
                    billableHours: entry.billable ? entry.time : 0,
                    nonBillableHours: !entry.billable ? entry.time : 0,
                    totalHours: entry.time
                };
                project.jobs.push(job);
            }
            else {
                job.billableHours += entry.billable ? entry.time : 0;
                job.nonBillableHours += entry.billable ? 0 : entry.time;
                job.totalHours += entry.time;
            }


            const task: Task = {
                idTask: entry.idTimesheet,
                taskDescription: entry.taskDescription,
                time: entry.time,
                idEmployee: entry.idEmployee,
                employeeName: entry.employeeName,
                date: entry.date
            };
            job.tasks.push(task);

            let date = dates.find((d) => d.date === new Date(entry.date).toLocaleDateString());

            if (!date) {
                date = {
                    date: new Date(entry.date).toLocaleDateString(),
                    billableHours: entry.billable ? entry.time : 0,
                    nonBillableHours: !entry.billable ? entry.time : 0
                };
                dates.push(date);
            }
            else {
                date.billableHours += entry.billable ? entry.time : 0;
                date.nonBillableHours += entry.billable ? 0 : entry.time;
            }

            let employee = employees.find((j) => j.idEmployee === entry.idEmployee);
            if (!employee) {
                employee = {
                    idEmployee: entry.idEmployee,
                    employeeName: entry.employeeName,
                    billableHours: entry.billable ? entry.time : 0,
                    nonBillableHours: entry.billable ? 0 : entry.time,
                    totalHours: entry.time,
                    hourlyRate: entry.hourlyRate,
                    monthlyRate: entry.monthlyRate,
                    billingAmount: !entry.billable ? 0 : (entry.hourlyRate > 0 ? entry.time / 60 * entry.hourlyRate : 0)
                }
                employees.push(employee);
            }
            else {
                employee.billableHours += entry.billable ? entry.time : 0;
                employee.nonBillableHours += entry.billable ? 0 : entry.time;
                employee.totalHours += entry.time;
                employee.billingAmount += !entry.billable ? 0 : (entry.hourlyRate > 0 ? (entry.time * entry.hourlyRate) / 60 : 0);
            }

        }


        //Pie Data
        const data = {
            labels: dates.map((c) => c.date.toLocaleString()),
            datasets: [{
                label: 'Billable',
                data: dates.map((c) => c.billableHours / 60),

                borderWidth: 0,
                hoverOffset: 6
            },
            {
                label: 'Non Billable',
                data: dates.map((c) => c.nonBillableHours / 60),

                borderWidth: 0,
                hoverOffset: 6
            }

            ]

        };

        setChartData(data);

        const sortedClients = clients.sort((a,b)=>{
            if(a.billableHours>b.billableHours)
                return -1;
            if(a.billableHours<b.billableHours)
                return 1;
            return 0;
            
        });

       

        const data2 = {
            labels: sortedClients.map((c) => c.clientName),
            datasets: [{
                label: 'Billable',
                data: sortedClients.map((c) => c.billableHours / 60),

                borderWidth: 0,
                hoverOffset: 6
            },
            {
                label: 'Non Billable',
                data: sortedClients.map((c) => c.nonBillableHours / 60),

                borderWidth: 0,
                hoverOffset: 6
            }

            ]

        };
        setClientChartData(data2);

        const sortedEmployees = employees.sort((a,b)=>{
            if(a.totalHours>b.totalHours)
                return -1;
            if(a.totalHours<b.totalHours)
                return 1;
            return 0;
            
        });

        const data3 = {
            labels: sortedEmployees.map((c) => c.employeeName),
            datasets: [{
                label: 'Billable',
                data: sortedEmployees.map((c) => c.billableHours / 60),

                borderWidth: 0,
                hoverOffset: 6
            },
            {
                label: 'Non Billable',
                data: sortedEmployees.map((c) => c.nonBillableHours / 60),

                borderWidth: 0,
                hoverOffset: 6
            }

            ]

        };
        setEmployeeChartData(data3);

        return clients;
    }

    function JobRow(props: { jobRow: Job }) {
        const { jobRow } = props;
        const [open2, setOpen2] = React.useState(false);
        return (<><TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell><IconButton aria-label="expand row" size="small" onClick={() => setOpen2(!open2)}>
                {open2 ? <HiChevronUp /> : <HiChevronDown />}</IconButton>{jobRow.jobDescription}</TableCell>
            <TableCell align="right">{(jobRow.totalHours / 60).toFixed(2)}</TableCell>
            <TableCell align="right">{(jobRow.billableHours / 60).toFixed(2)}</TableCell>
            <TableCell align="right">{(jobRow.nonBillableHours / 60).toFixed(2)}</TableCell>
        </TableRow>
            <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell colSpan={4} align="right" style={{ paddingRight: 0 }} >
                    <Collapse in={open2} timeout="auto" unmountOnExit>
                        <Table key={jobRow.idJob} size="small" padding="normal">
                            <TableHead>
                                <TableRow>
                                    <TableCell><b>Date</b></TableCell>
                                    <TableCell><b>Employee</b></TableCell>
                                    <TableCell><b>Task Description</b></TableCell>
                                    <TableCell align="right"><b>Hours</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    jobRow.tasks.map((task) => (
                                        <TaskRow key={`${task.idTask}-task-client`} task={task}></TaskRow>))
                                }</TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow></>
        );
    }

    function ProjectRow(props: { row: Project }) {
        const { row } = props;
        const [open, setOpen] = React.useState(false);
        const [open2, setOpen2] = React.useState(false);


        return (
            <React.Fragment>
                <TableRow>
                    <TableCell>
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <HiChevronUp /> : <HiChevronDown />}
                        </IconButton>
                    </TableCell>
                    <TableCell scope="row" >
                        <b>{row.projectName}</b>
                    </TableCell>
                    <TableCell>

                    </TableCell>
                    <TableCell scope="row" align="right">
                        {(row.totalHours / 60).toFixed(2)}
                    </TableCell>
                    <TableCell scope="row" align="right">
                        {(row.billableHours / 60).toFixed(2)}
                    </TableCell>
                    <TableCell scope="row" align="right">
                        {(row.nonBillableHours / 60).toFixed(2)}
                    </TableCell>

                </TableRow>
                {open && row.jobs.map((jobRow) => (
                    <JobRow key={jobRow.idJob}   jobRow={jobRow}></JobRow>
                ))}

            </React.Fragment>
        );
    }

    function ClientRow(props: { client: Client }) {

        const { client } = props;
        return (
            <div className="card mt-4">
                <div className="container card-header">
                    <h4>{client.clientName}</h4>
                </div>
                <div className="container card-body">
                    <TableContainer component={Paper}>
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell><b>Project</b></TableCell>
                                    <TableCell><b>Job</b></TableCell>
                                    <TableCell align="right"><b>Total Hours</b></TableCell>
                                    <TableCell align="right"><b>Billable</b></TableCell>
                                    <TableCell align="right"><b>Not Billable</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {client.projects.map((row) => (
                                    <ProjectRow key={row.idProject} row={row} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>)

    }

    function TaskRow(props: { task: Task }) {
        const { task } = props;
        return (<TableRow key={task.idTask}>
            <TableCell>
                {new Date(task.date).toLocaleDateString()} </TableCell>
            <TableCell component="th" scope="row">
                {task.employeeName}
            </TableCell>
            <TableCell>
                {task.taskDescription}
            </TableCell>
            <TableCell align="right">{(task.time / 60).toFixed(2)}</TableCell>

        </TableRow>);
    }
    
    

    return (
        <div className='container'>
            <div className='card mt-4'>
                <div className='container card-header'>
                    <h2 className="title"><HiOutlineClock /> {intl.get('reportsComponent.timsheetClient.header')}</h2>
                </div>
                <div className='container card-body'>
                    <ReportsFilterComponent handleFilterChange={handleFilterChange} errors={errors} setErrors={setErrors}></ReportsFilterComponent>
                    <div className='card'>
                        <div className='card-header'>
                            <h4>{intl.get('reportsComponent.body.date.header')}</h4>
                        </div>
                        <div className='card-body'>
                            <div className="col-md-12 col-sm-12">{chartData && <Bar options={options} data={chartData} />}</div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-md-12">
                            <div className='card mt-4'>
                                <div className='card-header'>
                                    <h4>{intl.get('reportsComponent.body.member.header')}</h4>
                                </div>
                                <div className='card-body'>
                                    <div className="col-md-12 col-sm-12">{employeeChartData && <Bar options={options} data={employeeChartData} />}</div>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                    <PermissionsGate viewScopes={[SCOPES['reports.timesheet.read']]} editScopes={[SCOPES['reports.timesheet.read']]} viewRoles={[]} editRoles={[]} RenderError={() => (<span>{intl.get('permissionsGate')}</span>)} >
                        {
                            clients && clients.map((client, i) =>
                                <ClientRow key={client.idClient} client={client}></ClientRow>
                            )
                        }
                    </PermissionsGate>
                    
                </div>
            </div>
        </div>

    )
}


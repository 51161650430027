import React, { useEffect, useRef, useState } from 'react'
import { HiOutlineSave } from 'react-icons/hi'
import intl from 'components/i18n/ReactIntlWrapper'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material'
import { LuWorkflow } from 'react-icons/lu'
interface AddStateModalProps {
    open: boolean
    setOpen: (open: boolean) => void
    onAddState: (stateName: string) => void
}

const AddStateModal: React.FC<AddStateModalProps> = ({ open, setOpen, onAddState }) => {
    const [stateName, setStateName] = useState<string>('')

    const handleAddState = () => {
        onAddState(stateName)
        onRequestClose()
    }

    const handleOpen = () => {
        setOpen(true)
    }

    const onRequestClose = () => {
        setStateName('')
        setOpen(false)
    }

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (open) {
            setStateName('')
            setTimeout(() => {
                inputRef.current.focus()
            }, 1)
        }
    }, [open]);

    return (
        <>
            <Button size='small' variant='contained' color='primary' className='newAddress' onClick={handleOpen}>
                <LuWorkflow size={18} />
                <span className="px-2">{intl.get('workflowDetail.addNewState')}</span>
            </Button>
            <Dialog
                open={open}
                onClose={onRequestClose}
            >
                <DialogTitle className='py-2 px-3'>{intl.get('workflowDetail.addNewState')}</DialogTitle>
                <DialogContent className='pt-2 my-2'>
                    <TextField
                        id="stateName"
                        inputRef={inputRef}
                        label={intl.get('editableNode.placeholder.nodeName')}
                        size="small"
                        type="text"
                        inputProps={{ maxLength: 100 }}
                        value={stateName}
                        onKeyDown={(e) => e.key === 'Enter' && stateName?.length && handleAddState()}
                        onChange={(e) => setStateName(e.target.value)}
                        placeholder="Enter state name"
                        sx={{
                            minWidth: '350px',
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' color='inherit' onClick={onRequestClose}>
                        {intl.get('cancel.button')}
                    </Button>
                    <Button
                        variant='contained'
                        color='primary'
                        type="submit"
                        onClick={handleAddState}
                        disabled={stateName === '' ? true : false}
                    >
                        <Box className="d-flex align-items-center gap-2">
                            <HiOutlineSave className="mb-1" /> <Typography>{intl.get('save.button')}</Typography>
                        </Box>
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default AddStateModal

import React, { FC } from 'react';
import Select from 'react-select';
import { StateProvince } from '../axiosApi/models';
import intl from 'components/i18n/ReactIntlWrapper';
import { sortCollection } from '../common/utils';
import { SORT_TYPES } from '../common/constants';

type StateSelectProps = {
  handleStateChange: any,
  selectedState: any,
  states: any,
  inputState: any,
  inputCountry: any
};

const StateSelect: FC<StateSelectProps> = ({ handleStateChange, selectedState, states, inputState, inputCountry }) => {

  const statesSortedByName = sortCollection(states, "name", SORT_TYPES.ASC);

  return (
    <Select
      id="addressState"
      name="addressState"
      getOptionLabel={e => e.name}
      getOptionValue={e => e.id}
      placeholder={intl.get('addressForm.placeholder.stateProvince')}
      value={(states != null && selectedState != null && inputState != '') ? (states.find((state: StateProvince) => state.name === selectedState)) : ''}
      onChange={handleStateChange}
      isLoading={states == null}
      options={states}
      isDisabled={inputCountry ? false : true}
    />
  );
};

export default StateSelect;
/* tslint:disable */
/* eslint-disable */
/**
 * Intranet - Boilerplate API
 * You need to change all the object names to your project ones
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { BooleanResponse } from '../models';
// @ts-ignore
import type { CreateNonWorkingDayCommand } from '../models';
// @ts-ignore
import type { EventDTOSearchResponseResponse } from '../models';
// @ts-ignore
import type { ListOptionDTOSearchResponseResponse } from '../models';
// @ts-ignore
import type { NonWorkingDayResponse } from '../models';
// @ts-ignore
import type { NonWorkingDaySearchResponseResponse } from '../models';
// @ts-ignore
import type { StatusCodeResult } from '../models';
// @ts-ignore
import type { StringResponse } from '../models';
// @ts-ignore
import type { StringStringDictionaryResponse } from '../models';
// @ts-ignore
import type { TimeOffDTOResponse } from '../models';
// @ts-ignore
import type { TimeOffDTOSearchResponseResponse } from '../models';
// @ts-ignore
import type { TimeOffRequestTypeSearchResponseResponse } from '../models';
// @ts-ignore
import type { UnitResponse } from '../models';
// @ts-ignore
import type { UpdateNonWorkingDayCommand } from '../models';
/**
 * TimeoffApi - axios parameter creator
 * @export
 */
export const TimeoffApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all Events.
         * @param {string} version 
         * @param {string} [eventType] 
         * @param {Date} [start] 
         * @param {Date} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionEventAllGet: async (version: string, eventType?: string, start?: Date, end?: Date, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionEventAllGet', 'version', version)
            const localVarPath = `/api/v{version}/Event/all`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (eventType !== undefined) {
                localVarQueryParameter['eventType'] = eventType;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Searches for Events
         * @param {string} version 
         * @param {string} [term] 
         * @param {string} [order] 
         * @param {Array<string>} [f] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionEventSearchGet: async (version: string, term?: string, order?: string, f?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionEventSearchGet', 'version', version)
            const localVarPath = `/api/v{version}/Event/search`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (f) {
                localVarQueryParameter['f'] = f;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary requestType ListOptionDto
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionListRequestTypeGet: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionListRequestTypeGet', 'version', version)
            const localVarPath = `/api/v{version}/List/requestType`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the list of all NonWorkingDays
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionNonWorkingDayAllGet: async (version: string, pageNum?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionNonWorkingDayAllGet', 'version', version)
            const localVarPath = `/api/v{version}/NonWorkingDay/all`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a NonWorkingDay by Id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionNonWorkingDayIdDelete: async (id: number, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVversionNonWorkingDayIdDelete', 'id', id)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionNonWorkingDayIdDelete', 'version', version)
            const localVarPath = `/api/v{version}/NonWorkingDay/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets an NonWorkingDay by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionNonWorkingDayIdGet: async (id: number, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVversionNonWorkingDayIdGet', 'id', id)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionNonWorkingDayIdGet', 'version', version)
            const localVarPath = `/api/v{version}/NonWorkingDay/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a New NonWorkingDay.
         * @param {string} version 
         * @param {CreateNonWorkingDayCommand} [createNonWorkingDayCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionNonWorkingDayPost: async (version: string, createNonWorkingDayCommand?: CreateNonWorkingDayCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionNonWorkingDayPost', 'version', version)
            const localVarPath = `/api/v{version}/NonWorkingDay`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createNonWorkingDayCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a NonWorkingDay.
         * @param {string} version 
         * @param {UpdateNonWorkingDayCommand} [updateNonWorkingDayCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionNonWorkingDayPut: async (version: string, updateNonWorkingDayCommand?: UpdateNonWorkingDayCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionNonWorkingDayPut', 'version', version)
            const localVarPath = `/api/v{version}/NonWorkingDay`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateNonWorkingDayCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Searches for NonWorkingDays
         * @param {string} version 
         * @param {string} [term] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {Array<string>} [f] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionNonWorkingDaySearchGet: async (version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionNonWorkingDaySearchGet', 'version', version)
            const localVarPath = `/api/v{version}/NonWorkingDay/search`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (f) {
                localVarQueryParameter['f'] = f;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a TimeOff\'s attached file (requires userentity header)
         * @param {string} version 
         * @param {number} [id] 
         * @param {string} [key] 
         * @param {number} [employeeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTimeOffFileDelete: async (version: string, id?: number, key?: string, employeeId?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionTimeOffFileDelete', 'version', version)
            const localVarPath = `/api/v{version}/TimeOff/file`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (employeeId !== undefined) {
                localVarQueryParameter['employeeId'] = employeeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a TimeOff\'s attached file
         * @param {string} version 
         * @param {number} [id] 
         * @param {string} [key] 
         * @param {number} [employeeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTimeOffFileGet: async (version: string, id?: number, key?: string, employeeId?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionTimeOffFileGet', 'version', version)
            const localVarPath = `/api/v{version}/TimeOff/file`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (employeeId !== undefined) {
                localVarQueryParameter['employeeId'] = employeeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a TimeOff by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTimeOffIdDelete: async (id: number, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVversionTimeOffIdDelete', 'id', id)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionTimeOffIdDelete', 'version', version)
            const localVarPath = `/api/v{version}/TimeOff/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a TimeOff by Id (requires userentity header)
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTimeOffIdGet: async (id: number, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVversionTimeOffIdGet', 'id', id)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionTimeOffIdGet', 'version', version)
            const localVarPath = `/api/v{version}/TimeOff/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a New TimeOff.
         * @param {string} version 
         * @param {string} [commandJson] 
         * @param {Array<File>} [uploads] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTimeOffPost: async (version: string, commandJson?: string, uploads?: Array<File>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionTimeOffPost', 'version', version)
            const localVarPath = `/api/v{version}/TimeOff`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (commandJson !== undefined) { 
                localVarFormParams.append('commandJson', commandJson as any);
            }
                if (uploads) {
                uploads.forEach((element) => {
                    localVarFormParams.append('uploads', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an existing TimeOff.
         * @param {string} version 
         * @param {string} [commandJson] 
         * @param {Array<File>} [uploads] 
         * @param {Array<string>} [keysForDeletion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTimeOffPut: async (version: string, commandJson?: string, uploads?: Array<File>, keysForDeletion?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionTimeOffPut', 'version', version)
            const localVarPath = `/api/v{version}/TimeOff`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (commandJson !== undefined) { 
                localVarFormParams.append('commandJson', commandJson as any);
            }
                if (uploads) {
                uploads.forEach((element) => {
                    localVarFormParams.append('uploads', element as any);
                })
            }

                if (keysForDeletion) {
                keysForDeletion.forEach((element) => {
                    localVarFormParams.append('keysForDeletion', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the list of all TimeOffRequestTypes
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTimeOffRequestTypeAllGet: async (version: string, pageNum?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionTimeOffRequestTypeAllGet', 'version', version)
            const localVarPath = `/api/v{version}/TimeOffRequestType/all`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Searches for a TimeOff (request)
         * @param {string} version 
         * @param {string} [term] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {boolean} [includeInactive] 
         * @param {Array<string>} [f] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTimeOffSearchGet: async (version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, includeInactive?: boolean, f?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionTimeOffSearchGet', 'version', version)
            const localVarPath = `/api/v{version}/TimeOff/search`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (includeInactive !== undefined) {
                localVarQueryParameter['includeInactive'] = includeInactive;
            }

            if (f) {
                localVarQueryParameter['f'] = f;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Searches for a TimeOff (request)
         * @param {string} version 
         * @param {string} [term] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {Array<string>} [f] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTimeOffSearchMineGet: async (version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionTimeOffSearchMineGet', 'version', version)
            const localVarPath = `/api/v{version}/TimeOff/search/mine`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (f) {
                localVarQueryParameter['f'] = f;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TimeoffApi - functional programming interface
 * @export
 */
export const TimeoffApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TimeoffApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all Events.
         * @param {string} version 
         * @param {string} [eventType] 
         * @param {Date} [start] 
         * @param {Date} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionEventAllGet(version: string, eventType?: string, start?: Date, end?: Date, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventDTOSearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionEventAllGet(version, eventType, start, end, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TimeoffApi.apiVversionEventAllGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Searches for Events
         * @param {string} version 
         * @param {string} [term] 
         * @param {string} [order] 
         * @param {Array<string>} [f] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionEventSearchGet(version: string, term?: string, order?: string, f?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventDTOSearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionEventSearchGet(version, term, order, f, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TimeoffApi.apiVversionEventSearchGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary requestType ListOptionDto
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionListRequestTypeGet(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListOptionDTOSearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionListRequestTypeGet(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TimeoffApi.apiVversionListRequestTypeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets the list of all NonWorkingDays
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionNonWorkingDayAllGet(version: string, pageNum?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NonWorkingDaySearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionNonWorkingDayAllGet(version, pageNum, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TimeoffApi.apiVversionNonWorkingDayAllGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete a NonWorkingDay by Id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionNonWorkingDayIdDelete(id: number, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnitResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionNonWorkingDayIdDelete(id, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TimeoffApi.apiVversionNonWorkingDayIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets an NonWorkingDay by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionNonWorkingDayIdGet(id: number, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NonWorkingDayResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionNonWorkingDayIdGet(id, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TimeoffApi.apiVversionNonWorkingDayIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Creates a New NonWorkingDay.
         * @param {string} version 
         * @param {CreateNonWorkingDayCommand} [createNonWorkingDayCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionNonWorkingDayPost(version: string, createNonWorkingDayCommand?: CreateNonWorkingDayCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NonWorkingDayResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionNonWorkingDayPost(version, createNonWorkingDayCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TimeoffApi.apiVversionNonWorkingDayPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a NonWorkingDay.
         * @param {string} version 
         * @param {UpdateNonWorkingDayCommand} [updateNonWorkingDayCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionNonWorkingDayPut(version: string, updateNonWorkingDayCommand?: UpdateNonWorkingDayCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NonWorkingDayResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionNonWorkingDayPut(version, updateNonWorkingDayCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TimeoffApi.apiVversionNonWorkingDayPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Searches for NonWorkingDays
         * @param {string} version 
         * @param {string} [term] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {Array<string>} [f] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionNonWorkingDaySearchGet(version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NonWorkingDaySearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionNonWorkingDaySearchGet(version, term, pageNum, pageSize, sort, order, f, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TimeoffApi.apiVversionNonWorkingDaySearchGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes a TimeOff\'s attached file (requires userentity header)
         * @param {string} version 
         * @param {number} [id] 
         * @param {string} [key] 
         * @param {number} [employeeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTimeOffFileDelete(version: string, id?: number, key?: string, employeeId?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionTimeOffFileDelete(version, id, key, employeeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TimeoffApi.apiVversionTimeOffFileDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets a TimeOff\'s attached file
         * @param {string} version 
         * @param {number} [id] 
         * @param {string} [key] 
         * @param {number} [employeeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTimeOffFileGet(version: string, id?: number, key?: string, employeeId?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringStringDictionaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionTimeOffFileGet(version, id, key, employeeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TimeoffApi.apiVversionTimeOffFileGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes a TimeOff by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTimeOffIdDelete(id: number, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionTimeOffIdDelete(id, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TimeoffApi.apiVversionTimeOffIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets a TimeOff by Id (requires userentity header)
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTimeOffIdGet(id: number, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeOffDTOResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionTimeOffIdGet(id, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TimeoffApi.apiVversionTimeOffIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Creates a New TimeOff.
         * @param {string} version 
         * @param {string} [commandJson] 
         * @param {Array<File>} [uploads] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTimeOffPost(version: string, commandJson?: string, uploads?: Array<File>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeOffDTOResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionTimeOffPost(version, commandJson, uploads, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TimeoffApi.apiVversionTimeOffPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates an existing TimeOff.
         * @param {string} version 
         * @param {string} [commandJson] 
         * @param {Array<File>} [uploads] 
         * @param {Array<string>} [keysForDeletion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTimeOffPut(version: string, commandJson?: string, uploads?: Array<File>, keysForDeletion?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeOffDTOResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionTimeOffPut(version, commandJson, uploads, keysForDeletion, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TimeoffApi.apiVversionTimeOffPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets the list of all TimeOffRequestTypes
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTimeOffRequestTypeAllGet(version: string, pageNum?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeOffRequestTypeSearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionTimeOffRequestTypeAllGet(version, pageNum, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TimeoffApi.apiVversionTimeOffRequestTypeAllGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Searches for a TimeOff (request)
         * @param {string} version 
         * @param {string} [term] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {boolean} [includeInactive] 
         * @param {Array<string>} [f] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTimeOffSearchGet(version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, includeInactive?: boolean, f?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeOffDTOSearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionTimeOffSearchGet(version, term, pageNum, pageSize, sort, order, includeInactive, f, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TimeoffApi.apiVversionTimeOffSearchGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Searches for a TimeOff (request)
         * @param {string} version 
         * @param {string} [term] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {Array<string>} [f] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTimeOffSearchMineGet(version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeOffDTOSearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionTimeOffSearchMineGet(version, term, pageNum, pageSize, sort, order, f, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TimeoffApi.apiVversionTimeOffSearchMineGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TimeoffApi - factory interface
 * @export
 */
export const TimeoffApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TimeoffApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all Events.
         * @param {string} version 
         * @param {string} [eventType] 
         * @param {Date} [start] 
         * @param {Date} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionEventAllGet(version: string, eventType?: string, start?: Date, end?: Date, options?: RawAxiosRequestConfig): AxiosPromise<EventDTOSearchResponseResponse> {
            return localVarFp.apiVversionEventAllGet(version, eventType, start, end, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Searches for Events
         * @param {string} version 
         * @param {string} [term] 
         * @param {string} [order] 
         * @param {Array<string>} [f] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionEventSearchGet(version: string, term?: string, order?: string, f?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<EventDTOSearchResponseResponse> {
            return localVarFp.apiVversionEventSearchGet(version, term, order, f, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary requestType ListOptionDto
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionListRequestTypeGet(version: string, options?: RawAxiosRequestConfig): AxiosPromise<ListOptionDTOSearchResponseResponse> {
            return localVarFp.apiVversionListRequestTypeGet(version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the list of all NonWorkingDays
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionNonWorkingDayAllGet(version: string, pageNum?: number, pageSize?: number, options?: RawAxiosRequestConfig): AxiosPromise<NonWorkingDaySearchResponseResponse> {
            return localVarFp.apiVversionNonWorkingDayAllGet(version, pageNum, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a NonWorkingDay by Id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionNonWorkingDayIdDelete(id: number, version: string, options?: RawAxiosRequestConfig): AxiosPromise<UnitResponse> {
            return localVarFp.apiVversionNonWorkingDayIdDelete(id, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets an NonWorkingDay by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionNonWorkingDayIdGet(id: number, version: string, options?: RawAxiosRequestConfig): AxiosPromise<NonWorkingDayResponse> {
            return localVarFp.apiVversionNonWorkingDayIdGet(id, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a New NonWorkingDay.
         * @param {string} version 
         * @param {CreateNonWorkingDayCommand} [createNonWorkingDayCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionNonWorkingDayPost(version: string, createNonWorkingDayCommand?: CreateNonWorkingDayCommand, options?: RawAxiosRequestConfig): AxiosPromise<NonWorkingDayResponse> {
            return localVarFp.apiVversionNonWorkingDayPost(version, createNonWorkingDayCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a NonWorkingDay.
         * @param {string} version 
         * @param {UpdateNonWorkingDayCommand} [updateNonWorkingDayCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionNonWorkingDayPut(version: string, updateNonWorkingDayCommand?: UpdateNonWorkingDayCommand, options?: RawAxiosRequestConfig): AxiosPromise<NonWorkingDayResponse> {
            return localVarFp.apiVversionNonWorkingDayPut(version, updateNonWorkingDayCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Searches for NonWorkingDays
         * @param {string} version 
         * @param {string} [term] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {Array<string>} [f] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionNonWorkingDaySearchGet(version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<NonWorkingDaySearchResponseResponse> {
            return localVarFp.apiVversionNonWorkingDaySearchGet(version, term, pageNum, pageSize, sort, order, f, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a TimeOff\'s attached file (requires userentity header)
         * @param {string} version 
         * @param {number} [id] 
         * @param {string} [key] 
         * @param {number} [employeeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTimeOffFileDelete(version: string, id?: number, key?: string, employeeId?: number, options?: RawAxiosRequestConfig): AxiosPromise<BooleanResponse> {
            return localVarFp.apiVversionTimeOffFileDelete(version, id, key, employeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a TimeOff\'s attached file
         * @param {string} version 
         * @param {number} [id] 
         * @param {string} [key] 
         * @param {number} [employeeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTimeOffFileGet(version: string, id?: number, key?: string, employeeId?: number, options?: RawAxiosRequestConfig): AxiosPromise<StringStringDictionaryResponse> {
            return localVarFp.apiVversionTimeOffFileGet(version, id, key, employeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a TimeOff by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTimeOffIdDelete(id: number, version: string, options?: RawAxiosRequestConfig): AxiosPromise<BooleanResponse> {
            return localVarFp.apiVversionTimeOffIdDelete(id, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a TimeOff by Id (requires userentity header)
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTimeOffIdGet(id: number, version: string, options?: RawAxiosRequestConfig): AxiosPromise<TimeOffDTOResponse> {
            return localVarFp.apiVversionTimeOffIdGet(id, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a New TimeOff.
         * @param {string} version 
         * @param {string} [commandJson] 
         * @param {Array<File>} [uploads] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTimeOffPost(version: string, commandJson?: string, uploads?: Array<File>, options?: RawAxiosRequestConfig): AxiosPromise<TimeOffDTOResponse> {
            return localVarFp.apiVversionTimeOffPost(version, commandJson, uploads, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an existing TimeOff.
         * @param {string} version 
         * @param {string} [commandJson] 
         * @param {Array<File>} [uploads] 
         * @param {Array<string>} [keysForDeletion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTimeOffPut(version: string, commandJson?: string, uploads?: Array<File>, keysForDeletion?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<TimeOffDTOResponse> {
            return localVarFp.apiVversionTimeOffPut(version, commandJson, uploads, keysForDeletion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the list of all TimeOffRequestTypes
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTimeOffRequestTypeAllGet(version: string, pageNum?: number, pageSize?: number, options?: RawAxiosRequestConfig): AxiosPromise<TimeOffRequestTypeSearchResponseResponse> {
            return localVarFp.apiVversionTimeOffRequestTypeAllGet(version, pageNum, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Searches for a TimeOff (request)
         * @param {string} version 
         * @param {string} [term] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {boolean} [includeInactive] 
         * @param {Array<string>} [f] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTimeOffSearchGet(version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, includeInactive?: boolean, f?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<TimeOffDTOSearchResponseResponse> {
            return localVarFp.apiVversionTimeOffSearchGet(version, term, pageNum, pageSize, sort, order, includeInactive, f, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Searches for a TimeOff (request)
         * @param {string} version 
         * @param {string} [term] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {Array<string>} [f] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTimeOffSearchMineGet(version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<TimeOffDTOSearchResponseResponse> {
            return localVarFp.apiVversionTimeOffSearchMineGet(version, term, pageNum, pageSize, sort, order, f, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TimeoffApi - object-oriented interface
 * @export
 * @class TimeoffApi
 * @extends {BaseAPI}
 */
export class TimeoffApi extends BaseAPI {
    /**
     * 
     * @summary Get all Events.
     * @param {string} version 
     * @param {string} [eventType] 
     * @param {Date} [start] 
     * @param {Date} [end] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeoffApi
     */
    public apiVversionEventAllGet(version: string, eventType?: string, start?: Date, end?: Date, options?: RawAxiosRequestConfig) {
        return TimeoffApiFp(this.configuration).apiVversionEventAllGet(version, eventType, start, end, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Searches for Events
     * @param {string} version 
     * @param {string} [term] 
     * @param {string} [order] 
     * @param {Array<string>} [f] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeoffApi
     */
    public apiVversionEventSearchGet(version: string, term?: string, order?: string, f?: Array<string>, options?: RawAxiosRequestConfig) {
        return TimeoffApiFp(this.configuration).apiVversionEventSearchGet(version, term, order, f, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary requestType ListOptionDto
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeoffApi
     */
    public apiVversionListRequestTypeGet(version: string, options?: RawAxiosRequestConfig) {
        return TimeoffApiFp(this.configuration).apiVversionListRequestTypeGet(version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the list of all NonWorkingDays
     * @param {string} version 
     * @param {number} [pageNum] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeoffApi
     */
    public apiVversionNonWorkingDayAllGet(version: string, pageNum?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return TimeoffApiFp(this.configuration).apiVversionNonWorkingDayAllGet(version, pageNum, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a NonWorkingDay by Id
     * @param {number} id 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeoffApi
     */
    public apiVversionNonWorkingDayIdDelete(id: number, version: string, options?: RawAxiosRequestConfig) {
        return TimeoffApiFp(this.configuration).apiVversionNonWorkingDayIdDelete(id, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets an NonWorkingDay by id
     * @param {number} id 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeoffApi
     */
    public apiVversionNonWorkingDayIdGet(id: number, version: string, options?: RawAxiosRequestConfig) {
        return TimeoffApiFp(this.configuration).apiVversionNonWorkingDayIdGet(id, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a New NonWorkingDay.
     * @param {string} version 
     * @param {CreateNonWorkingDayCommand} [createNonWorkingDayCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeoffApi
     */
    public apiVversionNonWorkingDayPost(version: string, createNonWorkingDayCommand?: CreateNonWorkingDayCommand, options?: RawAxiosRequestConfig) {
        return TimeoffApiFp(this.configuration).apiVversionNonWorkingDayPost(version, createNonWorkingDayCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a NonWorkingDay.
     * @param {string} version 
     * @param {UpdateNonWorkingDayCommand} [updateNonWorkingDayCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeoffApi
     */
    public apiVversionNonWorkingDayPut(version: string, updateNonWorkingDayCommand?: UpdateNonWorkingDayCommand, options?: RawAxiosRequestConfig) {
        return TimeoffApiFp(this.configuration).apiVversionNonWorkingDayPut(version, updateNonWorkingDayCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Searches for NonWorkingDays
     * @param {string} version 
     * @param {string} [term] 
     * @param {number} [pageNum] 
     * @param {number} [pageSize] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {Array<string>} [f] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeoffApi
     */
    public apiVversionNonWorkingDaySearchGet(version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>, options?: RawAxiosRequestConfig) {
        return TimeoffApiFp(this.configuration).apiVversionNonWorkingDaySearchGet(version, term, pageNum, pageSize, sort, order, f, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a TimeOff\'s attached file (requires userentity header)
     * @param {string} version 
     * @param {number} [id] 
     * @param {string} [key] 
     * @param {number} [employeeId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeoffApi
     */
    public apiVversionTimeOffFileDelete(version: string, id?: number, key?: string, employeeId?: number, options?: RawAxiosRequestConfig) {
        return TimeoffApiFp(this.configuration).apiVversionTimeOffFileDelete(version, id, key, employeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a TimeOff\'s attached file
     * @param {string} version 
     * @param {number} [id] 
     * @param {string} [key] 
     * @param {number} [employeeId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeoffApi
     */
    public apiVversionTimeOffFileGet(version: string, id?: number, key?: string, employeeId?: number, options?: RawAxiosRequestConfig) {
        return TimeoffApiFp(this.configuration).apiVversionTimeOffFileGet(version, id, key, employeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a TimeOff by id
     * @param {number} id 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeoffApi
     */
    public apiVversionTimeOffIdDelete(id: number, version: string, options?: RawAxiosRequestConfig) {
        return TimeoffApiFp(this.configuration).apiVversionTimeOffIdDelete(id, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a TimeOff by Id (requires userentity header)
     * @param {number} id 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeoffApi
     */
    public apiVversionTimeOffIdGet(id: number, version: string, options?: RawAxiosRequestConfig) {
        return TimeoffApiFp(this.configuration).apiVversionTimeOffIdGet(id, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a New TimeOff.
     * @param {string} version 
     * @param {string} [commandJson] 
     * @param {Array<File>} [uploads] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeoffApi
     */
    public apiVversionTimeOffPost(version: string, commandJson?: string, uploads?: Array<File>, options?: RawAxiosRequestConfig) {
        return TimeoffApiFp(this.configuration).apiVversionTimeOffPost(version, commandJson, uploads, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates an existing TimeOff.
     * @param {string} version 
     * @param {string} [commandJson] 
     * @param {Array<File>} [uploads] 
     * @param {Array<string>} [keysForDeletion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeoffApi
     */
    public apiVversionTimeOffPut(version: string, commandJson?: string, uploads?: Array<File>, keysForDeletion?: Array<string>, options?: RawAxiosRequestConfig) {
        return TimeoffApiFp(this.configuration).apiVversionTimeOffPut(version, commandJson, uploads, keysForDeletion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the list of all TimeOffRequestTypes
     * @param {string} version 
     * @param {number} [pageNum] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeoffApi
     */
    public apiVversionTimeOffRequestTypeAllGet(version: string, pageNum?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return TimeoffApiFp(this.configuration).apiVversionTimeOffRequestTypeAllGet(version, pageNum, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Searches for a TimeOff (request)
     * @param {string} version 
     * @param {string} [term] 
     * @param {number} [pageNum] 
     * @param {number} [pageSize] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {boolean} [includeInactive] 
     * @param {Array<string>} [f] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeoffApi
     */
    public apiVversionTimeOffSearchGet(version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, includeInactive?: boolean, f?: Array<string>, options?: RawAxiosRequestConfig) {
        return TimeoffApiFp(this.configuration).apiVversionTimeOffSearchGet(version, term, pageNum, pageSize, sort, order, includeInactive, f, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Searches for a TimeOff (request)
     * @param {string} version 
     * @param {string} [term] 
     * @param {number} [pageNum] 
     * @param {number} [pageSize] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {Array<string>} [f] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeoffApi
     */
    public apiVversionTimeOffSearchMineGet(version: string, term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>, options?: RawAxiosRequestConfig) {
        return TimeoffApiFp(this.configuration).apiVversionTimeOffSearchMineGet(version, term, pageNum, pageSize, sort, order, f, options).then((request) => request(this.axios, this.basePath));
    }
}


/* tslint:disable */
/* eslint-disable */
/**
 * Intranet - Timesheet API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { CreateTimesheetCommand } from '../models';
// @ts-ignore
import type { GetTimesheetsByIdEmployeeAndDateRangeGroupedByTaskAndDateResponseResponse } from '../models';
// @ts-ignore
import type { StatusCodeResult } from '../models';
// @ts-ignore
import type { StringResponse } from '../models';
// @ts-ignore
import type { TimesheetByIdResponseResponse } from '../models';
// @ts-ignore
import type { TimesheetListResponseResponse } from '../models';
// @ts-ignore
import type { TimesheetResponse } from '../models';
// @ts-ignore
import type { UpdateTimesheetCommand } from '../models';
/**
 * TimesheetApi - axios parameter creator
 * @export
 */
export const TimesheetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets a list of Timesheets by Date Range
         * @param {number} idEmployee 
         * @param {Date} fromDate 
         * @param {Date} toDate 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTimesheetGetByEmployeeIdAndDateRangeIdEmployeeFromDateToDateGet: async (idEmployee: number, fromDate: Date, toDate: Date, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idEmployee' is not null or undefined
            assertParamExists('apiVversionTimesheetGetByEmployeeIdAndDateRangeIdEmployeeFromDateToDateGet', 'idEmployee', idEmployee)
            // verify required parameter 'fromDate' is not null or undefined
            assertParamExists('apiVversionTimesheetGetByEmployeeIdAndDateRangeIdEmployeeFromDateToDateGet', 'fromDate', fromDate)
            // verify required parameter 'toDate' is not null or undefined
            assertParamExists('apiVversionTimesheetGetByEmployeeIdAndDateRangeIdEmployeeFromDateToDateGet', 'toDate', toDate)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionTimesheetGetByEmployeeIdAndDateRangeIdEmployeeFromDateToDateGet', 'version', version)
            const localVarPath = `/api/v{version}/Timesheet/getByEmployeeIdAndDateRange/{idEmployee}/{fromDate}/{toDate}`
                .replace(`{${"idEmployee"}}`, encodeURIComponent(String(idEmployee)))
                .replace(`{${"fromDate"}}`, encodeURIComponent(String(fromDate)))
                .replace(`{${"toDate"}}`, encodeURIComponent(String(toDate)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of Timesheets by Job Id
         * @param {number} idJob 
         * @param {string} version 
         * @param {number} [offset] 
         * @param {number} [take] Limits the number of records returned. Default:              All
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTimesheetGetByJobIdJobGet: async (idJob: number, version: string, offset?: number, take?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idJob' is not null or undefined
            assertParamExists('apiVversionTimesheetGetByJobIdJobGet', 'idJob', idJob)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionTimesheetGetByJobIdJobGet', 'version', version)
            const localVarPath = `/api/v{version}/Timesheet/getByJob/{idJob}`
                .replace(`{${"idJob"}}`, encodeURIComponent(String(idJob)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets last Timesheet by Employee id
         * @param {number} idEmployee 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTimesheetGetLastByEmployeeIdIdEmployeeGet: async (idEmployee: number, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idEmployee' is not null or undefined
            assertParamExists('apiVversionTimesheetGetLastByEmployeeIdIdEmployeeGet', 'idEmployee', idEmployee)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionTimesheetGetLastByEmployeeIdIdEmployeeGet', 'version', version)
            const localVarPath = `/api/v{version}/Timesheet/getLastByEmployeeId/{idEmployee}`
                .replace(`{${"idEmployee"}}`, encodeURIComponent(String(idEmployee)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a timesheet by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTimesheetIdDelete: async (id: number, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVversionTimesheetIdDelete', 'id', id)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionTimesheetIdDelete', 'version', version)
            const localVarPath = `/api/v{version}/Timesheet/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a Timesheet by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTimesheetIdGet: async (id: number, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVversionTimesheetIdGet', 'id', id)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionTimesheetIdGet', 'version', version)
            const localVarPath = `/api/v{version}/Timesheet/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a New Timesheet.
         * @param {string} version 
         * @param {CreateTimesheetCommand} [createTimesheetCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTimesheetPost: async (version: string, createTimesheetCommand?: CreateTimesheetCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionTimesheetPost', 'version', version)
            const localVarPath = `/api/v{version}/Timesheet`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTimesheetCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an existing Timesheet
         * @param {string} version 
         * @param {UpdateTimesheetCommand} [updateTimesheetCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTimesheetPut: async (version: string, updateTimesheetCommand?: UpdateTimesheetCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionTimesheetPut', 'version', version)
            const localVarPath = `/api/v{version}/Timesheet`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTimesheetCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TimesheetApi - functional programming interface
 * @export
 */
export const TimesheetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TimesheetApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets a list of Timesheets by Date Range
         * @param {number} idEmployee 
         * @param {Date} fromDate 
         * @param {Date} toDate 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTimesheetGetByEmployeeIdAndDateRangeIdEmployeeFromDateToDateGet(idEmployee: number, fromDate: Date, toDate: Date, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTimesheetsByIdEmployeeAndDateRangeGroupedByTaskAndDateResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionTimesheetGetByEmployeeIdAndDateRangeIdEmployeeFromDateToDateGet(idEmployee, fromDate, toDate, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TimesheetApi.apiVversionTimesheetGetByEmployeeIdAndDateRangeIdEmployeeFromDateToDateGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets a list of Timesheets by Job Id
         * @param {number} idJob 
         * @param {string} version 
         * @param {number} [offset] 
         * @param {number} [take] Limits the number of records returned. Default:              All
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTimesheetGetByJobIdJobGet(idJob: number, version: string, offset?: number, take?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimesheetListResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionTimesheetGetByJobIdJobGet(idJob, version, offset, take, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TimesheetApi.apiVversionTimesheetGetByJobIdJobGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets last Timesheet by Employee id
         * @param {number} idEmployee 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTimesheetGetLastByEmployeeIdIdEmployeeGet(idEmployee: number, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimesheetByIdResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionTimesheetGetLastByEmployeeIdIdEmployeeGet(idEmployee, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TimesheetApi.apiVversionTimesheetGetLastByEmployeeIdIdEmployeeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes a timesheet by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTimesheetIdDelete(id: number, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusCodeResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionTimesheetIdDelete(id, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TimesheetApi.apiVversionTimesheetIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets a Timesheet by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTimesheetIdGet(id: number, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimesheetByIdResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionTimesheetIdGet(id, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TimesheetApi.apiVversionTimesheetIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Creates a New Timesheet.
         * @param {string} version 
         * @param {CreateTimesheetCommand} [createTimesheetCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTimesheetPost(version: string, createTimesheetCommand?: CreateTimesheetCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimesheetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionTimesheetPost(version, createTimesheetCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TimesheetApi.apiVversionTimesheetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates an existing Timesheet
         * @param {string} version 
         * @param {UpdateTimesheetCommand} [updateTimesheetCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionTimesheetPut(version: string, updateTimesheetCommand?: UpdateTimesheetCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimesheetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionTimesheetPut(version, updateTimesheetCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TimesheetApi.apiVversionTimesheetPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TimesheetApi - factory interface
 * @export
 */
export const TimesheetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TimesheetApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets a list of Timesheets by Date Range
         * @param {number} idEmployee 
         * @param {Date} fromDate 
         * @param {Date} toDate 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTimesheetGetByEmployeeIdAndDateRangeIdEmployeeFromDateToDateGet(idEmployee: number, fromDate: Date, toDate: Date, version: string, options?: RawAxiosRequestConfig): AxiosPromise<GetTimesheetsByIdEmployeeAndDateRangeGroupedByTaskAndDateResponseResponse> {
            return localVarFp.apiVversionTimesheetGetByEmployeeIdAndDateRangeIdEmployeeFromDateToDateGet(idEmployee, fromDate, toDate, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of Timesheets by Job Id
         * @param {number} idJob 
         * @param {string} version 
         * @param {number} [offset] 
         * @param {number} [take] Limits the number of records returned. Default:              All
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTimesheetGetByJobIdJobGet(idJob: number, version: string, offset?: number, take?: number, options?: RawAxiosRequestConfig): AxiosPromise<TimesheetListResponseResponse> {
            return localVarFp.apiVversionTimesheetGetByJobIdJobGet(idJob, version, offset, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets last Timesheet by Employee id
         * @param {number} idEmployee 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTimesheetGetLastByEmployeeIdIdEmployeeGet(idEmployee: number, version: string, options?: RawAxiosRequestConfig): AxiosPromise<TimesheetByIdResponseResponse> {
            return localVarFp.apiVversionTimesheetGetLastByEmployeeIdIdEmployeeGet(idEmployee, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a timesheet by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTimesheetIdDelete(id: number, version: string, options?: RawAxiosRequestConfig): AxiosPromise<StatusCodeResult> {
            return localVarFp.apiVversionTimesheetIdDelete(id, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a Timesheet by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTimesheetIdGet(id: number, version: string, options?: RawAxiosRequestConfig): AxiosPromise<TimesheetByIdResponseResponse> {
            return localVarFp.apiVversionTimesheetIdGet(id, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a New Timesheet.
         * @param {string} version 
         * @param {CreateTimesheetCommand} [createTimesheetCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTimesheetPost(version: string, createTimesheetCommand?: CreateTimesheetCommand, options?: RawAxiosRequestConfig): AxiosPromise<TimesheetResponse> {
            return localVarFp.apiVversionTimesheetPost(version, createTimesheetCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an existing Timesheet
         * @param {string} version 
         * @param {UpdateTimesheetCommand} [updateTimesheetCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionTimesheetPut(version: string, updateTimesheetCommand?: UpdateTimesheetCommand, options?: RawAxiosRequestConfig): AxiosPromise<TimesheetResponse> {
            return localVarFp.apiVversionTimesheetPut(version, updateTimesheetCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TimesheetApi - object-oriented interface
 * @export
 * @class TimesheetApi
 * @extends {BaseAPI}
 */
export class TimesheetApi extends BaseAPI {
    /**
     * 
     * @summary Gets a list of Timesheets by Date Range
     * @param {number} idEmployee 
     * @param {Date} fromDate 
     * @param {Date} toDate 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimesheetApi
     */
    public apiVversionTimesheetGetByEmployeeIdAndDateRangeIdEmployeeFromDateToDateGet(idEmployee: number, fromDate: Date, toDate: Date, version: string, options?: RawAxiosRequestConfig) {
        return TimesheetApiFp(this.configuration).apiVversionTimesheetGetByEmployeeIdAndDateRangeIdEmployeeFromDateToDateGet(idEmployee, fromDate, toDate, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of Timesheets by Job Id
     * @param {number} idJob 
     * @param {string} version 
     * @param {number} [offset] 
     * @param {number} [take] Limits the number of records returned. Default:              All
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimesheetApi
     */
    public apiVversionTimesheetGetByJobIdJobGet(idJob: number, version: string, offset?: number, take?: number, options?: RawAxiosRequestConfig) {
        return TimesheetApiFp(this.configuration).apiVversionTimesheetGetByJobIdJobGet(idJob, version, offset, take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets last Timesheet by Employee id
     * @param {number} idEmployee 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimesheetApi
     */
    public apiVversionTimesheetGetLastByEmployeeIdIdEmployeeGet(idEmployee: number, version: string, options?: RawAxiosRequestConfig) {
        return TimesheetApiFp(this.configuration).apiVversionTimesheetGetLastByEmployeeIdIdEmployeeGet(idEmployee, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a timesheet by id
     * @param {number} id 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimesheetApi
     */
    public apiVversionTimesheetIdDelete(id: number, version: string, options?: RawAxiosRequestConfig) {
        return TimesheetApiFp(this.configuration).apiVversionTimesheetIdDelete(id, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a Timesheet by id
     * @param {number} id 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimesheetApi
     */
    public apiVversionTimesheetIdGet(id: number, version: string, options?: RawAxiosRequestConfig) {
        return TimesheetApiFp(this.configuration).apiVversionTimesheetIdGet(id, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a New Timesheet.
     * @param {string} version 
     * @param {CreateTimesheetCommand} [createTimesheetCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimesheetApi
     */
    public apiVversionTimesheetPost(version: string, createTimesheetCommand?: CreateTimesheetCommand, options?: RawAxiosRequestConfig) {
        return TimesheetApiFp(this.configuration).apiVversionTimesheetPost(version, createTimesheetCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates an existing Timesheet
     * @param {string} version 
     * @param {UpdateTimesheetCommand} [updateTimesheetCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimesheetApi
     */
    public apiVversionTimesheetPut(version: string, updateTimesheetCommand?: UpdateTimesheetCommand, options?: RawAxiosRequestConfig) {
        return TimesheetApiFp(this.configuration).apiVversionTimesheetPut(version, updateTimesheetCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


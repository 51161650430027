/* tslint:disable */
/* eslint-disable */
/**
 * Intranet - Address API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { AddressListResponse } from '../models';
// @ts-ignore
import type { AddressResponse } from '../models';
// @ts-ignore
import type { BooleanResponse } from '../models';
// @ts-ignore
import type { CountryListResponse } from '../models';
// @ts-ignore
import type { CountryResponse } from '../models';
// @ts-ignore
import type { CreateAddressCommand } from '../models';
// @ts-ignore
import type { ListOptionDTOSearchResponseResponse } from '../models';
// @ts-ignore
import type { StateProvinceListResponse } from '../models';
// @ts-ignore
import type { StateProvinceResponse } from '../models';
// @ts-ignore
import type { StatusCodeResult } from '../models';
// @ts-ignore
import type { StringResponse } from '../models';
// @ts-ignore
import type { UpdateAddressCommand } from '../models';
/**
 * AddressApi - axios parameter creator
 * @export
 */
export const AddressApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes an Address by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionAddressAddressIdDelete: async (id: number, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVversionAddressAddressIdDelete', 'id', id)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionAddressAddressIdDelete', 'version', version)
            const localVarPath = `/api/v{version}/Address/address/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Shows Address By Id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionAddressAddressIdGet: async (id: number, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVversionAddressAddressIdGet', 'id', id)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionAddressAddressIdGet', 'version', version)
            const localVarPath = `/api/v{version}/Address/address/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates an Address
         * @param {string} version 
         * @param {CreateAddressCommand} [createAddressCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionAddressAddressPost: async (version: string, createAddressCommand?: CreateAddressCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionAddressAddressPost', 'version', version)
            const localVarPath = `/api/v{version}/Address/address`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAddressCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an existing Address
         * @param {string} version 
         * @param {UpdateAddressCommand} [updateAddressCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionAddressAddressPut: async (version: string, updateAddressCommand?: UpdateAddressCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionAddressAddressPut', 'version', version)
            const localVarPath = `/api/v{version}/Address/address`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAddressCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of Addresses By Id
         * @param {string} version 
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionAddressAddressesGet: async (version: string, ids?: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionAddressAddressesGet', 'version', version)
            const localVarPath = `/api/v{version}/Address/addresses`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (ids) {
                localVarQueryParameter['Ids'] = ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all Countries
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionAddressCountryAllGet: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionAddressCountryAllGet', 'version', version)
            const localVarPath = `/api/v{version}/Address/country/all`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all StateProvinces
         * @param {number} countryId 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionAddressCountryCountryIdStateprovinceAllGet: async (countryId: number, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'countryId' is not null or undefined
            assertParamExists('apiVversionAddressCountryCountryIdStateprovinceAllGet', 'countryId', countryId)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionAddressCountryCountryIdStateprovinceAllGet', 'version', version)
            const localVarPath = `/api/v{version}/Address/country/{countryId}/stateprovince/all`
                .replace(`{${"countryId"}}`, encodeURIComponent(String(countryId)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all StateProvinces by typeahead
         * @param {number} countryId 
         * @param {string} term 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionAddressCountryCountryIdStateprovinceTypeaheadTermGet: async (countryId: number, term: string, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'countryId' is not null or undefined
            assertParamExists('apiVversionAddressCountryCountryIdStateprovinceTypeaheadTermGet', 'countryId', countryId)
            // verify required parameter 'term' is not null or undefined
            assertParamExists('apiVversionAddressCountryCountryIdStateprovinceTypeaheadTermGet', 'term', term)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionAddressCountryCountryIdStateprovinceTypeaheadTermGet', 'version', version)
            const localVarPath = `/api/v{version}/Address/country/{countryId}/stateprovince/typeahead/{term}`
                .replace(`{${"countryId"}}`, encodeURIComponent(String(countryId)))
                .replace(`{${"term"}}`, encodeURIComponent(String(term)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a Country By Id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionAddressCountryIdGet: async (id: number, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVversionAddressCountryIdGet', 'id', id)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionAddressCountryIdGet', 'version', version)
            const localVarPath = `/api/v{version}/Address/country/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a StateProvince By Id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionAddressCountryStateprovinceIdGet: async (id: number, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVversionAddressCountryStateprovinceIdGet', 'id', id)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionAddressCountryStateprovinceIdGet', 'version', version)
            const localVarPath = `/api/v{version}/Address/country/stateprovince/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all countries by typeahead
         * @param {string} term 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionAddressCountryTypeaheadTermGet: async (term: string, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'term' is not null or undefined
            assertParamExists('apiVversionAddressCountryTypeaheadTermGet', 'term', term)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionAddressCountryTypeaheadTermGet', 'version', version)
            const localVarPath = `/api/v{version}/Address/country/typeahead/{term}`
                .replace(`{${"term"}}`, encodeURIComponent(String(term)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary AddressType ListOptionDTO
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionListAddressTypeGet: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionListAddressTypeGet', 'version', version)
            const localVarPath = `/api/v{version}/List/addressType`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Countries ListOptionDTO
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionListCountriesGet: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionListCountriesGet', 'version', version)
            const localVarPath = `/api/v{version}/List/countries`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all countries by typeahead
         * @param {string} term 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionGetBillingAddressByPersonaIdGet: async (personaId: number, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'term' is not null or undefined
            if (personaId === null || personaId === undefined) {
                throw new RequiredError('term','Required parameter term was null or undefined when calling apiVversionGetBillingAddressByPersonaIdGet.');
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionGetBillingAddressByPersonaIdGet.');
            }
            const localVarPath = `/api/v{version}/Address/addressByPersona/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(personaId)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :RawAxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AddressApi - functional programming interface
 * @export
 */
export const AddressApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AddressApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Deletes an Address by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionAddressAddressIdDelete(id: number, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionAddressAddressIdDelete(id, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddressApi.apiVversionAddressAddressIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Shows Address By Id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionAddressAddressIdGet(id: number, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionAddressAddressIdGet(id, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddressApi.apiVversionAddressAddressIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Creates an Address
         * @param {string} version 
         * @param {CreateAddressCommand} [createAddressCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionAddressAddressPost(version: string, createAddressCommand?: CreateAddressCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionAddressAddressPost(version, createAddressCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddressApi.apiVversionAddressAddressPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates an existing Address
         * @param {string} version 
         * @param {UpdateAddressCommand} [updateAddressCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionAddressAddressPut(version: string, updateAddressCommand?: UpdateAddressCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionAddressAddressPut(version, updateAddressCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddressApi.apiVversionAddressAddressPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets a list of Addresses By Id
         * @param {string} version 
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionAddressAddressesGet(version: string, ids?: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionAddressAddressesGet(version, ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddressApi.apiVversionAddressAddressesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets all Countries
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionAddressCountryAllGet(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionAddressCountryAllGet(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddressApi.apiVversionAddressCountryAllGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets all StateProvinces
         * @param {number} countryId 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionAddressCountryCountryIdStateprovinceAllGet(countryId: number, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StateProvinceListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionAddressCountryCountryIdStateprovinceAllGet(countryId, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddressApi.apiVversionAddressCountryCountryIdStateprovinceAllGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets all StateProvinces by typeahead
         * @param {number} countryId 
         * @param {string} term 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionAddressCountryCountryIdStateprovinceTypeaheadTermGet(countryId: number, term: string, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StateProvinceListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionAddressCountryCountryIdStateprovinceTypeaheadTermGet(countryId, term, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddressApi.apiVversionAddressCountryCountryIdStateprovinceTypeaheadTermGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets a Country By Id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionAddressCountryIdGet(id: number, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionAddressCountryIdGet(id, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddressApi.apiVversionAddressCountryIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets a StateProvince By Id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionAddressCountryStateprovinceIdGet(id: number, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StateProvinceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionAddressCountryStateprovinceIdGet(id, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddressApi.apiVversionAddressCountryStateprovinceIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets all countries by typeahead
         * @param {string} term 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionAddressCountryTypeaheadTermGet(term: string, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionAddressCountryTypeaheadTermGet(term, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddressApi.apiVversionAddressCountryTypeaheadTermGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary AddressType ListOptionDTO
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionListAddressTypeGet(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListOptionDTOSearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionListAddressTypeGet(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddressApi.apiVversionListAddressTypeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Countries ListOptionDTO
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionListCountriesGet(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListOptionDTOSearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionListCountriesGet(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddressApi.apiVversionListCountriesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get address by personaId
         * @param {string} term 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionGetBillingAddressByPersonaIdGet(personaId: number, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionGetBillingAddressByPersonaIdGet(personaId, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddressApi.apiVversionGetBillingAddressByPersonaIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AddressApi - factory interface
 * @export
 */
export const AddressApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AddressApiFp(configuration)
    return {
        /**
         * 
         * @summary Deletes an Address by id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionAddressAddressIdDelete(id: number, version: string, options?: RawAxiosRequestConfig): AxiosPromise<BooleanResponse> {
            return localVarFp.apiVversionAddressAddressIdDelete(id, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Shows Address By Id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionAddressAddressIdGet(id: number, version: string, options?: RawAxiosRequestConfig): AxiosPromise<AddressResponse> {
            return localVarFp.apiVversionAddressAddressIdGet(id, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates an Address
         * @param {string} version 
         * @param {CreateAddressCommand} [createAddressCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionAddressAddressPost(version: string, createAddressCommand?: CreateAddressCommand, options?: RawAxiosRequestConfig): AxiosPromise<AddressResponse> {
            return localVarFp.apiVversionAddressAddressPost(version, createAddressCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an existing Address
         * @param {string} version 
         * @param {UpdateAddressCommand} [updateAddressCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionAddressAddressPut(version: string, updateAddressCommand?: UpdateAddressCommand, options?: RawAxiosRequestConfig): AxiosPromise<AddressResponse> {
            return localVarFp.apiVversionAddressAddressPut(version, updateAddressCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of Addresses By Id
         * @param {string} version 
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionAddressAddressesGet(version: string, ids?: Array<number>, options?: RawAxiosRequestConfig): AxiosPromise<AddressListResponse> {
            return localVarFp.apiVversionAddressAddressesGet(version, ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all Countries
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionAddressCountryAllGet(version: string, options?: RawAxiosRequestConfig): AxiosPromise<CountryResponse> {
            return localVarFp.apiVversionAddressCountryAllGet(version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all StateProvinces
         * @param {number} countryId 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionAddressCountryCountryIdStateprovinceAllGet(countryId: number, version: string, options?: RawAxiosRequestConfig): AxiosPromise<StateProvinceListResponse> {
            return localVarFp.apiVversionAddressCountryCountryIdStateprovinceAllGet(countryId, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all StateProvinces by typeahead
         * @param {number} countryId 
         * @param {string} term 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionAddressCountryCountryIdStateprovinceTypeaheadTermGet(countryId: number, term: string, version: string, options?: RawAxiosRequestConfig): AxiosPromise<StateProvinceListResponse> {
            return localVarFp.apiVversionAddressCountryCountryIdStateprovinceTypeaheadTermGet(countryId, term, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a Country By Id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionAddressCountryIdGet(id: number, version: string, options?: RawAxiosRequestConfig): AxiosPromise<CountryResponse> {
            return localVarFp.apiVversionAddressCountryIdGet(id, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a StateProvince By Id
         * @param {number} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionAddressCountryStateprovinceIdGet(id: number, version: string, options?: RawAxiosRequestConfig): AxiosPromise<StateProvinceResponse> {
            return localVarFp.apiVversionAddressCountryStateprovinceIdGet(id, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all countries by typeahead
         * @param {string} term 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionAddressCountryTypeaheadTermGet(term: string, version: string, options?: RawAxiosRequestConfig): AxiosPromise<CountryListResponse> {
            return localVarFp.apiVversionAddressCountryTypeaheadTermGet(term, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary AddressType ListOptionDTO
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionListAddressTypeGet(version: string, options?: RawAxiosRequestConfig): AxiosPromise<ListOptionDTOSearchResponseResponse> {
            return localVarFp.apiVversionListAddressTypeGet(version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Countries ListOptionDTO
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionListCountriesGet(version: string, options?: RawAxiosRequestConfig): AxiosPromise<ListOptionDTOSearchResponseResponse> {
            return localVarFp.apiVversionListCountriesGet(version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get address by personaId
         * @param {string} term 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionGetBillingAddressByPersonaIdGet(personaId: number, version: string, options?: RawAxiosRequestConfig): AxiosPromise<AddressResponse> {
            return localVarFp.apiVversionGetBillingAddressByPersonaIdGet(personaId, version, options).then((request) => request(axios, basePath)); //CHECK
        },
    };
};

/**
 * AddressApi - object-oriented interface
 * @export
 * @class AddressApi
 * @extends {BaseAPI}
 */
export class AddressApi extends BaseAPI {
    /**
     * 
     * @summary Deletes an Address by id
     * @param {number} id 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public apiVversionAddressAddressIdDelete(id: number, version: string, options?: RawAxiosRequestConfig) {
        return AddressApiFp(this.configuration).apiVversionAddressAddressIdDelete(id, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Shows Address By Id
     * @param {number} id 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public apiVversionAddressAddressIdGet(id: number, version: string, options?: RawAxiosRequestConfig) {
        return AddressApiFp(this.configuration).apiVversionAddressAddressIdGet(id, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates an Address
     * @param {string} version 
     * @param {CreateAddressCommand} [createAddressCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public apiVversionAddressAddressPost(version: string, createAddressCommand?: CreateAddressCommand, options?: RawAxiosRequestConfig) {
        return AddressApiFp(this.configuration).apiVversionAddressAddressPost(version, createAddressCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates an existing Address
     * @param {string} version 
     * @param {UpdateAddressCommand} [updateAddressCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public apiVversionAddressAddressPut(version: string, updateAddressCommand?: UpdateAddressCommand, options?: RawAxiosRequestConfig) {
        return AddressApiFp(this.configuration).apiVversionAddressAddressPut(version, updateAddressCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of Addresses By Id
     * @param {string} version 
     * @param {Array<number>} [ids] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public apiVversionAddressAddressesGet(version: string, ids?: Array<number>, options?: RawAxiosRequestConfig) {
        return AddressApiFp(this.configuration).apiVversionAddressAddressesGet(version, ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all Countries
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public apiVversionAddressCountryAllGet(version: string, options?: RawAxiosRequestConfig) {
        return AddressApiFp(this.configuration).apiVversionAddressCountryAllGet(version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all StateProvinces
     * @param {number} countryId 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public apiVversionAddressCountryCountryIdStateprovinceAllGet(countryId: number, version: string, options?: RawAxiosRequestConfig) {
        return AddressApiFp(this.configuration).apiVversionAddressCountryCountryIdStateprovinceAllGet(countryId, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all StateProvinces by typeahead
     * @param {number} countryId 
     * @param {string} term 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public apiVversionAddressCountryCountryIdStateprovinceTypeaheadTermGet(countryId: number, term: string, version: string, options?: RawAxiosRequestConfig) {
        return AddressApiFp(this.configuration).apiVversionAddressCountryCountryIdStateprovinceTypeaheadTermGet(countryId, term, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a Country By Id
     * @param {number} id 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public apiVversionAddressCountryIdGet(id: number, version: string, options?: RawAxiosRequestConfig) {
        return AddressApiFp(this.configuration).apiVversionAddressCountryIdGet(id, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a StateProvince By Id
     * @param {number} id 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public apiVversionAddressCountryStateprovinceIdGet(id: number, version: string, options?: RawAxiosRequestConfig) {
        return AddressApiFp(this.configuration).apiVversionAddressCountryStateprovinceIdGet(id, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all countries by typeahead
     * @param {string} term 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public apiVversionAddressCountryTypeaheadTermGet(term: string, version: string, options?: RawAxiosRequestConfig) {
        return AddressApiFp(this.configuration).apiVversionAddressCountryTypeaheadTermGet(term, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Gets address by personaId
     * @param {string} personaId
     * @param {string} version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public apiVversionGetBillingAddressByPersonaIdGet(personaId: number, version: string, options?: RawAxiosRequestConfig)  {
        return AddressApiFp(this.configuration).apiVversionGetBillingAddressByPersonaIdGet(personaId, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Gets address by personaId
     * @param {string} personaId
     * @param {string} version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public apiVversionListAddressTypeGet(version: string, options?: RawAxiosRequestConfig) {
        return AddressApiFp(this.configuration).apiVversionListAddressTypeGet(version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Countries ListOptionDTO
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public apiVversionListCountriesGet(version: string, options?: RawAxiosRequestConfig) {
        return AddressApiFp(this.configuration).apiVversionListCountriesGet(version, options).then((request) => request(this.axios, this.basePath));
    }
}


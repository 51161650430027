import React from 'react';
import CorporateEntitiesRow from './CorporateEntitiesRow';

const CorporateEntitiesList = ({ searchResult }) => {
    return (
        <div className="table-responsive my-2">
            <table className='table table-hover'>
                <thead>
                    <tr>
                        <th role='button' scope='col'>Name</th>
                        <th role='button' scope='col'>Country</th>
                        <th role='button' scope="col" style={{ textAlign: 'center' }}>Detail</th>
                    </tr>
                </thead>
                {
                    searchResult?.queryResult?.length > 0 ?
                    <tbody>
                        {
                            searchResult?.queryResult?.map((item, index) => (
                                <CorporateEntitiesRow
                                    className={index % 2 > 0 ? '' : 'odd'}
                                    key={item.id}
                                    id={item.id}
                                    name={item.name}
                                    countryLabelKey={item.country.labelKey}
                                />
                            ))
                        }
                    </tbody>
                    :
                    <tbody className='aling-center mb-0'>
                        <tr>
                            <td colSpan={12} className='mb-0' style={{cursor: "default"}}>Corporate Entities not found</td>
                        </tr>
                    </tbody>
                }
            </table>
        </div>
    )
};

export default CorporateEntitiesList;

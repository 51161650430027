/* tslint:disable */
/* eslint-disable */
/**
 * Intranet - Projects API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { BooleanResponse } from '../models';
// @ts-ignore
import type { BulkUpdateLocaleCommand } from '../models';
// @ts-ignore
import type { CreateLocaleCommand } from '../models';
// @ts-ignore
import type { LocaleDtoResponse } from '../models';
// @ts-ignore
import type { LocaleDtoSearchResponseResponse } from '../models';
// @ts-ignore
import type { StatusCodeResult } from '../models';
// @ts-ignore
import type { StringResponse } from '../models';
// @ts-ignore
import type { StringSearchResponseResponse } from '../models';
// @ts-ignore
import type { StringStringStringDictionaryDictionaryResponse } from '../models';
// @ts-ignore
import type { UpdateLocaleCommand } from '../models';
/**
 * LocaleApi - axios parameter creator
 * @export
 */
export const LocaleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Updates multiple Locales at once
         * @param {string} version 
         * @param {BulkUpdateLocaleCommand} [bulkUpdateLocaleCommand] The command containing the list of locales to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionLocaleBulkPut: async (version: string, bulkUpdateLocaleCommand?: BulkUpdateLocaleCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionLocaleBulkPut', 'version', version)
            const localVarPath = `/api/v{version}/Locale/bulk`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkUpdateLocaleCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all Locales
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionLocaleGet: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionLocaleGet', 'version', version)
            const localVarPath = `/api/v{version}/Locale`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a locale by id
         * @param {string} key 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionLocaleKeyDelete: async (key: string, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('apiVversionLocaleKeyDelete', 'key', key)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionLocaleKeyDelete', 'version', version)
            const localVarPath = `/api/v{version}/Locale/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a Locale by key
         * @param {string} key 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionLocaleKeyGet: async (key: string, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('apiVversionLocaleKeyGet', 'key', key)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionLocaleKeyGet', 'version', version)
            const localVarPath = `/api/v{version}/Locale/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a New Locale.
         * @param {string} version 
         * @param {CreateLocaleCommand} [createLocaleCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionLocalePost: async (version: string, createLocaleCommand?: CreateLocaleCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionLocalePost', 'version', version)
            const localVarPath = `/api/v{version}/Locale`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLocaleCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an existing Locale
         * @param {string} version 
         * @param {UpdateLocaleCommand} [updateLocaleCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionLocalePut: async (version: string, updateLocaleCommand?: UpdateLocaleCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionLocalePut', 'version', version)
            const localVarPath = `/api/v{version}/Locale`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLocaleCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary searchs a Locale
         * @param {string} version 
         * @param {string} [term] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionLocaleSearchGet: async (version: string, term?: string, pageNum?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionLocaleSearchGet', 'version', version)
            const localVarPath = `/api/v{version}/Locale/search`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary searchs a Locale key
         * @param {string} version 
         * @param {string} [term] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionLocaleSearchKeyGet: async (version: string, term?: string, pageNum?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionLocaleSearchKeyGet', 'version', version)
            const localVarPath = `/api/v{version}/Locale/searchKey`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Userentity required
            await setApiKeyToObject(localVarHeaderParameter, "Userentity", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocaleApi - functional programming interface
 * @export
 */
export const LocaleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LocaleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Updates multiple Locales at once
         * @param {string} version 
         * @param {BulkUpdateLocaleCommand} [bulkUpdateLocaleCommand] The command containing the list of locales to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionLocaleBulkPut(version: string, bulkUpdateLocaleCommand?: BulkUpdateLocaleCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionLocaleBulkPut(version, bulkUpdateLocaleCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocaleApi.apiVversionLocaleBulkPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets all Locales
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionLocaleGet(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringStringStringDictionaryDictionaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionLocaleGet(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocaleApi.apiVversionLocaleGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes a locale by id
         * @param {string} key 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionLocaleKeyDelete(key: string, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionLocaleKeyDelete(key, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocaleApi.apiVversionLocaleKeyDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets a Locale by key
         * @param {string} key 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionLocaleKeyGet(key: string, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocaleDtoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionLocaleKeyGet(key, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocaleApi.apiVversionLocaleKeyGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Creates a New Locale.
         * @param {string} version 
         * @param {CreateLocaleCommand} [createLocaleCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionLocalePost(version: string, createLocaleCommand?: CreateLocaleCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionLocalePost(version, createLocaleCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocaleApi.apiVversionLocalePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates an existing Locale
         * @param {string} version 
         * @param {UpdateLocaleCommand} [updateLocaleCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionLocalePut(version: string, updateLocaleCommand?: UpdateLocaleCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionLocalePut(version, updateLocaleCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocaleApi.apiVversionLocalePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary searchs a Locale
         * @param {string} version 
         * @param {string} [term] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionLocaleSearchGet(version: string, term?: string, pageNum?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocaleDtoSearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionLocaleSearchGet(version, term, pageNum, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocaleApi.apiVversionLocaleSearchGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary searchs a Locale key
         * @param {string} version 
         * @param {string} [term] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionLocaleSearchKeyGet(version: string, term?: string, pageNum?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringSearchResponseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionLocaleSearchKeyGet(version, term, pageNum, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocaleApi.apiVversionLocaleSearchKeyGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * LocaleApi - factory interface
 * @export
 */
export const LocaleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LocaleApiFp(configuration)
    return {
        /**
         * 
         * @summary Updates multiple Locales at once
         * @param {string} version 
         * @param {BulkUpdateLocaleCommand} [bulkUpdateLocaleCommand] The command containing the list of locales to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionLocaleBulkPut(version: string, bulkUpdateLocaleCommand?: BulkUpdateLocaleCommand, options?: RawAxiosRequestConfig): AxiosPromise<BooleanResponse> {
            return localVarFp.apiVversionLocaleBulkPut(version, bulkUpdateLocaleCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all Locales
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionLocaleGet(version: string, options?: RawAxiosRequestConfig): AxiosPromise<StringStringStringDictionaryDictionaryResponse> {
            return localVarFp.apiVversionLocaleGet(version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a locale by id
         * @param {string} key 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionLocaleKeyDelete(key: string, version: string, options?: RawAxiosRequestConfig): AxiosPromise<BooleanResponse> {
            return localVarFp.apiVversionLocaleKeyDelete(key, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a Locale by key
         * @param {string} key 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionLocaleKeyGet(key: string, version: string, options?: RawAxiosRequestConfig): AxiosPromise<LocaleDtoResponse> {
            return localVarFp.apiVversionLocaleKeyGet(key, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a New Locale.
         * @param {string} version 
         * @param {CreateLocaleCommand} [createLocaleCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionLocalePost(version: string, createLocaleCommand?: CreateLocaleCommand, options?: RawAxiosRequestConfig): AxiosPromise<BooleanResponse> {
            return localVarFp.apiVversionLocalePost(version, createLocaleCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an existing Locale
         * @param {string} version 
         * @param {UpdateLocaleCommand} [updateLocaleCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionLocalePut(version: string, updateLocaleCommand?: UpdateLocaleCommand, options?: RawAxiosRequestConfig): AxiosPromise<BooleanResponse> {
            return localVarFp.apiVversionLocalePut(version, updateLocaleCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary searchs a Locale
         * @param {string} version 
         * @param {string} [term] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionLocaleSearchGet(version: string, term?: string, pageNum?: number, pageSize?: number, options?: RawAxiosRequestConfig): AxiosPromise<LocaleDtoSearchResponseResponse> {
            return localVarFp.apiVversionLocaleSearchGet(version, term, pageNum, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary searchs a Locale key
         * @param {string} version 
         * @param {string} [term] 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionLocaleSearchKeyGet(version: string, term?: string, pageNum?: number, pageSize?: number, options?: RawAxiosRequestConfig): AxiosPromise<StringSearchResponseResponse> {
            return localVarFp.apiVversionLocaleSearchKeyGet(version, term, pageNum, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LocaleApi - object-oriented interface
 * @export
 * @class LocaleApi
 * @extends {BaseAPI}
 */
export class LocaleApi extends BaseAPI {
    /**
     * 
     * @summary Updates multiple Locales at once
     * @param {string} version 
     * @param {BulkUpdateLocaleCommand} [bulkUpdateLocaleCommand] The command containing the list of locales to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocaleApi
     */
    public apiVversionLocaleBulkPut(version: string, bulkUpdateLocaleCommand?: BulkUpdateLocaleCommand, options?: RawAxiosRequestConfig) {
        return LocaleApiFp(this.configuration).apiVversionLocaleBulkPut(version, bulkUpdateLocaleCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all Locales
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocaleApi
     */
    public apiVversionLocaleGet(version: string, options?: RawAxiosRequestConfig) {
        return LocaleApiFp(this.configuration).apiVversionLocaleGet(version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a locale by id
     * @param {string} key 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocaleApi
     */
    public apiVversionLocaleKeyDelete(key: string, version: string, options?: RawAxiosRequestConfig) {
        return LocaleApiFp(this.configuration).apiVversionLocaleKeyDelete(key, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a Locale by key
     * @param {string} key 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocaleApi
     */
    public apiVversionLocaleKeyGet(key: string, version: string, options?: RawAxiosRequestConfig) {
        return LocaleApiFp(this.configuration).apiVversionLocaleKeyGet(key, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a New Locale.
     * @param {string} version 
     * @param {CreateLocaleCommand} [createLocaleCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocaleApi
     */
    public apiVversionLocalePost(version: string, createLocaleCommand?: CreateLocaleCommand, options?: RawAxiosRequestConfig) {
        return LocaleApiFp(this.configuration).apiVversionLocalePost(version, createLocaleCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates an existing Locale
     * @param {string} version 
     * @param {UpdateLocaleCommand} [updateLocaleCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocaleApi
     */
    public apiVversionLocalePut(version: string, updateLocaleCommand?: UpdateLocaleCommand, options?: RawAxiosRequestConfig) {
        return LocaleApiFp(this.configuration).apiVversionLocalePut(version, updateLocaleCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary searchs a Locale
     * @param {string} version 
     * @param {string} [term] 
     * @param {number} [pageNum] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocaleApi
     */
    public apiVversionLocaleSearchGet(version: string, term?: string, pageNum?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return LocaleApiFp(this.configuration).apiVversionLocaleSearchGet(version, term, pageNum, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary searchs a Locale key
     * @param {string} version 
     * @param {string} [term] 
     * @param {number} [pageNum] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocaleApi
     */
    public apiVversionLocaleSearchKeyGet(version: string, term?: string, pageNum?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return LocaleApiFp(this.configuration).apiVversionLocaleSearchKeyGet(version, term, pageNum, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}


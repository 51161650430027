import React, { useState, useEffect, createContext, useContext } from 'react';
import { useApi } from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import useEffectOnce from "../Hooks/useEffectOnce"
import useLocale from "../../i18n/useLocale";

export type newInvoiceProviderContextProps = {
    taxesValues?: Array<any>,
    invoiceTypes?: Array<any>,
    setTaxesValues?: Function,
    isLoading?: boolean,
    currencies?: Array<any>,
    setCurrencies?: Function,
    isManualCreation?: boolean,
    billToPersonas?: Array<any>,
    billFromPersonas?: Array<any>,
    isBillFromPersonasLoading?: boolean,
    isBillToPersonasLoading?: boolean,
    input?: any,
    setInput?: any,
    loadBillingAddressByPersonaId?: Function,
    isBillingAddressFromLoading?: boolean,
    isBillingAddressToLoading?: boolean,
}

const DefaultNewInvoiceInput = {
    to: "",
    Amount: 1,
    number: "",
    from: "",
    idFrom: "",
    idTo: "",
    fileURI: "",
    idWorkflowState: null,
    paymentTerms: "",
    idInvoiceType: "",
    comments: "",
    unit: "",
    invoiceTaxes: [],
    invoiceItems: [{
        id: 1,
        amount: "",
        unit: 1,
        invoiceItemTaxes: []
    }]
};

const NewInvoiceContext = createContext<newInvoiceProviderContextProps>({});

export const NewInvoiceProvider = ({children}) => {
    const [taxesValues, setTaxesValues] = useState<any>();
    const [invoiceTypes, setInvoiceTypes] = useState<any>();
    const [currencies, setCurrencies] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>()
    const [isBillFromPersonasLoading, setIsBillFromPersonasLoading] = useState<boolean>();
    const [isBillToPersonasLoading, setIsBillToPersonasLoading] = useState<boolean>();
    const [isBillingAddressToLoading, setIsBillingAddressToLoading] = useState<boolean>();
    const [isBillingAddressFromLoading, setIsBillingAddressFromLoading] = useState<boolean>();
    const [billToPersonas, setBillToPersonas] = useState<any>();
    const [billFromPersonas, setBillFromPersonas] = useState<any>();
    const [input, setInput] = useState<any>(DefaultNewInvoiceInput);
    const [isManualCreation, setIsManualCreation] = useState<boolean>(true);
    const api: Api = useApi();
    const { locale } = useLocale();

    //TODO: get the invoice from the api, and check if it is a manual creation
    // const checkIfManualCreation = async () => {
    //     await api.invoiceApi.apiVversionInvoiceIdGet(1).then((response) => {
    //         setIsManualCreation(response.data?.["data"].isManualCreation);
    //     })
    // }

    const loadBillingAddressByPersonaId = (id, type) => {
        if (type === "to"){
            setIsBillingAddressToLoading(true)
        } else {
            setIsBillingAddressFromLoading(true)
        }
        api.addressApi.apiVversionGetBillingAddressByPersonaIdGet(id, "1").then((response) => {
            const responseData = response.data?.["data"];
            if (type === "to"){
                setInput({
                    ...input,
                    addressTo: locale === "en" ? `${responseData.address1}, ${responseData.city}, ${responseData.stateProvince.code} ${responseData.zipCode}, ${responseData.country.name}` : `${responseData.address1}, ${responseData.city}, ${responseData.stateProvince.name}, ${responseData.country.name}, ${responseData.zipCode}`
                });
            } else {
                setInput({
                    ...input,
                    addressFrom: locale === "en" ? `${responseData.address1}, ${responseData.city}, ${responseData.stateProvince.code} ${responseData.zipCode}, ${responseData.country.name}` : `${responseData.address1}, ${responseData.city}, ${responseData.stateProvince.name}, ${responseData.country.name}, ${responseData.zipCode}`
                });
            }
        }).catch((error) => {
            if (type === "to"){
                setInput({
                    ...input,
                    addressTo: ""
                });
            } else {
                setInput({
                    ...input,
                    addressFrom: ""
                });
            }
        }).finally(() => {
            setIsBillingAddressToLoading(false)
            setIsBillingAddressFromLoading(false)
        })
    }

    const loadBillToPersonas = async () => {
        setIsBillToPersonasLoading(true)
        await api.invoiceApi.apiVversionPersonaEligibleInvoiceToGet("1").then((response) => {
            const responseData = response.data?.["data"].queryResult;
            setBillToPersonas(responseData)
        }).catch((error) => {
            setBillToPersonas([]);
        }).finally(() => {
            setIsBillToPersonasLoading(false)
        })
    }

    const loadBillFromPersonas = async () => {
        setIsBillFromPersonasLoading(true)
        await api.invoiceApi.apiVversionPersonaEligibleInvoiceFromGet("1").then((response) => {
            const responseData = response.data?.["data"];
            setBillFromPersonas([responseData]);
        }).catch((error) => {
            setBillFromPersonas([]);
        }).finally(() => {
            setIsBillFromPersonasLoading(false)
        })
    }

    const loadTaxRates = async () => {
        setIsLoading(true)
        await api.invoiceApi.apiVversionTaxRateCountryIdGet(3, "1").then((response) => {
            const responseData = response.data?.["data"].queryResult;
            setTaxesValues(responseData);
        }).catch((error) => {
            setTaxesValues([]);
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const loadInvoiceType = async () => {
        setIsLoading(true);
        await api.invoiceApi.apiVversionInvoiceTypeCountryIdCountryGet(1, "1").then((response) => {
            const responseData = response.data?.["data"].queryResult;
            setInvoiceTypes(responseData);
        }).catch((error) => {
            setInvoiceTypes([]);
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const loadCurrencies = async () => {
      await api.invoiceApi.apiVversionCurrencyAllGet("1").then((response) => {
          setCurrencies(response.data?.data?.queryResult);
      }).catch((error) => {
          console.log(error)
      });
  }

    useEffect(() => {
        if (input.idFrom && input.idFrom !== ""){
            loadBillingAddressByPersonaId(input.idFrom, "from")
        }
    }, [input.idFrom])

    useEffect(() => {
        if (input.idTo && input.idTo !== ""){
            loadBillingAddressByPersonaId(input.idTo, "to")
        }
    }, [input.idTo])

    useEffectOnce(() => {
        loadTaxRates();
        loadCurrencies();
        loadInvoiceType();
        loadBillFromPersonas();
        loadBillToPersonas();
    })

    return (
        <NewInvoiceContext.Provider value={{ isManualCreation, taxesValues, invoiceTypes, setTaxesValues, isLoading, currencies, setCurrencies, billToPersonas, billFromPersonas, isBillFromPersonasLoading, input, setInput, isBillToPersonasLoading, isBillingAddressFromLoading, isBillingAddressToLoading }}>
          {children}
        </NewInvoiceContext.Provider>
      );
}

export const useNewInvoice = () => {
  const context = useContext(NewInvoiceContext);
  if (!context) {
    throw new Error("useTax must be used within a TaxProvider");
  }
  return context;
};

import React, { FC } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import intl from 'components/i18n/ReactIntlWrapper';


type StatusSelectProps = {
  handleStatusChange: (event: any, value: any) => void,
  selectedStatus: any,
  status: any,
  disabled: boolean,
  defaultValue: any,
  isClearable: boolean
};

const StatusSelect: FC<StatusSelectProps> = ({ handleStatusChange, status, selectedStatus, disabled, defaultValue, isClearable }) => {
  let statusOptions = status.map(s => ({...s, status: s.active ? intl.get(`statusSelect.active`) : intl.get(`statusSelect.inactive`)}));
  let defaultOption = defaultValue ? statusOptions[0] : null;

  return (
    <Autocomplete
      id="status"
      options={statusOptions || []}
      getOptionLabel={(option) => option.status}
      value={statusOptions?.find((status) => status.active === selectedStatus) || defaultOption}
      onChange={(event, value) => handleStatusChange(event, value)}
      isOptionEqualToValue={(option, value) => option.active === value.active}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          label={intl.get('statusSelect.placeholder')}
          variant="outlined"
          fullWidth
        />
      )}
      clearOnEscape
    />
  );
}

export default StatusSelect;

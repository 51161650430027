import React, { ChangeEventHandler, MouseEventHandler, useEffect, useRef, useState } from 'react';

import { isValid, parse } from 'date-fns';
import { usePopper } from 'react-popper';
import { BsCalendarDate } from "react-icons/bs";
import { formatDate, getCurrentDateFormat, newDateAsUTC, uniformizeDate } from 'common/utils';
import { DatePicker } from '@mui/x-date-pickers';
type DatePickerDialogProps = {
  selected: Date | string,
  setSelected: Function,
  className: string,
  inputName: string,
  required: boolean,
  disabled: boolean
}



export default function DatePickerDialog({ selected, setSelected, className, inputName, required, disabled }: DatePickerDialogProps) {

  const [selectedDate, setSelectedDate] = useState(selected);
  const [valueDate, setValueDate] = useState<Date | undefined>();
  const [inputValue, setInputValue] = useState<string>('');
  const [isPopperOpen, setIsPopperOpen] = useState(false);

  const popperRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );

  const popper = usePopper(popperRef.current, popperElement, {
    placement: 'bottom-start'
  });

  const closePopper = () => {
    setIsPopperOpen(false);
    buttonRef?.current?.focus();
  };
  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.currentTarget.value;
    setInputValue(value);
  
    //const date = moment(value, getCurrentDateFormat(), true);
    const date = parse(value, "P", new Date());
    if (date) {
      setSelected(date);
      setSelectedDate(date);
    }
  };

  const handleButtonClick = () => {
    setIsPopperOpen(true);
  };

  const handleDaySelect = (date: Date) => {
    if (date !== undefined) {
      const formatedDate = formatDate(date);
      setSelected(formatedDate);
      if (date) {
        setInputValue(formatedDate);
        closePopper();
      } else {
        setInputValue('');
      }
    }
  };

  useEffect(() => {
    if (selected) {
      setSelectedDate(selected);
      setInputValue(formatDate(selected as Date));
    } else {
      setInputValue('');
    }
  }, [selected]);

  useEffect(() => {
    if(selected == null || selected ==  undefined || selected == ''){
        setValueDate(null)
    }
    //if selected date is String
    else if (typeof selected === 'string') {
      //USE DATE-FNS
      const date = new Date(selected);
      if (isValid(date)) {
        setValueDate(date);
      }
    }

    // if selectedDate as a Date instance
    else if (selected instanceof Date) {
      setValueDate(selected);
    }
  }, [selected]);

  return (
    <div className={className}>
      <DatePicker
        value={valueDate}
        onChange={(date) => {
          handleDaySelect(date as Date);
        }}
        format={"P"}
        disabled={disabled}
        name={inputName}
        slotProps={{
          textField: {
            size: 'small',
            className: 'w-100',
            InputProps: {
              required: required
            }
          }
        }}
      />
    </div>
  )
}
import React from 'react';
import NewInvoiceItemTableRow from './NewInvoiceItemTableRow';
import intl from 'components/i18n/ReactIntlWrapper';
import { useNewInvoice } from './newInvoiceProvider';

const NewInvoiceItemTable = ({setErrors}) => {
    const { setInput, input } = useNewInvoice();
    const handleInputItemTableChange = function (e) {
        const index = e.target.name.split("[")[1].split("")[0]
        const newItem = input.invoiceItems.find(item => item.id === Number(index))
        const category = e.target.name.split("[")[0]
        newItem[category] = e.target.value
        newItem["amount"] = (newItem.rate * newItem.quantity).toFixed(2);
        if (isNaN(newItem["amount"])) newItem["amount"] = 0;
        setInput({
            ...input,
            invoiceItems: input?.invoiceItems?.map((item, i) =>
                i === index ? { ...item, ...newItem } : item
            )
        });
    };

    const handleDelete = function(id){
        const newItems = input.invoiceItems.filter(item => item.id !== id)
        setInput({
            ...input,
            invoiceItems: newItems
        });
    }

    const handleNewRow = function(){
        let latestsId = 0;
        if (input.invoiceItems[0]){
            latestsId = input.invoiceItems[input.invoiceItems.length - 1]["id"]
        }
        const newItem = {
            id: latestsId + 1,
            amount: "",
            unit: 1,
            invoiceItemTaxes: []
        }
        setInput({
            ...input,
            invoiceItems: [...input.invoiceItems, {...newItem}]
        });
    }

    return (
        <div className="table-responsive my-2">
            <table className='table table-hover'>
                <thead>
                    <tr>
                        <th scope="col" style={{ backgroundColor: "black", color: "white", alignItems: "center", width: "40%" }}>{intl.get('newInvoice.desctiption')}</th>
                        <th scope="col" style={{ backgroundColor: "black", color: "white", alignContent: "center", width: "15%" }}>{intl.get('newInvoice.qty')}</th>
                        <th scope="col" style={{ backgroundColor: "black", color: "white", alignContent: "center", width: "15%" }}>{intl.get('newInvoice.rate')}</th>
                        <th scope="col" style={{ backgroundColor: "black", color: "white", alignContent: "center", width: "25%" }}>{intl.get('newInvoice.amount')}</th>
                        <th scope="col" style={{ backgroundColor: "black" }}></th>
                        <th scope="col" style={{ backgroundColor: "black" }}></th>
                    </tr>
                </thead>
                {
                    input.invoiceItems !== null &&
                    <tbody>
                        {
                            input?.invoiceItems?.map((e,i)=> (
                                <NewInvoiceItemTableRow
                                    key={e.id}
                                    id={e.id}
                                    description={e.description}
                                    quantity={e.quantity}
                                    invoiceItemTaxes={e.invoiceItemTaxes}
                                    taxesValues={input.taxes}
                                    rate={e.rate}
                                    amount={e.amount}
                                    className={i%2>0?'':'odd'}
                                    handleInputItemTableChange={(e) => handleInputItemTableChange(e)}
                                    handleDelete={(id) => handleDelete(id)}
                                />
                            ))
                        }
                        <a style={{textDecoration: "underline", cursor: "pointer"}} onClick={handleNewRow}>
                            +{intl.get('newInvoice.add.new.row')}
                        </a>
                    </tbody>
                }
            </table>
        </div>
    )
}

export default NewInvoiceItemTable;

import React, { useEffect, useState } from "react";
import { JiraIssue, Task } from "axiosApi/models";
import { Button, Container, Form, Input, Label } from "reactstrap";
import JiraIssuesList from "./JiraIssuesList";
import { useToastMessageQueue } from "components/ToastMessages/ToastMessageProvider";
import Api from "axiosApi/api";
import { useApi } from '../../api/ApiProvider';
import { FaSearch } from "react-icons/fa";
import intl from 'components/i18n/ReactIntlWrapper';
import { handleAPIError } from "common/errorHandler";
import ErrorSummary from "components/common/ErrorSummary";

export type JiraIssueSearchProps = {
    idProject: number,
    handleIssuesAdd: Function,
    tasks: Task[]
}
const JiraIssueSearch = ({ idProject, handleIssuesAdd, tasks }: JiraIssueSearchProps) => {

    const api: Api = useApi();
    const toast = useToastMessageQueue();
    type TaskJobErrors = {
        term?: string,
    }
    const [errors, setErrors] = useState<TaskJobErrors>({});
    const [term, setTerm] = useState('');

    const [jiraIssues, setJiraIssues] = useState<JiraIssue[]>();
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [pageNum, setPageNum] = useState(0);
    const pageSize = 8;
    const [selectedIssues, setSelectedIssues] = useState<string[]>([]);
    const WAIT_INTERVAL = 2000;
    const [timerID, setTimerID]=useState<NodeJS.Timeout>(null);

    const validate = (inputVal) => {
        let errors = {};
        if (!inputVal.term) {
            errors['term'] = intl.get(' validate.errors.isRequired');
        }
        setErrors({ ...errors });
        return Object.keys(errors).length > 0 ? errors : null;
    }

    const doSearch = (_value?: string, _pageNum?: number) => {
        const searchTerm = _value ? _value : term;
        const seachPageNum = _pageNum ? _pageNum : pageNum;
        if (validate({ term: searchTerm }) == null) {
            clearTimeout(timerID)
            setTimerID(setTimeout(() => {
                setLoading(true);
                api.jobsApi.apiVversionTaskJiraSearchGet("1", idProject, searchTerm, seachPageNum, pageSize).then((response) => {
                    if (response.data.data) {
                        const filteredIssues = response.data.data.queryResult.filter(issue => !isIssueAlreadyATask(issue.idJira));
                        setJiraIssues([...filteredIssues]);
                        const total = response.data.data.totalRecords - (filteredIssues.length > 0 ? (response.data.data.queryResult.length - filteredIssues.length) : 0);
                        setTotalRecords(total);
                    };
                }).catch((error) => {
                    handleAPIError(error, toast, errors)
                    setErrors({...errors})
                }).finally(() => {
                   setLoading(false);
                });
            }, WAIT_INTERVAL));

        }
    };



    const handleTermChange = (value: string) => {
        setTerm(value);
        setPageNum(0);
        doSearch(value);
    }

    const handleIssueSelect = (idJira: string, checked: boolean) => {
        if (checked) {
            setSelectedIssues(prev => [...prev, idJira])
        }
        else {
            setSelectedIssues(prev => [...prev.filter(selectedId => selectedId != idJira)]);
        }
    }

    const isIssueSelected = (idJira: string): boolean => {
        return selectedIssues.includes(idJira);
    }
    const handleIssueSelectAll = (checked: boolean) => {
        if (checked) {
            setSelectedIssues([...jiraIssues.map(i => i.idJira)]);
        }
        else {
            setSelectedIssues([]);
        }
    }

    const isIssueSelectedAll = (): boolean => {
        if (selectedIssues == undefined || jiraIssues == undefined)
            return false;
        return selectedIssues.length == jiraIssues.length && jiraIssues.length > 0;
    }

    const isIssueAlreadyATask = (idJira: string): boolean => {
        return tasks != undefined && tasks.find(t => t.idJira == idJira) != undefined;
    }

    const handleAddSelected = () => {
        handleIssuesAdd([...jiraIssues.filter(j => selectedIssues.includes(j.idJira))]);
        setSelectedIssues([]);
    }

    const handleSearch = () => {
        setPageNum(0);
        doSearch(undefined, 0);
    }

    useEffect(() => {
        if (term.length > 0)
            doSearch();
    }, [pageNum]);

    useEffect(() => {
        if (jiraIssues) {
            const filteredIssues = jiraIssues.filter(issue => !isIssueAlreadyATask(issue.idJira));
            const total = totalRecords - (jiraIssues.length - filteredIssues.length);
            if (filteredIssues.length == 0) {
                doSearch();
            }
            if (total == 0) {
                setTotalRecords(0);
            }
            setJiraIssues([...filteredIssues]);
        }
    }, [tasks]);

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <Form className="">
                        <div className="row">
                            <div className="col-md-12">
                                <ErrorSummary errors={errors} showEverything={true}></ErrorSummary>
                            </div>
                            <div className="col-md-4">
                                <Label check for="description" className={`mx-2 ${errors.term ? 'text-danger' : ''}`}>
                                    {intl.get('jiraIssueSearch.label')} {errors.term && (<span className='text-danger'>{errors.term}</span>)}
                                </Label>
                                <Input
                                    autoComplete="off"
                                    autoFocus={true}
                                    type="text"
                                    className="form-control my-2"
                                    id="term"
                                    name="term"
                                    placeholder={intl.get('jiraIssueSearch.placeholder')}
                                    onChange={(e) => { handleTermChange(e.currentTarget.value) }}
                                    value={term}
                                ></Input>

                            </div>
                            <div className="col-md-3">
                                <div className="d-flex justify-content-start align-items-center h-100">
                                    <Button outline type="submit" className="btn btn-primary m-2 mt-auto" onClick={(e) => { e.preventDefault(); handleSearch(); }}><FaSearch className="mb-1"></FaSearch>&nbsp;{intl.get('jiraIssueSearch.search.button')}</Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
            {(term.length > 0) &&
                <div className='row mb-2'>
                    <JiraIssuesList jiraIssues={jiraIssues} handleIssuesAdd={handleIssuesAdd} handleIssueSelect={handleIssueSelect} handleIssueSelectAll={handleIssueSelectAll} isIssueSelected={isIssueSelected} isIssueSelectedAll={isIssueSelectedAll} setPageNum={setPageNum} pageNum={pageNum} pageSize={pageSize} totalRecords={totalRecords} loading={loading} />
                </div>

            }
            {selectedIssues.length > 0 &&
                <div className='row my-2'>
                    <div className="col-12 d-flex justify-content-start">
                        <Button outline  type="button" className="btn btn-primary text-white m-2 mt-auto ms-auto me-0" onClick={(e) => { e.preventDefault(); handleAddSelected() }}>{intl.get('jiraIssueSearch.addSelected.button')}</Button>
                    </div>
                </div>}
        </>
    )
}

export default JiraIssueSearch;
import React from 'react';
import { Row, Label } from 'reactstrap';
import TimeOffRequestRow from "./TimeOffRequestRow";
import { HiInformationCircle } from 'react-icons/hi';
import intl from 'components/i18n/ReactIntlWrapper';
import '../../scss/Time Off/RequestedTimeOff.scss';

const RequestedTimeOff = ({ timeOffData }) => {
    return (
        <div>
            {
                timeOffData.totalRecords > 0 ?
                <div className='requested'>
                    {
                        timeOffData?.queryResult?.map((data, key) => {
                            return (
                                <div key={key}>
                                    <TimeOffRequestRow data={data}/>
                                </div>
                            )
                        })
                    }
                </div>
                :
                <div>
                    <Row className='container-fluid my-3 p-3' style={{display: "flex", justifyContent: "center"}}>
                        <HiInformationCircle style={{height: "2em", width: "2em", color: "#bdbdbd"}}/>
                        <Label for="RequestedTimeOff" style={{display: "flex", justifyContent: "center"}}>
                            {intl.get('requestedTimeOff.information.label')}
                        </Label>
                    </Row>
                </div>
            }
        </div>
    )
}

export default RequestedTimeOff;
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import Spinner from '../Spinner';
import { useApi } from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import { Employee } from 'axiosApi/models';
import { useToastMessageQueue } from '../../components/ToastMessages/ToastMessageProvider';
import intl from 'components/i18n/ReactIntlWrapper';
import TimeOffRequestForm from './TimeOffRequestForm';
import RequestedTimeOff from './RequestedTimeOff';
import '../../scss/Time Off/TimeOffRequest.scss';
import { addDays } from "date-fns";
import { getUserEntity } from 'common/UserEntityProvider';
//import { UpdateTimeOffCommand } from 'axiosApi/models/update-time-off-command';
//import { CreateTimeOffCommand } from 'axiosApi/models/create-time-off-command';
import { WorkflowProvider } from 'components/Workflow/WorkflowContext';
import { handleAPIError } from 'common/errorHandler';
import { confirmAlert } from 'react-confirm-alert';
import WorkflowLogEntries from 'components/Workflow/WorkflowLogEntries';
import { DateRange } from 'common/constants';
import { Stack } from '@mui/material';

export type TimeOffInput = {
    id: number | null,
    range: DateRange | undefined,
    title: string | null,
    idEmployee: number,
    employeeName: string | null,
    employeeLastName: string | null,
    idWorkflowState: number | null,
    workflowStateName: string | null,
    changeLog: string | null,
    idTimeOffRequestType: number | null,
    timeOffRequestTypeName: string | null,
    workFlowLabelKey: string | null,
    workflowStateColor: string | null,
	attachments?: any,
};

export type SearchResult = {
	totalRecords: number | null,
	queryResult: any
};

const TimeOffRequest = ({ HRView }) => {

    const initialRange: DateRange = {
        from: new Date(),
        to: addDays(new Date(), 4)
    };
    const [comments, setComments] = useState("");

    const { id } = useParams();
    const idNumber = id && parseInt(id);

    const userDetails = getUserEntity();

    const [input, setInput] = useState<TimeOffInput | null>({
        id: null,
        range: initialRange,
        title: "",
        idEmployee: userDetails.entityId,
        employeeName: "",
        employeeLastName: "",
        idWorkflowState: null,
        workflowStateName: "",
        changeLog: "",
        idTimeOffRequestType: null,
        timeOffRequestTypeName: "",
        attachments: [],
        workFlowLabelKey: "",
        workflowStateColor: ""
	});

    const [searchResult, setSearchResult] = useState<SearchResult | null>({
        totalRecords: null,
        queryResult: null
	});

    const [requestType, setRequestType] = useState(null);

    const [loading, setLoading] = useState<boolean>(false);

    const [errors, setErrors] = useState({});

    const [employees, setEmployees] = useState<Employee[]>(null);

    const api: Api = useApi();

    const toast = useToastMessageQueue();

    const navigate = useNavigate();

    const fetchRequestType = async () => {
        const response = await api.timeoffApi.apiVversionListRequestTypeGet("1", {}).then((response) => {
            if (response.data.data) {
                setRequestType(response.data.data);
            } else if (response.data) {
                setRequestType(response.data);
            }
        }).catch((error) => {
            handleAPIError(error, toast, errors);
            setErrors({...errors});
            setLoading(false);
        });
    };

    const fetchEmployees = async () => {
        const response = await api.employeeApi.apiVversionEmployeeAllGet("1", 1, 100, {}).then((response) => {
            if (response.data.data) {
                setEmployees(response.data.data.queryResult);
            };
        }).catch((error) => {
            handleAPIError(error, toast, errors);
            setErrors({ ...errors });
            setLoading(false);
        });
    };

    useEffect(() => {
        fetchRequestType();
        if (HRView) {
            fetchEmployees();
        }
        if (idNumber) {
            fetchTimeOffApprove(idNumber);
        }

	}, []);

    const fetchTimeOffApprove = async (idNumber: number) => {
        setLoading(true);
        const response = await api.timeoffApi.apiVversionTimeOffIdGet(idNumber, "1", {}).then((response) => {
            if (response.data) {
                setInput({
                    ...input,
                    changeLog: response.data?.data?.changeLog,
                    employeeName: response.data?.data?.employeeName,
                    employeeLastName: response.data?.data?.employeeLastName,
                    range: { from: response.data?.data?.from, to: response.data?.data?.to },
                    id: response.data?.data?.id,
                    idEmployee: response.data?.data?.idEmployee,
                    idTimeOffRequestType: response.data?.data?.idTimeOffRequestType,
                    idWorkflowState: response.data?.data?.idWorkflowState,
                    timeOffRequestTypeName: response.data?.data?.timeOffRequestTypeName,
                    title: response.data?.data?.title,
                    workflowStateName: response.data?.data?.workflowStateName,
                    workFlowLabelKey: response.data?.data?.workFlowLabelKey,
                    workflowStateColor: response.data?.data?.workflowStateColor,
                    attachments: response.data?.data?.attachments
                });
                setLoading(false);
            };
        }).catch((error) => {
            setLoading(false);
            handleAPIError(error, toast, errors);
            setErrors({ ...errors });
            setTimeout(function () {
                navigate(-1);
            }, 2000);
        });
    };

    const fetchFile = async (timeOffId: number, fileName: string, employeeId: number): Promise<string | null> => {
        try {
            const response = await api.timeoffApi.apiVversionTimeOffFileGet("1", timeOffId, fileName, employeeId, {})
            if (response.data) {
                return formatFile(response.data.data[fileName])
            }
        } catch (error) {
            handleAPIError(error, toast, errors);
            setErrors({ ...errors });
        }
        return null;
    };

    const validate = (input) => {
        let errors: any = {};
        if (!input.range) {
            errors.range = intl.get('validate.errors.isRequired');
        }
        if (!input.idTimeOffRequestType) {
            errors.idTimeOffRequestType = intl.get('validate.errors.isRequired');
        }
        setErrors({ ...errors });
        return Object.keys(errors).length > 0 ? errors : {};
    };

    const handleRequestChange = (e) => {
        if (e !== null) {
            setInput({...input, idTimeOffRequestType: e.id});
            setErrors(validate({
                ...input,
                idTimeOffRequestType: e.id
            }));
        } else {
            setInput({...input, idTimeOffRequestType: null});
            setErrors(validate({
                ...input,
                idTimeOffRequestType: e
            }));
        };
    };

    const handleDaySelect = (date: DateRange | undefined) => {
        if (date) {
            setInput({...input, range: date});
            setErrors(validate({
                ...input,
                range: date
            }));
        };
    };

    const handleEmployeeChange = (e) => {
        const inputVal = {
            ...input,
            idEmployee: e.id
        };
        setInput({ ...inputVal });
    };

    const formatDateISO = (dateString) => {
        const day = String(dateString.getDate()).padStart(2, '0');
        const month = String(dateString.getMonth() + 1).padStart(2, '0');
        const year = dateString.getFullYear();
        const dateISO = new Date(`${year}-${month}-${day}T00:00:00`);
        return dateISO;
    };

    const formatFile = (file) => {
        try{
            const base64String = file;
            const binaryString = atob(base64String);
            const byteArray = new Uint8Array(binaryString.length);
            for (let i = 0; i < binaryString.length; i++) {
                byteArray[i] = binaryString.charCodeAt(i);
            }
            const fileExtension = file.split('.').pop()?.toLowerCase();
            let mimeType = "application/octet-stream";
            if (fileExtension === "pdf") mimeType = "application/pdf";
            else if (fileExtension === "png") mimeType = "image/png";
            else if (fileExtension === "jpg" || fileExtension === "jpeg") mimeType = "image/jpeg";
            const blob = new Blob([byteArray], { type: mimeType });
            return URL.createObjectURL(blob);
        } catch (error) {
            console.error('Error in formatting file:', error);
            return null;
        }
    }

    const handleSave = async (id, comments) => {
        setLoading(true);
        const errors = validate(input);
        if (JSON.stringify(errors) === JSON.stringify({})) {
            if (!input.id) {
                let cmdJSON: any = {//TODO CC: CreateTimeOffCommand reeplaced by any
                    from: input.range.from,
                    to: input.range.to,
                    title: input.title,
                    idEmployee: input.idEmployee,
                    idWorkflowState: id,
                    changeLog: input.changeLog,
                    idTimeOffRequestType: input.idTimeOffRequestType,
                    comments: comments
                };
                let cmd = JSON.stringify(cmdJSON);
                const response = await api.timeoffApi.apiVversionTimeOffPost("1", cmd, input.attachments, {}).then((response) => {
                    if (response.data) {
                        toast.success({ body: intl.get('calendarEvent.toast.success.handleSave.add')});
                        navigate(-1);
                    };
                }).catch((error) => {
                    handleAPIError(error, toast, errors);
                    setErrors({ ...errors });
                });
                setLoading(false);
            } else {
                let cmdJSON: any = { //TODO CC: UpdateTimeOffCommand replaced by any
                    id: input.id,
                    from: input.range.from,
                    to: input.range.to,
                    title: input.title,
                    idEmployee: input.idEmployee,
                    changeLog: input.changeLog,
                    idWorkflowState: id,
                    idTimeOffRequestType: input.idTimeOffRequestType,
                    comments: comments
                };
                let cmd = JSON.stringify(cmdJSON);
                const response = await api.timeoffApi.apiVversionTimeOffPut("1", cmd, input.attachments, [], {}).then((response) => {
                    if (response.data) {
                        toast.success({ body: intl.get('calendarEvent.toast.success.handleSave')});
                        navigate(-1);
                    };
                }).catch((error) => {
                    handleAPIError(error, toast, errors);
                    setErrors({ ...errors });
                });
                setLoading(false);
            }
        } else {
            setLoading(false);
        }
    };

    const handleDownload = async (file) => {
        let downloadUrl;
        if (file instanceof File) {
            downloadUrl = URL.createObjectURL(file)
        } else {
            downloadUrl = await fetchFile(input.id, file, input.idEmployee);
        }
        if (typeof downloadUrl == "string") {
            downloadFile(downloadUrl);
        }
        function downloadFile(url){
            const link = document.createElement('a');
            link.href = url;
            link.download = file.name ? file.name : file;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        };
    }

    const handleDelete = (index, file) => {
        const removeAttachment = () => {
            const temp = [...input.attachments];
            temp.splice(index, 1);
            setInput((prevInput) => ({
                ...prevInput,
                attachments: temp,
            }));
        };
        confirmAlert({
            title: intl.get('delete.modal.title'),
            message: intl.get('delete.modal.timeoff.message'),
            buttons: [
                {
                    label: intl.get('delete.modal.cancel.button'),
                    onClick: () => { }
                },
                {
                    label: intl.get('delete.modal.delete.button'),
                    onClick: async () =>
                    {   if(file instanceof File)
                        {
                            removeAttachment();
                        } else {
                            const response = await api.timeoffApi.apiVversionTimeOffFileDelete("1", input.id, file, input.idEmployee, {})
                            .then((response) =>
                            {
                                removeAttachment();
                                toast.success({ body: intl.get('timeOff.toast.success.handleDelete')});
                            }).catch((error) => {
                                handleAPIError(error, toast, errors);
                                setErrors({...errors});});
                        }
                    },
                }
            ]
        });
      };


    return (
        <div className="container" style={{width: "900px"}}>
            <WorkflowProvider  moduleId={2}>
            <div className='card mt-4'>
                <div className="card-header">
                    <h2 className="title">{intl.get('timeOff.request.header')}</h2>
                </div>
                <div className='card-body'>
                    <Stack gap={2}>
                        <TimeOffRequestForm
                            requestType={requestType?.queryResult}
                            input={input}
                            setInput={setInput}
                            errors={errors}
                            loading={loading}
                            handleRequestChange={handleRequestChange}
                            handleDaySelect={handleDaySelect}
                            handleSave={handleSave}
                            employees={employees}
                            handleEmployeeChange={handleEmployeeChange}
                            HRView={HRView}
                            setComments={setComments}
                            handleDownload={handleDownload}
                            handleDelete={handleDelete}
                        />

                        <WorkflowLogEntries idModule={2} idEntity={input.id} />
                        <div className='mt-20'>
                            <h4 className="timeOffTitle">{intl.get('timeOff.requested.header')}</h4>
                            {
                                loading === true ?
                                <Spinner /> :
                                <RequestedTimeOff timeOffData={searchResult}/>
                            }
                        </div>
                    </Stack>
                </div>
            </div>
            </WorkflowProvider>
        </div>
    )
}

export default TimeOffRequest;

import React, { useState, useEffect, FC } from 'react';
import PersonaRow from './PersonaRow';
import Spinner from '../Spinner';
import '../../scss/AddressCompact.scss';
import {useApi} from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import { PermissionsGate}  from '../PermissionsGate';
import { HiOutlineMailOpen, HiOutlinePlus } from 'react-icons/hi';
import intl from 'components/i18n/ReactIntlWrapper';
import { handleAPIError } from '../../common/errorHandler';
import ModalPersona from './ModalPersona';
import { CorporateEntity } from 'axiosApi/models/corporate-entity';
import { Label } from 'reactstrap';
import { PersonaDTOSearchResponse } from 'axiosApi/models';

interface PersonaCompactProps {
    disabled: boolean;
    personaExist: boolean
    setPersonaExist: (value: boolean) => void;
    entityId: number;
    idEntityType: number;
    editScopes?: string[];
    deleteScopes?: string[];
    inputProvider?: any; 
    setInputProvider?: (value: any) => void;
    maxRows?: number;
    personaTypesIds?: number[];
    corporateEntity?: boolean;
    inputCorporateEntity?: any;
    setInputCorporateEntity?: (value: any) => void;
    showCorporateEntities?: boolean; 
  }

  const PersonaCompact: FC<PersonaCompactProps> = (props) => {

    const { disabled, entityId, personaExist, setPersonaExist, idEntityType ,editScopes, deleteScopes, inputProvider = null,
        setInputProvider = null, maxRows = 2, personaTypesIds = [], corporateEntity = false, inputCorporateEntity = null, 
    setInputCorporateEntity = null, showCorporateEntities = true} = props;

    const api: Api = useApi();

    const [personas, setPersonas] = useState<PersonaDTOSearchResponse>({
        queryResult: [],
        totalRecords: 0
    });

	const [loading, setLoading] = useState<boolean>(false);

    const toast = useToastMessageQueue();

    const [isPersonaRowDetailOpen, setIsPersonaRowDetailOpen] = useState(false);

    const [corporateEntities, setcorporateEntities] = useState<CorporateEntity[]>([])

    const [errors, setErrors] = useState({});

    const [personaTypeExist, setPersonaTypeExist] = useState(0);

    const fetchPersona = async (entityId: number, idEntityType: number) => {
        setLoading(true);
        const response = await api.invoiceApi.apiVversionPersonaEntityIdentityIdGet(entityId, "1", idEntityType, {}).then((response) => {
            if(response.data) {
                setPersonas({
                    queryResult: response.data?.data?.queryResult,
                    totalRecords: response.data?.data?.totalRecords
                });
                setPersonaExist(true)
                if(response.data?.data?.queryResult?.length == 1){
                    setPersonaTypeExist(response.data?.data?.queryResult[0]?.idPersonaType);
                };      
            };
        }).catch((error) => {
            handleAPIError(error, toast, errors);
            setErrors({...errors});
        });
        setLoading(false);
    };

    const fetchCorporateEntities = async () => {
        setLoading(true);
        const response = await api.invoiceApi.apiVversionCorporateEntityAllGet("1").then((response) => {
            if(response.data) {
                setcorporateEntities(response.data?.data.queryResult);
                setLoading(false);      
            };   
        }).catch((error) => {
            handleAPIError(error, toast, errors);
            setErrors({...errors});
            setLoading(false);
        });
    };
    
    useEffect(() => {
        if(!personaExist){
            fetchPersona(entityId, idEntityType);
        }
    }, [personaExist]);

    useEffect(() => {
        fetchCorporateEntities();
    },[])
    
    const closePersonaRowDetail = () => setIsPersonaRowDetailOpen(false);

    const handlePersonaRowDetailOpen = () => {
        setIsPersonaRowDetailOpen(true);
    };

    return (
        <>
            {
                corporateEntity ?
                <div className='row'>
                    <div className='col'>
                        <Label for="corporateEntity">{intl.get('personaCompact.header')}</Label>
                    </div>
                    <PermissionsGate viewScopes={editScopes} editScopes={editScopes} viewRoles={[]} editRoles={[]} >  
                        <div className="col text-end">
                            <button type="submit" className="btn btn-primary newAddress" disabled={disabled || (personas?.queryResult?.length >= maxRows && personaExist || !entityId)} onClick={handlePersonaRowDetailOpen} ><HiOutlinePlus className='mb-1'/> {intl.get('personaCompact.newPersona.button')}</button>
                            <ModalPersona
                                entityId={entityId}
                                idEntityType={idEntityType}
                                isOpen={isPersonaRowDetailOpen} 
                                closeModal={closePersonaRowDetail} 
                                corporateEntities={corporateEntities} 
                                inputProvider={inputProvider} 
                                setInputProvider={setInputProvider}
                                setPersonaExist={setPersonaExist}
                                personaExists={personaExist}
                                personaTypeExist={personaTypeExist}
                                personaTypesIds={personaTypesIds}
                                inputCorporateEntity={inputCorporateEntity}
                                setInputCorporateEntity={setInputCorporateEntity}
                                showCorporateEntities={showCorporateEntities}
                            />    
                        </div>
                    </PermissionsGate>
                </div>
                :
                <div className="card-header">
                    <h4 className="title"><HiOutlineMailOpen className='mb-1'/> {intl.get('personaCompact.header')}</h4>
                </div>
            }
            {
                loading === true ?
                <Spinner small={true} /> :
                <div className="card-body">
                    <div className="table-responsive container-overflow">
                        <table className='table table-hover'>
                            <thead>
                                <tr>
                                    <th scope="col" >{intl.get('personaCompact.table.thead.name')}</th>
                                    <th scope="col" >{intl.get('personaCompact.table.thead.type')}</th>
                                    <th scope="col" >{intl.get('personaCompact.table.thead.taxId')}</th>
                                    {
                                        showCorporateEntities && (
                                            <th scope="col" >{intl.get('personaCompact.table.thead.billTo')}</th>
                                        )
                                    }
                                    <th scope="col" className='text-center'>{intl.get('personaCompact.table.thead.actions')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                    {   Array.isArray(personas.queryResult) &&
                                        personas.queryResult.slice(0, maxRows).map((p, i) => (
                                            <PersonaRow
                                                key={`${p.id}-personaRow`}
                                                editScopes = {editScopes}
                                                deleteScopes= {deleteScopes}
                                                entityId = {p.idEntity}
                                                idEntityType = {p.idEntityType}
                                                persona = {p}
                                                corporateEntities = {corporateEntities}
                                                inputProvider = {inputProvider}
                                                setInputProvider = {setInputProvider}
                                                errors = {errors}
                                                setErrors = {setErrors}
                                                disabled = {disabled}
                                                personaExist={personaExist}
                                                setPersonaExist={setPersonaExist}
                                                setPersonaTypeExist={setPersonaTypeExist}
                                                inputCorporateEntity={inputCorporateEntity}
                                                setInputCorporateEntity={setInputCorporateEntity}
                                                showCorporateEntities={showCorporateEntities}
                                            />
                                        ))
                                    }
                            </tbody>
                        </table>
                    </div>
                    {
                        !corporateEntity ?
                        <PermissionsGate viewScopes={editScopes} editScopes={editScopes} viewRoles={[]} editRoles={[]} >  
                            <div className="text-center mt-2">
                                <button type="submit" className="btn btn-primary newAddress" disabled={disabled || (personas?.queryResult?.length >= maxRows && personaExist)} onClick={handlePersonaRowDetailOpen} ><HiOutlinePlus className='mb-1'/> {intl.get('personaCompact.newPersona.button')}</button>
                            <ModalPersona
                                entityId={entityId}
                                idEntityType={idEntityType}
                                isOpen={isPersonaRowDetailOpen} 
                                closeModal={closePersonaRowDetail} 
                                corporateEntities={corporateEntities} 
                                inputProvider={inputProvider} 
                                setInputProvider={setInputProvider}
                                setPersonaExist={setPersonaExist}
                                personaExists={personaExist}
                                personaTypeExist={personaTypeExist}
                                showCorporateEntities={showCorporateEntities}
                            />    
                            </div>
                        </PermissionsGate>
                        :
                        null
                    }
                </div>
            }
        </>
    )
}

export default PersonaCompact;
import React from 'react';
import { Form, Row, Col, Label, Input } from 'reactstrap';
import intl from 'react-intl-universal';
import ErrorSummary from '../../components/common/ErrorSummary';
import '../../scss/Corporate Entities/CorporateEntityForm.scss';
import CountrySelect from '../CountrySelect';

const CorporateEntityForm = ({ errors, input, handleInputChange, disabled, handleStatusChange, countries, handleCountryChange }) => {

    return (
        <Form className='my-3'>
            <Row>
                <Col md={12}>
                    <ErrorSummary errors={errors} showEverything={true} ></ErrorSummary>
                </Col>
            </Row>
            <Row className='mb-2'>
                <Col md={6}>
                    <Label for="status" className='margin-label'>{intl.get('statusSelect.label')}</Label>
                    <div className="form-check form-switch switch">
                        <label className="form-check-label margin-label" htmlFor="flexSwitchCheckDefault">{`${input.active ? intl.get('roleRow.active') : intl.get('roleRow.inactive')}`}</label>
                        <input className="form-check-input input-switch" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={input.active} onChange={handleStatusChange} disabled={disabled}/>
                    </div>
                </Col>
            </Row>
            <Row className='mb-2'>
                <Col md={6}>
                    <Label for="companyFantasyName" className={`${errors.name ? 'text-danger mb-0' : 'mb-0'}`}>
                        * Company Fantasy Name {errors.name && (<span className='text-danger'>{errors.name}</span>)}
                    </Label>
                    <p className='fw-light purpose'>For system identity purpose</p>
                    <Input
                        id="companyFantasyName"
                        name="name"
                        value={input?.name}
                        onChange={handleInputChange}
                        disabled={disabled}
                    />
                </Col>
                <Col md={6}>
                    <Label for="idCountry" className={`${errors.idCountry ? 'text-danger margin-idCountry' : 'margin-idCountry'}`}>
                        {intl.get('addressForm.country')} {errors.idCountry && (<span className='text-danger'>{errors.idCountry}</span>)}
                    </Label>
                    <CountrySelect
                        handleCountryChange={handleCountryChange}
                        selectedCountry={input.country.labelKey}
                        countries={countries}
                        inputCountry={input.country.name}
                        disabled={disabled}
                    />
                </Col>
            </Row>
        </Form>
    )
}

export default CorporateEntityForm;
import React, { useState, useEffect, FC } from 'react';
import AddressForm from './AddressForm';
import { useApi } from '../api/ApiProvider';
import Api from '../axiosApi/api';
import { CreateAddressCommand, UpdateAddressCommand } from '../axiosApi/models';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import { HiOutlineSave } from 'react-icons/hi';
import RequiredField from './RequiredField';
import intl from 'components/i18n/ReactIntlWrapper';
import Grid from '@mui/material/Unstable_Grid2'; // Import Grid2
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Spinner from './Spinner';
import { handleAPIError } from '../common/errorHandler';
import corporateEntityAddressTypesData from '../fixture/corporateEntityAddressTypesData';

type AddressProps = {
    addressId: number | null,
    addresses: any,
    setAddresses: any,
    addressesId: any,
    setAddressesId: any,
    closeModal: any,
    inputProvider: any | null,
    setInputProvider: Function | null,
    realAddress?: boolean,
    legalAddress?: boolean,
    inputCorporate?: any,
    setInputCorporate?: any
};

const Address: FC<AddressProps> = ({ addressId, addresses, setAddresses, addressesId, setAddressesId, closeModal, inputProvider, setInputProvider, realAddress = null, legalAddress = null, inputCorporate = null, setInputCorporate = null }) => {

    const defaultAddressInput = {
        address1: '',
        address2: '',
        address3: '',
        idCountry: null,
        country: {name: '', labelKey: ''},
        city: '',
        idStateProvince: null,
        stateProvince: {name: ''},
        zipCode: null,
        idAddressType: null,
    };

    const [input, setInput] = useState(defaultAddressInput);

    const api: Api = useApi();

    const [errors, setErrors] = useState({});

    const [countries, setCountries] = useState(null);

    const [countriesList, setcountriesList] = useState(null)

    const [states, setStates] = useState(null);

    const [addressTypes, setAddresstypes] = useState(null);

    const [corporateEntityAddressTypes, setCorporateEntityAddressTypes] = useState(null);

    const [loading, setLoading] = useState<boolean>(false);

    const toast = useToastMessageQueue();

    const fetchAddress = async (addressId) => {
        if (addressId === null) {
            setInput(defaultAddressInput);
        } else {
            setLoading(true);
            const response = await api.addressApi.apiVversionAddressAddressIdGet(addressId, "1", {}).then((response) => {
                if (response.data) {
                    setInput({
                        address1: response.data?.data?.address1,
                        address2: response.data?.data?.address2,
                        address3: response.data?.data?.address3,
                        idCountry: response.data?.data?.idCountry,
                        country: {name: response.data?.data?.country?.name, labelKey: response.data?.data?.country?.labelKey},
                        city: response.data?.data?.city,
                        idStateProvince: response.data?.data?.idStateProvince,
                        stateProvince: {name: response.data?.data?.stateProvince?.name},
                        zipCode: response.data?.data?.zipCode,
                        idAddressType: response.data?.data?.idAddressType,
                    });
                };
            }).catch((error) => {
                handleAPIError(error, toast, errors);
                setErrors({...errors});
            }).finally(() => {
                setLoading(false);
            });
        }
    };

    const fetchCountries = async () => {
        const response = await api.addressApi.apiVversionAddressCountryAllGet("1", {}).then((response) => {
            if (response.data) {
                setCountries(response.data.data);                
            };
        }).catch((error) => {
            handleAPIError(error, toast, errors);
            setErrors({...errors});
        });
    };

    const fetchStates = async () => {
        if(input.idCountry) {
            const response = await api.addressApi.apiVversionAddressCountryCountryIdStateprovinceAllGet(input.idCountry, "1", {}).then((response) => {
                if (response.data) {
                    setStates(response.data.data);
                };
            }).catch((error) => {
                handleAPIError(error, toast, errors);
                setErrors({...errors});
            });
        }
    };

    const fetchListAddressType = async () => {
            setLoading(true);
            await api.addressApi.apiVversionListAddressTypeGet("1").then((response) => {
                if ('queryResult' in response.data.data ) {
                    setAddresstypes(response.data.data.queryResult);
                } else if ('queryResult' in response) {
                    setAddresstypes(response.queryResult);
                }  
            }).catch((error) => {
                handleAPIError(error, toast, errors);
                setErrors({...errors});
            }).finally(() => {
                setLoading(false);
            });
        };

    const fetchListCorporateEntityAddressType = async () => {
        setCorporateEntityAddressTypes(corporateEntityAddressTypesData.data.queryResult)
    };

    const fetchListCountries = async () => {
            setLoading(true);
            await api.addressApi.apiVversionListCountriesGet("1").then((response) => {
                 if ('queryResult' in response.data ) {
                    setcountriesList(response.data.queryResult);
                } else if ('queryResult' in response) {
                    setcountriesList(response.queryResult);
                }                
            }).catch((error) => {
                handleAPIError(error, toast, errors);
                setErrors({...errors});
            }).finally(() => {
                setLoading(false);
            });
        };

    useEffect(() => {
        fetchAddress(addressId);
        fetchListAddressType();
        if(realAddress || legalAddress){
            fetchListCorporateEntityAddressType();
        }
    }, [addressId]);

    useEffect(() => {
        fetchCountries();
        fetchListCountries();
        if(input.idCountry) {
            fetchStates();
        }
    }, [input.idCountry]);

    const handleInputChange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        });
        setErrors({});
    };

    const handleCountryChange = (e) => {
        const inputVal = {
            ...input,
            idCountry: e.id,
            country: {...e.country, name: intl.get(e.labelKey) ,labelKey: e.labelKey},
            idStateProvince: null,
            stateProvince: {name: ''}
        };
        setInput({ ...inputVal });
        setErrors({});
    };

    const handleStateChange = (e) => {
        const inputVal = {
            ...input,
            idStateProvince: e.id,
            stateProvince: {name: e.name}
        };
        setInput({ ...inputVal });
        setErrors({});
    };

    const handleAddressTypeChange = (e) => {
        const inputVal = {
            ...input,
            idAddressType: e.id,
            addressType: e.label
        };
        setInput({ ...inputVal });
        setErrors({});
        /*
        setErrors(validate({
            ...input,
            idAddressType: e.id
        }));
        */
    };

    const validate = (input) => {
        let errors: any = {};
        if (!input.address1) {
            errors.address1 = intl.get('validate.errors.isRequired');
        }
        if (!input.idAddressType) {
            errors.idAddressType = intl.get('validate.errors.isRequired');
        }
        if (!input.idCountry) {
            errors.idCountry = intl.get('validate.errors.isRequired');
        }
        if (!input.city) {
            errors.city = intl.get('validate.errors.isRequired');
        }
        if (!input.idStateProvince) {
            errors.idStateProvince = intl.get('validate.errors.isRequired');
        }
        if (!input.zipCode) {
            errors.zipCode = intl.get('validate.errors.isRequired');
        } else if (input.zipCode < 0) {
            errors.zipCode = intl.get('validate.errors.positive');
        }
        setErrors({ ...errors });
        return Object.keys(errors).length > 0 ? errors : {};
    };

    const handleSave = async (e) => {
        e.preventDefault();
        const errors = validate(input);
        if (JSON.stringify(errors) === JSON.stringify({})) {
            if (addressId) {
                let cmd: UpdateAddressCommand = {
                    address1: input?.address1,
                    address2: input?.address2,
                    address3: input?.address3,
                    idAddressType: input?.idAddressType,
                    city: input?.city,
                    zipCode: input?.zipCode,
                    idCountry: input?.idCountry,
                    idStateProvince: input?.idStateProvince,
                    id: addressId
                }
                setLoading(true);
                const response = await api.addressApi.apiVversionAddressAddressPut("1", cmd, {}).then((response) => {
                    if(realAddress || legalAddress){
                        let addressEdited = response.data.data;
                        addressEdited.country = {name: input.country.name, labelKey:input.country.labelKey};
                        addressEdited.stateProvince = {name: input.stateProvince.name};
                        let newCorporateEntityAddress = inputCorporate.corporateEntityAddresses.find(address => address.idAddress == addressId);
                        if(newCorporateEntityAddress) {
                            newCorporateEntityAddress.address = addressEdited;
                        };
                        let existingAddresses = inputCorporate.corporateEntityAddresses.filter(address => address.idAddress != addressId);
                        setInputCorporate({
                            ...inputCorporate,
                            corporateEntityAddressTypes: [existingAddresses[0], newCorporateEntityAddress]
                        });
                        toast.success({ body: intl.get('address.toast.success.handleSave')});
                    } else {
                        let addressEdited = response.data.data;
                        addressEdited.country = {name: input.country.name, labelKey:input.country.labelKey};
                        addressEdited.stateProvince = {name: input.stateProvince.name};
                        let existingAddresses = addresses.filter(address => address.id != addressId);
                        setAddresses([...existingAddresses, addressEdited]);
                        toast.success({ body: intl.get('address.toast.success.handleSave')});
                        if (inputProvider !== null) {
                            setInputProvider({...inputProvider, address: cmd})
                        }
                    }
                    closeModal();
                }).catch((error) => {
                    handleAPIError(error, toast, errors);
                    setErrors({...errors});
                }).finally(() => {
                    setLoading(false);
                });
            } else {
                let cmd: CreateAddressCommand = {
                    address1: input?.address1,
                    address2: input?.address2,
                    address3: input?.address3,
                    idAddressType: input?.idAddressType,
                    city: input?.city,
                    zipCode: input?.zipCode,
                    idCountry: input?.idCountry,
                    idStateProvince: input?.idStateProvince
                }
                setLoading(true);
                const response = await api.addressApi.apiVversionAddressAddressPost("1", cmd, {}).then((response) => {
                    if(realAddress || legalAddress){
                        let newAddress = response.data.data;
                        newAddress.country = {name: input.country.name, labelKey: input.country.labelKey};
                        newAddress.stateProvince = {name: input.stateProvince.name};
                        let finalAddress = {
                            idAddress: newAddress.id,
                            address: {
                                address1: newAddress.address1,
                                address2: newAddress.address2,
                                address3: newAddress.address3,
                                idAddressType: newAddress.idAddressType,
                                city: newAddress.city,
                                zipCode: newAddress.zipCode,
                                idCountry: newAddress.idCountry,
                                country: newAddress.country,
                                idStateProvince: newAddress.idStateProvince,
                                stateProvince: newAddress.stateProvince,
                                corporateEntityAddresses: [],
                                id: newAddress.id,
                            },
                            idCorporateEntity: inputCorporate.id,
                        }
                        setInputCorporate({
                            ...inputCorporate,
                            corporateEntityAddresses: [...inputCorporate.corporateEntityAddresses , finalAddress]
                        });
                        toast.success({ body: intl.get('address.toast.success.handleSave.add')});
                        setInput(defaultAddressInput);
                    } else {
                        let newAddress = response.data.data;
                        newAddress.country = {name: input.country.name, labelKey: input.country.labelKey};
                        newAddress.stateProvince = {name: input.stateProvince.name};
                        setAddresses([...addresses, newAddress]);
                        const newAddressId = addressesId.includes(newAddress.id);
                        if(!newAddressId) {
                            setAddressesId([...addressesId, newAddress.id]);
                        };
                        toast.success({ body: intl.get('address.toast.success.handleSave.add')});
                        setInput(defaultAddressInput);
                        if (inputProvider !== null) { 
                            const inputVal = {
                                ...cmd,
                                id: newAddress.id,
                                country: {name: input.country},
                                stateProvince: {name: input.stateProvince}
                            };                    
                            setInputProvider({
                                ...inputProvider, 
                                address: inputVal
                            });
                        }
                    }
                    closeModal();
                }).catch((error) => {
                    handleAPIError(error, toast, errors);
                    setErrors({...errors});
                }).finally(() => {
                    setLoading(false);
                });
            };
        }
    };

    const handleCancel = () => {
        fetchAddress(addressId);
        setErrors({});
        closeModal();
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ my: 3 }}>
                <h2 style={{ textAlign: 'start', marginBottom: '1rem' }}>
                    {addressId ? intl.get('address.title.edit') : intl.get('address.title.add')}
                </h2>
                <Grid container sx={{ textAlign: 'start', mb: 3 }}>
                    <AddressForm
                        errors={errors}
                        input={input}
                        handleInputChange={handleInputChange}
                        handleStateChange={handleStateChange}
                        handleCountryChange={handleCountryChange}
                        countries={countries}
                        selectedCountry={input.country.labelKey}
                        states={states}
                        selectedState={input.stateProvince.name}
                        addressTypes={realAddress || legalAddress ? corporateEntityAddressTypes : addressTypes}
                        selectedAddressType={input.idAddressType}
                        handleAddressTypeChange={handleAddressTypeChange}
                        realAddress={realAddress}
                        legalAddress={legalAddress}
                    />
                </Grid>
                <Grid container spacing={2}>
                    <Grid xs={12} md={4}>
                        <RequiredField />
                    </Grid>
                    <Grid xs={12} md={8}>
                        <Stack direction="row" spacing={2} justifyContent="flex-end">
                            {
                                loading === true ?
                                <Spinner small={true} /> :
                                <>
                                    <Button 
                                        variant="outlined" 
                                        color="inherit" 
                                        onClick={handleCancel}
                                    >
                                        {intl.get('cancel.button')}
                                    </Button>
                                    <Button 
                                        variant="contained" 
                                        color="primary" 
                                        onClick={handleSave} 
                                        startIcon={<HiOutlineSave />}
                                        disabled={JSON.stringify(errors) !== JSON.stringify({}) || input.address1 === "" ? true : false}
                                    >
                                        {intl.get('save.button')}
                                    </Button>
                                </>
                            }
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
};

export default Address;
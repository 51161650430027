import React, { useEffect, useState } from 'react';
import { DateRange } from 'common/constants';
import { DatePicker } from '@mui/x-date-pickers';
import { Box } from '@mui/material';

type DateRangePickerDialogProps = {
  selected: DateRange,
  setRange: Function,
  className: string,
  inputName: string,
  required: boolean,
  disabled: boolean,
  gap?: number
}

export default function DateRangePickerDialog({ selected, setRange, className, inputName, required, disabled, gap = 4 }: DateRangePickerDialogProps) {
  const [dateFrom, setDateFrom] = useState<Date | null>(selected?.from ? new Date(selected.from) : null);
  const [dateTo, setDateTo] = useState<Date | null>(selected?.to ? new Date(selected.to) : null);
  useEffect(() => {
    if (selected) {
      setDateFrom(selected?.from ? new Date(selected.from) : null);
      setDateTo(selected?.to ? new Date(selected.to) : null);
    } else {
      setDateFrom(null);
      setDateTo(null);
    }
  }, [selected]);

  const handleChange = (date) => {
    if(date.from){
      setRange({from: date.from, to: dateTo});
    }
    if(date.to){
      setRange({from: dateFrom, to: date.to});
    }
  }

  const renderDatePicker = (date: Date | null, key : string) => {
    return (
      <DatePicker
        value={date}
        onChange={(d: Date | null) => {
          handleChange({[key]: d});
        }}      
        format="P"
        disabled={disabled}
        name={`${inputName}-${key}`}
        slotProps={{
          textField: {
            size: 'small',
            className: 'w-100',
            InputProps: { required },
          },
        }}
      />
    )
  };

  return (
    <Box className={`${className} w-100 d-flex`} gap={gap}>
        {renderDatePicker(dateFrom,'from')}
        {renderDatePicker(dateTo,'to')}
    </Box>
  );
}

import React, {useState} from 'react';
import intl from 'components/i18n/ReactIntlWrapper';
import { HiOutlinePencilAlt, HiOutlineTrash } from 'react-icons/hi';
import { confirmAlert } from 'react-confirm-alert';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import { useNavigate } from 'react-router';
import {useApi} from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import { ActivateSecurityRoleCommand } from 'axiosApi/models';
import '../../scss/Roles/RoleRow.scss';
import ModalUsers from './ModalUsers';

const RoleRow = ({ id, name, description, className, users, status, searchResult, setSearchResult, setLoading }) => {

    const api: Api = useApi();

    const toast = useToastMessageQueue();

    const navigate = useNavigate();

    const handleClickRow = () => {
        navigate('/role/detail/' + id);
    };

    const [isAssignedUsersOpen, setIsAssignedUsersOpen] = useState(false);

    const closeAssignedUsers = () => setIsAssignedUsersOpen(false);

    const handleAssignedUsersOpen = () => {
        setIsAssignedUsersOpen(true);
    };

    const handleDelete = () => {
        confirmAlert({
            title: intl.get('delete.modal.title'),
            message: intl.get('delete.modal.roleRow.message'),
            buttons: [
                {
                    label: intl.get('delete.modal.cancel.button'),
                    onClick: () => { }
                },
                {
                    label: intl.get('delete.modal.delete.button'),
                    onClick: async () => {
                        const response = api.employeeApi.apiVversionSecurityRoleIdDelete(id, "1", {}).then((response) => {
                            let newSecurityRoles = searchResult.queryResult.filter(role => role.id !== id);
                            setSearchResult({...searchResult, queryResult: newSecurityRoles});
                            toast.success({ body: intl.get('roleRow.toast.success.handleDelete')});
                        }).catch((error) => {
                            if(error.response) {
                                console.log("Data :" , error.response.data);
                                console.log("Status :" + error.response.status);
                            } else if(error.request) {
                                console.log(error.request);
                            } else {
                                console.log("Error", error.message);
                            }
                            toast.error({ header: intl.get('roleRow.toast.error.handleDelete'), body: error.message });
                        });
                    },
                },
            ]
        });
    };

    const handleStatus = async () => {
        setLoading(true);
        let cmd: ActivateSecurityRoleCommand = {
            active: !status,
            id: id,
        };
        const response = await api.employeeApi.apiVversionSecurityRoleActivatePut("1", cmd, {}).then((response) => {
            if (response.data.data) {
                const updateQueryResult = searchResult.queryResult.map(role => {
                    if (role.id === response.data.data.id) {
                        return response.data.data;
                    } else {
                        return role;
                    }
                });
                setSearchResult({...searchResult, queryResult: updateQueryResult});
                toast.success({ body: intl.get('roleRow.toast.success.handleStatus')});
            };
            setLoading(false);
        }).catch((error) => {
            if (error.response) {
                console.log("Data :", error.response.data);
                console.log("Status :" + error.response.status);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log("Error", error.message);
            }
            toast.error({ header: intl.get('roleRow.toast.error.handleStatus'), body: error.message });
            setLoading(false);
        });
    };

    return (
        <>
            {
                name ?
                <tr className={className} style={{cursor: "default"}}>
                    <td>{name}</td>
                    <td>{description}</td>
                    <td>
                        <a className='link' href='#' onClick={handleAssignedUsersOpen}>{users.length} {users.length > 1 ? intl.get('roleRow.users') : intl.get('roleRow.user')}</a>
                        <ModalUsers isOpen={isAssignedUsersOpen} closeModal={closeAssignedUsers} users={users} />
                    </td>
                    <td>
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" style={{cursor: "pointer"}} role="switch" id="flexSwitchCheckDefault" checked={status} onChange={handleStatus} />
                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">{`${status ? intl.get('roleRow.active') : intl.get('roleRow.inactive')}`}</label>
                        </div>
                    </td>
                    <td className='text-center'>
                        <a className='td-icon mx-1' style={{cursor: "pointer"}} onClick={handleClickRow} title={intl.get('roleRow.editButton.title')}>
                            <HiOutlinePencilAlt/>
                        </a>
                        <a className='td-icon mx-1' style={{cursor: "pointer"}} onClick={handleDelete} title={intl.get('roleRow.deleteButton.title')}>
                            <HiOutlineTrash/>
                        </a>
                    </td>
                </tr> :
                null
            }
        </>
    )
}

export default RoleRow;

import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import CountrySelect from '../CountrySelect';
import intl from 'components/i18n/ReactIntlWrapper';
import DatePickerDialog from '../DatePickerDialog';
import { uniformizeDate } from '../../common/utils';
import ErrorSummary from 'components/common/ErrorSummary';

const CalendarEventForm = ({ errors, input, handleCountryChange, selectedCountry, countries, handleInputChange, handleDate }) => {

    // Helper function to create label with error handling
    const renderLabel = (labelText, errorKey) => (
        <Typography 
            component="label" 
            sx={{ 
                display: 'block', 
                mb: 1, 
                color: errors[errorKey] ? 'error.main' : 'text.primary'
            }}
        >
            {labelText} {errors[errorKey] && (
                <Typography component="span" color="error">
                    {errors[errorKey]}
                </Typography>
            )}
        </Typography>
    );

    return (
        <Box component="form" sx={{ width: '100%' }}>
            <Box sx={{ mb: 2 }}>
                <Grid container>
                    <Grid xs={12}>
                        <ErrorSummary errors={errors} showEverything={true} />
                    </Grid>
                </Grid>
            </Box>
            
            <Box sx={{ mb: 2 }}>
                <Grid container>
                    <Grid xs={12}>
                        {renderLabel(intl.get('calendarEventForm.country'), 'idCountry')}
                        <FormControl fullWidth>
                            <CountrySelect 
                                handleCountryChange={handleCountryChange}
                                selectedCountry={selectedCountry}
                                countries={countries}
                                inputCountry={input.country.name}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
            
            <Box sx={{ mb: 2 }}>
                <Grid container>
                    <Grid xs={12}>
                        {renderLabel(intl.get('calendarEventForm.description'), 'description')}
                        <TextField
                            fullWidth
                            id="description"
                            name="description"
                            placeholder={intl.get('calendarEventForm.placeholder.description')}
                            value={input.description}
                            onChange={handleInputChange}
                            error={!!errors.description}
                            size="small"
                        />
                    </Grid>
                </Grid>
            </Box>
            
            <Box sx={{ mb: 2 }}>
                <Grid container>
                    <Grid xs={12}>
                        {renderLabel(intl.get('calendarEventForm.date'), 'date')}
                        <DatePickerDialog
                            disabled={false}
                            inputName="date"
                            className={'timesheet-datepicker'}
                            selected={input.date ? uniformizeDate(input.date) : ''}
                            setSelected={handleDate}
                            required
                        />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default CalendarEventForm;
import { ToastMessageProviderProps } from "components/ToastMessages/ToastMessageProvider";
import intl from 'components/i18n/ReactIntlWrapper';

export function handleAPIError(error: any, toast: ToastMessageProviderProps, errors: any) {
    switch (error.response?.status) {
        case 404:
            error.response.data.errors.forEach(element => {
                toast.error({ header: intl.get('error'), body: intl.get(element.errorLabelKey) });
            });
            break;
        case 406: //Fill the collection for showing the validation errors above the form
            errors.summary = [];
            error.response.data.errors.forEach(element => {
                errors.summary = [...errors.summary, intl.get(element.errorLabelKey)];
            });
            break;
        case 401:
            toast.error({ header: intl.get('error'), body: error?.message });
            break;
        case 500:
            toast.error({ header: intl.get('error'), body: error?.message });
            break;
    }
}